import React, { Component } from 'react';
import { graphql } from 'react-apollo';
import gql from 'graphql-tag';
import Alerts from '../../../constants/Alerts';
import proptypes from 'prop-types';
const CREAR_CLIENTE = gql`
mutation($cliente:ClienteInput!){
    crearCliente(cliente:$cliente){
        clicod
    }
}
`;

export class CrearCliente extends Component {
    constructor(props) {
        super(props);
        this.state = {
            nombre: '',
            email: '',
            direcciones: [],
            rfc: '',
            telefono: "",
            mensajeAlert: "",
            mostrarAlert: false
        };
    }

    submitCliente = e => {
        e.preventDefault();
        const { crearClienteMutation, seleccionarClienteNuevo, cerrar } = this.props;
        const { email, nombre, telefono } = this.state;
        if (email !== "" && nombre !== "") {
            let tempDireccion = {
                nombre: 'principal',
                telefono: telefono + ""
            };
            let tempArray = [];
            tempArray.push(tempDireccion);
            this.setState({
                direcciones: tempArray
            },
                () => {
                    const { direcciones, rfc } = this.state;
                    crearClienteMutation({
                        variables: {
                            cliente: {
                                nombre,
                                email,
                                rfc,
                                direcciones
                            },
                            token: localStorage.getItem("token")
                        }
                    }).then(result => {
                        seleccionarClienteNuevo(result.data.crearCliente.clicod);
                    }).catch((err) => {
                        this.mostrarAlert("danger", err);
                    });
                    cerrar();
                }
            );
        } else {
            this.mostrarAlert("warning", "Email y nombre son obligatorios");
        }
    }

    mostrarAlert = (tipoAlert, mensajeAlert) => {
        this.setState({ tipoAlert, mensajeAlert },
            () => {
                this.setState({ mostrarAlert: true }, () => setTimeout(() => this.setState({ mostrarAlert: false }), 5000));
            }
        );
    }

    render() {
        const { cerrar } = this.props;
        //const { tipoAlert, mensajeAlert, mostrarAlert } = this.state;
        return (
            <React.Fragment>
                <div className='c-modal__content-2'>
                    <div className='c-modal__header'>
                        <h3 className='c-modal__title'>
                            Crear Cliente
                            </h3>
                        <span className="c-modal__close" data-dismiss="modal" aria-label="Close">
                            <i onClick={cerrar} className="far fa-times"></i>
                        </span>
                    </div>
                    <div style={{ display: 'flex', justifyContent: 'center', position: "absolute", width: "100%" }}>
                        <Alerts
                            type={this.state.tipoAlert}
                            value={this.state.mensajeAlert}
                            show={this.state.mostrarAlert}
                            closeRequest={() => this.setState({ mostrarAlert: false })}
                        />
                    </div>
                    <div className='c-modal__body'>
                        <div className='c-panel_client'>
                            <div className='row'>
                                <div className='col-sm-6'>
                                    <div className="c-field u-mb-small">
                                        <label className="c-field__label" htmlFor="firstName">Nombre *</label>
                                        <input autoFocus={true} autoComplete="off" className="c-input" type="text" id="Name" placeholder="Escribir nombre completo" onChange={e => this.setState({ nombre: e.target.value })} />
                                    </div>
                                    <div className="c-field u-mb-small">
                                        <label className="c-field__label" htmlFor="email">E-mail*</label>
                                        <input autoComplete="off" className="c-input" id="email" type="email" placeholder="Ingresar email" onChange={e => this.setState({ email: e.target.value })} />
                                    </div>
                                </div>
                                <div className='col-sm-6'>
                                    <div className="c-field u-mb-small">
                                        <label className="c-field__label" htmlFor="lastName">RFC</label>
                                        <input maxLength={13} autoComplete="off" className="c-input" type="text" id="RFC" placeholder="Escribir RFC" onChange={e => this.setState({ rfc: e.target.value })} />
                                    </div>
                                    <div className="c-field u-mb-small">
                                        <label className="c-field__label" htmlFor="lastName">Telefóno</label>
                                        <input autoComplete="off" className="c-input" type="text" id="Phone" placeholder="Ingresar número de telefono" onChange={e => this.setState({ telefono: e.target.value })} />
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                    <div className='c-modal__footer-1'>
                        <p>Agregar Producto a Ticket</p>
                        <div className='c-add_client__2'>
                            <button type="submit" className="c-btn " onClick={this.submitCliente} >Registrar</button>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        )
    }
}

CrearCliente.proptypes = {
    seleccionarClienteNuevo: proptypes.func,
    cerrar: proptypes.func
}

export default graphql(CREAR_CLIENTE, { name: 'crearClienteMutation' })(CrearCliente);
/*
||===================================================================
||                          COMPONENTE:
||===================================================================
||                          DESCRIPCIÓN
||
||
||
||
||
||
||
||
||
||
||===================================================================
||                             PROPS
||
||
||
||
||
||
||
||
||
||
||===================================================================
||                             STATE
||
||
||
||
||
||
||
||
||
||
||===================================================================
||                             FUNCIONES
||
||
||
||
||
||
||
||
||
||
||===================================================================
||                             OTROS
||
||
||
||
||
||
||
||
||
||
||===================================================================
*/