import React from 'react';

const Ventas = ({ error, data, loading }) => {
    if (loading || !data.getComparacionVentasDiarias) return (
        <React.Fragment>
            <div className='row'>
                <div className='col-sm-6 col-lg-3'>
                    <div className='reg-card reg-card--store'>
                        <h3 className='reg-card__title u-hidden'>Prueba</h3>
                        <h4 className='reg-card__store'>Cargando...</h4>
                        <p className='reg-card__status'>Tienda #0</p>
                        <span className='reg-card__indicator'>
                            <i className=''></i>
                        </span>
                    </div>
                </div>
                <div className='col-sm-6 col-lg-3'>
                    <div className='reg-card'>
                        <h3 className='reg-card__title'>Ventas del día</h3>
                        <h4 className='reg-card__number'>$0.00</h4>
                        <p className='reg-card__status'>0% INCREMENTO</p>
                        <span className='reg-card__indicator'>
                            <i className={` far fa-arrow-circle-up`}></i>
                        </span>
                    </div>
                </div>
                <div className='col-sm-6 col-lg-3'>
                    <div className='reg-card'>
                        <h3 className='reg-card__title'>Número de tickets</h3>
                        <h4 className='reg-card__number'>0</h4>
                        <p className='reg-card__status'>0% DISMINUCIÓN</p>
                        <span className='reg-card__indicator'>
                            <i className={`far fa-arrow-circle-down`}></i>
                        </span>
                    </div>
                </div>
                <div className='col-sm-6 col-lg-3'>
                    <div className='reg-card'>
                        <h3 className='reg-card__title'>Piezas Vendidas</h3>
                        <h4 className='reg-card__number'>0</h4>
                        <p className='reg-card__status'>0% INCREMENTO</p>
                        <span className='reg-card__indicator'>
                            <i className={`far fa-arrow-circle-down`}></i>
                        </span>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
    if (error) return <p>Error cargando ventas diarias: {error.message}</p>
    return (
        <React.Fragment>
            <div className='row'>
                <div className='col-sm-6 col-lg-3'>
                    <div className='reg-card reg-card--store'>
                        <h3 className='reg-card__title u-hidden'>Prueba</h3>
                        <h4 className='reg-card__store'>{localStorage.getItem('nombreTienda')}</h4>
                        <p className='reg-card__status'>Tienda #{localStorage.getItem('tienda')}</p>
                        <span className='reg-card__indicator'>
                            <i className=''></i>
                        </span>
                    </div>
                </div>
                <div className='col-sm-6 col-lg-3'>
                    <div className='reg-card'>
                        <h3 className='reg-card__title'>Ventas del día</h3>
                        <h4 className='reg-card__number'>{new Intl.NumberFormat('es-MX', { style: 'currency', currency: 'MXN' }).format(data.getComparacionVentasDiarias[0].comparativo.actual.total)}</h4>
                        <p className='reg-card__status'>{data.getComparacionVentasDiarias[0].comparativo.pasado.total === 0 ? '' : `${(((data.getComparacionVentasDiarias[0].comparativo.actual.total * 100) / data.getComparacionVentasDiarias[0].comparativo.pasado.total) - 100) > 0 ? `${((((data.getComparacionVentasDiarias[0].comparativo.actual.total * 100) / data.getComparacionVentasDiarias[0].comparativo.pasado.total) - 100)).toFixed(2)}% INCREMENTO` : `${((((data.getComparacionVentasDiarias[0].comparativo.actual.total * 100) / data.getComparacionVentasDiarias[0].comparativo.pasado.total) - 100) * -1).toFixed(2)}% DISMINUCIÓN`}`}</p>
                        <span className='reg-card__indicator'>
                            <i className={` far fa-arrow-circle-${(((data.getComparacionVentasDiarias[0].comparativo.actual.total * 100) / data.getComparacionVentasDiarias[0].comparativo.pasado.total) - 100) > 0 ? 'up' : 'down'}`}></i>
                        </span>
                    </div>
                </div>
                <div className='col-sm-6 col-lg-3'>
                    <div className='reg-card'>
                        <h3 className='reg-card__title'>Número de tickets</h3>
                        <h4 className='reg-card__number'>{data.getComparacionVentasDiarias[0].comparativo.actual.tickets}</h4>
                        <p className='reg-card__status'>{data.getComparacionVentasDiarias[0].comparativo.pasado.tickets === 0 ? '' : `${(((data.getComparacionVentasDiarias[0].comparativo.actual.tickets * 100) / data.getComparacionVentasDiarias[0].comparativo.pasado.tickets) - 100) > 0 ? `${((((data.getComparacionVentasDiarias[0].comparativo.actual.tickets * 100) / data.getComparacionVentasDiarias[0].comparativo.pasado.tickets) - 100)).toFixed(2)}% INCREMENTO` : `${((((data.getComparacionVentasDiarias[0].comparativo.actual.tickets * 100) / data.getComparacionVentasDiarias[0].comparativo.pasado.tickets) - 100) * -1).toFixed(2)}% DISMINUCIÓN`}`}</p>
                        <span className='reg-card__indicator'>
                            <i className={`far fa-arrow-circle-${(((data.getComparacionVentasDiarias[0].comparativo.actual.tickets * 100) / data.getComparacionVentasDiarias[0].comparativo.pasado.tickets) - 100) > 0 ? 'up' : 'down'}`}></i>
                        </span>
                    </div>
                </div>
                <div className='col-sm-6 col-lg-3'>
                    <div className='reg-card'>
                        <h3 className='reg-card__title'>Piezas Vendidas</h3>
                        <h4 className='reg-card__number'>{data.getComparacionVentasDiarias[0].comparativo.actual.piezas}</h4>
                        <p className='reg-card__status'>{data.getComparacionVentasDiarias[0].comparativo.pasado.piezas === 0 ? '' : `${(((data.getComparacionVentasDiarias[0].comparativo.actual.piezas * 100) / data.getComparacionVentasDiarias[0].comparativo.pasado.piezas) - 100) > 0 ? `${((((data.getComparacionVentasDiarias[0].comparativo.actual.piezas * 100) / data.getComparacionVentasDiarias[0].comparativo.pasado.piezas) - 100)).toFixed(2)}% INCREMENTO` : `${((((data.getComparacionVentasDiarias[0].comparativo.actual.piezas * 100) / data.getComparacionVentasDiarias[0].comparativo.pasado.piezas) - 100) * -1).toFixed(2)}% DISMINUCIÓN`}`}</p>
                        <span className='reg-card__indicator'>
                            <i className={`far fa-arrow-circle-${(((data.getComparacionVentasDiarias[0].comparativo.actual.piezas * 100) / data.getComparacionVentasDiarias[0].comparativo.pasado.piezas) - 100) > 0 ? 'up' : 'down'}`}></i>
                        </span>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
};
export default Ventas;
/*
||===================================================================
||                        COMPONENTE: Ventas
||===================================================================
||                          DESCRIPCIÓN
|| Muestra los reportes de ventas en tarjetas para el corte de caja
||===================================================================
*/