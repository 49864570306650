import React from 'react';
import proptypes from 'prop-types';

function OptionsBusqueda({ callback, cerrar, agregar, nombre, codigo }) {
  return (
    <React.Fragment>
      <div onClick={() => { agregar(codigo, callback); cerrar(); }} className='option-search'>
        <div className='c-state_search'>
          <p align="left">{codigo}</p>
        </div>
        <div className='c-state_search'>
          <p align="right">{nombre}</p>
        </div>
      </div>
    </React.Fragment>
  );
}

OptionsBusqueda.proptypes = {
  callback: proptypes.func,
  cerrar: proptypes.func,
  agregar: proptypes.func,
  nombre: proptypes.string,
  codigo: proptypes.string
}

export default OptionsBusqueda;
/*
||===================================================================
||                          COMPONENTE:
||===================================================================
||                          DESCRIPCIÓN
||
||
||
||
||
||
||
||
||
||
||===================================================================
||                             PROPS
||
||
||
||
||
||
||
||
||
||
||===================================================================
||                             STATE
||
||
||
||
||
||
||
||
||
||
||===================================================================
||                             FUNCIONES
||
||
||
||
||
||
||
||
||
||
||===================================================================
||                             OTROS
||
||
||
||
||
||
||
||
||
||
||===================================================================
*/