import React, { Component } from 'react';
import proptypes from 'prop-types';
import { ReemplazarDatos } from '../../../constants/ReemplazarDatos';

class Tipo1 extends Component {
    getCantidad=()=> {
        const { items } = this.props.datos;
        let cantidad = 0;
        items.map(
            item => cantidad += item.cantidad
        );
        return cantidad;
    }

    quitarGuion = () => {
        const { ticket } = this.props.datos;
        let tempString = ticket.cambio;
        tempString = tempString.replace(/-/g, ' ');
        let num=parseFloat(tempString);
        return `${new Intl.NumberFormat('es-MX', { style: 'currency', currency: 'MXN' }).format(num)}`;
    }

    render() {
        const { ticket, productos, pagos } = this.props.datos;
        let {items} = this.props.datos;
        items = items.filter((item)=>item.producto!=='ZFLETE');
        return (
            <div style={{ width: '100%', backgroundColor: 'white' }}>
                {/* Header */}
                <div dangerouslySetInnerHTML={{ __html: ReemplazarDatos(localStorage.getItem('ticketHeader')) }}></div>
                {/* Body */}
                <div style={{ width: '100%', display: 'flex', justifyContent: 'space-between' }}>
                    <p>Emitido en {localStorage.getItem('ciudad')==='null'?'':localStorage.getItem('ciudad')}, {localStorage.getItem('estado')==='null'?'':localStorage.getItem('estado')}</p>
                    <p>No. Orden</p>
                </div>
                <div style={{ width: '100%', display: 'flex', justifyContent: 'space-between' }}>
                    <p>Serie {ticket.id.substring(0, 3)}</p>
                    <p>No. Folio {ticket.id.substring(3, ticket.id.length)}</p>
                </div>
                <div style={{ width: '100%', display: 'flex', justifyContent: 'space-between' }}>
                    <p>Fecha {ticket.fecha.toLocaleDateString('es-MX')}</p>
                    <p>Hora: {ticket.fecha.toLocaleTimeString('es-MX')}</p>
                </div>
                <p>LE ATENDIO:</p>
                <p>{localStorage.getItem('username')} {localStorage.getItem('nombre')}</p>
                <div style={{ width: '100%', display: 'flex' }}>
                    <div style={{ width: '25%' }}><p style={{fontSize:'12px'}}>MOD.</p></div>
                    <div style={{ width: '75%' }}><p style={{fontSize:'12px'}}>DESCRIPCIÓN</p></div>
                </div>
                <div style={{ width: '100%', display: 'flex' }}>
                    <div style={{ width: '20%', display:'flex', justifyContent:'center' }}><p style={{fontSize:'12px'}}>CANT.</p></div>
                    <div style={{ width: '20%', display:'flex', justifyContent:'center' }}><p style={{fontSize:'12px'}}>PRECIO</p></div>
                    <div style={{ width: '20%', display:'flex', justifyContent:'center' }}><p style={{fontSize:'12px'}}>DESCTO.</p></div>
                    <div style={{ width: '20%', display:'flex', justifyContent:'center' }}><p style={{fontSize:'12px'}}>IMPORTE</p></div>
                    <div style={{ width: '20%', display:'flex', justifyContent:'center' }}><p style={{fontSize:'12px'}}>TOTAL.</p></div>
                </div>
                <div style={{ width: '100%', borderTop: '2px solid black', }}></div>
                {
                    items.map(
                        (item, index) => {
                            let productoInfo;
                            productos.forEach(
                                producto => {
                                    if (item.producto === producto.icod) productoInfo = producto;
                                }
                            );
                            return (
                                <div key={index}>
                                    <div style={{ width: '100%', display: 'flex' }}>
                                        <div style={{ width: '25%' }}><p>{productoInfo.icod.substring(0,7)}</p><p>{productoInfo.icod.substring(7,productoInfo.icod.length)}</p></div>
                                        <div style={{ width: '75%' }}><p>{productoInfo.nombre}</p></div>
                                    </div>
                                    <div style={{ width: '100%', display: 'flex', marginBottom:'12px' }}>
                                        <div style={{ width: '20%', display:'flex', justifyContent:'center' }}><p>{item.cantidad}</p></div>
                                        <div style={{ width: '20%', display:'flex', justifyContent:'center' }}><p>{new Intl.NumberFormat('es-MX', { style: 'currency', currency: 'MXN' }).format(productoInfo.precio)}</p></div>
                                        <div style={{ width: '20%', display:'flex', justifyContent:'center' }}><p>{productoInfo.descuento}%</p></div>
                                        <div style={{ width: '20%', display:'flex', justifyContent:'center' }}><p>{new Intl.NumberFormat('es-MX', { style: 'currency', currency: 'MXN' }).format(item.cantidad * productoInfo.precio)}</p></div>
                                        <div style={{ width: '20%', display:'flex', justifyContent:'center' }}><p>{new Intl.NumberFormat('es-MX', { style: 'currency', currency: 'MXN' }).format(item.precio_unitario * item.cantidad)}</p></div>
                                    </div>
                                </div>
                            );
                        }
                    )
                }
                <div style={{ width: '100%', borderTop: '2px solid black', }}></div>
                <p>TOTAL DE ARTICULOS: {this.getCantidad()}</p>
                <div style={{ width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'flex-end', padding: '8px' }}>
                    <p>SUB - TOTAL {new Intl.NumberFormat('es-MX', { style: 'currency', currency: 'MXN' }).format(ticket.subtotal)}</p>
                    <p>TOTAL NOTA {new Intl.NumberFormat('es-MX', { style: 'currency', currency: 'MXN' }).format(ticket.subtotal + ticket.iva + ticket.ieps)}</p>
                </div>
                {/* EFECTIVO */}
                {
                    pagos.efectivo > 0
                        ?
                        <div style={{ display: 'flex', flexDirection:'column', alignItems: 'flex-end', padding: '4px' }}>
                            <p><b>EFECTIVO</b> {new Intl.NumberFormat('es-MX', { style: 'currency', currency: 'MXN' }).format(pagos.efectivo)}</p>
                            <p><b>CAMBIO</b> {this.quitarGuion()}</p>
                            <br />
                        </div>
                        :
                        null
                }
                {/* TARJETA */}
                <div style={{ width: '100%', display: 'flex', alignItems: 'flex-end', padding: '8px', flexDirection: 'column' }}>
                    {
                        pagos.tarjeta.map(
                            (tarjeta, index) => {
                                return (
                                    <div key={index} style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-end' }}>
                                        <p><b>TARJETA</b> {new Intl.NumberFormat('es-MX', { style: 'currency', currency: 'MXN' }).format(tarjeta.monto)}</p>
                                        <p>Terminación: {tarjeta.terminacion}</p>
                                        <p>Autorización: {tarjeta.autorizacion}</p>
                                        <p>Banco: {tarjeta.banco}</p>
                                        <br />
                                    </div>
                                );
                            }
                        )
                    }
                </div>
                {/* PUNTOS */}
                {
                    pagos.puntos.monto > 0
                        ?
                        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-end', padding: '12px' }}>
                            <p><b>PUNTOS:</b> {new Intl.NumberFormat('es-MX', { style: 'currency', currency: 'MXN' }).format(pagos.puntos.monto)}</p>
                            <p>Saldo restante: {new Intl.NumberFormat('es-MX', { style: 'currency', currency: 'MXN' }).format(pagos.puntos.saldo_anterior - pagos.puntos.monto)}</p>
                            <br />
                        </div>
                        :
                        null
                }
                {/* MONEDERO */}
                <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-end', marginLeft: '11px' }}>
                    {
                        pagos.monedero.map(
                            (monedero,index) => {
                                return (
                                    <div key={index}>
                                        <p><b>MONEDERO:</b> {new Intl.NumberFormat('es-MX', { style: 'currency', currency: 'MXN' }).format(monedero.monto)}</p>
                                        <p>Saldo restante: {new Intl.NumberFormat('es-MX', { style: 'currency', currency: 'MXN' }).format(monedero.saldo_anterior - monedero.monto)}</p>
                                        <p>Terminación: {monedero.terminacion}</p>
                                        <p>Vigencia: {monedero.caducidad}</p>
                                        <br />
                                    </div>
                                );
                            }
                        )
                    }
                </div>
                {/* Footer */}
                <div style={{ display: 'flex', justifyContent: 'space-around', padding: '12px' }}>
                    <div dangerouslySetInnerHTML={{ __html: ReemplazarDatos(localStorage.getItem('ticketFooter')) }}></div>
                </div>
            </div>

        );
    }
}

Tipo1.proptypes={
    datos: proptypes.shape({
        ticket: proptypes.shape({
            cambio: proptypes.string,
            fecha: proptypes.string,
            id: proptypes.string,
            clave_factura: proptypes.string,
            descuento: proptypes.number,
            iva: proptypes.number,
            ieps: proptypes.number,
            subtotal: proptypes.number
        }),
        pagos: proptypes.shape({
            efectivo: proptypes.number,
            monedero: proptypes.arrayOf(proptypes.shape({
                terminacion: proptypes.string,
                caducidad: proptypes.string,
                monto:proptypes.number,
                saldo_anterior: proptypes.number
            })),
            puntos: proptypes.shape({
                monto: proptypes.number,
                saldo_anterior: proptypes.number
            }),
            tarjeta: proptypes.arrayOf(proptypes.shape({
                terminacion:proptypes.string,
                autorizacion:proptypes.string,
                monto:proptypes.number
            }))
        }),
        items: proptypes.arrayOf(proptypes.shape({
            producto:proptypes.string,
            cantidad:proptypes.number,
            descuento:proptypes.number,
            precio_unitario:proptypes.number
        })),
        productos: proptypes.arrayOf(proptypes.shape({
            nombre:proptypes.string,
            icod:proptypes.string,
            precio:proptypes.number
        })),
        cliente: proptypes.string,
        agente: proptypes.string
    })
}

export default Tipo1;


/**
 *
 *
 *
 */