let CryptoJS = require('crypto-js');

let _AES = {};
_AES.encrypt = function (keyBase64, text) {
    const key = CryptoJS.enc.Base64.parse(keyBase64);
    const iv = CryptoJS.lib.WordArray.create([0, 0, 0, 0, 0, 0]);
    const result = CryptoJS.AES.encrypt(text, key, { iv }).toString();
    return result;
}

_AES.decrypt = function (keyBase64, text) {
    const key = CryptoJS.enc.Base64.parse(keyBase64);
    const iv = CryptoJS.lib.WordArray.create([0, 0, 0, 0, 0, 0]);
    const result = CryptoJS.AES.decrypt(text, key, { iv }).toString(CryptoJS.enc.Utf8);
    return result;
}

module.exports = _AES;