import React, { Component } from 'react';
import Routes from '../../constants/Routes';
import BarraNavegacion from '../BarraNavegacion';
import Header from '../Header';
import Alerts from '../../constants/Alerts';
import auth from '../../constants/Auth';
import { withRouter } from 'react-router-dom';


class App extends Component {
  constructor(props) {
    super(props)
    this.state = {
      nombre: '',
      tipoAlert: '',
      mensajeAlert: '',
      mostrarAlert: false,
      error: false,
      loading:false,
      intervalId:''
    }
  }

  componentDidMount(){
    const phone = new URLSearchParams(this.props.location.search).get('phone');
    const channel = new URLSearchParams(this.props.location.search).get('channel');
    if (phone && channel) {
      localStorage.setItem('phone',phone.replace(' ','+'));
      localStorage.setItem('channel',channel);
    }
    const intervalId = setInterval(async ()=>{
    if(!auth.estaVigenteToken() && auth.hayToken()){
        await auth.getToken();
      }
    },3000);
    this.setState({intervalId});
  }

  componentWillUnmount(){
    const {intervalId} = this.state;
    clearInterval(intervalId);
  }

  mostrarAlert = (tipoAlert, mensajeAlert) => {
    this.setState({ mensajeAlert, tipoAlert }, () => this.setState({ mostrarAlert: true }, () => setTimeout(() => this.setState({ mostrarAlert: false }), 5000)));
  }

  render() {
    const { tipoAlert, mensajeAlert, mostrarAlert } = this.state;
      return (
        <React.Fragment>
          <div style={{ display: 'flex', justifyContent: 'center', position: 'absolute', zIndex: '100', width: '100vw' }}>
            <Alerts
              type={tipoAlert}
              value={mensajeAlert}
              show={mostrarAlert}
              closeRequest={() => this.setState({ mostrarAlert: false })}
            />
          </div>
          <Header />
          <BarraNavegacion {...this.props} />
          <Routes />
        </React.Fragment>
      );
}

}
export default withRouter(App);
/*
||===================================================================
||                          COMPONENTE: App
||===================================================================
||                          DESCRIPCIÓN
|| Es el componente principal que contiene toda la aplicación y admin
|| el token, busca actualizaciones y regenera los nuevos, una vez que
|| éste venció. De igual manera verifica que el usuario se encuentre
|| logeado. Si el usuario esta logeado muestra la webapp sino el
|| componente Login.
||
|| Se logra identificar que solo renderea tres elementos que son el
|| header, la barra de navegación y las rutas. Pero la barra de nave-
|| gacion en conjunto con las rutas, muestran los diferentes compo-
|| nentes que conforman la app.
||===================================================================
||                             STATE
||  * nombre: Nombre del usuario.
||
||  * mostrarAlert: boleano que es true cuando se muestra el alert.
||
||  * mensajeAlert: string con el mensaje del alert.
||
||  * tipoAlert: string que debe contener el tipo de alert a mostrar.
||===================================================================
||                             FUNCIONES
||  * React - componentDidMount & componentDidUpdate: Si hay token en
||  localStorage revisa si es vigente, si no lo es pide uno nuevo,
||  es el metodo con el que se maneja el token de toda la aplicación.
||
||  * revisarToken: Revisa la vigencia del token, si no lo es obtiene
||  uno nuevo.
||
||  * getToken: Obtiene los datos del endpoint info.
||
||  * loged: guarda los datos necesarios en localStorage despues
||  del login.
||
||  * fetchToken: Realiza el proceso para la generación del token.
||
||  * mostrarAlert: muestra los alerts de la app.
||===================================================================
||                             OTROS
||   * decrypt - 3DES: Utilizado para desencriptar información en el
||  proceso de obtención del token.
||===================================================================
*/