import React from 'react';
import proptypes from 'prop-types';

function CategoriaContainer({categorias, removerCategoria}){
        return(
            <div style={{width:'100%',height:'68px', marginBottom:'21px', display:'flex', flexWrap:'wrap', overflowY:'auto', padding:'0'}} className='c-input'>
                {
                    categorias.map(
                        (categoria, index)=>{
                            return(
                                <div key={index} style={{borderRadius:'4px', height:'30px', backgroundImage:'linear-gradient(to top, #1991eb, #2da1f8)', border:'1px solid #1a91eb',padding:'6px', display:'flex', alignItems:'center', justifyContent:'center', margin:'3px'}}>
                                    <p style={{color:'white'}}>{categoria}</p>
                                    <i className="fal fa-times" onClick={()=>removerCategoria(index)} style={{marginLeft:'5px', color:'#c7d2e0', fontSize:'14px', cursor:'pointer'}}></i>
                                </div>
                            );
                        }
                    )
                }
            </div>
        );
}
CategoriaContainer.proptypes = {
    categorias: proptypes.arrayOf(proptypes.string),
    removerCategoria: proptypes.func,
}
export default CategoriaContainer;