import React, { Component } from 'react';
import proptypes from 'prop-types';

class FilaProducto extends Component {
    constructor(props) {
        super(props)
        this.state = {
            icod: '',
            edit: false,
            tempCantidad: 0
        }
    }

    componentDidMount() {
        const { producto, item } = this.props;
        this.setState({
            icod: producto.icod,
            tempCantidad: item.cantidad
        })
    }

    Clic = () => {
        const { borrarItem, borrandoItem } = this.props;
        const { icod } = this.state;
        if(!borrandoItem) borrarItem(icod);
    }

    changeCantidad = () => {
        const { cambiarCantidad, changeActivoEditarCantidad } = this.props;
        const { icod, tempCantidad } = this.state;
        cambiarCantidad(icod, parseInt(tempCantidad,10));
        this.setState({ edit: false }, () => changeActivoEditarCantidad(false));
    }

    changeInputCantidad = e => {
        e.preventDefault();
        if (e.key === "Tab" || e.key === "Enter") {
            this.changeCantidad()
        }
    }

    getDescuento = descuento =>{
        if(!isNaN(descuento)){
            const tempDesc= descuento/100;
            return 1-tempDesc;
        }else{
            return 1;
        }
    }

    render() {
        const { producto, changeActivoEditarCantidad, item } = this.props;
        const { edit, tempCantidad } = this.state;
        return (
            <React.Fragment>
                <div className='c-table__row '>
                    <div className='c-table__cell'>
                        <div className='c-shelf_line'>
                            <div className='img-tabla-productos'>
                                <img className="loading u-border-radius" align="center" src={producto.imagen} alt='' onError={(e) => { e.target.src = "/img/image_not_found.png" }} />
                            </div>
                        </div>
                    </div>
                    <div className='c-row_product u-color-white c-table__cell'>
                        {producto.nombre}
                        <br />
                        <div className='u-color-my-primary'>
                            {`Color:${producto.color} | Talla:${producto.talla}`}
                        </div>
                    </div>
                    <div className='c-row_product u-color-white c-table__cell'>{producto.icod}</div>
                    <div className='c-table__cell  u-color-white' onDoubleClick={() => this.setState({ edit: true })}>
                        {
                            edit
                                ?
                                <React.Fragment>
                                    <input autoFocus={true} type="text" onKeyUp={this.changeInputCantidad} onFocus={e =>{ e.currentTarget.select(); changeActivoEditarCantidad(true)}} onBlur={this.changeCantidad} value={tempCantidad} className="inputChangeCantidad "
                                        onChange=
                                        {
                                            event => {
                                                let RegExpression = /(^[0]{1}$|^[-]?[1-9]{1}\d*$)/;
                                                if (RegExpression.test(event.target.value) || event.target.value==="-" || event.target.value==="") {
                                                    this.setState({ tempCantidad: event.target.value });
                                                } else {
                                                    event.target.value = tempCantidad;
                                                }

                                            }
                                        } />
                                </React.Fragment>
                                :
                                <div  className='o-media__body u-color-white cantidad_pos '>
                                    {item.cantidad + " "}
                                    <small >{item.cantidad > 1 ? "pzas." : "pza."}</small>
                                </div>
                        }
                    </div>
                    <div className='u-color-white  c-table__cell u-color-white u-mr-xsmall '>{new Intl.NumberFormat('es-MX', { style: 'currency', currency: 'MXN' }).format(producto.precio)}</div>
                    <div className='u-color-white  c-table__cell u-mr-xsmall '>{producto.descuento === null || producto.descuento === undefined ? 0 : producto.descuento.toFixed(2)}%</div>
                    <div className='u-color-white  c-table__cell u-ml-small  '>{new Intl.NumberFormat('es-MX', { style: 'currency', currency: 'MXN' }).format(item.precio_unitario ? item.cantidad * (item.precio_unitario * this.getDescuento(item.descuento) ): item.cantidad * (producto.precio * this.getDescuento(item.descuento)))}</div>
                    <div className='close-item c-table__cell'><div className="borrarItem"><i onClick={this.Clic} className='far fa-times'></i></div></div>
                </div>
            </React.Fragment>
        )
    }
}

FilaProducto.proptypes={
    producto:proptypes.shape({
        imagen:proptypes.string,
        nombre:proptypes.string,
        icod:proptypes.string,
        precio:proptypes.number,
        talla:proptypes.string,
        color:proptypes.string,
        descuento:proptypes.number
    }),
    item:proptypes.shape({
        producto:proptypes.string,
        cantidad:proptypes.number,
        descuento:proptypes.number,
        precio_unitario:proptypes.number
    }), 
    changeActivoEditarCantidad:proptypes.func,
    cambiarCantidad:proptypes.func,
    borrarItem:proptypes.func
}

export default FilaProducto;
/*
||===================================================================
||                          COMPONENTE:
||===================================================================
||                          DESCRIPCIÓN
||
||
||
||
||
||
||
||
||
||
||===================================================================
||                             PROPS
||
||
||
||
||
||
||
||
||
||
||===================================================================
||                             STATE
||
||
||
||
||
||
||
||
||
||
||===================================================================
||                             FUNCIONES
||
||
||
||
||
||
||
||
||
||
||===================================================================
||                             OTROS
||
||
||
||
||
||
||
||
||
||
||===================================================================
*/