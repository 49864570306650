import React from 'react';
import { BeatLoader } from 'react-spinners';
import proptypes from 'prop-types';

function TablaCobro({ items, ticket, loading, loadingTable, seleccionarMonederoYDevolver, cambiar, cobrar, calcularTotalTemportal, cancelar }) {
    return (
        <div className='container-box-total border'>
            <div className='left-half'>
                <article>
                    <ul>
                        <li className='results-border-bottom'>
                            <h3 className='line-item '>Cantidad</h3>
                            {loadingTable === false ? <React.Fragment><span className='result-item'>{cantidad(items) !== 1 ? `${cantidad(items)} pzas.` : '1 pza.'}</span></React.Fragment> : <React.Fragment><span className='result-item'><BeatLoader color={'white'} /></span></React.Fragment>}
                        </li>
                        <li className='results-border-bottom'>
                            <h3 className='line-item '>Descuento</h3>
                            {loadingTable === false ? <React.Fragment><span className='result-item'>{new Intl.NumberFormat('es-MX', { style: 'currency', currency: 'MXN' }).format(ticket.descuento)}</span></React.Fragment> : <React.Fragment><span className='result-item'><BeatLoader color={'white'} /></span></React.Fragment>}
                        </li>
                        <li className='results-border-bottom'>
                            <h3 className='line-item '>Impuestos</h3>
                            {loadingTable === false ? <React.Fragment><span className='result-item'>{new Intl.NumberFormat('es-MX', { style: 'currency', currency: 'MXN' }).format(ticket.iva)}</span></React.Fragment> : <React.Fragment><span className='result-item'><BeatLoader color={'white'} /></span></React.Fragment>}
                        </li>
                        <li className='results-border-bottom'>
                            <h3 className='line-item '>Subtotal</h3>
                            {
                                !loadingTable 
                                ? 
                                <React.Fragment><span className='result-item'>{new Intl.NumberFormat('es-MX', { style: 'currency', currency: 'MXN' }).format(ticket.subtotal)}</span></React.Fragment> 
                                : 
                                <React.Fragment><span className='result-item'><BeatLoader color={'white'} /></span></React.Fragment>
                                }
                        </li>
                    </ul>
                </article>
            </div>

            <div className='right-half'>
                <article>
                    <div className='u-text-center'>
                        <p className=' c-total' >Total</p>
                            {
                                !loadingTable
                                ? 
                                <React.Fragment><p className=' c-price'>{new Intl.NumberFormat('es-MX', { style: 'currency', currency: 'MXN' }).format(ticket.total)}</p></React.Fragment> 
                                : 
                                <React.Fragment><p className=' c-price'>{new Intl.NumberFormat('es-MX', { style: 'currency', currency: 'MXN' }).format(calcularTotalTemportal())}</p></React.Fragment>
                            }
                        <div className='c-toggle '>
                            <div className='c-toggle__btn' onClick={cancelar}>
                                <label className='c-toggle__label' htmlFor='toggle1'>
                                    <input className='c-toggle__input' id='toggle1' type='radio' name='toggles-disabled' defaultChecked />
                                    CANCELAR </label>
                            </div>
                            {
                                getBoton(parseInt(ticket.total,10),items.length,loading, loadingTable, seleccionarMonederoYDevolver, cambiar, cobrar)
                            }
                        </div>
                    </div>
                </article>
            </div>
        </div>

    );
}

function getBoton(total, cantidad,loading, loadingTable, seleccionarMonederoYDevolver, cambiar, cobrar) {
    if (cantidad > 0 && total < 0 && loading!==true) {
        return (
            <React.Fragment>
                {/* DEVOLVER A MONEDERO */}
                <button id="botonDevolverPOS" className={` c-toggle__btn_blue ${cantidad <= 0 || loadingTable===true ? 'is-disabled' : 'is-active'}`} disabled={cantidad <= 0 || loading===true ? true : false} onClick={()=>seleccionarMonederoYDevolver(total)}>
                    <label className='c-toggle__label' htmlFor='toggle2'>
                        DEVOLVER
                    </label>
                </button>
            </React.Fragment>
        );
    } else if (cantidad > 0 && total === 0 && loading!==true) {
        return (
            <React.Fragment>
                {/* CAMBIO DE TALLA O COLOR */}
                <button id="botonCambiarPOS" className={` c-toggle__btn_blue ${cantidad <= 0 || loadingTable===true ? 'is-disabled' : 'is-active'}`} disabled={cantidad <= 0 || loading===true ? true : false} onClick={cambiar}>
                    <label className='c-toggle__label' htmlFor='toggle2'>
                        CAMBIAR
                    </label>
                </button>
            </React.Fragment>
        );
    } else {
        return (
            <React.Fragment>
                {/* COBRAR */}
                <button id="botonCobrarPOS" className={` c-toggle__btn  ${cantidad <= 0 || loadingTable===true ? 'is-disabled' : 'is-active'}`} disabled={cantidad <= 0 || loading===true ? true : false} onClick={cobrar}>
                    <label className='c-toggle__label' htmlFor='toggle2'>
                        COBRAR
                    </label>
                </button>
            </React.Fragment>
        );
    }
}

function cantidad(items) {
    if (items.length > 0) {
        let cantidad = 0;
        items.map(
            item => cantidad = cantidad + item.cantidad
        );
        return cantidad;
    } else {
        return 0;
    }
}

TablaCobro.proptypes={ 
    items: proptypes.arrayOf(proptypes.shape({
        producto:proptypes.string,
        cantidad:proptypes.number,
        descuento:proptypes.number,
        precio_unitario:proptypes.number
    })), 
    ticket:proptypes.shape({
        descuento: proptypes.number,
        subtotal: proptypes.number,
        iva: proptypes.number,
        total: proptypes.number
    }), 
    loading:proptypes.bool, 
    loadingTable:proptypes.bool, 
    seleccionarMonederoYDevolver:proptypes.func, 
    cambiar:proptypes.func, 
    cobrar:proptypes.func, 
    calcularTotalTemportal:proptypes.func,
    cancelar:proptypes.func
}

export default TablaCobro;
/*
seleccionarMonederoYDevolver={seleccionarMonederoYDevolver} 
cambiar={cambiar} 
calcularTotalTemportal={calcularTotalTemportal} 
loadingTable={loadingTable} 
cancelar={cancelar} 
cobrar={cobrar} 
ticket={ticket} 
items={items}
||===================================================================
||                          COMPONENTE:
||===================================================================
||                          DESCRIPCIÓN
||
||
||
||
||
||
||
||
||
||
||===================================================================
||                             PROPS
||
||
||
||
||
||
||
||
||
||
||===================================================================
||                             STATE
||
||
||
||
||
||
||
||
||
||
||===================================================================
||                             FUNCIONES
||
||
||
||
||
||
||
||
||
||
||===================================================================
||                             OTROS
||
||
||
||
||
||
||
||
||
||
||===================================================================
*/