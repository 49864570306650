import React, { useState, useEffect } from 'react';
import gql from 'graphql-tag';
import Pagos from '../Pagos';

const CREAR_PEDIDO_MUTATION = gql`
mutation($cliente:String!, $agente:String!, $tienda:String!, $items:[ItemInput], $pagos:[PagoInput]!, $tienda_entrega:String!, $descuento:Float!, $almacen_surte:String!, $momento_pago:String!){
    crearPedido(cliente:$cliente,agente:$agente, tienda:$tienda,items:$items,pagos:$pagos,tienda_entrega:$tienda_entrega, descuento:$descuento,almacen_surte:$almacen_surte,momento_pago:$momento_pago){
      id
    }
  }
`;

const GUARDAR_PEDIDO_MUTATION = gql`
mutation($cliente:String!, $agente:String!, $tienda:String!, $items:[ItemInput], $pagos:[PagoInput]!, $tienda_entrega:String!, $descuento:Float!, $almacen_surte:String!, $momento_pago:String!, $direccion_entrega:Int, $tipo_entrega:String!, $transporte:String, $fecha:String!, $total:String!){
    guardarPedido(cliente:$cliente,agente:$agente, tienda:$tienda,items:$items,pagos:$pagos,tienda_entrega:$tienda_entrega, descuento:$descuento,almacen_surte:$almacen_surte,momento_pago:$momento_pago, direccion_entrega:$direccion_entrega, tipo_entrega:$tipo_entrega, transporte:$transporte, fecha:$fecha, total:$total){
      id
    }
  }
`;

export const CrearPedido = ({ tiendaEntrega: tienda_entrega, cliente, agente, items, descuento, client, monto, domicilio, transporte, costoTransporte, subtotal, products, impuestos, setLoadingImg, setImage, seleccion, cerrarPedido, cerrarModal, handleAlert, telefono, location }) => {

    const [momento_pago, setMomentoPago] = useState(seleccion === 'tienda' ? 'tienda' : 'prepago');


    useEffect(() => {
        const getImage = async () => {
            const rfc = localStorage.getItem('rfc');
            const productos = items.map(item => {
                const { cantidad, precio_unitario, descuento, producto } = item;
                let nombre = '';
                let imagen = ''
                const product = products.find((p) => p.icod === producto);
                if (product) {
                    nombre = product.nombre;
                    imagen = product.imagen;
                }
                return {
                    cantidad,
                    descuento,
                    precio: precio_unitario,
                    nombre,
                    imagen
                }
            });
            const phone = localStorage.getItem('phone');
            const channel = localStorage.getItem('channel');
            const body = JSON.stringify({
                total: monto.toFixed(2),
                subtotal,
                descuento,
                empresa: localStorage.getItem('razonSocial'),
                productos,
                impuestos,
                envio: costoTransporte,
                telefono: phone,
                canal: channel
            });
            const res = await fetch(`https://proscai-pos-library.herokuapp.com/webhook/cotizacion/${rfc}`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body
            });
            if (res.ok) {
                const buffer = await res.arrayBuffer();
                const blob = new Blob([buffer], { type: 'image/png' });
                const url = URL.createObjectURL(blob);
                setImage(url);
                setLoadingImg(false);
                if(!localStorage.getItem('phone')){
                    await navigator.clipboard.write([new ClipboardItem({ 'image/png': blob })])
                    handleAlert('info', 'Imágen copiada al portapapeles');
                }
                    handleAlert('info', 'Cotización enviada al whatsapp');
                
            }
        };
        getImage();
    }, [])

    const submit = async () => {
        const variables = {
            tienda: localStorage.getItem('tienda'),
            tienda_entrega,
            almacen_surte: tienda_entrega,
            pagos: [],
            cliente,
            agente,
            items,
            descuento,
            momento_pago,
            tipo_entrega: 'entrega_tienda'
        };
        if (momento_pago === 'tienda') {
            try {
                const result = await client.mutate({
                    mutation: CREAR_PEDIDO_MUTATION,
                    variables
                });
                handleAlert('info', 'Pedido ' + result.data.crearPedido.id + ' creado');
                cerrarPedido();
                cerrarModal();
            } catch (error) {
                handleAlert('danger', 'Error creando pedido ' + error);
            }
        } else {
            variables.momento_pago = 'pre_pagado';
            variables.fecha = `${new Date().getTime()}`;
            variables.total = `${monto.toFixed(2)}`;
            if (seleccion !== 'tienda') {
                variables.direccion_entrega = domicilio;
                variables.tipo_entrega = 'domicilio';
                variables.transporte = transporte;
                variables.items.push({
                    producto: 'ZFLETE',
                    cantidad: 1,
                    precio_unitario: costoTransporte,
                    descuento: 0,
                })
            }
            try {
                const result = await client.mutate({
                    mutation: GUARDAR_PEDIDO_MUTATION,
                    variables
                });
                return result.data.guardarPedido.id;
            } catch (error) {
                handleAlert('danger', 'Error creando pedido ' + error);
            }
        }
    }

    return (
        <div>
            <Pagos callbackPestana={setMomentoPago} client={client} monto={monto} submit={submit} seleccion={seleccion} cerrarModal={cerrarModal} cerrarPedido={cerrarPedido} handleAlert={handleAlert} telefono={telefono} location={location} />
        </div>
    )
}

