import React from 'react';
import proptypes from 'prop-types';

function FilaAgentes ({ nombreAgente, idAgente, total, piezas, tickets, piezasTicket }){
        return (
            <div className='row-tabla-ventas-agentes'>
                <div className='celda-tabla-ventas-agentes fl-ai_c'>
                    <div className='circulo-tabla-agentes'>{nombreAgente.length>1?nombreAgente[0]+nombreAgente[1]:nombreAgente[0]}</div>
                    <div className='contenedor-info-agentes'>
                        <p className='c-agent_name'>{nombreAgente}</p>
                        <p style={{ color: '#9ea0a5', fontSize: '12px' }}>{idAgente}</p>
                    </div>
                </div>
                <div className='celda-tabla-ventas-agentes fl-ai_c'>
                    <p style={{ color: 'white', fontSize: '14px', }}>{new Intl.NumberFormat('es-MX', { style: 'currency', currency: 'MXN' }).format(total)}</p>
                </div>
                <div className='celda-tabla-ventas-agentes fl-ai_c'>
                    <p style={{ color: 'white', fontSize: '14px' }}>{piezas}</p>
                </div>
                <div className='celda-tabla-ventas-agentes fl-ai_c'>
                    <p style={{ color: 'white', fontSize: '14px' }}>{tickets}</p>
                </div>
                <div className='celda-tabla-ventas-agentes fl-ai_c'>
                    <p style={{ color: 'white', fontSize: '14px' }}>{(piezasTicket).toFixed(2)}</p>
                </div>
            </div>
        );
}
FilaAgentes.proptypes={ 
    nombreAgente:proptypes.string, 
    idAgente:proptypes.string, 
    total:proptypes.number, 
    piezas:proptypes.number, 
    tickets:proptypes.number, 
    piezasTicket:proptypes.number
}
export default FilaAgentes;