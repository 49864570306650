import React, { useState, useEffect } from 'react';
import { useApolloClient } from 'react-apollo';
import gql from 'graphql-tag';
import auth from '../../constants/Auth';
import util from '../../constants/Util';
const FORMAS_PAGO = '1,2,3,4,5,6,7,8,9,10,11,12,13'.split(',');

const FIREDATA_QUERY = gql`
query($rfc: String!) {
    getFirerbaseData(rfc: $rfc) {
      tokenMercadoPago
      formaPagoMercadoPago
      templateProducto
      templateTicket
      logo
      pagoTienda
    }
}
`;

const LOGIN_MUTATION = gql`
mutation($usuario: String!, $contrasena: String!) {
  loginAdmin(usuario: $usuario, contrasena: $contrasena) {
      ok
  }
}
`;

const SUBMIT_MUTATION = gql`
mutation($rfc:String!, $tokenMercadoPago:String!, $formaPagoMercadoPago:String!, $templateProducto:String!, $templateTicket:String!, $logo:String!, $pagoTienda:Boolean!){
    subirDatosFirebase(rfc:$rfc,tokenMercadoPago:$tokenMercadoPago, formaPagoMercadoPago:$formaPagoMercadoPago, templateProducto:$templateProducto, templateTicket:$templateTicket, logo:$logo, pagoTienda:$pagoTienda){
      ok
    }
}
`;

export function Admin() {
    const client = useApolloClient();
    const [tokenMercado, setTokenMercado] = useState('');
    const [formaPagoMercado, setFormaPagoMercado] = useState('');
    const [plantillaTicket, setPlantillaTicket] = useState('');
    const [plantillaProducto, setPlantillaProducto] = useState('');
    const [logo, setLogo] = useState('');
    const [pagoTienda, setPagoTienda] = useState(true);
    const [logeado, setLogeado] = useState(false);
    const [usuario, setUsuario] = useState('');
    const [pass, setPass] = useState('');
    const [disabled, setDisabled] = useState(true);


    useEffect(() => {
        const fetchData = async () => {
            if (!auth.hayToken()) {
                await auth.fetchConnString();
                setDisabled(false);
            }
            await util.wait(5000);
            Object.keys(client.cache.data.data).forEach(key =>
                key.includes('ROOT_QUERY.getFirerbaseData') && client.cache.data.delete(key)
            );
            const res = await client.query({
                query: FIREDATA_QUERY,
                variables: {
                    rfc: localStorage.getItem('rfc')
                }
            });
            setTokenMercado(res.data.getFirerbaseData.tokenMercadoPago);
            setFormaPagoMercado(res.data.getFirerbaseData.formaPagoMercadoPago);
            setPlantillaTicket(res.data.getFirerbaseData.templateTicket);
            setPlantillaProducto(res.data.getFirerbaseData.templateProducto);
            setLogo(res.data.getFirerbaseData.logo);
            setPagoTienda(res.data.getFirerbaseData.pagoTienda);
        };
        fetchData();
    }, []);

    const submit = async () => {
        const res = await client.mutate({
            mutation: SUBMIT_MUTATION,
            variables: {
                rfc: localStorage.getItem('rfc'),
                tokenMercadoPago: tokenMercado,
                formaPagoMercadoPago: formaPagoMercado,
                templateProducto: plantillaProducto,
                templateTicket: plantillaTicket,
                logo,
                pagoTienda
            }
        });
        if (res.data.subirDatosFirebase.ok) {
            alert('Listo.');
        }
    }

    const login = async () => {
        try {
            const res = await client.mutate({
                mutation: LOGIN_MUTATION,
                variables: {
                    usuario,
                    contrasena: pass
                }
            });
            setLogeado(res.data.loginAdmin.ok);
        } catch (exception) {
            alert(exception.toString().substring(0, 73).substring(22, 73));
        }
    }

    if (!logeado) {
        return (
            <div className="admin-container">
                <div className="admin-input-container">
                    <label className="label-input">Usuario</label>
                    <input className="c-input" type="text" value={usuario} onChange={({ target }) => setUsuario(target.value)} />
                </div>
                <div className="admin-input-container">
                    <label className="label-input">Contraseña</label>
                    <input className="c-input" type="password" value={pass} onChange={({ target }) => setPass(target.value)} />
                </div>
                <button className="c-btn c-btn--info" onClick={login} disabled={disabled}>Iniciar Sesión</button>
            </div>
        );
    }

    return (
        <div className="admin-container">
            <div className="admin-input-container">
                <label className="label-input">Token Mercado Pago</label>
                <input className="c-input" type="text" value={tokenMercado} onChange={({ target }) => setTokenMercado(target.value)} />
            </div>
            <div className="admin-input-container">
                <label className="label-input">Forma de Pago Mercado Pago</label>
                <select className="c-input" value={formaPagoMercado} onChange={({ target }) => setFormaPagoMercado(target.value)}>
                    <option value=''>Selecciona Forma de Pago para MercadoPago</option>
                    {
                        FORMAS_PAGO.map((formaPago) => <option key={formaPago} value={formaPago}>{formaPago}</option>)
                    }
                </select>
            </div>
            <div className="admin-input-container">
                <label className="label-input">Plantilla Cotización General</label>
                <textarea className="c-input" value={plantillaTicket} onChange={({ target }) => setPlantillaTicket(target.value)} />
            </div>
            <div className="admin-input-container">
                <label className="label-input">Plantilla Cotización Producto</label>
                <textarea className="c-input" value={plantillaProducto} onChange={({ target }) => setPlantillaProducto(target.value)} />
            </div>
            <div className="admin-input-container">
                <label className="label-input">Liga para logo en cotización</label>
                <input className="c-input" type="text" value={logo} onChange={({ target }) => setLogo(target.value)} />
            </div>
            <div className="admin-checkbox-container">
                <label className="label-input">Activar Pagar en Tienda</label>
                <input className="admin-checkbox" type="checkbox" checked={pagoTienda} onChange={({ target }) => setPagoTienda(target.checked)} />
            </div>
            <button className="c-btn c-btn--info" onClick={submit}>Actualizar Información</button>
        </div>
    );
}