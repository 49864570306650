import React from 'react';
import { NavLink } from 'react-router-dom';

function BarraNavegacion({ location }) {
        return (
            <div className='c-sidebar'  style={{display:`${location.pathname==='/login'?'none':''}`}}>
                <ul className='c-sidebar__list'>
                    <li className='c-sidebar__item'> <NavLink activeClassName='is-active' className='c-sidebar__link' to='/pos'> <span className='c-sidebar__icon'> <i className='fas fa-shopping-cart'></i> </span>POS </NavLink> </li>
                    <li className='c-sidebar__item'> <NavLink activeClassName='is-active' className='c-sidebar__link' to='/tickets'> <span className='c-sidebar__icon'> <i className='fas fa-th-list'></i> </span>Tickets </NavLink> </li>
                    <li className='c-sidebar__item'> <NavLink activeClassName='is-active' className='c-sidebar__link' to='/corte'> <span className='c-sidebar__icon'> <i className='fas fa-cut'></i> </span>Corte de Caja </NavLink> </li>
                    <li className='c-sidebar__item'> <NavLink activeClassName='is-active' className='c-sidebar__link' to='/ventas'> <span className='c-sidebar__icon'> <i className='fas fa-chart-bar'></i> </span>Ventas del Día </NavLink> </li>
                    <li className='c-sidebar__item'> <NavLink activeClassName='is-active' className='c-sidebar__link' to='/inventario'> <span className='c-sidebar__icon'> <i className="fas fa-warehouse-alt"></i> </span>Inventario</NavLink> </li>
                    <li className='c-sidebar__item'> <NavLink activeClassName='is-active' className='c-sidebar__link' to='/pedidos'> <span className='c-sidebar__icon'> <i className="fas fa-ballot-check"></i> </span>Pedidos</NavLink> </li>
                    {/*<li className='c-sidebar__item'> <NavLink activeClassName='is-active' className='c-sidebar__link' to='/test'> <span className='c-sidebar__icon'> <i className="fas fa-code"></i> </span>Pruebas</NavLink> </li>*/}
                </ul>
            </div>
        );
}

export default BarraNavegacion;
/*
||===================================================================
||                  COMPONENTE: Barra de navegación
||===================================================================
||                          DESCRIPCIÓN
|| Es el encargado de administrar las ligas para dirigir a los
|| diferentes componente de la aplicación. En vez  de utilizar la 
|| etiqueta a, se utiliza Navlink que pertenece a react router.
|| Se utiliza navlink y no link porque éste nos permite identificar
|| cual es el link utilizado actualmente y así darle estilos.
||===================================================================
*/