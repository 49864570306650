import React, { Component } from 'react';
import { ReemplazarDatos } from '../../../constants/ReemplazarDatos';
import proptypes from 'prop-types';

class Tipo2 extends Component {

    getTotalMonedero = () => {
        const { pagos } = this.props.datos;
        if (pagos) {
            const { monedero } = pagos;
            let total = 0;
            monedero.forEach(
                pago => {
                    total += pago.monto
                }
            );
            return total;
        }
    }
    getTotalTarjeta = () => {
        const { pagos } = this.props.datos;
        if (pagos) {
            const { tarjeta } = pagos;
            let total = 0;
            tarjeta.forEach(
                pago => {
                    total += pago.monto
                }
            );
            return total;
        }
    }
    render() {
        const { ticket, pagos, items, productos, cliente, agente } = this.props.datos;
        return (
            <div style={{ width: '100%', backgroundColor: 'white' }}>
                {/* Header */}
                <div dangerouslySetInnerHTML={{ __html: ReemplazarDatos(localStorage.getItem('ticketHeader'))}}></div>
                <div style={{ width: '100%' }}>
                    <div style={{ width: '100%', marginTop: '12px' }}>
                        <div style={{ width: '100%', display: 'flex', justifyContent: 'space-between' }}>
                            <p style={{fontSize:'14px', fontWeight:'bold', color:'rgba(0, 0, 0, 0.87)'}}>Cliente</p>
                            <p style={{fontSize:'14px', fontWeight:'600', color:'rgba(0, 0, 0, 0.87)'}}>{cliente}</p>
                        </div>
                        <div style={{ width: '100%', display: 'flex', justifyContent: 'space-between', marginBottom: '12px' }}>
                            <p style={{fontSize:'14px', fontWeight:'bold', color:'rgba(0, 0, 0, 0.87)'}}>Agente</p>
                            <p style={{fontSize:'14px', fontWeight:'600', color:'rgba(0, 0, 0, 0.87)'}}>{agente}</p>
                        </div>
                        <div style={{ width: '100%', borderTop: '1px dashed rgba(0, 0, 0, 0.12)', }}></div>
                        <div style={{ width: '100%', display: 'flex', justifyContent: 'center', marginTop: '12px', marginBottom: '12px' }}>
                            <p style={{fontSize:'16px', fontWeight:'bold'}}>{ticket.id}</p>
                        </div>
                        <div style={{ width: '100%', borderTop: '1px dashed rgba(0, 0, 0, 0.12)', }}></div>
                        {
                            items.map(
                                (item, index) => {
                                    let productoInfo;
                                    productos.forEach(
                                        producto => {
                                            if (item.producto === producto.icod) productoInfo = producto;
                                        }
                                    );
                                    return (
                                        <div key={index} style={{ width: '100%', marginTop: '12px' }}>
                                            <div style={{ width: '100%', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                                <p style={{fontSize:'13px', fontWeight:'bold'}}>{productoInfo.nombre}</p>
                                                <p style={{fontSize:'14px', fontWeight:'bold', color:'#46545c'}}>{new Intl.NumberFormat('es-MX', { style: 'currency', currency: 'MXN' }).format(productoInfo.precio)}</p>
                                            </div>
                                            <div style={{ width: '100%', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                                <p style={{fontSize:'12px', fontWeight:'600', color:'rgba(0, 0, 0, 0.54)'}}>{productoInfo.icod}</p>
                                                <p style={{fontSize:'12px', fontWeight:'600', color:'rgba(0, 0, 0, 0.54)'}}>{'x' + item.cantidad}</p>
                                            </div>
                                            <div style={{ width: '100%', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                                <p style={{fontSize:'12px', fontWeight:'600', color:'rgba(0, 0, 0, 0.54)'}}>Descuento</p>
                                                <p style={{fontSize:'12px', fontWeight:'600', color:'rgba(0, 0, 0, 0.54)'}}>{`${item.descuento ? item.descuento : 0}%`}</p>
                                            </div>
                                            <div style={{ width: '100%', display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '12px' }}>
                                                <p style={{fontSize:'12px', fontWeight:'bold'}}>Total</p>
                                                <p style={{fontSize:'12px', fontWeight:'bold'}}>{new Intl.NumberFormat('es-MX', { style: 'currency', currency: 'MXN' }).format(item.cantidad * productoInfo.precio)}</p>
                                            </div>
                                            <div style={{ width: '100%', borderTop: '1px dashed rgba(0, 0, 0, 0.12)', }}></div>
                                        </div>
                                    );
                                }
                            )
                        }
                    </div>
                    <div style={{ width: '100%' }}>
                        <div style={{ width: '50%', display: 'flex', justifyContent: 'space-between', marginLeft: '50%', marginTop: '12px' }}>
                            <p style={{fontSize:'14px', fontWeight:'600', color:'#4a4a4a'}}>Subtotal</p>
                            <p style={{fontSize:'12px', fontWeight:'600'}}>{new Intl.NumberFormat('es-MX', { style: 'currency', currency: 'MXN' }).format(ticket.subtotal)}</p>
                        </div>
                        <div style={{ width: '50%', display: 'flex', justifyContent: 'space-between', marginLeft: '50%', marginTop: '12px', marginBottom: '12px' }}>
                            <p style={{fontSize:'14px', fontWeight:'600', color:'#4a4a4a'}}>Impuestos</p>
                            <p style={{fontSize:'12px', fontWeight:'600'}}> {new Intl.NumberFormat('es-MX', { style: 'currency', currency: 'MXN' }).format(ticket.iva + ticket.ieps)}</p>
                        </div>
                        <div style={{ width: '50%', display: 'flex', justifyContent: 'space-between', marginLeft: '50%', marginBottom: '12px' }}>
                            <p style={{fontSize:'14px', fontWeight:'600', color:'#4a4a4a'}}>Total</p>
                            <p style={{fontSize:'12px', fontWeight:'600'}}>{new Intl.NumberFormat('es-MX', { style: 'currency', currency: 'MXN' }).format(ticket.subtotal + ticket.iva + ticket.ieps)}</p>
                        </div>
                    </div>
                    <div style={{ width: '100%', borderTop: '2px solid black' }}></div>
                    <div style={{ width: '100%', display: 'flex', justifyContent: 'space-between', marginBottom:'6px', marginTop:'6px' }}>
                        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                            <p style={{fontSize:'14px', fontWeight:'600', color:'#4a4a4a'}}>Efectivo</p>
                            <p style={{fontSize:'14px', fontWeight:'600', color:'#4a4a4a'}}>{new Intl.NumberFormat('es-MX', { style: 'currency', currency: 'MXN' }).format(pagos.efectivo)}</p>
                        </div>
                        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                            <p style={{fontSize:'14px', fontWeight:'600', color:'#4a4a4a'}}>Tarjeta</p>
                            <p style={{fontSize:'14px', fontWeight:'600', color:'#4a4a4a'}}>{new Intl.NumberFormat('es-MX', { style: 'currency', currency: 'MXN' }).format(this.getTotalTarjeta())}</p>
                        </div>
                        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                            <p style={{fontSize:'14px', fontWeight:'600', color:'#4a4a4a'}}>Puntos</p>
                            <p style={{fontSize:'14px', fontWeight:'600', color:'#4a4a4a'}}>{pagos.puntos.monto!==1?`${pagos.puntos.monto} ptos.`:`${pagos.puntos.monto} pto.`}</p>
                        </div>
                        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                            <p style={{fontSize:'14px', fontWeight:'600', color:'#4a4a4a'}}>Monedero</p>
                            <p style={{fontSize:'14px', fontWeight:'600', color:'#4a4a4a'}}>{new Intl.NumberFormat('es-MX', { style: 'currency', currency: 'MXN' }).format(this.getTotalMonedero())}</p>
                        </div>
                    </div>
                    <div style={{ width: '100%', borderTop: '2px solid black', }}></div>
                    <div style={{ width: '100%', display: 'flex', justifyContent: 'center', flexDirection: 'column', alignItems: 'center', marginTop:'12px', marginBottom:'12px' }}>
                        <p>Clave de Facturación</p>
                        <p><b>{ticket.clave_factura}</b></p>
                    </div>
                </div>
                {/* Footer */}
                <div dangerouslySetInnerHTML={{ __html: ReemplazarDatos(localStorage.getItem('ticketFooter')) }}></div>
            </div>
        );
    }
}

Tipo2.proptypes={
    datos: proptypes.shape({
        ticket: proptypes.shape({
            cambio: proptypes.string,
            fecha: proptypes.string,
            id: proptypes.string,
            clave_factura: proptypes.string,
            descuento: proptypes.number,
            iva: proptypes.number,
            ieps: proptypes.number,
            subtotal: proptypes.number
        }),
        pagos: proptypes.shape({
            efectivo: proptypes.number,
            monedero: proptypes.arrayOf(proptypes.shape({
                terminacion: proptypes.string,
                caducidad: proptypes.string,
                monto:proptypes.number,
                saldo_anterior: proptypes.number
            })),
            puntos: proptypes.shape({
                monto: proptypes.number,
                saldo_anterior: proptypes.number
            }),
            tarjeta: proptypes.arrayOf(proptypes.shape({
                terminacion:proptypes.string,
                autorizacion:proptypes.string,
                monto:proptypes.number
            }))
        }),
        items: proptypes.arrayOf(proptypes.shape({
            producto:proptypes.string,
            cantidad:proptypes.number,
            descuento:proptypes.number,
            precio_unitario:proptypes.number
        })),
        productos: proptypes.arrayOf(proptypes.shape({
            nombre:proptypes.string,
            icod:proptypes.string,
            precio:proptypes.number
        })),
        cliente: proptypes.string,
        agente: proptypes.string
    })
}

export default Tipo2;