import React, { Component, Fragment } from 'react';
import { graphql } from 'react-apollo';
import {flowRight as compose} from 'lodash';
import gql from 'graphql-tag';
import { BarLoader } from 'react-spinners';
import Alerts from '../../constants/Alerts';
import { Mixpanel } from '../../constants/Mixpanel';
import auth from '../../constants/Auth';

const LOGIN_MUTATION = gql`
mutation LoginMutation($usuario: String!, $contrasena: String!) {
  login(usuario: $usuario, contrasena: $contrasena) {
    nombre
    username
    tienda {
      id
      nombre
      direccion{
      num_ext
      calle
      colonia
      delegacion
      ciudad
      estado
      telefono
    }
    }
  }
}
`;

const GET_TICKET = gql`
query{
  getEstructuraTicket{
    header,
    footer,
    tipo
  }
}
`;

const GET_PAGOS = gql`
query{
  getPagos{
    tipo_nombre,
    tipo,
    id,
    descripcion
  }
}
`;

const GET_EMPRESA = gql`
query{
  getEmpresa{
    razonSocial
  }
}
`;

class Login extends Component {
  constructor(props) {
    super(props);
    this.state = {
      usuario: '',
      contrasena: '',
      loading: false,
      mostrarAlert: false,
      mensajeAlert: '',
      tipoAlert: '',
      existeToken: false
    };
  }

  componentDidMount() {
    let intervalId = setInterval(this.verificarToken, 1000);
    this.setState({ intervalId })
  }

  verificarToken = () => {
    if (this.state.existeToken === false) {
      if (auth.hayToken()) {
        this.setState({
          existeToken: true
        });
      }
    }
  }

  componentWillUnmount() {
    // use intervalId from the state to clear the interval
    clearInterval(this.state.intervalId);
  }

  loged = (tienda, nombre, username, nombreTienda, direccion) => {
    localStorage.setItem('tienda', tienda);
    localStorage.setItem('nombre', nombre);
    localStorage.setItem('username', username);
    localStorage.setItem('nombreTienda', nombreTienda);
    localStorage.setItem('num_ext', direccion.num_ext);
    localStorage.setItem('calle', direccion.calle);
    localStorage.setItem('colonia', direccion.colonia);
    localStorage.setItem('delegacion', direccion.delegacion);
    localStorage.setItem('ciudad', direccion.ciudad);
    localStorage.setItem('estado', direccion.estado);
    localStorage.setItem('telefono', direccion.telefono);
    localStorage.setItem('defaultAgente', '01');
  }

  _confirm = () => {
    if (auth.hayToken()) {
      this.setState({ loading: true }, () => {
        const { usuario, contrasena } = this.state;
        const { loginMutation } = this.props;
        loginMutation({
          variables: {
            usuario,
            contrasena
          }
        })
          .then(result => {
            this.getTicket();
            this.getPagos();
            this.getEmpresa();
            const { nombre, tienda, username } = result.data.login;
            this.loged(tienda.id, nombre, username, tienda.nombre, tienda.direccion);
            auth.login();
            Mixpanel.login(localStorage.getItem('rfc'));
            this.props.history.push('/pos')
          })
          .catch(error => {
            this.setState({ loading: false });
            if (error.message.includes('401')) {
              this.setState(
                {
                  tipoAlert: 'warning',
                  mensajeAlert: 'Usuario o contraseña incorrecto'
                },
                () => this.setState({ mostrarAlert: true },
                  () => {
                    setTimeout(() => {
                      this.setState({ mostrarAlert: false });
                    }, 5000);
                  }
                )
              );
            } else {
              this.setState(
                { tipoAlert: 'danger', mensajeAlert: 'Error al intentar inciar sesión: ' + error.message },
                () => this.setState({ mostrarAlert: true },
                  () => {
                    setTimeout(() => {
                      this.setState({ mostrarAlert: false })
                    }, 5000);
                  }
                )
              );
            }
          });
      });
    }
  }

  getTicket = () => {
    this.props.client.query({
      query: GET_TICKET
    }).then(result => {
      const { header, tipo, footer } = result.data.getEstructuraTicket;
      localStorage.setItem('ticketHeader', header);
      localStorage.setItem('ticketTipo', tipo);
      localStorage.setItem('ticketFooter', footer);
    }).catch(err => this.mostrarAlert('danger', ' Error obteniendo la plantilla del ticket: ' + err.message));

  }

  getEmpresa = () => {
    this.props.client.query({
      query: GET_EMPRESA
    }).then(result => {
      const { razonSocial } = result.data.getEmpresa;
      localStorage.setItem('razonSocial', razonSocial);
    }).catch(err => this.mostrarAlert('danger', ' Error obteniendo la plantilla del ticket: ' + err.message));

  }

  getPagos = () => {
    this.props.client.query({
      query: GET_PAGOS
    }).then(result => {
      const pagos = result.data.getPagos;
      let efectivo = null;
      let tarjeta = [];
      let puntos = null;
      let otros = [];
      let monedero = null;
      pagos.forEach(
        pago => {
          const { tipo, id, descripcion } = pago;
          if (pago.tipo_nombre.includes('Efectivo')) {
            efectivo = JSON.stringify({ tipo, id })
          } else if (pago.tipo_nombre.includes('Puntos')) {
            puntos = JSON.stringify({ tipo, id })
          } else if (pago.tipo_nombre.includes('Monedero')) {
            monedero = JSON.stringify({ tipo, id })
          } else if (pago.tipo_nombre.includes('Tarjeta')) {
            tarjeta.push({ tipo, id, descripcion });
          } else {
            otros.push({ tipo, id, descripcion });
          }
        }
      );
      localStorage.setItem('efectivo', efectivo);
      localStorage.setItem('tarjeta', JSON.stringify(tarjeta));
      localStorage.setItem('puntos', puntos);
      localStorage.setItem('otros', JSON.stringify(otros));
      localStorage.setItem('monedero', monedero);
    }).catch(err => this.mostrarAlert('danger', ' Error obteniendo la plantilla del ticket: ' + err.message));

  }

  mostrarAlert = (tipoAlert, mensajeAlert) => {
    this.setState({ mensajeAlert, tipoAlert }, () => this.setState({ mostrarAlert: true }, () => setTimeout(() => this.setState({ mostrarAlert: false }), 5000)));
  }

  render() {
    return (
      <Fragment>
        <BarLoader
          color='#36D7B7'
          loading={this.state.loading}
          heightUnit='px'
          height={4}
          widthUnit='%'
          width={100}
        />
        <div className='o-page o-page--center'>
          <div className='o-page__card'>
            <div className='c-card u-mb-xsmall'>
              <header className='c-card__login u-pt-medium'>
                <a className='c-card__icon' href=''>
                  <img src='/img/logo.png' alt='PROSCAI POS' />
                </a>
              </header>
              <form
                className='c-card__body'
                onSubmit={e => {
                  e.preventDefault();
                  this._confirm();
                }}
              >
                <div className='c-field u-mb-small'>
                  <label className='c-field__label' htmlFor='input1'>
                    Nombre de usuario{' '}
                  </label>
                  <input
                    autoFocus={true}
                    autoComplete='off'
                    className='c-input-login'
                    type='text'
                    id='input1'
                    placeholder='Usuario'
                    onChange={e => this.setState({ usuario: e.target.value })}
                  />
                </div>
                <div className='c-field u-mb-small'>
                  <label className='c-field__label' htmlFor='input2'>
                    Contraseña
                  </label>
                  <input
                    autoComplete='off'
                    className='c-input-login'
                    type='password'
                    id='input2'
                    placeholder='Password'
                    onChange={e =>
                      this.setState({ contrasena: e.target.value })
                    }
                  />
                </div>
                <button
                  className='c-btn-base c-btn--success c-btn--fullwidth'
                  type='submit'
                  disabled={!this.state.existeToken}
                >
                  Ingresar
                </button>
              </form>
            </div>
          </div>
        </div>
        <div style={{ display: 'flex', justifyContent: 'center', width: '100vw' }}>
          <Alerts
            type={this.state.tipoAlert}
            value={this.state.mensajeAlert}
            show={this.state.mostrarAlert}
            closeRequest={() => this.setState({ mostrarAlert: false })}
          />
        </div>
      </Fragment>
    );
  }
}

export default compose(graphql(LOGIN_MUTATION, { name: 'loginMutation' }))(Login);
/*
||===================================================================
||                        COMPONENTE: Login
||===================================================================
||                          DESCRIPCIÓN
|| Componente para realizar el login en la aplicación.
||===================================================================
||                             PROPS
||  * loged: función para guardar en localStorage algunos datos al
||  ser positivo el login.
||===================================================================
||                             STATE
||  * usuario: string para guardar el contenido del input de
||  usuario.
||
||  * contrasena: string para guardar el contenido del input de
||  password.
||
||  * loading: boleano que es positivo para mostrar spinner de carga.
||
||  * mostrarAlert: boleano que es true cuando se muestra el alert.
||
||  * mensajeAlert: string con el mensaje del alert.
||
||  * tipoAlert: string que debe contener el tipo de alert a mostrar.
||===================================================================
||                             FUNCIONES
||  * mostrarAlert: muestra los alerts de la app.
||
||  * _confirm: Enciende el loading mientras se realiza la validación
||  del login.
||===================================================================
*/
