import React, { useEffect, useState } from 'react';
import gql from 'graphql-tag';
import { PulseLoader } from 'react-spinners';

const NOTIFICAR_MUTATION = gql`
mutation($mensaje:String!,$destino:String!,$imagenUrl:String){
    enviarNotificacionWA(mensaje:$mensaje, destino:$destino,imagenUrl:$imagenUrl){
      ok
    }
  }
`;

const EXISTE_PEDIDO_QUERY = gql`
query($id:String!,$rfc:String!){
    existePedido(id:$id,rfc:$rfc){
      existe
    }
  }
`;

export function Orden({ id, client, link, cerrarPedido, cerrarModal, telefono }) {
    const [existe, setExiste] = useState(true);

    useEffect(() => {
        const intervalId = setInterval(async () => {
            if (existe) {
                const rfc = localStorage.getItem('rfc');
                const result = await client.query({
                    variables: {
                        rfc,
                        id
                    },
                    query: EXISTE_PEDIDO_QUERY
                });
                
                if (!result.data.existePedido.existe) {
                    const channel = localStorage.getItem('channel');
                    await client.mutate({
                        query: NOTIFICAR_MUTATION,
                        variables:{
                            mensaje:'Gracias por tu pago. Estamos trabajando en tu pedido y te mantendremos informado por este medio.',
                            destino: channel
                        }
                    });
                }
                setExiste(result.data.existePedido.existe);
                Object.keys(client.cache.data.data).forEach(key =>
                    key.includes('ROOT_QUERY.existePedido') && client.cache.data.delete(key)
                );
            }
        }, 4000);
        return () => {
            clearInterval(intervalId);
        }
    }, []);

    useEffect(()=>{
        if (!existe) {
            const timeoutId = setTimeout(()=>{
                cerrarPedido();
                cerrarModal();
            },10000);
            return ()=>{
                clearTimeout(timeoutId);
            }
        }
    },[existe]);

    if (existe) {
        return (
            <div className="esperando-pago">
                <div style={{ display: 'flex', justifyContent: 'center', width: '100%' }}>
                    <PulseLoader color='#36D7B7' loading={true} heightUnit='px' height={100} widthUnit='px' width={100} />
                </div>
                <div style={{ textAlign: 'center', width: '100%' }}>Esperando pago...</div>
                <div>El pedido será creado cuando se reciba confirmación de pago.</div>
                <div>
                    <div>Liga:</div>
                    <textarea className='c-input esperando-pago-liga' value={link} />
                </div>
            </div>
        );
    }

    return (
        <div className="esperando-pago" style={{ alignItems: 'center', justifyContent: 'center' }}>
            <div style={{ display: 'flex', width: '100%', justifyContent: 'center' }}>
                <i className="fas fa-check-circle" style={{ color: '#36D7B7', margin: '0 10px' }}></i>
                <div>Pedido Creado</div>
            </div>
            <div style={{ width: '100%' }}>El pago ha sido confirmado.</div>
            <div style={{ width: '100%' }}>El modal se cerrará en 10 segundos.</div>
        </div>
    );
}