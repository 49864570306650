import React, { Component } from 'react';
import Tipo1 from './Tipo1';
import Tipo2 from './Tipo2'
import proptypes from 'prop-types'

class FormatosTickets extends Component{
    getFormato=()=>{
        const { tipo,datos }= this.props;
        const props={datos};
        switch (tipo) {
            case 1:
            return(
                <Tipo1 {...props}/>
            );
            case 2:
            return(
                <Tipo2 {...props}/>
            );
            default:
                break;
        }
    }

    render(){
        return(
            <div>
                {
                    this.getFormato()
                }
            </div>
        );
    }
}

FormatosTickets.proptypes={
    tipo:proptypes.number,
    datos: proptypes.shape({
        ticket: proptypes.shape({
            cambio: proptypes.string,
            fecha: proptypes.string,
            id: proptypes.string,
            clave_factura: proptypes.string,
            descuento: proptypes.number,
            iva: proptypes.number,
            ieps: proptypes.number,
            subtotal: proptypes.number
        }),
        pagos: proptypes.shape({
            efectivo: proptypes.number,
            monedero: proptypes.arrayOf(proptypes.shape({
                terminacion: proptypes.string,
                caducidad: proptypes.string,
                monto:proptypes.number,
                saldo_anterior: proptypes.number
            })),
            puntos: proptypes.shape({
                monto: proptypes.number,
                saldo_anterior: proptypes.number
            }),
            tarjeta: proptypes.arrayOf(proptypes.shape({
                terminacion:proptypes.string,
                autorizacion:proptypes.string,
                monto:proptypes.number
            }))
        }),
        items: proptypes.arrayOf(proptypes.shape({
            producto:proptypes.string,
            cantidad:proptypes.number,
            descuento:proptypes.number,
            precio_unitario:proptypes.number
        })),
        productos: proptypes.arrayOf(proptypes.shape({
            nombre:proptypes.string,
            icod:proptypes.string,
            precio:proptypes.number
        })),
        cliente: proptypes.string,
        agente: proptypes.string
    })
}

export default FormatosTickets;