import React, { Component } from 'react';
import Fecha from '../Fecha';
import Formulario from './Formulario';
import ProductosContainer from './ProductosContainer';
import gql from 'graphql-tag';
import Alerts from '../../constants/Alerts';
import { Mixpanel } from '../../constants/Mixpanel'; 
const PRODUCTO_QUERY = gql`
query getProducto($id:String!){
    getProductoCliente(id:$id){
        icod,
        variaciones{
            icod
            color
            talla
        }
        imagen
        nombre
        precio
        talla
        color
        descuento
    }
}
`;

const BUSCAR_PRODUCTOS_QUERY = gql`
query buscarProductos($q: String!) {
    buscarProducto(q:$q) {
    productos{
        imagen,
        nombre,
        precio,
        talla,
        color,
        icod,
        descuento,
        variaciones {
            icod,
            color,
            talla
        }
    },
    ultimaPagina
    }
}
`;

class Inventario extends Component {
    constructor(props) {
        super(props);
        this.state = {
            categorias: [],
            nombre: '',
            codigo: '',
            categoriaString: '',
            productos: [],
            mensajeAlert: '',
            tipoAlert: '',
            mostrarAlert: false,
            loading:false
        }
    }

    componentDidMount(){
        Mixpanel.inventarioMounted(localStorage.getItem('rfc'));
    }

    mostrarAlert = (tipoAlert, mensajeAlert) => {
        this.setState({ mensajeAlert, tipoAlert }, () => this.setState({ mostrarAlert: true }, () => setTimeout(() => this.setState({ mostrarAlert: false }), 5000)));
    }

    changeNombre = event => this.setState({ nombre: event.target.value, codigo: '', loading:true, productos:[] }, this.buscar)

    changeCodigo = event => this.setState({ codigo: event.target.value, nombre: '' });

    changeCategoriaString = event => {
        if (!event.target.value.includes(' ') && !event.target.value.includes('  ')) {
            this.setState({ categoriaString: event.target.value })
        }
    }

    actionCategorias = event => {
        event.preventDefault();
        if (event.key === 'Enter' || event.key === 'Tab') {
            let { categorias, categoriaString } = this.state;
            if (categoriaString.includes(',')) {
                let tempArray = categoriaString.split(',');
                categorias = [...categorias, ...tempArray];
            } else {
                categorias = [...categorias, categoriaString];
            }
            categoriaString = '';
            this.setState({ categorias, categoriaString });
        }
    }

    actionCodigo = event => {
        event.preventDefault();
        if (event.key === 'Enter' || event.key === 'Tab') {
            this.get();
        }
    }

    removerCategoria = indice => {
        let { categorias } = this.state;
        categorias.splice(indice, 1);
        this.setState({ categorias });
    }

    get = () => {
        this.setState({loading:true},
            ()=>{
                const { codigo } = this.state;
                const { client } = this.props;
                client.query({
                    query: PRODUCTO_QUERY,
                    variables: {
                        id: codigo
                    }
                }).then(response => {
                    let productos = [];
                    productos.push(response.data.getProductoCliente);
                    this.setState({ productos, loading:false });
                }).catch(err => {
                    if (err.message.includes('404')){
                        this.mostrarAlert('warning','El producto no existe');
                    this.setState({loading:false, productos:[]})
                    }else{
                        this.mostrarAlert('danger','Error obteniendo el producto: '+err.message);
                    this.setState({loading:false, productos:[]})
                    }
                });
            });
    }

    buscar = () => {
        this.setState({loading:true},
            ()=>{
                const { nombre } = this.state;
                const { client } = this.props;
                client.query({
                    query: BUSCAR_PRODUCTOS_QUERY,
                    variables: {
                        q: nombre
                    }
                }).then(response => {
                    const { productos } = response.data.buscarProducto;
                    this.setState({ productos, loading:false });
                }).catch(err => {
                    if (err.message.includes('404')){
                        this.mostrarAlert('warning','Nada encontrado');
                    this.setState({loading:false, productos:[]})
                    }else{
                        this.mostrarAlert('danger','Error buscando productos: '+err.message);
                    this.setState({loading:false, productos:[]})
                    }
                });
            });
    }

    click = () => {
        const { nombre, codigo }= this.state;
        if (nombre !== '') {
            this.buscar();
        } else if (codigo !== '') {
            this.get();
        }
    }

    render() {
        const { client } = this.props;
        const { tipoAlert, mensajeAlert, mostrarAlert, loading, productos, categorias, categoriaString, nombre, codigo } = this.state;
        const formulario_props={ categorias, nombre, codigo, categoriaString };
        const contairner_props={ loading, productos, client };
        return (
            <React.Fragment>
                <div style={{ display: 'flex', justifyContent: 'center', position: 'absolute', width: '100vw', left: 0, top: '53px' }}>
                    <Alerts
                        type={tipoAlert}
                        value={mensajeAlert}
                        show={mostrarAlert}
                        closeRequest={() => this.setState({ mostrarAlert: false })}
                    />
                </div>
                <div style={{ marginLeft: '60px', width: 'calc(100vw - 60px)', height: 'calc(100vh - 53px)', paddingTop: '10px', paddingBottom: '10px', paddingLeft: '36px', paddingRight: '47px' }}>
                    <Fecha titulo='Inventario' />
                    <div style={{ width: '100%', height: 'calc(100% - 69px)', display: 'flex', padding: '12px' }}>
                        <ProductosContainer
                            {...contairner_props}
                        />
                        <Formulario
                            {...formulario_props}
                            changeNombre={this.changeNombre}
                            changeCodigo={this.changeCodigo}
                            changeCategoriaString={this.changeCategoriaString}
                            actionCategorias={this.actionCategorias}
                            removerCategoria={this.removerCategoria}
                            actionCodigo={this.actionCodigo}
                            click={this.click}
                        />
                    </div>
                </div>
            </React.Fragment>
        );

    }
}

export default Inventario;