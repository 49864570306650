import React, { Component } from 'react';
import Modal from 'react-modal';
import { graphql } from 'react-apollo';
import { flowRight as compose } from 'lodash';
import gql from 'graphql-tag';
import Ticket from './Ticket';
import Buscador from './Buscador';
import Cobro from './Cobro';
import CobroOmni from './Cobro/CobroOmni';
import CrearCliente from './CrearCliente';
import Sidebar from "./Sidebar";
import ProductoHijo from "./Sidebar/ProductoHijo";
import LeerMonedero from './LeerMonedero';
import ConfirmacionCobro from './Cobro/ConfirmacionCobro';
import ImprimirTicket from '../FormatosTickets';
import ReactToPrint from 'react-to-print';
import Alerts from '../../constants/Alerts';
import Hotkeys from 'react-hot-keys';
import { Mixpanel } from '../../constants/Mixpanel';
import { formatItems } from '../../constants/Util';
const SerialPort = typeof window.require === 'function' ? window.require('serialport') : null;

Modal.setAppElement('#root')

const GUARDAR_TICKET_INCOMPLETO_MUTATION = gql`
    mutation($rfc:String!, $id:String!, $estado:String!){
    guardarPedidoIncompleto(rfc:$rfc, id:$id, estado:$estado){
        ok
    }
    }
`;

const GET_PEDIDO_INCOMPLETO_QUERY = gql`
    query($rfc:String!, $id:String!){
    getPedidoIncompleto(rfc:$rfc, id:$id){
        estado
    }
    }
`;

const TICKET_MUTATION = gql`
mutation calcTicket($cliente:String!,$tienda:String!,$agente:String!,$items:[ItemInput]!){
    calcularTicket(cliente:$cliente,tienda:$tienda,agente:$agente,items:$items){
        subtotal,
        iva,
        ieps,
        descuento,
        items{
            cantidad,
            producto,
            precio_unitario,
            descuento
        },
    productos{
      icod,
        variaciones{
            icod
            color
            talla
        }
        imagen
        nombre
        precio
        talla
        color
        descuento
    }
    }
}
`;

const GET_CLIENTE_TARJETA_QUERY = gql`
query($tarjeta:String!){
    getClienteTarjeta(tarjeta:$tarjeta){
      clicod,
      puntos,
      nombre
    }
  }
`;

const CREAR_TICKET_MUTATION = gql`
mutation crearTickets($cliente:String!,$agente:String!,$tienda:String!,$items:[ItemInput]!,$pagos:[PagoInput]){
    crearTicket(cliente:$cliente,agente:$agente,tienda:$tienda,items:$items,pagos:$pagos){
        id,
        clave_factura
    }
}
`;

const PRODUCTO_QUERY = gql`
query getProducto($id:String!, $cliente:String!){
    getProductoCliente(id:$id,cliente:$cliente){
        icod,
        variaciones{
            icod
            color
            talla
        }
        imagen
        nombre
        precio
        talla
        color
        descuento
    }
}
`;

const TICKET_QUERY = gql`
query($id:String!){
    getTicket(id:$id){
    items{
        cantidad,
        descuento,
        producto
    },
    productos{
        nombre,
        talla,
        color,
        icod,
        precio,
        imagen
        },
    pagos{
        tipo,
        monto
    },
    subtotal,
    iva,
    descuento
    }
}
`;


class POS extends Component {
    constructor(props) {
        super(props);
        this.state = {
            cliente: '',
            agente: '',
            items: [],
            productos: [],
            ticket: {
                descuento: 0,
                subtotal: 0,
                iva: 0,
                total: 0
            },
            showModal: false,
            showModalHijos: false,
            showModalCrearCliente: false,
            q: '',
            tempProducto: {},
            loadingProducto: false,
            loadingTable: false,
            clienteOpcional: true,
            agenteOpcional: true,
            activoAgregarCliente: false,
            activoHijo: false,
            activoCobro: false,
            activoEditarCantidad: false,
            editandoCantidad: false,
            borrandoItem: false,
            itemsNegativos: false,
            ticketVersion2: false,
            montoMonederoDevolucion: 0,
            showModalMonedero: false,
            showModalConfirmacion: false,
            idTicketCambioDevolucion: '',
            guardarTicket: true,
            mostrarAlert: false,
            mensajeAlert: '',
            tipoAlert: '',
            puntosNombre: '',
            puntos: 0,
            tarjetaPuntosString: '',
            busquedaProducto: '',
            tiempoInicio: 0,
            tiempoFin: 0,
            omnichannel: true
        };
        this.Main = this.Main.bind(this);
        if (SerialPort !== null) this.port = new SerialPort('/dev/tty.usbserial', { baudRate: 9600 });
    }

    seleccionarClienteNuevo = cliente => {
        this.mostrarAlert("success", "Cliente nuevo creado y seleccionado");
        this.setState({ cliente });
    }

    componentDidMount() {
        if (SerialPort !== null) {
            this.port.on('data', data => {
                let datos = '';
                datos += data.toString();
                datos = datos.substring(datos.indexOf('%') + 1, datos.indexOf('?') - 1);
                //this.getClienteTarjeta(datos);
                if (datos.includes('A010049939')) {
                    this.getClienteTarjeta('0012893789064');
                } else {
                    this.getClienteTarjeta('A1');
                }
            });
        }
        const { location } = this.props;
        const ticketGuardado = localStorage.getItem("ticketGuardado");
        const phone = localStorage.getItem('phone');
        if (location.state) {
            this.getTicket(location.state.ticket, location.state.tipo, location.state.agente, location.state.cliente, location.state.itemsCambio ? location.state.itemsCambio : []);
        } else if (ticketGuardado) {
            const state = JSON.parse(ticketGuardado);
            this.setState(state);
        }
        
        if (phone) {
            this.downloadTicket(phone);
        }
        Mixpanel.posMounted(localStorage.getItem('rfc'));
    }

    downloadTicket = async idTicket => {
        /*
        const res = await this.props.client.query({
            query: GET_PEDIDO_INCOMPLETO_QUERY,
            variables: {
                id: idTicket,
                rfc: localStorage.getItem('rfc')
            },
            fetchPolicy: "no-cache"
        });
        const { estado } = res.data.getPedidoIncompleto;
        */
        try {
            const estado = localStorage.getItem(idTicket);
            if (estado) {
                const state = JSON.parse(estado);
                this.setState(state);
            }
        } catch (error) {
            alert('error cargando ticket de cache ' + error);
        }
    }

    getClienteTarjeta = tarjeta => {
        const { client } = this.props;
        client.query({
            variables: {
                tarjeta
            },
            query: GET_CLIENTE_TARJETA_QUERY
        }).then(result => {
            const { clicod, puntos, nombre } = result.data.getClienteTarjeta;
            this.setState({ puntos, cliente: clicod, puntosNombre: nombre, tarjetaPuntosString: tarjeta });
        }).catch(err => {
            const { showModal, showModalMonedero } = this.state;
            if (err.message.includes('404') && !showModal && !showModalMonedero) {
                this.mostrarAlert("warning", 'Tarjeta no encontreda');
            } else if (!showModal && !showModalMonedero) {
                this.mostrarAlert("danger", err.message);
            }
        });
    }

    calcularTotalTemportal = () => {
        const { items, productos } = this.state;
        let total = 0;
        for (const item of items) {
            const producto = productos.find(producto => producto.icod === item.producto);
            if (producto) {
                total += item.cantidad * producto.precio;
            }
        }
        return total;
    }

    cancelar = () => {
        this.setState(
            {
                guardarTicket: false
            },
            () => {
                const phone = localStorage.getItem('phone');
                if (phone) {
                    localStorage.setItem(phone, null);
                }
                localStorage.setItem("ticketGuardado", null);
                const { history } = this.props;
                history.push("/");
            }
        );
    }

    componentWillUnmount() {
        const { guardarTicket } = this.state;
        if (guardarTicket) {
            localStorage.setItem("ticketGuardado", JSON.stringify(this.state));
        }
    }

    changeTiempoFinal = tiempoFin => this.setState(
        { tiempoFin },
        () => {
            const { tiempoFin, tiempoInicio } = this.state;
            const time = tiempoFin - tiempoInicio;
            Mixpanel.tiempoVenta(localStorage.getItem('rfc'), time);
        }
    )

    Main = (eanTemp) => {
        if (this.state.items.length === 0) this.setState({ tiempoInicio: performance.now() })
        this.loading(true, () => {
            let ean = eanTemp + "".toUpperCase();
            this.validarAgente(() => {
                this.validarCliente(() => {
                    if (this.state.agente !== '' && this.state.cliente !== '') {
                        if ((eanTemp[0] === "X" && this.state.items.length > 0) || (eanTemp[0] === "x" && this.state.items.length > 0)) {
                            let { items } = this.state;
                            let obj = items[items.length - 1];
                            const x = eanTemp.substring(1)
                            const num = parseInt(x, 10);
                            if (!isNaN(num)) {
                                this.cambiarCantidad(obj.producto, num);
                            } else {
                                this.loading(false, () => this.mostrarAlert("warning", "No hay un número despues de la X"));
                            }
                        } else {
                            const { client } = this.props;
                            client.query({
                                query: PRODUCTO_QUERY,
                                variables: {
                                    id: ean,
                                    cliente: this.state.cliente
                                }
                            }).then(
                                results => {
                                    let { icod, variaciones, imagen, nombre, precio, descuento, talla, color } = results.data.getProductoCliente;
                                    let tempProducto = {
                                        imagen,
                                        nombre,
                                        icod,
                                        precio,
                                        descuento,
                                        talla,
                                        color
                                    }
                                    if (variaciones !== null && variaciones !== undefined) {
                                        if (ean === process.env.REACT_APP_MONEDERO_ICOD) {
                                            this.setState({ showModalMonedero: true, activoHijo: true });
                                        } else {
                                            this.mostrarProductoPadre(icod);
                                        }
                                    } else {
                                        this.agregarItem(icod, tempProducto);
                                        this.changeActivoHijo(false);
                                    }
                                }
                            ).catch(
                                error => {
                                    if (error.message + ''.includes('404')) {
                                        this.onChangeSearch(ean, () => { this.loading(false); this.changeActivoHijo(false); });
                                    } else {
                                        this.loading(false, () => { this.mostrarAlert("danger", error.message); this.changeActivoHijo(false); });
                                    }
                                }
                            );
                        }
                    } else {
                        this.loading(false, () => this.mostrarAlert("warning", 'Selecciona agente y cliente'));
                    }
                });
            });
        });
    }

    validarAgente = (callback) => {
        const { agenteOpcional, agente } = this.state;
        if (agenteOpcional && !agente) {
            this.setState({ agente: localStorage.getItem('defaultAgente') }, callback);
        } else {
            callback();
        }
    }

    calculoFinal = () => {
        this.mutacionTicket(async () => {
            const { client } = this.props;
            Object.keys(client.cache.data.data).forEach(key =>
                key.includes('ROOT_MUTATION.calcularTicket') && client.cache.data.delete(key)
            );
            await this.sleep(1000);
            this.setState({ activoCobro: true, showModal: true }, this.subirTicketIncomplento);

        });
    }

    subirTicketIncomplento = async () => {
        const id = localStorage.getItem('phone');
        if (id) {
            try {
                const estado = JSON.stringify(this.state);
                localStorage.setItem(id, estado);
            } catch (error) {
                alert('error guardando ticket en cache ' + error);
            }

            /*
            const rfc = localStorage.getItem('rfc');
            const res = await this.props.client.mutate({
                mutation: GUARDAR_TICKET_INCOMPLETO_MUTATION,
                variables: {
                    rfc,
                    estado,
                    id
                },
                fetchPolicy: "no-cache"
            });
            if (!res.data.guardarPedidoIncompleto.ok) {
                alert('Hubo un error subiendo ticket incompleto');
            }
            */
        }
    }

    sleep = async (ms) => {
        return new Promise(resolve => setTimeout(resolve, ms));
    }

    validarCliente = (callback) => {
        const { clienteOpcional, cliente, omnichannel } = this.state;
        if (omnichannel && !cliente) {
            this.mostrarAlert('warning', 'Seleccionar cliente');
            return;
        }
        if (clienteOpcional && !cliente) {
            this.setState({ cliente: localStorage.getItem('tienda') }, callback);
        } else {
            callback();
        }
    }

    realizarValidaciones = (callback) => {
        this.validarAgente(() => {
            this.validarCliente(() => {
                if (callback) {
                    callback();
                }
            })
        })
    }

    mostrarProductoPadre = async id => {
        const { cliente } = this.state;
        const { client } = this.props;
        try {
            const results = await client.query({
                query: PRODUCTO_QUERY,
                variables: {
                    id,
                    cliente
                }
            });
            let tempProducto = results.data.getProductoCliente;
            this.setState({ tempProducto, showModalHijos: true, activoHijo: true }, () => this.loading(false));
        } catch (error) {
            this.loading(false, () => this.mostrarAlert("danger", error.message));
        }
    }

    agregarItem = (icod, producto) => {
        this.realizarValidaciones(
            () => {
                let indexIgual = this.buscarEnItems(icod);
                let { items } = this.state;
                if (indexIgual.verdad) {
                    //Sumando a cantidad a producto ya existente
                    const tempCantidad = items[indexIgual.indice].cantidad + 1;
                    const tempObject = {
                        producto: icod,
                        cantidad: tempCantidad
                    };
                    items[indexIgual.indice] = tempObject;
                    this.setState({
                        items
                    }, this.mutacionTicket);
                } else {
                    this.setState({ loadingProducto: true }, () => {
                        //Agregando el primer producto a ticket
                        let { productos } = this.state;
                        productos.push(producto);
                        items.push({ producto: icod, cantidad: 1 });
                        this.setState({
                            items,
                            productos,
                            loadingProducto: false
                        }, this.mutacionTicket);
                    })
                }
            }
        )

    }

    cambiarCantidad = (icod, cantidad) => {
        this.realizarValidaciones(
            () => {
                this.setState({ loadingTable: true, editandoCantidad: true },
                    () => {
                        if (cantidad < 0) this.setState({ itemsNegativos: true });
                        let { items } = this.state;
                        let indexIgual = this.buscarEnItems(icod);
                        if (indexIgual.verdad) {
                            items[indexIgual.indice] = {
                                producto: icod,
                                cantidad
                            }
                            this.setState({
                                items
                            }, this.mutacionTicket);
                        }
                    }
                );
            }
        );
    }

    borrarItem = icod => {
        this.realizarValidaciones(
            () => {
                this.setState({ loadingTable: true, borrandoItem: true },
                    () => {
                        let { items, productos } = this.state;
                        let existeItem = this.buscarEnItems(icod);
                        let existeProducto = this.buscarEnProductos(icod);
                        if (existeItem.verdad && existeProducto.verdad) {
                            items.splice(existeItem.indice, 1);
                            productos.splice(existeProducto.indice, 1);
                            this.setState({
                                items,
                                productos
                            }, () => this.mutacionTicket(() => this.setState({ borrandoItem: false })));
                        }
                    }
                );
            }
        );
    }

    buscarEnProductos = (icod) => {
        const { productos } = this.state;
        return this.busquedaEnArreglo(productos, icod, 'icod');
    }

    buscarEnItems = (icod) => {
        const { items } = this.state;
        return this.busquedaEnArreglo(items, icod, 'producto');
    }

    busquedaEnArreglo = (arreglo, icod, key = 'producto') => {
        const indice = arreglo.findIndex(item => item[key] === icod);
        return { verdad: indice !== -1, indice };
    }

    mutacionTicket = async callback => {
        const t0 = performance.now();
        const { agente, cliente } = this.state;
        const itemState = this.state.items;
        if (agente && cliente) {
            const { ticketMutation } = this.props;
            try {
                const result = await ticketMutation({
                    variables: {
                        agente,
                        cliente,
                        items: itemState,
                        tienda: localStorage.getItem("tienda")
                    }
                });
                let { items, productos } = result.data.calcularTicket;
                if (this.ticketVigente(items, productos)) {
                    let { iva, subtotal, descuento, ieps } = result.data.calcularTicket;
                    let total = 0;
                    if (iva === null) iva = 0;
                    if (subtotal === null) subtotal = 0;
                    total = subtotal - descuento + iva + ieps;
                    const ticket = {
                        descuento: descuento === null ? 0 : descuento,
                        iva: iva === null ? 0 : iva,
                        subtotal: subtotal === null ? 0 : subtotal,
                        total
                    };
                    items = formatItems(items);
                    this.setState({
                        ticket,
                        items
                    }, () => {
                        this.loading(false);
                        this.setState({ totalCargado: true }, () => {
                            this.subirTicketIncomplento();
                        });
                        if (callback) callback();
                    });
                }
            } catch (error) {
                this.loading(false, () => this.mostrarAlert("danger", error.message));
            }
        } else {
            this.mostrarAlert("warning", "selecciona agente y cliente");
        }
        const t1 = performance.now();
        const time = t1 - t0;
        Mixpanel.tiempoCalcularTicket(localStorage.getItem('rfc'), `${time}`);
    }

    getTicket = async (id, tipo, agente, cliente, itemsCambio) => {
        const { client } = this.props;
        try {
            const result = await client.query({
                query: TICKET_QUERY,
                variables: {
                    id
                }
            });
            let { iva, subtotal, descuento, items, productos } = result.data.getTicket;
            let total = 0;
            if (iva === null) iva = 0;
            if (subtotal === null) subtotal = 0;
            total = iva + subtotal;
            let ticket = {
                descuento: descuento === null ? 0 : descuento,
                iva: iva === null ? 0 : iva,
                subtotal: subtotal === null ? 0 : subtotal,
                total
            };
            if (tipo === 1) ticket.total = ticket.total * -1;
            items = formatItems(items);
            productos = this.formatProductos(productos);
            if (tipo === 0) {
                items = this.getItemsCambio(items, itemsCambio);
                productos = this.getProductosCambio(productos, itemsCambio);
            }
            items = this.aItemsNegativos(items);
            this.setState({
                items,
                productos,
                ticketVersion2: true
            }, () => {
                this.loading(false);
                this.setState({ totalCargado: true })
            });
        } catch (error) {
            this.loading(false, () => this.mostrarAlert("danger", error.message));
        }
    }

    getProductosCambio = (completos, necesarios) => {
        return this.getItemsCambio(completos, necesarios, 'icod');
    }

    getItemsCambio = (completos, necesarios, key = 'producto') => {
        const tempArray = completos.filter(
            producto => {
                const productIcod = necesarios.find(icod => icod === producto[key]);
                return !!productIcod;
            }
        );
        return tempArray;
    }

    aItemsNegativos = items => {
        let tempItems = items.map(
            item => {
                item.cantidad = item.cantidad * -1;
                return item;
            }
        );
        return tempItems
    }

    formatProductos = productos => {
        let tempProductos = [];
        let arreglo = [];
        productos.forEach(
            (producto, index) => {
                if (index === 0) {
                    arreglo.push(producto.icod);
                    tempProductos.push(producto);
                } else {
                    if (!arreglo.includes(producto.icod)) {
                        arreglo.push(producto.icod);
                        tempProductos.push(producto);
                    }
                }
            }
        );
        return tempProductos;
    }

    ticketVigente = (itemsTemp, productosTemp) => {
        itemsTemp.forEach(
            item => {
                if (item.cantidad < 0) this.setState({ itemsNegativos: true });
            }
        );
        const { itemsNegativos, items } = this.state;
        if (itemsNegativos) return true;
        const itemsT = formatItems(itemsTemp);
        if (items.length < itemsT.length) {
            this.setState({ items: itemsT, productos: productosTemp });
            return true;
        } else if (items.length === itemsT.length) {
            let verdaderos = [];
            itemsT.forEach(
                itemT => {
                    items.forEach(
                        item => {
                            if (item.producto === itemT.producto && item.cantidad === itemT.cantidad) {
                                verdaderos.push(true);
                            }
                        }
                    );
                }
            );
            if (verdaderos.length === items.length && items.length === itemsT.length) {
                return true;
            } else {
                return false;
            }
        } else {
            return false;
        }
    }

    buscarEnItemEspecial = (item, array) => {
        let indexIgual = { verdad: false };
        let checked = false;
        if (array.length > 0) {
            array.forEach(
                (producto, index) => {
                    if (producto.producto === item && checked !== true) {
                        indexIgual = { verdad: true, indice: index };
                        checked = true;
                    }
                }
            )
        }
        return indexIgual;
    }

    cambiar = () => {
        this.realizarValidaciones(
            async () => {
                const { cliente, agente, items } = this.state;
                if (cliente && agente) {
                    const pagos = [];
                    const { crearTicketMutation } = this.props;
                    try {
                        const result = await crearTicketMutation({
                            variables: {
                                agente,
                                cliente,
                                items,
                                tienda: localStorage.getItem("tienda"),
                                pagos
                            }
                        });
                        this.loading(false,
                            () => {
                                const { id: idTicketCambioDevolucion, clave_factura } = result.data.crearTicket;
                                this.setState({ idTicketCambioDevolucion, clave_factura },
                                    () => this.setState({ showModalConfirmacion: true },
                                        () => document.getElementById("imprimir").click()
                                    )
                                );
                            }
                        );
                    } catch (error) {
                        this.loading(false, () => this.mostrarAlert("danger", error.message));
                    }
                } else {
                    this.mostrarAlert("warning", "selecciona cliente y agente");
                }
            }
        );
    }

    agregarAlMonedero = (monedero, monto, callback) => {
        this.realizarValidaciones(
            async () => {
                const { cliente, agente, montoMonederoDevolucion } = this.state;
                if (cliente && agente) {
                    let { items } = this.state;
                    if (montoMonederoDevolucion !== 0) {
                        //Devolución
                        const pagos = [
                            {
                                tipo: 0,
                                terminacion: monedero,
                                monto: parseFloat(monto) * -1
                            }
                        ];
                        const { crearTicketMutation } = this.props;
                        try {
                            const result = await crearTicketMutation({
                                variables: {
                                    agente,
                                    cliente,
                                    items,
                                    tienda: localStorage.getItem("tienda"),
                                    pagos
                                }
                            });
                            this.loading(false,
                                () => {
                                    const { id: idTicketCambioDevolucion, clave_factura } = result.data.crearTicket;
                                    this.setState({ idTicketCambioDevolucion, clave_factura },
                                        () => this.setState({ showModalConfirmacion: true },
                                            () => document.getElementById("imprimir").click()
                                        )
                                    );

                                }
                            );
                        } catch (error) {
                            this.loading(false, () => this.mostrarAlert("danger", error.message));
                        }
                    } else {
                        //Recarga de saldo a monedero
                        const item = {
                            precio_unitario: parseFloat(monto),
                            cantidad: 1,
                            producto: `A@A`
                        };
                        const producto = {
                            imagen: "/img/image_not_found.png",
                            icod: "A@A",
                            talla: "N/A",
                            color: "N/A",
                            precio: parseFloat(monto),
                            descuento: 0,
                            nombre: "Monedero"
                        }
                        let { productos } = this.state;
                        items.push(item);
                        productos.push(producto);
                        this.setState({ productos, items }, () => {
                            this.mutacionTicket(this.loading(false,
                                () => {
                                    if (callback) callback();
                                }
                            )
                            );
                        }
                        );
                    }
                } else {
                    this.mostrarAlert("warning", "selecciona cliente y agente");
                }

            }
        );
    }

    seleccionarMonederoYDevolver = montoMonederoDevolucion => {
        this.setState({ montoMonederoDevolucion },
            () => {
                this.changeActivoHijo(true, () => this.setState({
                    showModalMonedero: true,
                }));
            }
        );

    }

    onChangeSearch = (q, callback) => this.setState({ q }, () => { if (callback) callback(); })

    loading = (loadingTable, callback) => this.setState({ loadingTable }, () => { if (callback) callback() });

    changeActivoHijo = (activoHijo, callback) => this.setState({ activoHijo }, () => { if (callback) callback(); })

    changeActivoCobro = activoCobro => this.setState({ activoCobro })

    changeActivoEditarCantidad = activoEditarCantidad => this.setState({ activoEditarCantidad })

    changeMonedero = event => this.setState({ monedero: event.target.value })

    changeMonederoSaldo = ({ target }) => { if (!isNaN(target.value)) this.setState({ saldoMonedero: target.value }) }

    mostrarAlert = (tipoAlert, mensajeAlert) => {
        this.setState({ mensajeAlert, tipoAlert }, () => this.setState({ mostrarAlert: true }, () => setTimeout(() => this.setState({ mostrarAlert: false }), 5000)));
    }

    onKeyUp(keyName, e, handle) {
        const { loadingTable, ticket, items, busquedaProducto } = this.state;
        if (keyName === 'Enter' && busquedaProducto === '') {
            if (loadingTable === false && ticket.total > 0) {
                this.calculoFinal();
            } else if (loadingTable === false && ticket.total < 0) {
                this.seleccionarMonederoYDevolver(ticket.total);
            } else if (loadingTable === false && ticket.total === 0 && items.length > 0) {
                this.cambiar();
            }
        } else {
            if (loadingTable === false && ticket.total > 0) {
                this.calculoFinal();
            } else if (loadingTable === false && ticket.total < 0) {
                this.seleccionarMonederoYDevolver(ticket.total);
            } else if (loadingTable === false && ticket.total === 0 && items.length > 0) {
                this.cambiar();
            }
        }
    }

    setBusquedaProducto = busquedaProducto => {
        this.setState({
            busquedaProducto
        });
    }

    changeCliente = (cliente, callback) => this.setState({ cliente }, () => { if (callback) callback() })

    changeAgente = (agente, callback) => this.setState({ agente }, () => { if (callback) callback() })

    render() {
        const { client } = this.props;
        const { activoEditarCantidad, activoCobro, activoHijo, activoAgregarCliente, q, cliente, agente, busquedaProducto, borrandoItem, editandoCantidad, loadingTable, loadingProducto, productos, items, ticket, showModal, puntos, puntosNombre, tarjetaPuntosString, showModalCrearCliente, showModalHijos, tempProducto, showModalMonedero, montoMonederoDevolucion, showModalConfirmacion, idTicketCambioDevolucion, clave_factura, tipoAlert, mensajeAlert, mostrarAlert, omnichannel } = this.state;
        return (
            <React.Fragment>
                <Hotkeys
                    keyName="F1,Enter"
                    onKeyUp={this.onKeyUp.bind(this)}
                >
                    <div className='contenedor-pos' >
                        <div className='principal'>
                            <Buscador
                                activoEditarCantidad={activoEditarCantidad}
                                activoCobro={activoCobro}
                                activoHijo={activoHijo}
                                onRef={ref => (this.buscador = ref)}
                                activoAgregarCliente={activoAgregarCliente}
                                crearCliente={() => this.setState({ showModalCrearCliente: true, activoAgregarCliente: true })}
                                agregarATicket={this.Main}
                                q={q}
                                cliente={cliente}
                                agente={agente}
                                changeCliente={this.changeCliente}
                                changeAgente={this.changeAgente}
                                busquedaProducto={busquedaProducto}
                                setBusquedaProducto={this.setBusquedaProducto}
                            />
                            <Ticket
                                seleccionarMonederoYDevolver={this.seleccionarMonederoYDevolver}
                                devolver={this.devolver}
                                cambiar={this.cambiar}
                                ticketVersion2={this.stateticketVersion2}
                                calcularTotalTemportal={this.calcularTotalTemportal}
                                borrandoItem={borrandoItem}
                                editandoCantidad={editandoCantidad}
                                changeActivoEditarCantidad={this.changeActivoEditarCantidad}
                                loadingTable={loadingTable}
                                loading={loadingProducto}
                                cambiarCantidad={this.cambiarCantidad}
                                cancelar={this.cancelar}
                                cobrar={this.calculoFinal}
                                productos={productos}
                                items={items}
                                ticket={ticket}
                                borrarItem={this.borrarItem}
                                omnichannel={omnichannel}
                            />
                        </div>
                        <Sidebar
                            changeActivoHijo={this.changeActivoHijo}
                            loading={this.loading}
                            agregarAlTicket={this.Main}
                            q={q}
                            onChangeSearch={this.onChangeSearch}
                        />
                        <Modal
                            style={
                                {
                                    overlay: {
                                        top: 0,
                                        left: 0,
                                        right: 0,
                                        bottom: 0,
                                        zIndex: 500,
                                        backgroundColor: 'rgba(0, 0, 0, 0.75)'
                                    },
                                    content: {
                                        width: "950px",
                                        display: 'block',
                                        position: 'relative',
                                        top: 'calc((100% - 727px) / 2)',
                                        right: '0',
                                        bottom: '0',
                                        left: 'calc((100% - 950px) / 2)',
                                        padding: '0',
                                        zIndex: '-1',
                                        outline: "0",
                                        overflow: "hidden",
                                        backgroundColor: "rgba(0,0,0,0)",
                                        border: "0",
                                    }
                                }
                            }
                            onRequestClose={() => {
                                this.setState({ showModal: false, activoCobro: false }); const { client } = this.props; Object.keys(client.cache.data.data).forEach(key =>
                                    key.includes('ROOT_MUTATION.calcularTicket') && client.cache.data.delete(key)
                                );
                            }}
                            isOpen={showModal}
                            ariaHideApp={true}
                            shouldCloseOnEsc={true}
                            shouldReturnFocusAfterClose={true}
                        >
                            {
                                omnichannel
                                    ?
                                    <CobroOmni
                                        cerrar={() => this.setState({ showModal: false, activoCobro: false })}
                                        client={client}
                                        agente={agente}
                                        items={items}
                                        cliente={cliente}
                                        ticket={ticket}
                                        productos={productos}
                                        cancelar={this.cancelar}
                                        location={this.props.history}
                                    />
                                    :
                                    <Cobro
                                        client={client}
                                        changeTiempoFinal={this.changeTiempoFinal}
                                        cancelar={this.cancelar}
                                        cerrar={() => this.setState({ showModal: false, activoCobro: false })}
                                        productos={productos}
                                        ticket={ticket}
                                        agente={agente}
                                        cliente={cliente}
                                        items={items}
                                        changeCliente={cliente => this.setState({ cliente })}
                                        puntos={puntos}
                                        puntosNombre={puntosNombre}
                                        tarjetaPuntosString={tarjetaPuntosString}
                                        omnichannel={omnichannel}
                                    />
                            }
                        </Modal>
                        <Modal
                            isOpen={showModalCrearCliente}
                            ariaHideApp={true}
                            style={{
                                overlay: {
                                    top: 0,
                                    left: 0,
                                    right: 0,
                                    bottom: 0,
                                    zIndex: 500,
                                    backgroundColor: 'rgba(0, 0, 0, 0.75)'
                                },
                                content: {
                                    width: "750px",
                                    display: 'block',
                                    position: 'relative',
                                    top: 'calc((100% - 637px) / 2)',
                                    right: '0',
                                    bottom: '0',
                                    left: 'calc((100% - 750px) / 2)',
                                    padding: '0',
                                    zIndex: '-1',
                                    outline: "0",
                                    overflow: "hidden",
                                    backgroundColor: "rgba(0,0,0,0)",
                                    border: "0",
                                }
                            }}
                            onRequestClose={() => this.setState({ showModalCrearCliente: false, activoAgregarCliente: false, loadingTable: false })}
                        >
                            <CrearCliente
                                seleccionarClienteNuevo={this.seleccionarClienteNuevo}
                                cerrar={() => this.setState({ showModalCrearCliente: false, activoAgregarCliente: false })}
                            />
                        </Modal>
                        <Modal
                            isOpen={showModalHijos}
                            ariaHideApp={true}
                            onRequestClose={() => this.setState({ showModalHijos: false, loadingTable: false }, () => this.changeActivoHijo(false))}
                            style={
                                {
                                    overlay: {
                                        top: 0,
                                        left: 0,
                                        right: 0,
                                        bottom: 0,
                                        zIndex: 500,
                                        backgroundColor: 'rgba(0, 0, 0, 0.75)'
                                    },
                                    content: {
                                        width: "750px",
                                        display: 'block',
                                        position: 'relative',
                                        top: 'calc((100% - 637px) / 2)',
                                        right: '0',
                                        bottom: '0',
                                        left: 'calc((100% - 750px) / 2)',
                                        padding: '0',
                                        zIndex: '-1',
                                        outline: "0",
                                        overflow: "hidden",
                                        backgroundColor: "rgba(0,0,0,0)",
                                        border: "0",

                                    }
                                }
                            }
                        >
                            <ProductoHijo
                                producto={tempProducto}
                                agregarAlTicket={this.Main}
                                cerrar={() => this.setState({ showModalHijos: false }, () => this.changeActivoHijo(false))}
                            />
                        </Modal>
                        <Modal
                            isOpen={showModalHijos}
                            ariaHideApp={true}
                            onRequestClose={() => this.setState({ showModalHijos: false, loadingTable: false }, () => this.changeActivoHijo(false))}
                            style={
                                {
                                    overlay: {
                                        top: 0,
                                        left: 0,
                                        right: 0,
                                        bottom: 0,
                                        zIndex: 500,
                                        backgroundColor: 'rgba(0, 0, 0, 0.75)'
                                    },
                                    content: {
                                        width: "750px",
                                        display: 'block',
                                        position: 'relative',
                                        top: 'calc((100% - 637px) / 2)',
                                        right: '0',
                                        bottom: '0',
                                        left: 'calc((100% - 750px) / 2)',
                                        padding: '0',
                                        zIndex: '-1',
                                        outline: "0",
                                        overflow: "hidden",
                                        backgroundColor: "rgba(0,0,0,0)",
                                        border: "0",

                                    }
                                }
                            }
                        >
                            <ProductoHijo
                                producto={tempProducto}
                                agregarAlTicket={this.Main}
                                cerrar={() => this.setState({ showModalHijos: false }, () => this.changeActivoHijo(false))}
                            />
                        </Modal>
                        <Modal ariaHideApp={true} isOpen={showModalMonedero} onRequestClose={() => this.setState({ showModalMonedero: false }, () => this.changeActivoHijo(false))} style={{
                            overlay: {
                                top: 0,
                                left: 0,
                                right: 0,
                                bottom: 0,
                                zIndex: 500,
                                backgroundColor: 'rgba(0, 0, 0, 0.75)'
                            },
                            content: {
                                width: "570px",
                                height: "350px",
                                display: 'block',
                                position: 'relative',
                                top: 'calc((100% - 350px) / 2)',
                                right: '0',
                                bottom: '0',
                                left: 'calc((100% - 570px) / 2)',
                                padding: '0',
                                zIndex: '-1',
                                outline: "0",
                                overflow: "hidden",
                                backgroundColor: "rgba(0,0,0,0)",
                                border: "0"
                            }
                        }
                        }>
                            <LeerMonedero
                                client={client}
                                monto={montoMonederoDevolucion}
                                agregarAlMonedero={this.agregarAlMonedero}
                                cerrar={() => this.setState({ showModalMonedero: false }, () => this.changeActivoHijo(false))}
                            />
                        </Modal>
                        <Modal ariaHideApp={true} style={{
                            overlay: {
                                top: 0,
                                left: 0,
                                right: 0,
                                bottom: 0,
                                zIndex: 500,
                                backgroundColor: '#273142'
                            },
                            content: {
                                width: "750px",
                                display: 'block',
                                position: 'relative',
                                top: 'calc((100% - 544px) / 2)',
                                right: '0',
                                bottom: '0',
                                left: 'calc((100% - 750px) / 2)',
                                padding: '0',
                                zIndex: '-1',
                                outline: "0",
                                overflow: "hidden",
                                backgroundColor: "rgba(0,0,0,0)",
                                border: "0",
                                boxShadow: "0px 30px 60px -19px rgba(0,0,0,0.75)"
                            }
                        }} isOpen={showModalConfirmacion} >
                            <ConfirmacionCobro
                                omnichannel={omnichannel}
                                client={client}
                                cancelar={this.cancelar}
                                cambio={'0'}
                                idTicket={idTicketCambioDevolucion}
                                datos={{
                                    ticket: {
                                        cambio: '0',
                                        fecha: new Date(),
                                        id: idTicketCambioDevolucion,
                                        clave_factura,
                                        descuento: ticket.descuento,
                                        iva: ticket.iva,
                                        ieps: 0,
                                        subtotal: ticket.subtotal
                                    },
                                    pagos: {
                                        efectivo: 0,
                                        monedero: [],//monedero: this.state.pagosMonedero,
                                        puntos: {
                                            monto: 0,
                                            saldo_anterior: 0
                                        },
                                        tarjeta: []
                                    },
                                    items,
                                    productos,
                                    cliente,
                                    agente
                                }}
                            />
                        </Modal>
                        <div className='u-display_none'>
                            <ImprimirTicket
                                ref={el => (this.componentRef = el)}
                                datos={{
                                    ticket: {
                                        cambio: '0',
                                        fecha: new Date(),
                                        id: idTicketCambioDevolucion,
                                        clave_factura,
                                        descuento: ticket.descuento,
                                        iva: ticket.iva,
                                        ieps: 0,
                                        subtotal: ticket.subtotal
                                    },
                                    pagos: {
                                        efectivo: 0,
                                        monedero: [],//monedero: this.state.pagosMonedero,
                                        puntos: {
                                            monto: 0,
                                            saldo_anterior: 0
                                        },
                                        tarjeta: []
                                    },
                                    items,
                                    productos,
                                    cliente,
                                    agente
                                }}
                                tipo={parseInt(localStorage.getItem('ticketTipo'), 10)}
                            />
                            <ReactToPrint
                                trigger={() => <button id="imprimir" className='c-btn c-btn-cobro'>Cobrar</button>}
                                content={() => this.componentRef}
                            />
                        </div>
                    </div>
                    <div style={{ display: 'flex', justifyContent: 'center' }}>
                        <Alerts
                            type={tipoAlert}
                            value={mensajeAlert}
                            show={mostrarAlert}
                            closeRequest={() => this.setState({ mostrarAlert: false })}
                        />
                    </div>
                </Hotkeys>
            </React.Fragment>
        );
    }
}

export default compose(graphql(TICKET_MUTATION, { name: 'ticketMutation' }), graphql(CREAR_TICKET_MUTATION, { name: 'crearTicketMutation' }))(POS);
/*
||===================================================================
||                        COMPONENTE: POS
||===================================================================
||                          DESCRIPCIÓN
|| Componente principal del punto de venta, donde se administran todas
|| las acciones y variables que se utilizan para realizar ventes. Es
|| el componente mas grande de toda la app.
||===================================================================
||                             STATE
||  * cliente: id del cliente de la compra.
||
||  * agente: id del agente de la compra.
||
||  * items: array de los items de la compra.
||
||  * productos: array de los productos de la compra. Se diferencia de
||  items porque está contine toda la información del producto e items
||  solo el icod y cantidad.
||
||  * ticket: objeto con la información del cobro.
||
||  * showModal: boleano que controla si se muestra el modal de cobro.
||
||  * showModalHijos: boleano que controla si se muestra el modal de
||  producto hijo.
||
||  * showModalCrearCliente: boleano que controla si se muestra el modal
||  para crear clientes.
||
||  * q: string que contiene el query de busqueda para el sidebar.
||
||  * tempProducto: Es utilizado en metodos para construir objetos
||  producto.
||
||  * loadingProducto: boleano para mostrar spinner de cargando producto.
||
||  * loadingTable: boleano para mostrar spinner de cargando en tabla.
||
||  * clienteOpcional: boleano para saber si cliente es opcional.
||
||  * agenteOpcional: boleano para saber si agente es opcional.
||
||  * activoAgregarCliente: boleano para dar focus al modal agregar
||  cliente
||
||  * activoHijo: boleano para dar focus al modal producto hijo.
||
||  * activoCobro: boleano para dar focus al modal de cobro.
||
||  * activoEditarCantidad: boleano para dar focus al input de
||  editar cantidad.
||
||  * editandoCantidad: boleando que indica que se esta editando
||  cantidad en tabla.
||
||  * borrandoItem: boleando que indica que se esta borrando
||  item en tabla.
||
||  * itemsNegativos: boleano que muestra si se esta usando items
||  negativos ya sea por cambio o devolución.
||
||  * ticketVersion2: boleano que dice si el ticket se está
||  generando o fue enviado desde historial de tickets para cambio
||  o devolución.
||
||  * montoMonederoDevolucion: inicialización de monedero siempre
||  en cero.
||
||  * showModalMonedero: boleano que controla si se muestra el modal
||  de monedero.
||
||  * showModalConfirmacion: boleano que controla si se muestra el
||  modal de confirmación de venta para cambio o devolución.
||
||  * idTicketCambioDevolucion: id del ticket para cambio o devolución.
||
||  * guardarTicket: boleano que ayuda en la lógica para saber cuando
||  guardar ticket en la localStorage cuando se sale de la pantalla.
||
||  * mostrarAlert: boleano que es true cuando se muestra el alert.
||
||  * mensajeAlert: string con el mensaje del alert.
||
||  * tipoAlert: string que debe contener el tipo de alert a mostrar.
||===================================================================
||                             FUNCIONES
||  * seleccionarClienteNuevo: Selecciona el cliente al terminar de
||  crearlo.
||
||  * React - componentDidMount: Carga el ticket ya sea en localStorage
||  o mandado del historial de tickets.
||
||  * React - componentWillUnmount: Guarda el ticket en la sessionstorage.
||
||
||  * calcularTotalTemportal: Calcula temporalemente en base a los items
||  el total del ticket mientras que la API responde.
||
||  * cancelar: Cancela la vente y lo borra de localStorage.
||
||
||  * Main:
||
||
||  * validarAgente: Valida si hay agente seleccionado en la venta y si
||  es opcional y no hay agente activo, se selecciona el que está por
||  default.
||
||  * validarCliente:Valida si hay cliente seleccionado en la venta y si
||  es opcional y no hay agente activo, se selecciona el que está por
||  default.
||
||  * calculoFinal:
||
||
||  * mostrarProductoPadre:
||
||
||  * agregarItem:
||
||
||  * cambiarCantidad:
||
||
||  * borrarItem: Borra item del array items.
||
||  * buscarEnProductos: Busca en el array de productos un item en
||  especial y regresa si se encuentra y su indice.
||
||  * buscarEnItems: Busca en el array de items un item en especial
||  y regresa si se encuentra y su indice.
||
||  * mutacionTicket: Realiza el proceso del calculo de costos de la
||  venta actual. El "calculo ticket".
||
||  * getTicket:
||
||
||  * aItemsNegativos:
||
||
||  * formatProductos: Da formato al array de productos para que no
||  se repitan los productos.
||
||  * ticketVigente: Revisa si el ticket coincide con los productos
||  que se manejan en la venta.
||
||  * formatItems: formatea los items para que la cantidad no sea 1
||  sino las repeticiones del array.
||
||  * objetoTemporalProductoItem: generador de objeto producto a traves
||  de un item, este metodo es utilizado en la funcion formatItems.
||
||  * buscarEnItemEspecial: Es el mismo metodo que buscarEnItems pero
||  se utiliza en items donde cada item está en una posición sin
||  importar que se repita.
||
||  * cambiar: Realiza el fetch para hacer el ticket cuando se hace
||  un cambio.
||
||  * agregarAlMonedero: Se ejecuta al finalizar el uso del modal
||  para depositar al monedero, si es
||
||  * seleccionarMonederoYDevolver: Inicia la ejecucion del modal para
||  dar saldo al monedero, si es una devolución el monto ya viene por
||  defecto sino está abierto a escribirlo.
||
||  * onChangeSearch: cambia el query de la busqueda por el valor que
||  recibe.
||
||  * loading: cambia el loading por el boleano que recibe.
||
||  * changeActivoHijo: Cambia el focus del input de modal producto
||  hijo.
||
||  * changeActivoCobro: Cambia el focus del input de modal cobro.
||
||  * changeActivoEditarCantidad: Cambia el focus de el input editar
||  cantidad.
||
||  * changeMonedero:
||
||  * changeMonederoSaldo:
||
||  * onKeyUp: administra el shortcut de F1.
||
||  * mostrarAlert: muestra los alerts de la app.
||===================================================================
||                             OTROS
|| Se encuentra envuelto en un componente llamado Hotkeys para
|| administrar los shortcuts.
||===================================================================
*/