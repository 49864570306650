import React from "react";
import { Route, Redirect } from "react-router-dom";
import auth from "../Auth";
import { withApollo } from 'react-apollo';

const ProtectedRoute2 = ({
    client,
    component: Component,
    ...rest
}) => {
    return (
        <Route
            {...rest}
            render={props => {
                if (auth.isAuthenticated()) {
                    return (
                        <Redirect
                            to={{
                                pathname: "/pos",
                                state: {
                                    from: props.location
                                }
                            }}
                        />
                    );
                } else {
                    return <Component {...props} client={client} />;
                }
            }}
        />
    );
};

export default withApollo(ProtectedRoute2);