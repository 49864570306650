import React from 'react';
import proptypes from 'prop-types';
import OptionsBusqueda from "./OptionsBusqueda";

function ContainerBusqueda({ cerrar, mostrar, agregar, callback, elements, loading }) {
    const props={ callback, cerrar, agregar}
    if (!elements) {
        elements = [];
    }
    return (
        <div className='u-search_container' style={{ display: `${mostrar ? "" : "none"}` }}>
            {
                loading 
                ? 
                <React.Fragment><i className="fas fa-spinner-third fa-spin"></i></React.Fragment> 
                : 
                elements.map(
                    (element, index) => <OptionsBusqueda key={index} {...props} nombre={element.nombre} codigo={element.id ? element.id : element.clicod} />
                )
            }
        </div>
    );
}

ContainerBusqueda.proptypes={ 
    cerrar:proptypes.func, 
    mostrar:proptypes.bool, 
    agregar:proptypes.func, 
    callback:proptypes.func, 
    elements:proptypes.arrayOf(proptypes.shape({
        id:proptypes.string,
        clicod:proptypes.string,
        nombre:proptypes.string
    })),
    loading:proptypes.bool
}


export default ContainerBusqueda;
/*
||===================================================================
||                          COMPONENTE:
||===================================================================
||                          DESCRIPCIÓN
||
||
||
||
||
||
||
||
||
||
||===================================================================
||                             PROPS
||
||
||
||
||
||
||
||
||
||
||===================================================================
||                             STATE
||
||
||
||
||
||
||
||
||
||
||===================================================================
||                             FUNCIONES
||
||
||
||
||
||
||
||
||
||
||===================================================================
||                             OTROS
||
||
||
||
||
||
||
||
||
||
||===================================================================
*/