import React from 'react';
import { Switch, Redirect, Route } from 'react-router-dom';
import POS from '../../components/POS';
import HistorialTickets from '../../components/HistorialTickets';
import  CrearCliente  from '../../components/TestComponent';
import CorteDeCaja from '../../components/CorteDeCaja';
import Ventas from '../../components/Ventas';
import Inventario from '../../components/Inventario';
import Login from '../../components/Login_';
import ProtectedRoute  from './ProtectedRoute';
import ProtectedRoute2 from './ProtectedRoute2';
import Admin from '../../components/Admin';
import PedidosPendientes from '../../components/PedidosPendientes';
import PedidoPendiente from '../../components/PedidoPendiente';

const Routes = () => {
  return(
    <React.Fragment>
      <Switch>
      <ProtectedRoute2 strict exact path='/login' component={Login}/>
        <Route strict exact path='/admin' component={Admin}/>
        <ProtectedRoute strict exact path='/pos' component={POS}/>
        <ProtectedRoute strict exact path='/pedidos' component={PedidosPendientes}/>
        <ProtectedRoute strict exact path='/pedidos/:id' component={PedidoPendiente}/>
        <ProtectedRoute strict exact path='/tickets' component={HistorialTickets}/>
        <ProtectedRoute strict exact path='/test' component={CrearCliente}/>
        <ProtectedRoute strict exact path='/corte' component={CorteDeCaja}/>
        <ProtectedRoute strict exact path='/ventas' component={Ventas}/>
        <ProtectedRoute strict exact path='/inventario' component={Inventario}/>
        <ProtectedRoute2 strict exact path='/login' component={Login}/>
        <Redirect from='/' to='/pos' />
      </Switch>
    </React.Fragment>
  );
}
  
  export default Routes;