import React, { useState } from 'react';
import { Pestanas } from './Pestañas';
import { Tienda } from './Tienda';
import { Prepago } from './Prepago';

export function Pagos({ callbackPestana, client, monto, submit, seleccion, cerrarPedido, cerrarModal, handleAlert, telefono, location }) {
    const tiposDePago = seleccion !== 'tienda' ? [
        'prepago'
    ] : [
            'tienda',
            'prepago'
        ]
    const handleSeleccionado = (tipo) => {
        setSeleccionado(tipo);
        callbackPestana(tipo);
    }
    const [seleccionado, setSeleccionado] = useState(tiposDePago[0]);
    const propsPestañas = { seleccionado, setSeleccionado: handleSeleccionado, tiposDePago, callbackPestana };
    const getContent = () => {
        switch (seleccionado) {
            case 'prepago':
                return <Prepago client={client} monto={monto} submit={submit} cerrarPedido={cerrarPedido} cerrarModal={cerrarModal} handleAlert={handleAlert} telefono={telefono} location={location} />
            default:
                return <Tienda crear={submit} />
        }
    }
    return (
        <div>
            <div className='c-modal__title pagos-omni-title '>Métodos de Pago</div>
            <Pestanas {...propsPestañas} />
            <div className='pagos-omni-container'>
                {
                    getContent()
                }
            </div>
        </div>
    );
};


