import React from 'react'

function CargandoProductoPadre(){
    return (
      <React.Fragment>
        <div className="c-event">
          <div className="c-event__img">
            <img src="/img/image_not_found.png" alt="" />
          </div>
          <div className="c-event__meta">
            <h3 className="c-event__title">Cargando producto...</h3>
          </div>
          <div>
            <h4 className="c-event__place">Cargando código...</h4>
          </div>
        </div>
        <br/>
      </React.Fragment>
    );
}

export default CargandoProductoPadre;
/*
||===================================================================
||                          COMPONENTE:
||===================================================================
||                          DESCRIPCIÓN
||
||
||
||
||
||
||
||
||
||
||===================================================================
||                             PROPS
||
||
||
||
||
||
||
||
||
||
||===================================================================
||                             STATE
||
||
||
||
||
||
||
||
||
||
||===================================================================
||                             FUNCIONES
||
||
||
||
||
||
||
||
||
||
||===================================================================
||                             OTROS
||
||
||
||
||
||
||
||
||
||
||===================================================================
*/