export let ReemplazarDatos = function (html){
    let fecha = new Date();
    fecha=fecha.toLocaleDateString('es-MX');
    html=html.replace('{{razonSocial}}',localStorage.getItem('razonSocial'));
    html=html.replace('{{mail}}',localStorage.getItem('mail')==='null' || localStorage.getItem('mail')===null?'':localStorage.getItem('mail'));
    html=html.replace('{{rfc}}',localStorage.getItem('rfc'));
    html=html.replace('{{tienda}}',localStorage.getItem('nombreTienda'));
    html=html.replace('{{fecha}}',fecha);
    html=html.replace('{{num_ext}}',localStorage.getItem('num_ext'));
    html=html.replace('{{calle}}',localStorage.getItem('calle'));
    html=html.replace('{{colonia}}',localStorage.getItem('colonia'));
    html=html.replace('{{delegacion}}',localStorage.getItem('delegacion'));
    html=html.replace('{{ciudad}}',localStorage.getItem('ciudad'));
    html=html.replace('{{estado}}',localStorage.getItem('estado')==='null' || localStorage.getItem('estado')===null ?'':localStorage.getItem('estado'));
    html=html.replace('{{telefono}}',localStorage.getItem('telefono'));
    html=html.replace('{{direccion}}',`${localStorage.getItem('calle')} ${localStorage.getItem('num_ext')}, ${localStorage.getItem('colonia')}, ${localStorage.getItem('delegacion')}, ${localStorage.getItem('estado')==='null'?'':localStorage.getItem('estado')}`);
    return html;
}