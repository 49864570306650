import React from 'react';
import { Pestana } from './Pestaña';
export const Pestanas = ({ tiposDePago, seleccionado, setSeleccionado }) => {
    return (
        <div className='pestañas-pagos-omni'>
            {
                tiposDePago.map((tipo, i) => <Pestana titulo={tipo} handleClick={() => setSeleccionado(tipo)} seleccionado={seleccionado} primero={i === 0} key={'pestaña'+i} />)
            }
        </div>
    )
}