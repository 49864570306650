import React, { Component } from 'react';
import ProductoContainer from './ProdcutoContainer';
import proptypes from 'prop-types';
class Sidebar extends Component {
    constructor(props) {
        super(props);
        this.state = {
            productos: [],
            tempQ:""
        };
    }

    onChangeSearch = event => {
        const { onChangeSearch } = this.props;
        this.setState({tempQ:event.target.value},()=>{
            onChangeSearch(this.state.tempQ);
        });
    }

    handleEnter=event=>{
        if(event.key==="Enter" || event.key==="Tab"){
            this.onChangeSearch(event);
        }
    }

    render() {
        return (
                <div className="container-right_sidebar">
                    <aside className="c-panel ">
                        <div className="c-field has-icon-right">
                        <span className="c-field__icon">
                        <i className="fa fa-search"></i> 
                    </span>
                            <label className="c-field__label  has-icon-right u-hidden-visually u-hidden-down@tablet" htmlFor="productos">Label</label>
                            <input className="c-input" id="productos" type="text" placeholder="Buscar Producto" onKeyUp={this.handleEnter} onChange={this.onChangeSearch} onFocus={()=>this.props.changeActivoHijo(true)} onBlur={()=>this.props.changeActivoHijo(false)} />
                        </div>
                        <br/>
                        <div className='c-scroll-product'>
                        <ProductoContainer {...this.props}  />
                    </div>
                    </aside>
                </div>
            
        );
    }
}

Sidebar.proptypes={
    changeActivoHijo:proptypes.func,
    loading:proptypes.func,
    agregarAlTicket:proptypes.func,
    onChangeSearch:proptypes.func,
    q:proptypes.string
}

export default Sidebar;
/*
||===================================================================
||                          COMPONENTE:
||===================================================================
||                          DESCRIPCIÓN
||
||
||
||
||
||
||
||
||
||
||===================================================================
||                             PROPS
||
||
||
||
||
||
||
||
||
||
||===================================================================
||                             STATE
||
||
||
||
||
||
||
||
||
||
||===================================================================
||                             FUNCIONES
||
||
||
||
||
||
||
||
||
||
||===================================================================
||                             OTROS
||
||
||
||
||
||
||
||
||
||
||===================================================================
*/