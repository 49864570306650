import React, { useEffect, useState } from 'react';
import gql from 'graphql-tag';
import { useApolloClient } from 'react-apollo';
import { BounceLoader } from 'react-spinners';
const FIREDATA_QUERY = gql`
query($rfc: String!) {
    getFirerbaseData(rfc: $rfc) {
      pagoTienda
    }
}
`;

export function Tienda({ crear }) {
    const client = useApolloClient();
    const [pagoTienda, setPagoTienda] = useState(true);
    const [loading, setLoading] = useState(true);
    useEffect(() => {
        const fetchData = async () => {
            const res = await client.query({
                query: FIREDATA_QUERY,
                variables: {
                    rfc: localStorage.getItem('rfc')
                }
            });
            setPagoTienda(res.data.getFirerbaseData.pagoTienda);
            setLoading(false);
        };
        fetchData();
    }, []);
    if (loading) {
        return (
            <div className='pago-container' style={{ flexDirection: 'column' }}>
                <BounceLoader className='loadingTicket' color={'#36D7B7'} loading={true} heightUnit={'%'} height={25} widthUnit={'%'} width={25} />
            </div>
        );
    }

    if (!pagoTienda) {
        return (
            <div className='pago-container' style={{ flexDirection: 'column' }}>
                Tu empresa no acepta pago en tienda.
            </div>
        );
    }

    return (
        <div className='pago-container' style={{ flexDirection: 'column' }}>
            El pedido se pagará en la tienda.
            <button className='c-btn crear-pedido-boton' onClick={crear}>Crear Pedido</button>
        </div>
    );
}