import React, { Component } from 'react';
import Barras from './Barras';
import Tooltip from './Tooltip';
import proptypes from 'prop-types';

class GraficaBarras extends Component {
    constructor(props) {
        super(props);
        this.state = {
            maximo: 0,
            dia: 0,
            mostrar0: false,
            mostrar1: false,
            mostrar2: false,
            mostrar3: false,
            mostrar4: false,
            mostrar5: false,
            mostrar6: false
        }
    }

    componentDidMount() {
        let fecha = new Date();
        let dia = parseFloat(fecha.getDay());
        this.setState({ dia }, this.getMaximo);
    }

    getMaximo = () => {
        const { datos } = this.props;
        const { maximo } = this.state;
        if (datos) {
            let maximoTemp = datos[0][0];
            datos.forEach(
                dato => {
                    dato.forEach(
                        valor => {
                            if (valor > maximoTemp) maximoTemp = valor;
                        }
                    )
                }
            );
            if (maximoTemp !== maximo) this.setState({ maximo:maximoTemp });
        }
    }

    subtitulo = valor => {
        const { titulo } = this.props;
        switch (titulo) {
            case 'Tickets':
                valor = `${valor} ${valor !== 1 ? 'tickets' : 'ticket'}`
                return valor;
            case 'Ventas':
                valor = new Intl.NumberFormat('es-MX', { style: 'currency', currency: 'MXN' }).format(valor);
                return valor;
            default:
                valor = `${valor} ${valor !== 1 ? 'pzas.' : 'pza.'}`
                return valor;
        }
    }

    esDinero = (dato) => {
        const { titulo } = this.props;
        if (titulo === 'Ventas') {
            return new Intl.NumberFormat('es-MX', { style: 'currency', currency: 'MXN' }).format(dato)
        } else if (titulo === 'Tickets') {
            if (dato !== 1) {
                return `${dato} tickets`
            } else {
                return `${dato} ticket`
            }
        } else {
            if (dato !== 1) {
                return `${dato} piezas`
            } else {
                return `${dato} pieza`
            }
        }
    }

    render() {
        const { children, datos, lefts, titulo } = this.props;
        const { dia, maximo, mostrar0, mostrar1, mostrar2, mostrar3, mostrar4, mostrar5, mostrar6 } = this.state;
        return (
            <div style={{ border: '1px solid #313d4f', width: '217px', height: '149px', borderRadius: '4px', backgroundColor: '#273142', padding: '10px' }}>
                <div style={{ height: '21px', display: 'flex', alignItems: 'center' }}>
                    <h1 style={{ color: 'white', fontSize: '16px', margin: 0 }}>{titulo}</h1>
                </div>
                <div style={{ height: 'calc(100% - 21px)' }}>
                    {
                        children
                            ?
                            children
                            :
                            <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', height: '100%' }}>
                                <div style={{ display: 'flex', marginTop: '7px', height: '38px', alignItems: 'center', justifyContent: 'space-between' }}>
                                    <h1 style={{ color: 'white', fontSize: '26px' }}>{this.subtitulo(datos[dia][1])}</h1>
                                    <p style={{ color: `${parseFloat((datos[dia][1] * 100) / datos[dia][0] - 100) >= 0 ? 'green' : 'red'}`, fontSize: '12px' }}>{datos[dia][0] === 0 ? '' : `${parseFloat((datos[dia][1] * 100) / datos[dia][0] - 100).toFixed(2)}%`}</p>
                                </div>
                                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                    <Tooltip dia='DOM' valorDia={this.esDinero(datos[0][1])} valorDiaAnterior={this.esDinero(datos[0][0])} left={lefts[0]} top='140px' mostrar={mostrar0} />
                                    <div onMouseOver={e => { e.stopPropagation(); if (mostrar0 === false) this.setState({ mostrar0: true }) }} onMouseOut={e => { e.stopPropagation(); if (mostrar0 === true) this.setState({ mostrar0: false }) }}>
                                        <Barras dia='DOM' intDiaActual={dia} maximo={maximo} valorDia={datos[0][1]} valorDiaAnterior={datos[0][0]} />
                                    </div>
                                    <Tooltip dia='LUN' valorDia={this.esDinero(datos[1][1])} valorDiaAnterior={this.esDinero(datos[1][0])} left={lefts[1]} top='140px' mostrar={mostrar1} />
                                    <div onMouseOver={() => this.setState({ mostrar1: true })} onMouseLeave={() => this.setState({ mostrar1: false })}>
                                        <Barras dia='LUN' intDiaActual={dia} maximo={maximo} valorDia={datos[1][1]} valorDiaAnterior={datos[1][0]} />
                                    </div>
                                    <Tooltip dia='MAR' valorDia={this.esDinero(datos[2][1])} valorDiaAnterior={this.esDinero(datos[2][0])} left={lefts[2]} top='140px' mostrar={mostrar2} />
                                    <div onMouseOver={() => this.setState({ mostrar2: true })} onMouseLeave={() => this.setState({ mostrar2: false })}>
                                        <Barras dia='MAR' intDiaActual={dia} maximo={maximo} valorDia={datos[2][1]} valorDiaAnterior={datos[2][0]} />
                                    </div>
                                    <Tooltip dia='MIÉ' valorDia={this.esDinero(datos[3][1])} valorDiaAnterior={this.esDinero(datos[3][0])} left={lefts[3]} top='140px' mostrar={mostrar3} />
                                    <div onMouseOver={() => this.setState({ mostrar3: true })} onMouseLeave={() => this.setState({ mostrar3: false })}>
                                        <Barras dia='MIÉ' intDiaActual={dia} maximo={maximo} valorDia={datos[3][1]} valorDiaAnterior={datos[3][0]} />
                                    </div>
                                    <Tooltip dia='JUE' valorDia={this.esDinero(datos[4][1])} valorDiaAnterior={this.esDinero(datos[4][0])} left={lefts[4]} top='140px' mostrar={mostrar4} />
                                    <div onMouseOver={() => this.setState({ mostrar4: true })} onMouseLeave={() => this.setState({ mostrar4: false })}>
                                        <Barras dia='JUE' intDiaActual={dia} maximo={maximo} valorDia={datos[4][1]} valorDiaAnterior={datos[4][0]} />
                                    </div>
                                    <Tooltip dia='VIE' valorDia={this.esDinero(datos[5][1])} valorDiaAnterior={this.esDinero(datos[5][0])} left={lefts[5]} top='140px' mostrar={mostrar5} />
                                    <div onMouseOver={() => this.setState({ mostrar5: true })} onMouseLeave={() => this.setState({ mostrar5: false })}>
                                        <Barras dia='VIE' intDiaActual={dia} maximo={maximo} valorDia={datos[5][1]} valorDiaAnterior={datos[5][0]} />
                                    </div>
                                    <Tooltip dia='SÁB' valorDia={this.esDinero(datos[6][1])} valorDiaAnterior={this.esDinero(datos[6][0])} left={lefts[6]} top='140px' mostrar={mostrar6} />
                                    <div onMouseOver={() => this.setState({ mostrar6: true })} onMouseLeave={() => this.setState({ mostrar6: false })}>
                                        <Barras dia='SÁB' intDiaActual={dia} maximo={maximo} valorDia={datos[6][1]} valorDiaAnterior={datos[6][0]} />
                                    </div>
                                </div>
                            </div>
                    }
                </div>
            </div>
        );
    }
}


GraficaBarras.proptypes={
    titulo:proptypes.string,
    datos:proptypes.arrayOf(proptypes.arrayOf(proptypes.number)),
    lefts:proptypes.arrayOf(proptypes.string)
}
export default GraficaBarras;