import React, { useState } from 'react';
import gql from 'graphql-tag';
import {Orden} from '../CobroOmni/Orden';

const CREAR_LINK = gql`
mutation($monto:Float!,$idpedido:String!){
    crearLinkPago(monto:$monto,idPedido:$idpedido){
      link
    }
  }
`;

const NOTIFICAR_MUTATION = gql`
mutation($mensaje:String!,$destino:String!){
    enviarNotificacionWA(mensaje:$mensaje, destino:$destino){
      ok
    }
  }
`;


export function Prepago({client,monto, submit, cerrarPedido, cerrarModal, handleAlert, telefono, location}) {
    const [link, setLink] = useState('');
    const [escucharOrden, setEscucharOrden] = useState(false);
    const [idOrden, setIdOrden] = useState(false);
    const [disableButton, setDisableButton] = useState(false);

    const crear = async (e) => {
        try {
            const idpedido = await submit();
            setIdOrden(idpedido);
            const variables = {
                monto,
                idpedido
            };
            const result = await client.mutate({
                mutation: CREAR_LINK,
                variables
            });
            setLink(result.data.crearLinkPago.link);
            if (telefono) {
                const channel = localStorage.getItem('channel');
                await client.mutate({
                    mutation: NOTIFICAR_MUTATION,
                    variables: {
                        mensaje: `Realiza tu pago en: \n ${result.data.crearLinkPago.link}\n`,
                        destino: channel
                    }
                });
            }
            if (!localStorage.getItem('phone')) {
                await navigator.clipboard.writeText(result.data.crearLinkPago.link);
            }
            handleAlert('info','Liga de pago enviada al whatsapp');
            //setEscucharOrden(true);
            //setDisableButton(true);
            const ticketId = localStorage.getItem('phone');
            if (ticketId) {
                localStorage.setItem(ticketId, null);
            }
            location.push(`/pedidos/${idpedido}`);
        } catch (error) {
            handleAlert('danger','Error creando link ' + error);
        }
    }

    if (escucharOrden) {
        return(
            <Orden client={client} id={idOrden} link={link} cerrarPedido={cerrarPedido} cerrarModal={cerrarModal} telefono={telefono} />
        );
    }

    return (
        <div className='pago-container prepago-container'>
            <div>Crea la liga y compartela con el cliente para que realice el pago.</div>
            <button className='c-btn crear-pedido-boton' onClick={crear} disabled={disableButton}>Crear Link</button>
        </div>
    );
}