import React, { Component } from 'react';
import ReactToPrint from 'react-to-print';
import gql from 'graphql-tag';
import { Query } from 'react-apollo';
import { BounceLoader } from 'react-spinners';
import ImprimirCorte from '../../FormatosTickets';
import proptypes from 'prop-types'

export const GET_TICKET = gql`
query getTicket($id:String!){
    getTicket(id:$id){
        clave_factura,
        fecha,
        subtotal,
        iva,
        pagos{
            monto,
            tipo
        },
        items{
            cantidad,
            producto
        }
        productos{
            nombre,
            talla,
            color,
            icod,
            precio,
            descuento
        }
    }
}
`;

class ConfirmacionCorte extends Component {

    timeString = value => {
        let mydate = new Date(value);
        return mydate.toLocaleDateString() + ' - ' + mydate.toLocaleTimeString()
    }

    componentDidMount() {
        const { client } = this.props;
        Object.keys(client.cache.data.data).forEach(key => 
            key.includes('ROOT_QUERY.getTicket') && client.cache.data.delete(key)
          );
    }

    render() {
        const { idTicket, cambio, datos, cerrar } = this.props;
        return (
            <React.Fragment>
                <Query query={GET_TICKET} variables={{ id: idTicket }}>
                    {
                        ({ loading, error, data }) => {
                            if (loading) return (
                            <React.Fragment >
                                <BounceLoader className='loadingTicket' color={'#36D7B7'} loading={true} heightUnit={'%'} height={25} widthUnit={'%'} width={25} />
                            </React.Fragment>
                            )
                            if (error) return <p>Error cargando el ticket {idTicket}</p>;
                            return (
                                <React.Fragment>
                                    <div className='contenido-modal-confirmacion o-page' id='test_id'>
                                        <header className='header-modal-confirmacion '>
                                            <p className='icono-confirmacion'>
                                                <i className='fas fa-check' ></i>
                                            </p>
                                            <img className='c-sidebar__brand-img' src='img/big-logo.png' alt='Logo' />
                                        </header>
                                        <div className='row u-mb-medium u-justify-center'>
                                            <div className='col-xl-9'>
                                                <div className='c-invoice__header'>
                                                    <div className='c-invoice__title '>
                                                        <h4>Fecha</h4>
                                                        <div className='c-invoice__date'>{this.timeString(data.getTicket.fecha)}
                                                        </div>
                                                    </div>
                                                    <div className='c-invoice__title'>
                                                        <h4>Ticket</h4>
                                                        <div className='c-invoice__date'>{idTicket}
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className='c-invoice__details'>
                                                    <div className='c-invoice__company'>
                                                        <span className='u-text-mute u-text-uppercase u-text-xsmall'></span>
                                                        <div className='c-invoice__company-name'>
                                                            Total
                                                                </div>
                                                        <div className='c-invoice__company-address'>
                                                            {new Intl.NumberFormat('es-MX', { style: 'currency', currency: 'MXN' }).format(data.getTicket.subtotal + data.getTicket.iva)}<br />
                                                            <br />

                                                        </div>
                                                    </div>
                                                    <div className='c-invoice__company'>
                                                        <span className='u-text-mute u-text-uppercase u-text-xsmall'></span>
                                                        <div className='c-invoice__company-name'>
                                                            Cambio
                                                                </div>
                                                        <div className='c-invoice__company-address'>
                                                            {cambio}<br />
                                                            <br />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className='c-invoice__buttons'>
                                                    <div className='c-invoice__company'>
                                                        <div className='u-mb-medium'>
                                                            <ReactToPrint
                                                                trigger={() => <button className='c-btn c-btn--print'>
                                                                    <i className='fal fa-print u-mr-xsmall'></i>Reimprimir
                                                                    </button>}
                                                                content={() => this.reimpresionCorte}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className='c-invoice__company'>
                                                        <div className='u-mb-medium'>
                                                            <a className='c-btn c-btn--print' href='#!'>
                                                                <i className='fal fa-file-invoice u-mr-xsmall'></i>Facturar
                                                                    </a>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='footer-modal-confirmacion' align='center'>
                                        <div className='u-display_none'>
                                            <ImprimirCorte
                                                datos={datos}
                                                tipo={parseInt(localStorage.getItem('ticketTipo'),10)}
                                                ref={el => (this.reimpresionCorte = el)} />
                                        </div>
                                        <button align='center' onClick={cerrar} className='c-btn-base c-btn--success boton-modal-confirmacion'>Nueva Venta</button>
                                    </div>
                                </React.Fragment>
                            );
                        }
                    }

                </Query>
            </React.Fragment>
        );
    }

}

ConfirmacionCorte.propTypes = {
    idTicket: proptypes.string,
    cambio: proptypes.string,
    datos: proptypes.shape({
        ticket: proptypes.shape({
            cambio: proptypes.string,
            fecha: proptypes.string,
            id: proptypes.string,
            clave_factura: proptypes.string,
            descuento: proptypes.number,
            iva: proptypes.number,
            ieps: proptypes.number,
            subtotal: proptypes.number
        }),
        pagos: proptypes.shape({
            efectivo: proptypes.number,
            monedero: proptypes.arrayOf(proptypes.shape({
                terminacion: proptypes.string,
                caducidad: proptypes.string,
                monto: proptypes.number,
                saldo_anterior: proptypes.number
            })),
            puntos: proptypes.shape({
                monto: proptypes.number,
                saldo_anterior: proptypes.number
            }),
            tarjeta: proptypes.arrayOf(proptypes.shape({
                terminacion: proptypes.string,
                autorizacion: proptypes.string,
                monto: proptypes.number
            }))
        }),
        items: proptypes.arrayOf(proptypes.shape({
            producto: proptypes.string,
            cantidad: proptypes.number,
            descuento: proptypes.number,
            precio_unitario: proptypes.number
        })),
        productos: proptypes.arrayOf(proptypes.shape({
            nombre: proptypes.string,
            icod: proptypes.string,
            precio: proptypes.number
        })),
        cliente: proptypes.string,
        agente: proptypes.string
    }),
    cerrar: proptypes.func
};
export default ConfirmacionCorte;
/*
||===================================================================
||                    COMPONENTE: ConfirmacionCorte
||===================================================================
||                          DESCRIPCIÓN
||  Pantalla de confirmación al realizar corte parcial o final. Con
||  la posibilidad de impirmir y facturar.
||===================================================================
||                             PROPS
||  * idTicket: String con el id del corte de caja realizado
||
||  * cambio: Para complementar la interfaz y como no hay algun tipo
||  de cambio, tiene un valor de 0
||
||  cerrar:
||
||===================================================================
||                             FUNCIONES
||  * getProductos: crea un array productos pues este no se crea en
||  este endpoint.
||
||  * getPagos: recorre el array de pagos para obtener el monto total
||  de un mismo tipo de pago.
||
||  * timeString: da formato legible a la fecha de la api.
||===================================================================
*/