import React, { Component } from 'react';
import ReactToPrint from 'react-to-print';
import gql from 'graphql-tag';
import { Query } from 'react-apollo';
import {BounceLoader} from "react-spinners"
import ImprimirTicket from '../../../FormatosTickets';
import { Mixpanel } from '../../../../constants/Mixpanel';
import proptypes from 'prop-types';

const GET_TICKET = gql`
query getTicket($id:String!){
    getTicket(id:$id){
        fecha,
        subtotal,
        iva,
        ieps,
        descuento
    }
}
`;

class ConfirmacionCobro extends Component {
    quitarGuion = () => {
        let tempString = this.props.datos.ticket.cambio;
        tempString = tempString.replace(/-/g, ' ');
        const num=parseFloat(tempString);
        return `${new Intl.NumberFormat('es-MX', { style: 'currency', currency: 'MXN' }).format(num)}`;
    }

    timeString = value => {
        const mydate = new Date(value);
        return mydate.toLocaleDateString() + " - " + mydate.toLocaleTimeString()
    }

    componentDidMount(){
        const { client } = this.props;
        Object.keys(client.cache.data.data).forEach(key => 
            key.includes('ROOT_QUERY.getTicket') && client.cache.data.delete(key)
          );
    }

    render() {
        const { idTicket, datos, cancelar } = this.props;
        return (
            <React.Fragment>
                <Query query={GET_TICKET} variables={{ id: idTicket}}>
                    {
                        ({ loading, error, data }) => {
                            if (loading) return <React.Fragment >
                                                    <BounceLoader className="loadingTicket" color={'#36D7B7'} loading={true} heightUnit={"%"} height={25} widthUnit={"%"} width={25}/>
                                                </React.Fragment>
                            if (error) return <p>Nada encontrado :(</p>;
                            return (
                                <React.Fragment>


                                    <div className="contenido-modal-confirmacion o-page">
                                        <header className="header-modal-confirmacion ">
                                            <p className="icono-confirmacion">
                                                <i className="fas fa-check" ></i>
                                            </p>
                                            <img className="c-sidebar__brand-img" src="img/big-logo.png" alt="Logo" />

                                        </header>
                                        <div className='row u-mb-medium u-justify-center'>
                                            <div className='col-xl-9'>
                                                <div className='c-invoice__header'>
                                                    <div className='c-invoice__title '>
                                                        <h4>Fecha</h4>
                                                        <div className="c-invoice__date">{this.timeString(data.getTicket.fecha)}
                                                        </div>
                                                    </div>
                                                    <div className="c-invoice__title">
                                                        <h4>Ticket</h4>
                                                        <div className="c-invoice__date">{idTicket}
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="c-invoice__details">
                                                    <div className="c-invoice__company">
                                                        <span className="u-text-mute u-text-uppercase u-text-xsmall"></span>
                                                        <div className="c-invoice__company-name">
                                                            Total
                                                            </div>
                                                        <div className="c-invoice__company-address">
                                                            {new Intl.NumberFormat('es-MX', { style: 'currency', currency: 'MXN' }).format(data.getTicket.subtotal + data.getTicket.iva + data.getTicket.ieps - data.getTicket.descuento)}<br />
                                                            <br />

                                                        </div>
                                                    </div>
                                                    <div className="c-invoice__company">
                                                        <span className="u-text-mute u-text-uppercase u-text-xsmall"></span>
                                                        <div className="c-invoice__company-name">
                                                            Cambio
                                                            </div>
                                                        <div className="c-invoice__company-address">
                                                            {this.quitarGuion()}<br />
                                                            <br />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="c-invoice__buttons">
                                                    <div className="c-invoice__company">
                                                        <div className="u-mb-medium">
                                                            <ReactToPrint
                                                                trigger={() => <a className="c-btn c-btn--print" onClick={()=>Mixpanel.reimpresionTicketCofirmacion(localStorage.getItem('rfc'))}>
                                                                    <i className="fal fa-print u-mr-xsmall"></i>Reimprimir
                                                                </a>}
                                                                content={() => this.reimpresionTicketConfirmacion}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="c-invoice__company">
                                                        <div className="u-mb-medium">
                                                            <a className="c-btn c-btn--print" href="#!" onClick={()=>Mixpanel.botonFacturar(localStorage.getItem('rfc'))}>
                                                                <i className="fal fa-file-invoice u-mr-xsmall"></i>Facturar
                                                                </a>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="footer-modal-confirmacion" align="center">
                                        <div className='u-display_none'>
                                            <ImprimirTicket 
                                                rad
                                                datos={datos} 
                                                tipo={parseInt(localStorage.getItem('ticketTipo'),10)}
                                                ref={el => (this.reimpresionTicketConfirmacion = el)} />
                                        </div>
                                        <button align="center" onClick={cancelar} className='c-btn-base c-btn--success boton-modal-confirmacion'>Nueva Venta</button>
                                    </div>
                                </React.Fragment>
                            );
                        }
                    }

                </Query>
            </React.Fragment>
        )
    }
}

ConfirmacionCobro.proptypes={
    idTicket:proptypes.string,
    cancelar:proptypes.func,
    datos: proptypes.shape({
        ticket: proptypes.shape({
            cambio: proptypes.string,
            fecha: proptypes.string,
            id: proptypes.string,
            clave_factura: proptypes.string,
            descuento: proptypes.number,
            iva: proptypes.number,
            ieps: proptypes.number,
            subtotal: proptypes.number
        }),
        pagos: proptypes.shape({
            efectivo: proptypes.number,
            monedero: proptypes.arrayOf(proptypes.shape({
                terminacion: proptypes.string,
                caducidad: proptypes.string,
                monto:proptypes.number,
                saldo_anterior: proptypes.number
            })),
            puntos: proptypes.shape({
                monto: proptypes.number,
                saldo_anterior: proptypes.number
            }),
            tarjeta: proptypes.arrayOf(proptypes.shape({
                terminacion:proptypes.string,
                autorizacion:proptypes.string,
                monto:proptypes.number
            }))
        }),
        items: proptypes.arrayOf(proptypes.shape({
            producto:proptypes.string,
            cantidad:proptypes.number,
            descuento:proptypes.number,
            precio_unitario:proptypes.number
        })),
        productos: proptypes.arrayOf(proptypes.shape({
            nombre:proptypes.string,
            icod:proptypes.string,
            precio:proptypes.number
        })),
        cliente: proptypes.string,
        agente: proptypes.string
    })
}

export default ConfirmacionCobro;
/*
||===================================================================
||                          COMPONENTE:
||===================================================================
||                          DESCRIPCIÓN
||
||
||
||
||
||
||
||
||
||
||===================================================================
||                             PROPS
||
||
||
||
||
||
||
||
||
||
||===================================================================
||                             STATE
||
||
||
||
||
||
||
||
||
||
||===================================================================
||                             FUNCIONES
||
||
||
||
||
||
||
||
||
||
||===================================================================
||                             OTROS
||
||
||
||
||
||
||
||
||
||
||===================================================================
*/