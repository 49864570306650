import React, { Component } from 'react';
import FilaAgentes from './FilaAgentes';
import gql from 'graphql-tag';
import Alerts from '../../../constants/Alerts';
const GET_VENTAS_QUERY = gql`
query($tienda:String!){
    getComparacionVentasAgentes(tienda:$tienda){
      agente{
        id,
        nombre
      }
      venta{
        total,
        tickets,
        piezas
      }
    }
  }
`;

class TablaAgentes extends Component {
    constructor(props) {
        super(props);
        this.state = {
            ventasAgentes: [],
            totalSwitch: false,
            piezasSwitch: false,
            ticketsSwitch: false,
            piezasTicketSwitch: false,
            mensajeAlert: '',
            tipoAlert: '',
            mostrarAlert: false
        }
    }

    mostrarAlert = (tipoAlert, mensajeAlert) => {
        this.setState({ mensajeAlert, tipoAlert }, () => this.setState({ mostrarAlert: true }, () => setTimeout(() => this.setState({ mostrarAlert: false }), 5000)));
    }

    compararTotal = (a, b) => a.ventas.total - b.ventas.total

    compararTotal2 = (a, b) => b.ventas.total - a.ventas.total

    compararPiezas = (a, b) => a.ventas.piezas - b.ventas.piezas

    compararPiezas2 = (a, b) => b.ventas.piezas - a.ventas.piezas

    compararTickets = (a, b) => a.ventas.tickets - b.ventas.tickets

    compararTickets2 = (a, b) => b.ventas.tickets - a.ventas.tickets

    compararPiezasTicket = (a, b) => a.ventas.piezasTicket - b.ventas.piezasTicket

    compararPiezasTicket2 = (a, b) => b.ventas.piezasTicket - a.ventas.piezasTicket

    ordenarTotal = () => {
        let { ventasAgentes, totalSwitch } = this.state;
        if (totalSwitch) {
            ventasAgentes.sort(this.compararTotal);
        } else {
            ventasAgentes.sort(this.compararTotal2);
        }
        totalSwitch = !totalSwitch;
        this.setState({ ventasAgentes, totalSwitch });
    }

    ordenarPiezas = () => {
        let { ventasAgentes, piezasSwitch } = this.state;
        if (piezasSwitch) {
            ventasAgentes.sort(this.compararPiezas);
        } else {
            ventasAgentes.sort(this.compararPiezas2);
        }
        piezasSwitch = !piezasSwitch;
        this.setState({ ventasAgentes, piezasSwitch });
    }

    ordenarTickets = () => {
        let { ventasAgentes, ticketsSwitch } = this.state;
        if (ticketsSwitch) {
            ventasAgentes.sort(this.compararTickets);
        } else {
            ventasAgentes.sort(this.compararTickets2);
        }
        ticketsSwitch = !ticketsSwitch;
        this.setState({ ventasAgentes, ticketsSwitch });
    }


    ordenarPiezasTickets = () => {
        let { ventasAgentes, piezasTicketSwitch } = this.state;
        if (piezasTicketSwitch) {
            ventasAgentes.sort(this.compararPiezasTicket);
        } else {
            ventasAgentes.sort(this.compararPiezasTicket2);
        }
        piezasTicketSwitch = !piezasTicketSwitch;
        this.setState({ ventasAgentes, piezasTicketSwitch });
    }

    componentDidMount() {
        const { client } = this.props;
        client.query(
            {
                query: GET_VENTAS_QUERY,
                variables: {
                    tienda: localStorage.getItem('tienda')
                }
            }
        ).then(
            result => this.setState({ ventasAgentes: this.getPiezasTicket(Array.from(result.data.getComparacionVentasAgentes)) })
        ).catch(err => this.mostrarAlert('danger', 'Error al cargar ventas de agentes: '+err.message))
    }

    getPiezasTicket = array => {
        let tempArray = [];
        let tempObj = {};
        array.forEach(
            objeto => {
                tempObj.agente = {}
                tempObj.agente.id = objeto.agente.id;
                tempObj.agente.nombre = objeto.agente.nombre;
                tempObj.ventas = {}
                tempObj.ventas.total = objeto.venta.total;
                tempObj.ventas.tickets = objeto.venta.tickets;
                tempObj.ventas.piezas = objeto.venta.piezas;
                tempObj.ventas.piezasTicket = objeto.venta.piezas / objeto.venta.tickets;
                tempArray.push(tempObj);
                tempObj = {};
            }
        );
        return tempArray;
    }

    render() {
        const { tipoAlert, mensajeAlert, mostrarAlert, ventasAgentes } = this.state;
        return (
            <React.Fragment>
                <div style={{ display: 'flex', justifyContent: 'center', position: 'absolute', width: '100vw', left: 0, top: '53px' }}>
                    <Alerts
                        type={tipoAlert}
                        value={mensajeAlert}
                        show={mostrarAlert}
                        closeRequest={() => this.setState({ mostrarAlert: false })}
                    />
                </div>
                <div className='sales_table'>
                    <h1 style={{ fontSize: '14px', color: 'white', marginBottom: '14px', marginTop: '10px', marginLeft: '10px' }}>Ventas por agente</h1>
                    <div className='sales-base'>
                        <div className='header-tabla-ventas-agentes'>
                            <div className='celda-agente titulo-header-tabla-ventas '>Agente</div>
                            <div className='celda-agente titulo-header-tabla-ventas'>
                                <div className='fl c_p' onClick={this.ordenarTotal}>
                                    <p className='celda-total titulo-header-tabla-ventas mr-9 ' >Total</p>
                                    <i className="fa fa-sort"></i>
                                </div>
                            </div>
                            <div className='celda-piezas titulo-header-tabla-ventas'>
                                <div className='fl c_p' onClick={this.ordenarPiezas}>
                                    <p className=' titulo-header-tabla-ventas mr-9'>Piezas</p>
                                    <i className="fa fa-sort"></i>
                                </div>
                            </div>
                            <div className='celda-tabla-ventas-agentes titulo-header-tabla-ventas'>
                                <div className='fl c_p' onClick={this.ordenarTickets}>
                                    <p className='titulo-header-tabla-ventas mr-9'>Tickets</p>
                                    <i className="fa fa-sort"></i>
                                </div>
                            </div>
                            <div className='celda-tabla-ventas-agentes titulo-header-tabla-ventas'>
                                <div className='fl c_p' onClick={this.ordenarPiezasTickets}>
                                    <p className='titulo-header-tabla-ventas mr-9'>Piezas/Tickets</p>
                                    <i className="fa fa-sort"></i>
                                </div>
                            </div>
                        </div>
                        <React.Fragment>
                            {
                                ventasAgentes.length > 0
                                    ?
                                    <div className='body-tabla-ventas-agentes'>
                                        {
                                            ventasAgentes.map(
                                                venta => <FilaAgentes nombreAgente={venta.agente.nombre} idAgente={venta.agente.id} total={venta.ventas.total} piezas={venta.ventas.piezas} tickets={venta.ventas.tickets} piezasTicket={venta.ventas.piezasTicket} />
                                            )
                                        }
                                    </div>
                                    :
                                    <div style={{display:'flex', justifyContent:'center', padding:'10px 0 10px 0'}}>
                                        <img src='/img/tabla-agentes.png' style={{height:'222px', width:'177px'}} alt='' />
                                    </div>
                            }
                        </React.Fragment>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}
export default TablaAgentes;