import React, { useState, useEffect } from 'react';
import gql from 'graphql-tag';
import BeatLoader from "react-spinners/BeatLoader";

const GET_TRANSPORTES_QUERY = gql`
query($cliente:String!, $agente:String!, $tienda:String!, $tipo_entrega:String!, $items:[ItemInput]!, $lat:Float!, $lon:Float! ){
    getTransportes(cliente:$cliente, tienda:$tienda, tipo_entrega:$tipo_entrega, items:$items, lat:$lat, lon:$lon, agente:$agente){
        transporte
        nombre
        tiempo_entrega
        costo
    }
}
`;

const MAPS_QUERY = gql`
query($query:String!){
    buscarEnGoogleMaps(query:$query){
      lat
      long
    }
  }
`;


export const SeleccionarTransporte = ({ client, agente, items, selectTransporte, cliente, cp, buscar, transporteSelecto, showAlert }) => {
    const [loading, setLoading] = useState(false);
    const [transportes, setTransportes] = useState([]);

    useEffect(()=>{
        const request = async () => {
            if (!cp) {
                return;
            }
            setLoading(true);
            try {
                const result = await client.query({
                    variables: {
                        query:cp
                    },
                    query: MAPS_QUERY
                });
                const { lat, long } = result.data.buscarEnGoogleMaps
                fetchTransportes(lat, long);
            } catch (error) {
                showAlert('danger','Hubo un error buscando su longitud y latitud' + error);
            }
    
        }
        request();
    },[buscar]);


    const fetchTransportes = async (lat, long) => {
        const result = await client.query({
            variables: {
                agente,
                tienda: localStorage.getItem('tienda'),
                "tipo_entrega": "domicilio",
                lat,
                lon: long,
                items,
                cliente
            },
            query: GET_TRANSPORTES_QUERY
        });
        setTransportes(result.data.getTransportes);
        setLoading(false);
    }

    return (
        <div className="seleccionar-transporte-resultados-contain-omni">
            Selecciona Transporte: 
            {
                loading
                    ?
                    <div className="loading-container">
                        <BeatLoader color='#36D7B7' />
                    </div>
                    :
                    transportes.map((transporte, index) => (
                        <div className={`seleccionar-tienda-inputs-box-omni seleccionar-tienda-inputs-box-space-omni ${transporteSelecto === transporte.transporte ? 'transporte-selecto' : ''}`} onClick={() => selectTransporte(transporte.transporte, transporte.costo)} key={'transporte'+index} >
                            <h5 className="c-cobro_label u-mb-small">{transporte.nombre}</h5>
                            <h5 className="c-cobro_label u-mb-small">${transporte.costo}</h5>
                            <h5 className="c-cobro_label u-mb-small">Entrega en {transporte.tiempo_entrega / 60 >= 24 ? `${((transporte.tiempo_entrega / 60) / 24).toFixed(0)} días.` : `${(transporte.tiempo_entrega / 60).toFixed(0)} hrs.`}</h5>
                        </div>
                    ))
            }
        </div>
    );
}