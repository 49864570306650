import React from 'react';
import Modal from 'react-modal';
import proptypes from 'prop-types';

function Tooltip({ mostrar, left, top, dia, valorDia, valorDiaAnterior }){
        return (
            <React.Fragment>
                <Modal isOpen={mostrar} style={{
                    overlay: {
                        backgroundColor: 'rgba(0,0,0,0)',
                        width: "151px",
                        height: "70px",
                        left:0,
                        top:0
                    },
                    content: {
                        left,
                        top,
                        width: "151px",
                        height: "70px",
                        padding: '0',
                        outline: "0",
                        bottom:0,
                        right:0,
                        overflow: "hidden",
                        backgroundColor: "rgba(0,0,0,0)",
                        border: "0"
                    }
                }
                }>
                    <div className='tooltip-graficas'>
                        <div style={{ height: "80%", width: '100%', borderRadius: '4px', backgroundColor: '#222c3c', padding: '12px 16px 12px 16px' }}>
                            {/* Uno */}
                            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                    <div style={{ width: '6px', height: '6px', borderRadius: '50%', backgroundImage: 'linear-gradient(to top, #1991eb, #2da1f8)', marginRight:'6px' }}></div>
                                    <p style={{ color: 'white', fontSize: '10px' }}>{`${dia} - ${getAñoActual()}`}</p>
                                </div>
                                <p style={{ color: 'white', fontSize: '10px' }}>{valorDia}</p>
                            </div>
                            {/* Dos */}
                            <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '8px' }}>
                                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                    <div style={{ width: '6px', height: '6px', borderRadius: '50%', backgroundColor: '#facf55', marginRight:'6px' }}></div>
                                    <p style={{ color: 'white', fontSize: '10px' }}>{`${dia} - ${getAñoPasado()}`}</p>
                                </div>
                                <p style={{ color: 'white', fontSize: '10px' }}>{valorDiaAnterior}</p>
                            </div>
                        </div>
                        <div className='triangle-down'></div>
                    </div>

                </Modal>
            </React.Fragment>
        );
}

function getAñoActual(){
    let d = new Date();
    return d.getFullYear();
}

function getAñoPasado(){
    let d = new Date();
    return parseInt(d.getFullYear(),10)-1;
}

Tooltip.proptypes ={ 
    mostrar:proptypes.func, 
    left:proptypes.string, 
    top:proptypes.string, 
    dia:proptypes.string, 
    valorDia:proptypes.number, 
    valorDiaAnterior:proptypes.number 
}

export default Tooltip;