let _Util ={};
_Util.formatItems=function(items){
    let tempItems = [];
    let tempProducto = [];
    items.forEach(
        (item, index) => {
            if (index === 0 || tempProducto.includes(item.producto) === false) {
                tempProducto.push(item.producto);
                const tempObj = objetoTemporalProductoItem(item.producto, items, item.precio_unitario, item.descuento);
                tempItems.push(tempObj);
            }
        }
    );
    return tempItems;
}

function objetoTemporalProductoItem (producto, array, precio_unitario, descuento){
    let cantidad = 0;
    array.forEach(
        item => {
            if (producto === item.producto) {
                cantidad++;
            }
        }
    );
    const tempObj = {
        producto,
        cantidad,
        precio_unitario,
        descuento
    }
    return tempObj;
}
_Util.getPagos=function(pagos, tipo)  {
    let dinero = 0;
    if(tipo!==null && tipo!==undefined && tipo!=='null' && tipo!=='undefined' ){
        pagos.forEach(
            pago => { if (pago.tipo === ( isNaN(tipo) ? parseInt(tipo,10) : tipo) ) dinero += pago.monto; }
        );
    }
    return dinero;
}

_Util.wait=function (ms=0) {
    return new Promise((resolve)=>{
        setTimeout(()=>resolve(),ms);
    })
}

module.exports = _Util;