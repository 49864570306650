import React from 'react'
import proptypes from 'prop-types'

const TestComponent = ({ title }) => (
  <h1>{title}</h1>
);

TestComponent.proptypes = {
  title: proptypes.string
};

export default TestComponent;