import jwt from 'jwt-decode';
import { decrypt, encrypt } from '../_AES';
import utf8 from 'utf8';

class Auth {
    constructor() {
        this.pidiendo = false;
        this.connString = localStorage.getItem('connString') || '';
        this.token = localStorage.getItem('token') || '';
        if (!this.connString) {
            this.logeado = false;
            this.fetchConnString();
        } else {
            if (localStorage.getItem('username')) {
                this.logeado = true;
            }
            if (!this.token || !this.estaVigenteToken()) {
                this.getToken();
            }
        }
    }

    fetchConnString = async () => {
        const subdomain = window.location.hostname.split('.');
        const rfc = subdomain[0].toUpperCase();
        const ts = Math.floor(Date.now() / 1000)
        const obj = {
            rfc,
            ts
        };
        const body = '\'' + encrypt(process.env.REACT_APP_AES_KEY, JSON.stringify(obj)) + '\'';
        const res = await fetch(process.env.REACT_APP_URL_TOKEN_BROKER + '/cinfo/test', {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json'
            },
            body
        });
        const result = await res.json();
        const token = decrypt(process.env.REACT_APP_AES_KEY, result);
        const tokenInfo = jwt(token);
        const connString = `Database=${tokenInfo.dbname};Data Source=${tokenInfo.dbip};Max Pool Size=200;`;
        this.connString = Buffer.from(connString).toString('base64');
        localStorage.setItem('connString', this.connString);
        await this.getToken();
    }

    getToken = async () => {
        if (!this.pidiendo) {
            this.pidiendo = true;
            const res = await fetch(`${process.env.REACT_APP_REST_BACKEND_URL}/autorizacion?dataId=${this.connString}`, {
                method: 'POST'
            });
            const response = await res.json();
            let datos = Buffer.from(response, 'base64').toString();
            datos = utf8.encode(datos);
            datos = JSON.parse(datos);
            const fcia = JSON.parse(datos.fcia);
            let tempObject = {};
            tempObject.ts = datos.ts;
            tempObject.rfc = datos.rfc;
            localStorage.setItem('rfc', tempObject.rfc);
            tempObject.dbid = datos.dbid;
            tempObject.fcia = {}
            tempObject.fcia.CIARAIZ = fcia.CIARAIZ;
            tempObject.fcia.CIACOLORUNICO = fcia.CIACOLORUNICO;
            tempObject.fcia.CIATALLA = fcia.CIATALLA;
            tempObject.fcia.CIANAME = fcia.CIANAME
            tempObject.fcia.CIAALIAS = fcia.CIAALIAS
            tempObject.fcia.CIACOMPORTA = fcia.CIACOMPORTA;
            datos = '\'' + encrypt(process.env.REACT_APP_AES_KEY, JSON.stringify(tempObject)) + '\'';
            await this.fetchToken(datos);
        }
    }

    fetchToken = async (datos) => {
        const res = await fetch(process.env.REACT_APP_URL_TOKEN_BROKER + '/auth/test', {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json'
            },
            body: datos
        });
        const response = await res.json();
        const auth = decrypt(process.env.REACT_APP_AES_KEY, response);
        this.token = auth;
        localStorage.setItem('token', this.token);
        this.pidiendo = false;
    }

    getAuthorization = () => {
        return this.token;
    }

    getConexionAuth = () => {
        return this.connString;
    }

    estaVigenteToken = () => {
        if (this.token !== '') {
            let tokenInformation = jwt(this.token);
            if (new Date() < new Date(tokenInformation.exp * 1000)) {
                return true
            } else {
                return false
            }
        } else {
            return false;
        }
    }

    hayToken = () => {
        return !!this.token;
    }

    login = () => {
        this.logeado = true;
    }

    logout = () => {
        this.logeado = false;
    }

    isAuthenticated = () => {
        return this.logeado;
    }
}

export default new Auth();