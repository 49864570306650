import React, { useEffect, useState } from 'react';
import gql from 'graphql-tag';
import { PedidoCard } from './PedidoCard';
import { PulseLoader } from 'react-spinners';

const GET_PEDIDOS_QUERY = gql`
    query getPedidosEsperando($rfc:String!){
        getPedidosEsperando(rfc:$rfc){
            id,
            body,
            fecha,
            liga,
            total
        }
    }
`;

const sortFunction = (a, b) => (parseInt(a.fecha) || 0) - (parseInt(b.fecha) || 0);
export default function PedidosPendientes({ client, history }) {
    const [pedidos, setPedidos] = useState([]);
    const [loading, setLoading] = useState(true);
    useEffect(() => {
        clearCache();
        const fetchData = async () => {
            console.log(client.cache.data.data)
            
            const res = await client.query({
                query: GET_PEDIDOS_QUERY,
                variables: {
                    rfc: localStorage.getItem('rfc')
                },
                fetchPolicy: "no-cache"
            });
            setPedidos(res.data.getPedidosEsperando);
            setLoading(false);
            clearCache();
        };
        const intervalId = setInterval(fetchData, 6000);
        return () => {
            clearInterval(intervalId);
        }
    }, []);

    const clearCache = () => {
        Object.keys(client.cache.data.data).forEach(key => {
            if (key.includes('FirebasePedido')) {
                delete client.cache.data.data[key];
            }
        });
        if (client.cache.data.data.ROOT_QUERY) {
            Object.keys(client.cache.data.data.ROOT_QUERY).forEach(key => {
                if (key.includes('getPedidosEsperando')) {
                    delete client.cache.data.data.ROOT_QUERY[key];
                }
            });
        }
    }

    if (loading) {
        return (
            <div style={{ width: '100%', height: 'calc(100vh - 53px)', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <PulseLoader color='#36D7B7' loading={true} heightUnit='px' height={100} widthUnit='px' width={100} />
            </div>
        );
    }
    return (
        <div style={{ marginLeft: '60px', padding: '10px', display: 'flex', flexWrap: 'wrap' }}>
            {
                pedidos.sort(sortFunction).map(pedido => (
                    <PedidoCard key={`pedido-${pedido.id}`} {...pedido} client={client} history={history} />
                ))
            }
        </div>
    );
}