import React, { Component } from 'react';
import Fecha from '../Fecha';
import Total from './Total';
import Parcial from './Parcial';
import Ventas from './Ventas';
import gql from 'graphql-tag';
import ConfirmacionCorte from './ConfirmacionCorte';
import Modal from 'react-modal';
import NavegacionCorte from './NavegacionCorte';
import ReactToPrint from 'react-to-print';
import ImprimirCorte from '../FormatosTickets';
import Alerts from '../../constants/Alerts';
import { Mixpanel } from '../../constants/Mixpanel';
const CORTE_MUTATION = gql`
mutation corte($cajero:String!,$pagos:[PagoInput]!,$comentario:String,$tienda:String!){
    corte(cajero:$cajero,pagos:$pagos,tienda:$tienda,comentario:$comentario){
        id
    }
}
`;
const GET_TICKET = gql`
query getTicket($id:String!){
    getTicket(id:$id){
        fecha,
        subtotal,
        iva,
        agente,
        cliente,
        pagos{
            monto,
            tipo
        },
        items{
            cantidad,
            producto,
            precio_unitario
        },
        productos{
          nombre,
          talla,
          color,
          icod,
          precio,
          imagen,
          descuento
      }
    }
}
`;
const GET_VENTAS_QUERY = gql`
query($tienda:String!){
    getComparacionVentasDiarias(tienda:$tienda){
  		comparativo{
        actual{
          pagos{
            monto,
            tipo
          },
          total,
          piezas,
          tickets
        },
        pasado{
          pagos{
            monto,
            tipo
          },
          total,
          piezas,
          tickets
        }
      }
    }
}
`;

class CorteDeCaja extends Component {
  constructor(props) {
    super(props);
    this.state = {
      parcial: true,
      margin: '100vh',
      pagos: [],
      comentario: '',
      corteId: '',
      showModalConfirmacion: false,
      Tfecha: '',
      Tsubtotal: 0,
      Tiva: 0,
      Tpagos: [],
      Titems: [],
      tipoAlert: '',
      mensajeAlert: '',
      mostrarAlert: false,
      display: 'none',
      Tproductos: [],
      total: 0,
      loadingVentas:true,
      dataVentas:null,
      errorVentas:null
    }
  }

  componentDidMount() {
    Mixpanel.corteMounted(localStorage.getItem('rfc'));
    this.props.client.query({
      variables: {
          tienda: localStorage.getItem('tienda')
      },
      query: GET_VENTAS_QUERY
  }).then(
    result => {
        const { data } = result;
        this.setState({ dataVentas:data, loadingVentas:false });
    }
).catch(err => this.mostrarAlert('danger', 'Error cargando ventas del día: ' + err.message))
  }

  cambiarTotal = total => this.setState({ total })

  mostrar = mostrar => {
    if (!mostrar) {
      this.setState({ margin: '100vh', display: 'none' });
    } else {
      this.setState({ margin: 'calc(100vh - 127px)', display: 'flex' });
    }
  }

  cortar = () => {
    const { pagos, comentario, parcial, total } = this.state;
    const { client } = this.props;
    if (pagos.length > 0) {
      client.mutate({
        mutation:CORTE_MUTATION,
        variables: {
          cajero: localStorage.getItem('username'),
          pagos,
          comentario,
          tienda: localStorage.getItem('tienda')
        }
      }).then(
        response => {
          if (parcial) {
            const prom = (pagos[0].monto * 100) / total;
            Mixpanel.corteParcial(localStorage.getItem('rfc'), prom);
          }
          this.setState({ corteId: response.data.corte.id },
            () => {
              const { corteId } = this.state;
              client.query({
                query: GET_TICKET,
                variables: {
                  id: corteId
                }
              }).then(
                result => {
                  const { fecha, subtotal, iva, pagos, items, productos, cliente, agente } = result.data.getTicket;
                  this.setState({
                    Tfecha: fecha,
                    Tsubtotal: subtotal,
                    Tiva: iva,
                    Tpagos: pagos,
                    Titems: items,
                    Tproductos: productos,
                    Tcliente: cliente,
                    Tagente: agente
                  },
                    () => {
                      document.getElementById('imprimirCorte').click();
                      this.setState({ showModalConfirmacion: true });
                    });
                }
              ).catch(err => this.mostrarAlert('danger', 'Error obteniendo corte de caja realizado' + err.message));
            }
          );
        }
      ).catch(err => this.mostrarAlert('danger', 'Error al hacer corte de caja' + err.message));
    }
  }

  changePagos = (pagos, callback) => this.setState({ pagos }, () => { if (callback) callback() });

  changeComentario = (comentario, callback) => this.setState({ comentario }, () => { if (callback) callback() });

  changeParcial = parcial =>{
    this.props.client.cache.reset();
    this.setState({ parcial })
  }

  quitarGuion = cambio => {
    let tempString = cambio + '';
    tempString = tempString.replace(/-/g, ' ');
    let num = parseFloat(tempString);
    return `${new Intl.NumberFormat('es-MX', { style: 'currency', currency: 'MXN' }).format(num)}`;
  }

  getEfectivo = () => {
    const { Tpagos } = this.state;
    const tempObj = JSON.parse(localStorage.getItem('efectivo'));
    const tarjeta = tempObj.tipo;
    const pagosTarjeta = Tpagos.filter(
      pago => pago.tipo === tarjeta
    );
    return pagosTarjeta
  }

  getTarjeta = () => {
    const { Tpagos } = this.state;
    const tempObj = JSON.parse(localStorage.getItem('tarjeta'));
    const tarjeta = tempObj.tipo;
    const pagosTarjeta = Tpagos.filter(
      pago => pago.tipo === tarjeta
    );
    return pagosTarjeta
  }

  timeString = value => {
    let mydate = new Date(value);
    return mydate.toLocaleDateString() + ' - ' + mydate.toLocaleTimeString()
  }

  mostrarAlert = (tipoAlert, mensajeAlert) => {
    this.setState({ mensajeAlert, tipoAlert }, () => this.setState({ mostrarAlert: true }, () => setTimeout(() => this.setState({ mostrarAlert: false }), 5000)));
  }

  render() {
    const { history, client } = this.props;
    const { tipoAlert, mensajeAlert, mostrarAlert, margin, display, parcial, showModalConfirmacion, corteId, Tsubtotal, Titems, Tproductos, Tcliente, Tagente, Tiva, loadingVentas, dataVentas, errorVentas } = this.state;
    return (
      <React.Fragment>
        <div style={{ display: 'flex', justifyContent: 'center', position: 'absolute', width: '100vw' }}>
          <Alerts
            type={tipoAlert}
            value={mensajeAlert}
            show={mostrarAlert}
            closeRequest={() => this.setState({ mostrarAlert: false })}
          />
        </div>
        <div className='main-corte' style={{ marginTop: margin, display: `${display}` }}>
          <div className='box-cut_alert '>
            <p className='u-color-white u-text-large '>{`¿Deseas proceder con el ${parcial === true ? 'corte parcial' : 'corte de caja'}?`}</p>
            <div className='u-flex'>
              <button onClick={this.cortar} className='corte-ask_btn c-btn--success'>
                <i className='far fa-check'></i>
                &nbsp;Sí
              </button>
              <div className='u-width-4'></div>
              <button onClick={() => this.mostrar(false)} className='btn-delete_corte  c-btn c-btn--info'>
                <i className='far fa-ban'></i>
                &nbsp;No
              </button>
            </div>
          </div>
        </div>
        <div className='historialTicketsContainer2'>
          <Fecha titulo={'Corte de Caja'} />
          <Ventas data={dataVentas} loading={loadingVentas} error={errorVentas} />
          {
            parcial
              ?
              <React.Fragment>
                <Parcial cambiarTotal={this.cambiarTotal} mostrar={this.mostrar} changeComentario={this.changeComentario} changePagos={this.changePagos} client={client}>
                  <NavegacionCorte parcial={parcial} changeParcial={this.changeParcial} />
                </Parcial>
              </React.Fragment>
              :
              <React.Fragment>
                <Total mostrar={this.mostrar} changeComentario={this.changeComentario} changePagos={this.changePagos} client={client} cambiarTotal={this.cambiarTotal}>
                  <NavegacionCorte parcial={parcial} changeParcial={this.changeParcial} />
                </Total>
              </React.Fragment>
          }
        </div>
        <Modal style={{
          overlay: {
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            zIndex: 500,
            backgroundColor: '#273142'
          },
          content: {
            width: '750px',
            display: 'block',
            position: 'relative',
            top: 'calc((100% - 544px) / 2)',
            right: '0',
            bottom: '0',
            left: 'calc((100% - 750px) / 2)',
            padding: '0',
            zIndex: '-1',
            outline: '0',
            overflow: 'hidden',
            backgroundColor: 'rgba(0,0,0,0)',
            border: '0',
            boxShadow: '0px 30px 60px -19px rgba(0,0,0,0.75)'
          }
        }} isOpen={showModalConfirmacion} >
          <ConfirmacionCorte
            cerrar={() => history.push('/')}
            cambio={'0'}
            idTicket={corteId}
            datos={{
              ticket: {
                cambio: '0',
                fecha: new Date(),
                id: corteId,
                clave_factura: '',
                descuento: 0,
                iva: Tiva,
                ieps: 0,
                subtotal: Tsubtotal
              },
              pagos: {
                efectivo: this.getEfectivo(),
                monedero: [],
                puntos: {
                  monto: 0,
                  saldo_anterior: 0
                },
                tarjeta: this.getTarjeta()
              },
              items: Titems,
              productos: Tproductos,
              cliente: Tcliente,
              agente: Tagente
            }}
            client={client}
          />
        </Modal>
        <ReactToPrint
          trigger={() => <button id='imprimirCorte'>Imprimir</button>}
          content={() => this.ImprimirCorte}
        />
        <div className='u-display_none'>
          <ImprimirCorte
            date={this.timeString(this.state.Tfecha)}
            datos={{
              ticket: {
                cambio: '0',
                fecha: new Date(),
                id: corteId,
                clave_factura: '',
                descuento: 0,
                iva: Tiva,
                ieps: 0,
                subtotal: Tsubtotal
              },
              pagos: {
                efectivo: this.getEfectivo(),
                monedero: [],
                puntos: {
                  monto: 0,
                  saldo_anterior: 0
                },
                tarjeta: this.getTarjeta()
              },
              items: Titems,
              productos: Tproductos,
              cliente: Tcliente,
              agente: Tagente
            }}
            tipo={parseInt(localStorage.getItem('ticketTipo'),10)}
            ref={el => (this.ImprimirCorte = el)}
          />
        </div>
      </React.Fragment>
    );
  }
}

export default CorteDeCaja;
/*
||===================================================================
||                    COMPONENTE: Corte de caja
||===================================================================
||                          DESCRIPCIÓN
|| Es el componente principal y contenedor de toda la sección de corte
|| a partir de aquí se realizan todos los cortes y contiene toda la
|| información necesaria en su state.
||===================================================================
||                             PROPS
||  * corte: Este componente esta envuelto en un mutation de graphql
||  para realizar el corte de caja.
||===================================================================
||                             STATE
||  * parcial: es un boleano que representa cual de los dos tipos de
||  corte está activo para mostrar la UI corresponendiente, true si
||  si el corte parcial es el actual o false si es el total.
||
||  * margin: Es un string que contiene el margen que se utiliza para
||  mostrar el alert de confirmación si se quiere hacer el corte.
||
||  * pagos: Array que contiene los pagos para hacer el corte, si es
||  parcial solo contiene un pago en efectivo pero si es total,
||  contendrá todos los que lo componen normalmente
||
||  *comentario: Donde se pueden escribir notas al hacer el corte de
||  caja. (String)
||
||  *corteId: Una vez realizado el corte, aquí se guarda el id en un
||  String para mostrar los detalles del corte en el modal de corte
||  realizado o confirmacionCorte
||
||  *showModalConfirmacion: Es un boleano que una vez completado el
||  corte se vuelve true y muestra el modal de corte finalizado.
||===================================================================
||                             FUNCIONES
||  * changeComentario: recibe un string y lo guarda como comentario
||  en el state, acepta callback
||
||  * changePagos: recibe un array y lo guarda como pagos en el state,
||  acepta callback
||
||  * changeParcial: recibe un boleano y lo guarda como parcial en el
||  state
||
||  * cortar: realiza el corte de caja
||
||  * mostrar: recibe un boleano donde true indica que se muestre y
||  false lo contrario.
||
||  *mostrarAlert: muestra los alerts de la app.
||===================================================================
*/