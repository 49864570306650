import React from 'react';
import FilaProducto from './FilaProducto';
import proptypes from 'prop-types';

function TablaProducto({ productos, loadingTable, editandoCantidad, borrandoItem, items, cambiarCantidad, borrarItem, changeActivoEditarCantidad }) {
    if (productos.length < 1) {
        return (
            <React.Fragment>
                <table className='c-table-empty'>
                    <thead className='c-table__head--slim-empty'>
                        <tr className='c-table__row'>
                            <th className='c-table__cell c-table__cell--head' >{' '}</th>
                            <th className='c-table__cell c-table__cell--head' style={{ marginLeft: '13%' }} >Nombre</th>
                            <th className='c-table__cell c-table__cell--head ' style={{ marginLeft: '11%' }}>Código</th>
                            <th className='c-table__cell c-table__cell--head ' style={{ marginLeft: '4%' }}>Cantidad</th>
                            <th className='c-table__cell c-table__cell--head '>Precio</th>
                            <th className='c-table__cell c-table__cell--head '>Descuento</th>
                            <th className='c-table__cell c-table__cell--head '>Importe</th>
                            <th className='c-table__cell c-table__cell--head u-widht-66'>{' '}</th>
                        </tr>
                    </thead>
                </table>
                <div className="carrito-vacio" >
                    <div className="fake-cell"></div>
                    <div className="fake-cell"></div>
                    <div className="fake-cell"></div>
                    <div className="fake-cell"></div>
                    <div className="fake-cell"></div>
                    <div className="fake-cell"></div>
                    <div className="fake-cell"></div>
                    <div className="fake-cell"></div>
                    <div className="fake-cell"></div>
                    <div className="fake-cell"></div>
                    <div className="fake-cell"></div>
                    <div className="fake-cell"></div>
                    <div className="fake-cell"></div>
                    <div className="fake-cell"></div>
                    <div className="fake-cell"></div>
                    <div className="fake-cell"></div>
                    <div className="fake-cell"></div>
                    <div className="fake-cell"></div>
                    <div className="fake-cell"></div>
                    <div className="fake-cell"></div>
                    <div className="fake-cell"></div>
                    <div className="fake-cell"></div>
                    <div className="fake-cell"></div>
                    <div className="fake-cell"></div>
                    <div className="fake-cell"></div>
                    <div className="fake-cell"></div>
                    <div className="fake-cell"></div>
                    <div className="fake-cell"></div>
                    <div className="fake-cell"></div>
                    <div className="fake-cell"></div>
                </div>
            </React.Fragment>
        )
    } else {
        return (
            <React.Fragment>
                <table className='c-table'>
                    <thead className='c-table__head--slim c-messages'>
                        <tr className='c-table__row'>
                            <th className='c-table__cell c-table__cell--head' >{' '}</th>
                            <th className='c-table__cell c-table__cell--head' style={{ marginLeft: '13%' }} >Nombre</th>
                            <th className='c-table__cell c-table__cell--head ' style={{ marginLeft: '11%' }}>Código</th>
                            <th className='c-table__cell c-table__cell--head ' style={{ marginLeft: '4%' }}>Cantidad</th>
                            <th className='c-table__cell c-table__cell--head '>Precio</th>
                            <th className='c-table__cell c-table__cell--head '>Descuento</th>
                            <th className='c-table__cell c-table__cell--head '>Importe</th>
                            <th className='c-table__cell c-table__cell--head u-width-66'>{' '}</th>
                        </tr>
                    </thead>
                </table>
                <div className="scroll-table-productos">
                    <div className="c-table c-table--highlight" cellSpacing="0" cellPadding="1">
                        <div>
                            {
                                loadingTable && !editandoCantidad && !borrandoItem
                                    ?
                                    <React.Fragment>
                                        <div className='c-table__row'>
                                            <div className='c-table__cell'>
                                                <div className='table-container'>
                                                    <div className='img-tabla-productos'>
                                                        <img className="loading u-boder-radius" align="center" src="/img/image_not_found.png" alt='' />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='c-table__cell c-width_calc u-color-white'>
                                                Cargando...
                                                    <br />
                                                <div className='u-color-my-primary'>
                                                    {`Color: N/A | Talla:N/A`}
                                                </div>
                                            </div>
                                            <div className='c-table__cell c-width_calc u-color-white'>0000000</div>
                                            <div className='c-table__cell u-color-white'>
                                                <div className='o-media__body u-color-white'>
                                                    0
                                                                <small >pzas.</small>
                                                </div>
                                            </div>
                                            <div className='c-table__cell u-color-white '>$0.00</div>
                                            <div className='c-table__cell u-color-white '>0%</div>
                                            <div className='c-table__cell u-color-white '>$0.00</div>
                                            <div className='c-table__cell delete-container'><div className="borrarItem"><i className='far fa-times'></i></div></div>
                                        </div>
                                    </React.Fragment>
                                    :
                                    null
                            }
                            {
                                propsToTable(productos, items, cambiarCantidad, borrarItem, changeActivoEditarCantidad, borrandoItem)
                            }
                        </div>
                    </div>
                </div>
            </React.Fragment>
        )
    }
}

function propsToTable( productos, items, cambiarCantidad, borrarItem, changeActivoEditarCantidad, borrandoItem ) {
    let productosT =Array.from(productos);
    productosT.reverse();
    return productosT.map(
        (producto,index) => {
            return (
                <div key={index}>
                    {
                        items.map(
                            (item, index) => {
                                const props={ changeActivoEditarCantidad, producto, item, borrarItem, cambiarCantidad, borrandoItem};
                                if (item.producto === producto.icod){
                                    return <FilaProducto key={index} {...props} />
                                }else{
                                    return null;
                                }
                            }
                        )
                    }
                </div>
            );
        }
    )
}

TablaProducto.proptypes = { 
    productos:proptypes.arrayOf(proptypes.shape({
        imagen:proptypes.string,
        nombre:proptypes.string,
        icod:proptypes.string,
        precio:proptypes.number,
        talla:proptypes.string,
        color:proptypes.string,
        descuento:proptypes.number
    })), 
    loadingTable:proptypes.bool, 
    editandoCantidad:proptypes.bool, 
    borrandoItem:proptypes.bool, 
    items:proptypes.arrayOf(proptypes.shape({
        producto:proptypes.string,
        cantidad:proptypes.number,
        descuento:proptypes.number,
        precio_unitario:proptypes.number
    })), 
    cambiarCantidad:proptypes.func, 
    borrarItem:proptypes.func, 
    changeActivoEditarCantidad:proptypes.func
}

export default TablaProducto;
/*
borrandoItem={borrandoItem} 
editandoCantidad={editandoCantidad} 
changeActivoEditarCantidad={changeActivoEditarCantidad} 
loadingTable={loading} 
cambiarCantidad={cambiarCantidad} 
productos={productos} 
items={items} 
borrarItem={borrarItem}
||===================================================================
||                          COMPONENTE:
||===================================================================
||                          DESCRIPCIÓN
||
||
||
||
||
||
||
||
||
||
||===================================================================
||                             PROPS
||
||
||
||
||
||
||
||
||
||
||===================================================================
||                             STATE
||
||
||
||
||
||
||
||
||
||
||===================================================================
||                             FUNCIONES
||
||
||
||
||
||
||
||
||
||
||===================================================================
||                             OTROS
||
||
||
||
||
||
||
||
||
||
||===================================================================
*/