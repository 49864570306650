import React, { useState, useEffect } from 'react';
import gql from 'graphql-tag';
import BeatLoader from "react-spinners/BeatLoader";
import { CrearDireccion } from './CrearDireccion';

const GET_DIRECCIONES_QUERY = gql`
query($cliente:String!){
    getDirecciones(cliente:$cliente){
        id
        nombre
        telefono
        calle
        num_ext
        num_int
        colonia
        delegacion
        ciudad
        estado
        cp
    }
}
`;


export const SeleccionarDireccion = ({ client, cliente, selectDireccion }) => {
    const [loading, setLoading] = useState(true);
    const [direcciones, setDirecciones] = useState([]);
    const [crearDir, setCrearDir] = useState(false);

    useEffect(() => {
        const fetchDirecciones = async () => {
            const result = await client.query({
                variables: {
                    cliente
                },
                query: GET_DIRECCIONES_QUERY
            });
            setDirecciones(result.data.getDirecciones);
            setLoading(false);
        }
        fetchDirecciones();
    }, []);

    if (crearDir) {
        return <CrearDireccion cliente={cliente} client={client} selectDireccion={selectDireccion} />
    }

    return (
        <div className="seleccionar-direccion-container">
            <button className="c-btn c-btn--pills u-mr-xsmall crear-dir-btn" onClick={() => setCrearDir(true)}>Crear Dirección</button>
            <div style={{color:'white'}}>Selecciona dirección existente:</div>
            <div className="direcciones-list-container">
                {
                    loading
                        ?
                        <div className="loading-container">
                            <BeatLoader color='#36D7B7' />
                        </div>
                        :
                        direcciones.map((direccion, index) => (
                            <div className="seleccionar-tienda-inputs-box-omni seleccionar-tienda-inputs-box-space-omni" onClick={() => selectDireccion(direccion.id, direccion.cp)} key={'direccion'+index} >
                                <div className="c-cobro_label u-mb-small">{direccion.nombre}</div>
                                <div className="c-cobro_label u-mb-small">{`${direccion.calle} ${direccion.num_ext} ${direccion.num_int} ${direccion.colonia} ${direccion.delegacion} ${direccion.ciudad} ${direccion.estado} cp. ${direccion.cp}`}</div>
                            </div>
                        ))
                }
            </div>
        </div>
    );
}