import React, { Component } from 'react';
import gql from 'graphql-tag';
import Fecha from '../Fecha'
import ImprimirTicket from './ImprimirTicketRealizado';
import ReactToPrint from 'react-to-print';
import Modal from 'react-modal';
import Alerts from '../../constants/Alerts';
import Hotkeys from 'react-hot-keys';
import { Mixpanel } from '../../constants/Mixpanel';
import { formatItems} from '../../constants/Util';

const GET_TICKETS_QUERY2 = gql`
query($tienda:String!,$finicio:String,$ffinal:String){
    getTicketFecha(tienda:$tienda,finicio:$finicio,ffinal:$ffinal){
    fecha,
    id,
    cliente,
    agente,
    subtotal,
    ieps,
    iva,
    descuento
    }
}
`;

const GET_TICKET_QUERY = gql`
    query($id:String!){
        getTicket(id:$id){
        items{
            cantidad,
            descuento,
            producto,
            precio_unitario
        },
        productos{
            nombre,
            talla,
            color,
            icod,
            imagen,
            descuento,
            precio
            },
        pagos{
            tipo,
            monto
        },
        subtotal,
        iva,
        ieps,
        descuento,
        agente,
        cliente,
        fecha
        }
    }
`;

class HistorialTickets extends Component {
    constructor(props) {
        super(props)
        this.state = {
            ticketSeleccionado: '',
            finicio: '',
            ffinal: '',
            statusImpresion: false,
            vacio: true,
            ticketSeleccionadoItems: [],
            ticketSeleccionadoDescuento: 0,
            ticketSeleccionadoSubtotal: 0,
            ticketSeleccionadoTotal: 0,
            ticketSeleccionadoIva: 0,
            ticketSeleccionadoPagos: [],
            tickets: [],
            ticketSeleccionadoAgente: '',
            ticketSeleccionadoCliente: '',
            mostrarAlert: false,
            mensajeAlert: '',
            tipoAlert: '',
            buscado: false,
            ticketSeleccionadoFecha: '',
            indexTicketSeleccionado: 0,
            activoCambio: false,
            itemsCambio: [],
            tiposPago: {}
        }
    }

    getItems = items => {
        let total = 0;
        items.forEach(
            item => total += item.cantidad
        );
        if (total === 1) {
            return <React.Fragment>{`${total} pza.`}</React.Fragment>;
        } else {
            return <React.Fragment>{`${total} pzas.`}</React.Fragment>;
        }
    }

    handleCheckCambio = (event, icod) => {
        const operacion = event.target.checked;
        let { itemsCambio } = this.state;
        if (operacion) {
            //Agregar a productos a ticket
            itemsCambio.push(icod);
        } else {
            //borrar de productos del ticket
            const index = itemsCambio.indexOf(icod);
            itemsCambio.splice(index, 1);
        }
        this.setState({ itemsCambio });
    }

    mapearItems = () => {
        const { ticketSeleccionadoItems, ticketSeleccionadoProductos, activoCambio } = this.state
        let Titems = formatItems(ticketSeleccionadoItems);
        let Tproductos = ticketSeleccionadoProductos;
        return (
            <React.Fragment>
                {
                    Titems.map(
                        (item, index) => {
                            let productoInfo;
                            Tproductos.forEach(
                                producto => {
                                    if (item.producto === producto.icod) {
                                        productoInfo = producto;
                                    }
                                }
                            );
                            return (
                                <div key={index} className='c-list-container'>
                                    <div className='boxes' style={{ width: `calc(100% / auto`, display: `${activoCambio ? '' : 'none'}` }}>
                                        <div className='control-group'>
                                            <label className='control control--checkbox'>
                                                <input type='checkbox' onChange={e => this.handleCheckCambio(e, productoInfo.icod)} />
                                                <div className='control__indicator'>
                                                </div>
                                            </label>
                                        </div>
                                    </div>
                                    <div className='boxes' style={{ width: `calc(100% / ${activoCambio ? '0' : '6'})` }}>
                                        <div className='c-fileitem__img'>
                                            <img src={productoInfo.imagen} alt='' onError={(e) => { e.target.src = '/img/image_not_found.png' }} />
                                        </div>
                                    </div>
                                    <div className='boxes' style={{ width: `calc(100% / ${activoCambio ? '5' : '5'})` }}>
                                        <div className='c-media__body e-table__cell'>
                                            <div className='name-product'>
                                                {productoInfo.nombre}
                                                <span className='c-info-ticket u-block u-text-mute'>
                                                    Color: {productoInfo.color} | Talla: {productoInfo.talla}
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='boxes' style={{ width: `calc(100% / ${activoCambio ? '4.7' : '4.5'})` }}>
                                        <div className='o-media__body e-table__cell mt-21 o-code_id' >
                                            {productoInfo.icod}
                                        </div>
                                    </div>
                                    <div className='boxes' style={{ width: `calc(100% / ${activoCambio ? '14' : '12'})` }}>
                                        <div className='o-media__body e-table__cell mt-21' style={{ marginleft: '14px' }}>
                                            {item.cantidad === 1 ? `${item.cantidad} pza.` : `${item.cantidad} pzas.`}
                                        </div>
                                    </div>
                                    <div className='boxes' style={{ width: `calc(100% / ${activoCambio ? '10.9' : '11'})` }}>
                                        <div className='o-media__body e-table__cell mt-21'>
                                            {new Intl.NumberFormat('es-MX', { style: 'currency', currency: 'MXN' }).format(productoInfo.precio)}
                                        </div>
                                    </div>

                                    <div className='boxes' style={{ width: `calc(100% / ${activoCambio ? '12' : '10'})` }}>
                                        <div className='o-media__body e-table__cell  mt-21' style={{ marginleft: '7px' }}>
                                            {item.descuento === null ? '0%' : `${productoInfo.descuento}%`}
                                        </div>
                                    </div>
                                    <div className='boxes' style={{ width: `calc(100% / ${activoCambio ? '10' : '11'})` }}>
                                        <div className='o-media__body e-table__cell mt-21'>
                                            {new Intl.NumberFormat('es-MX', { style: 'currency', currency: 'MXN' }).format((item.precio_unitario * this.getDescuento(item.descuento)) * item.cantidad)}
                                        </div>
                                    </div>
                                </div>
                            )
                        }
                    )
                }
            </React.Fragment>
            );
    }

    componentDidMount() {
        const efectivo = JSON.parse(localStorage.getItem('efectivo'));
        const tarjeta = JSON.parse(localStorage.getItem('tarjeta'));
        const puntos = JSON.parse(localStorage.getItem('puntos'));
        const monedero = JSON.parse(localStorage.getItem('monedero'));
        const otros = JSON.parse(localStorage.getItem('otros'));
        const tiposPago = { efectivo, tarjeta, puntos, monedero, otros };
        this.setState(
            { tiposPago },
            () => {
                let dat = new Date();
                this.setState({ finicio: `${dat.getFullYear()}-${dat.getMonth() + 1 < 10 ? `0${dat.getMonth() + 1}` : dat.getMonth() + 1}-${dat.getDate() < 10 ? `0${dat.getDate()}` : dat.getDate()}`, ffinal: `${dat.getFullYear()}-${dat.getMonth() + 1 < 10 ? `0${dat.getMonth() + 1}` : dat.getMonth() + 1}-${dat.getDate() < 10 ? `0${dat.getDate()}` : dat.getDate()}` }, this.getTicketsDelDia);
            }
        );
        Mixpanel.ticketsMounted(localStorage.getItem('rfc'));
    }

    getDescuento = descuento => {
        if (!isNaN(descuento)) {
            const tempDesc = descuento / 100;
            return 1 - tempDesc;
        } else {
            return 1;
        }
    }

    changeIndexTicketSeleccionado = (operacion) => {
        let { indexTicketSeleccionado } = this.state;
        const { tickets } = this.state;
        if (indexTicketSeleccionado >= 0 && indexTicketSeleccionado < tickets.length) {
            switch (operacion) {
                case '+':
                    if (indexTicketSeleccionado + 1 < tickets.length) {
                        this.setState({ indexTicketSeleccionado: indexTicketSeleccionado + 1 }, this.getTicketSeleccionadoXIndex);
                    }
                    break;
                case '-':
                    if (indexTicketSeleccionado - 1 >= 0) {
                        this.setState({ indexTicketSeleccionado: indexTicketSeleccionado - 1 }, this.getTicketSeleccionadoXIndex);
                    }
                    break;
                default:
                    break;
            }
        }
    }

    getTicketSeleccionadoXIndex = () => {
        const { tickets, indexTicketSeleccionado } = this.state;
        this.setState({ ticketSeleccionado: tickets[indexTicketSeleccionado].id }, this.getTicketSeleccionado);
    }

    getTicketSeleccionado = callback => {
        const { client } = this.props;
        const { ticketSeleccionado } = this.state;
        client.query({ query: GET_TICKET_QUERY, variables: { id: ticketSeleccionado } }).then(
            result => {
                this.setState({
                    ticketSeleccionadoPagos: result.data.getTicket.pagos,
                    ticketSeleccionadoItems: result.data.getTicket.items,
                    ticketSeleccionadoProductos: result.data.getTicket.productos,
                    ticketSeleccionadoDescuento: result.data.getTicket.descuento,
                    ticketSeleccionadoSubtotal: result.data.getTicket.subtotal,
                    ticketSeleccionadoIva: result.data.getTicket.iva,
                    ticketSeleccionadoTotal: result.data.getTicket.subtotal - result.data.getTicket.descuento + result.data.getTicket.ieps + result.data.getTicket.iva,
                    ticketSeleccionadoAgente: result.data.getTicket.agente,
                    ticketSeleccionadoCliente: result.data.getTicket.cliente,
                    ticketSeleccionadoFecha: result.data.getTicket.fecha,
                    vacio: false
                }, () => this.setState(this.state, () => { if (callback) callback() }));
            }
        ).catch(err => {
            this.setState({ loading: false });
            if (err.message.includes('404')) {
                this.setState({ tipoAlert: 'warning', mensajeAlert: 'Ticket no encontrado.' },
                    () => this.setState({ mostrarAlert: true },
                        () => {
                            setTimeout(() => {
                                this.setState({ mostrarAlert: false })
                            }, 5000);
                        }
                    )
                );
            } else {
                this.setState({ tipoAlert: 'danger', mensajeAlert: 'Error obteniendo ticket: ' + err.message },
                    () => this.setState({ mostrarAlert: true },
                        () => {
                            setTimeout(() => {
                                this.setState({ mostrarAlert: false })
                            }, 5000);
                        }
                    )
                );
            }
        });
    }

    getTicketsDelDia = () => {
        const { client } = this.props;
        const { finicio, ffinal } = this.state;
        client.query({ query: GET_TICKETS_QUERY2, variables: { tienda: localStorage.getItem('tienda'), finicio, ffinal } }).then(
            result => {
                if (result.data.getTicketFecha.length > 0) {
                    this.setState(
                        {
                            ticketSeleccionado: result.data.getTicketFecha[result.data.getTicketFecha.length - 1].id,
                            indexTicketSeleccionado: result.data.getTicketFecha.length - 1,
                            vacio: false, tickets: result.data.getTicketFecha
                        },
                        this.getTicketSeleccionado);
                } else {
                    this.setState({
                        ticketSeleccionadoItems: [],
                        ticketSeleccionadoDescuento: 0,
                        ticketSeleccionadoSubtotal: 0,
                        ticketSeleccionadoTotal: 0,
                        ticketSeleccionadoIva: 0,
                        ticketSeleccionadoPagos: [],
                        tickets: []
                    }, this.setState({ vacio: true }));
                }
            }
        ).catch(err => {
            this.setState({
                ticketSeleccionadoItems: [],
                ticketSeleccionadoDescuento: 0,
                ticketSeleccionadoSubtotal: 0,
                ticketSeleccionadoTotal: 0,
                ticketSeleccionadoIva: 0,
                ticketSeleccionadoPagos: [],
                tickets: []
            }, this.setState({ vacio: true })); this.mostrarAlert('danger', 'Error obteniendo lista de tickets: ' + err.message)
        });
    }

    clickTicket = (ticketSeleccionado, callback) => {
        if (callback) {
            this.setState({ ticketSeleccionado }, () => this.getTicketSeleccionado(callback));
        } else {
            this.setState({ ticketSeleccionado }, this.getTicketSeleccionado);
        }
    }

    timeString = value => {
        let mydate = new Date(value);
        return mydate.toLocaleDateString() + ' - ' + mydate.toLocaleTimeString()
    }

    getPago = (pagos, tipo) => {
        if (!isNaN(tipo)) {
            let monto = 0;
            pagos.forEach(
                pago => {
                    if (pago.tipo === parseInt(tipo,10)) monto = pago.monto;
                }
            );
            return new Intl.NumberFormat('es-MX', { style: 'currency', currency: 'MXN' }).format(monto);
        } else {
            return '$0.00'
        }
    }

    ticket2Cambio = () => {
        this.setState({ activoCambio: true });
    }

    cambio2POS = () => {
        const { history } = this.props;
        const { ticketSeleccionado, vacio, itemsCambio, ticketSeleccionadoAgente, ticketSeleccionadoCliente } = this.state;
        if (ticketSeleccionado !== '' && !vacio && itemsCambio.length > 0) {
            history.push({
                pathname: '/pos',
                state: { ticket: ticketSeleccionado, tipo: 0, agente: ticketSeleccionadoAgente, cliente: ticketSeleccionadoCliente, itemsCambio }
            });
        }

    }

    ticket2Devolucion = () => {
        const { history } = this.props;
        const { ticketSeleccionado, vacio, ticketSeleccionadoAgente, ticketSeleccionadoCliente } = this.state;
        if (ticketSeleccionado !== '' && !vacio) history.push({
            pathname: '/pos',
            state: { ticket: ticketSeleccionado, tipo: 1, agente: ticketSeleccionadoAgente, cliente: ticketSeleccionadoCliente }
        });
    }

    changeStatusImpresion = statusImpresion => {
        this.setState({
            statusImpresion
        });
    }

    imprimir = () => {
        const { statusImpresion, vacio } = this.state;
        if (statusImpresion === true && !vacio) document.getElementById('imprimir').click();
    }

    buscarTicket = e => {
        e.preventDefault();
        const { busquedaTicket, } = this.state;
        if (e.key === 'Tab' || e.key === 'Enter') {
            this.clickTicket(busquedaTicket, () => this.setState({ buscado: true }));

        }
    }

    mapTicketsDelDia = () => {
        let { tickets } = this.state;
        let tickets2 = Array.from(tickets);
        tickets2.reverse();
        const { ticketSeleccionado } = this.state;
        return tickets2.map(
            (item, index) => {
                const total = item.subtotal - item.descuento + item.iva + item.ieps;
                return (<div key={index} className='c-state-container is-active' onClick={() => this.clickTicket(item.id)}>
                    <div className={`c-state c-state--success ${ticketSeleccionado === item.id ? 'selected-ticket' : ''}`}>
                        <p className='c-state__title'>{this.timeString(item.fecha)}</p>
                        <p className='c-state__ticket' style={{ marginBottom: `${item.id[0] === 'H' ? '16px' : ''}`, marginTop: `${item.id[0] === 'H' ? '14px' : ''}` }}>{item.id[0] === 'T' ? 'Ticket' : 'Corte de Caja'}</p>
                        <span className='c-state__ticket_number' style={{ marginTop: `${item.id[0] === 'H' ? '11px' : ''}` }}>
                            {item.id}
                        </span>
                        <p className='c-cliente__user-meta' style={{ display: `${item.id[0] === 'H' ? 'none' : ''}` }}>
                            <span className='u-text-mute '>Cliente:</span> <span className='u-color-my-primary'>{item.cliente}</span>
                        </p>
                        <p className='c-user-meta' style={{ display: `${item.id[0] === 'H' ? 'none' : ''}` }}>
                            <span className='u-text-mute'>Agente:</span> <span className='u-color-my-primary'>{item.agente}</span>
                        </p>
                        <h6 className='c-state__total'>Total</h6>
                        <h6 className='c-state__price'>{new Intl.NumberFormat('es-MX', { style: 'currency', currency: 'MXN' }).format(total)}</h6>
                    </div>
                </div>)
            }
        );
    }

    mostrarAlert = (tipoAlert, mensajeAlert) => {
        this.setState({ mensajeAlert, tipoAlert }, () => this.setState({ mostrarAlert: true }, () => setTimeout(() => this.setState({ mostrarAlert: false }), 5000)));
    }

    handleKey = (keyNm, e, handle) => {
        if (keyNm === 'down') {
            this.changeIndexTicketSeleccionado('-');
        } else if (keyNm === 'up') {
            this.changeIndexTicketSeleccionado('+');
        }
    }

    render() {
        const { vacio, tipoAlert, mensajeAlert, mostrarAlert, finicio, ffinal, ticketSeleccionado, activoCambio, buscado, ticketSeleccionadoFecha, ticketSeleccionadoCliente, ticketSeleccionadoAgente, ticketSeleccionadoTotal, ticketSeleccionadoItems, ticketSeleccionadoPagos, tiposPago, ticketSeleccionadoDescuento, ticketSeleccionadoIva, ticketSeleccionadoSubtotal } = this.state;
        return (
            <Hotkeys
                keyName="down,up"
                onKeyUp={this.handleKey.bind(this)}

            >
                <Modal isOpen={vacio} style={{
                    overlay: {
                        top: 189,
                        left: 60,
                        right: 0,
                        bottom: 0,
                        zIndex: 1,
                        backgroundColor: 'rgba(27,36,49, 1)'
                    },
                    content: {
                        width: '556px',
                        height: '244px',
                        display: 'block',
                        position: 'relative',
                        top: 'calc(((100% - 189px) - 113px) / 2)',
                        right: '0',
                        bottom: '0',
                        left: 'calc(((100% - 60px) - 556px) / 2)',
                        padding: '0',
                        zIndex: '-1',
                        outline: '0',
                        overflow: 'hidden',
                        backgroundColor: 'rgba(0,0,0,0)',
                        border: '0'
                    }
                }
                }>
                    <div className='h-empty-state'></div>
                </Modal>
                <div className='historialTicketsContainer'>
                    <div style={{ display: 'flex', justifyContent: 'center', position: 'absolute', left: 0, width: '100vw' }}>
                        <Alerts
                            type={tipoAlert}
                            value={mensajeAlert}
                            show={mostrarAlert}
                            closeRequest={() => this.setState({ mostrarAlert: false })}
                        />
                    </div>
                    <Fecha titulo={'Historial de Tickets'} />
                    <div className='tablaHistorialTickets'>
                        <div className='inputsTablaHistorialTickets'>
                            <div className='c-field has-icon-right c-date-inline t-ml_13'>
                                <span className='c-field__icon'>
                                    <i className='far fa-calendar-alt'></i>
                                </span>
                                <label className='c-field__label u-hidden-visually' htmlFor='input12'>Disabled Input</label>
                                <input className='c-input-date' data-toggle='datepicker' id='input12' type='date' placeholder='Oct 2, 2018' onChange={e => this.setState({ finicio: e.target.value, buscado: false }, this.getTicketsDelDia)} value={finicio} />
                                <i className='c-arrow_down far fa-angle-down t-mt-xsmall t-ml_13 '></i>
                            </div>
                            <div className='c-divider_ticket'>
                                <a className='c-toolbar__icon' href='' style={{ margin: '0 12.75px 0 13px' }}>-</a>
                            </div>
                            <div className='c-field has-icon-right c-date-inline'>
                                <span className='c-field__icon'>
                                    <i className='far fa-calendar-alt'></i>
                                </span>
                                <label className='c-field__label u-hidden-visually' htmlFor='input13'>Disabled Input</label>
                                <input className='c-input-date' data-toggle='datepicker' id='input13' type='date' placeholder='Oct 2, 2018' onChange={e => this.setState({ ffinal: e.target.value, buscado: false }, this.getTicketsDelDia)} value={ffinal} />
                                <i className='c-arrow_down far fa-angle-down t-mt-xsmall'></i>
                            </div>
                            <div className='c-field c-field--inline has-icon-right t-ht'>
                                <label htmlFor='search-projects' className='u-visually-hidden' />
                                <input autoComplete='off' className='c-input t-ht' id='search-projects' type='text' placeholder='Consulta por número de ticket' onChange={e => this.setState({ busquedaTicket: e.target.value })} onKeyUp={this.buscarTicket} />
                                <span className='c-field__icon'>
                                    <i className='fa fa-search' />
                                </span>
                            </div>
                            <div className='action-ticket_container'>
                                <div className='actions-ticket1'>
                                    <span className='c-tooltip c-tooltip--top' style={{ color: `${vacio !== true && ticketSeleccionado !== '' ? 'white' : 'gray'}` }} aria-label='Cancelar' ><i className='far fa-ban'></i>
                                    </span>
                                </div>
                                <div className='actions-ticket2' onClick={this.ticket2Cambio}>
                                    <span className='c-tooltip c-tooltip--top' style={{ color: `${vacio !== true && ticketSeleccionado !== '' ? 'white' : 'gray'}` }} aria-label='Cambio' >
                                        <i className='far fa-exchange-alt'></i>
                                    </span>
                                </div>
                                <div className='actions-ticket2' onClick={this.ticket2Devolucion}>
                                    <span className='c-tooltip c-tooltip--top' style={{ color: `${vacio !== true && ticketSeleccionado !== '' ? 'white' : 'gray'}` }} aria-label='Devolución'>
                                        <i className='far fa-undo'></i>
                                    </span>
                                </div>
                                <div className='actions-ticket3' onClick={this.imprimir} >
                                    <span className='c-tooltip c-tooltip--top' style={{ color: `${vacio !== true && ticketSeleccionado !== '' ? 'white' : 'gray'}` }} aria-label='Imprimir'>
                                        <i className='far fa-print'></i>
                                    </span>
                                    <div className='u-display_none'>
                                        {ticketSeleccionado !== '' ? <ImprimirTicket listo={this.changeStatusImpresion} id={ticketSeleccionado} ref={el => (this.componentRef = el)} /> : null}
                                        <ReactToPrint
                                            trigger={() => <button id='imprimir' className='c-btn  c-btn-cobro' >Cobrar</button>}
                                            content={() => this.componentRef}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='headerTablaHistorialTickets'>
                            <div className='header-ticker_title'>
                                <h6 className='head-title-info'>Últimos tickets</h6>
                            </div>
                            <div className='name-titles'>
                                <div className='boxes' style={{ width: `calc(100%)/`, display: `${activoCambio ? '5' : 'none'}` }}></div>
                                <div className='boxes' style={{ width: `calc(100% / ${activoCambio ? '5' : '6'})` }}></div>
                                <div className='boxes' style={{ width: `calc(100% / ${activoCambio ? '4' : '4.8'})` }}><h6 className='head-title-info-1'>Nombre</h6></div>
                                <div className='boxes' style={{ width: `calc(100% / ${activoCambio ? '6.2' : '5'})` }}><h6 className='head-title-info-2'>Código</h6></div>
                                <div className='boxes' style={{ width: `calc(100% / ${activoCambio ? '11' : '11'})` }}><h6 className='head-title-info-3'>Cantidad</h6></div>
                                <div className='boxes' style={{ width: `calc(100% / ${activoCambio ? '15' : '12'})` }}><h6 className='head-title-info'>Precio</h6></div>
                                <div className='boxes' style={{ width: `calc(100% / ${activoCambio ? '10' : '9'})` }}><h6 className='head-title-info'>Descuento</h6></div>
                                <div className='boxes' style={{ width: `calc(100% / ${activoCambio ? '8' : ''})` }}><h6 className='head-title-info'>Importe</h6></div>
                            </div>
                        </div>
                        <div className='contenedorLadosTablaHistorialTickets'>
                            <div className='izquierdaTablaHistorialTickets'>
                                {
                                    buscado
                                        ?
                                        <React.Fragment>
                                            <div className='c-state-container is-active'>
                                                <div className='c-state c-state--success selected-ticket'>
                                                    <p className='c-state__title'>{this.timeString(ticketSeleccionadoFecha)}</p>
                                                    <p className='c-state__ticket'>Ticket</p>
                                                    <span className='c-state__ticket_number'>
                                                        {ticketSeleccionado}
                                                    </span>
                                                    <p className='c-cliente__user-meta'>
                                                        <span className='u-text-mute '>Cliente:</span> <span className='u-color-my-primary'>{ticketSeleccionadoCliente}</span>
                                                    </p>
                                                    <p className='c-user-meta'>
                                                        <span className='u-text-mute'>Agente:</span> <span className='u-color-my-primary'>{ticketSeleccionadoAgente}</span>
                                                    </p>
                                                    <h6 className='c-state__total'>Total</h6>
                                                    <h6 className='c-state__price'>{new Intl.NumberFormat('es-MX', { style: 'currency', currency: 'MXN' }).format(ticketSeleccionadoTotal)}</h6>
                                                </div>
                                            </div>
                                        </React.Fragment>
                                        :
                                        <React.Fragment>
                                            {
                                                this.mapTicketsDelDia()
                                            }
                                        </React.Fragment>
                                }
                            </div>
                            <div className='derechaTablaHistorialTickets'>
                                <div className='derechaTopTablaHistorialTickets c-scroll-tickets'>
                                    {
                                        this.mapearItems()
                                    }
                                    <div style={{ position: 'absolute', bottom: '0', height: '215px', display: 'block', left: '41%' }}>
                                        <div className='container-az' style={{ display: `${activoCambio ? '' : 'none'}` }}>
                                            <div className='actionzone'>
                                                <p className='u-color-white u-text-small u-mr-auto'>Selecciona los productos que deseas cambiar</p>
                                                <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '21px' }}>
                                                    <button className="c-btn c-btn--primary c-btn--small u-mr-medium" onClick={() => this.setState({ activoCambio: false })}>
                                                        Cancelar
                                                    </button>
                                                    <button className="c-btn  c-btn--primary c-btn--small" onClick={this.cambio2POS}>
                                                        Cambiar
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className='derechaBottomTablaHistorialTickets'>
                                    <div className='tablaCobroDetalleHistorialTickets'>
                                        <div className='izquierdaTablaCobroDetalleHistorialTickets'>
                                            <div className='left-half-2'>
                                                <article>
                                                    <ul>
                                                        <li className='results-border-bottom'>
                                                            <h3 className='line-item '>Cantidad</h3>
                                                            <span className='result-item'>
                                                                {
                                                                    this.getItems(ticketSeleccionadoItems)
                                                                }
                                                            </span>
                                                        </li>
                                                        <li className='results-border-bottom'>
                                                            <h3 className='line-item '>Descuento</h3>
                                                            <span className='result-item'>{new Intl.NumberFormat('es-MX', { style: 'currency', currency: 'MXN' }).format(ticketSeleccionadoDescuento === null ? 0 : ticketSeleccionadoDescuento)}</span>
                                                        </li>
                                                        <li className='results-border-bottom'>
                                                            <h3 className='line-item '>Impuestos</h3>
                                                            <span className='result-item'>{new Intl.NumberFormat('es-MX', { style: 'currency', currency: 'MXN' }).format(ticketSeleccionadoIva)}</span>
                                                        </li>
                                                        <li className='results-border-bottom'>
                                                            <h3 className='line-item '>Subtotal</h3>
                                                            <span className='result-item'>{new Intl.NumberFormat('es-MX', { style: 'currency', currency: 'MXN' }).format(ticketSeleccionadoSubtotal)}</span>
                                                        </li>
                                                    </ul>
                                                </article>
                                            </div>
                                        </div>
                                        <div className='derechaTablaCobroDetalleHistorialTickets'>
                                            <div className='head-title'>
                                                <div className='method-title'>
                                                    <h6 className='u-color-my-primary u-m-zero'>Metodos de Pago</h6>
                                                </div>
                                                <div className='method-list'>
                                                    <div >
                                                        <div className='u-flex u-justify-between t-mt-xsmall'>
                                                            <div>
                                                                <i className='fas fa-money-bill u-mr-small u-color-my-primary'></i>
                                                                Efectivo
                                                                                    </div>
                                                            <span className='pay-space'>{this.getPago(ticketSeleccionadoPagos, tiposPago.efectivo ? tiposPago.efectivo.tipo : null)}</span>
                                                        </div>
                                                        <div className='u-flex u-justify-between'>
                                                            <div>
                                                                <i className='fas fa-credit-card u-mr-small u-color-my-primary'></i>
                                                                Tarjeta
                                                                                    </div>
                                                            <span className='pay-space'>{this.getPago(ticketSeleccionadoPagos, tiposPago.tarjeta ? tiposPago.tarjeta[0].tipo : null)}</span>
                                                        </div>
                                                        <div className='u-flex u-justify-between'>
                                                            <div>
                                                                <i className='fas fa-credit-card-blank u-mr-small u-color-my-primary'></i>
                                                                Puntos
                                                                                    </div>
                                                            <span className='pay-space'>{this.getPago(ticketSeleccionadoPagos, tiposPago.puntos ? tiposPago.puntos.tipo : null)}</span>
                                                        </div>
                                                        <div className='.c-method__total u-flex u-justify-between'>
                                                            <h5 className='u-text-bold u-color-my-primary '>Total</h5>
                                                            <span className='total-method'>{new Intl.NumberFormat('es-MX', { style: 'currency', currency: 'MXN' }).format(ticketSeleccionadoTotal)}</span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Hotkeys>
        );
    }
}
export default HistorialTickets;
/*
||===================================================================
||                   COMPONENTE: HistorialTickets
||===================================================================
||                          DESCRIPCIÓN
|| Componente principal del historial de tickets donde se alojan
|| todas las funciones de esta sección.
||===================================================================
||                             STATE
||  * ticketSeleccionado: id del ticket seleccionado.
||
||  * finicio: fecha incial del primer input date.
||
||  * ffinal: fecha incial del ultimo input date.
||
||  * tickets: array con los tickets de la fecha.
||
||  * statusImpresion: boleano que indica si el ticket seleccionado
||  esta listo para ser impreso.
||
||  * vacio: boleano que indica si el dia actual esta vacio.
||
||  * ticketSeleccionadoItems:  array con los items del ticket
||  seleccionado.
||
||  * ticketSeleccionadoDescuento: descuento del ticket seleccionado.
||
||  * ticketSeleccionadoSubtotal: subtotal del ticket seleccionado.
||
||  * ticketSeleccionadoTotal: total del ticket seleccionado.
||
||  * ticketSeleccionadoIva: impuestos del ticket seleccionado.
||
||  * ticketSeleccionadoPagos: array con los pagos del ticket
||  seleccionado.
||
||  * ticketSeleccionadoAgente: agente del ticket seleccionado.
||
||  * ticketSeleccionadoCliente: cliente del ticket seleccionado.
||
||  * buscado: string con el contenido del input de busqueda.
||
||  * ticketSeleccionadoFecha: fecha del ticket seleccionado.
||
||  * mostrarAlert: boleano que es true cuando se muestra el alert.
||
||  * mensajeAlert: string con el mensaje del alert.
||
||  * tipoAlert: string que debe contener el tipo de alert a mostrar.
||===================================================================
||                             FUNCIONES
||  * getItems: Cuenta el numero de piezas en los items y lo imprime.
||
||  * mapearItems: Imprime los productos del ticket seleccionado.
||
||  * formatItems: formatea los items para que la cantidad no sea 1
||  sino las repeticiones del array.
||
||  * objetoTemporalProductoItem: generador de objeto producto a traves
||  de un item, este metodo es utilizado en la funcion formatItems.
||
||  * React - componentDidMount: Se utiliza para obtener la fecha.
||
||  * getTicketSeleccionado: Hace el fetch para obtener detalles del
||  del ticket seleccionado.
||
||  * getTicketsDelDia: Hace el fetch para obtener el array con los
||  tickets del dia.
||
||  * clickTicket: guarda en el state el id del ticket al que se le
||  hizo click.
||
||  * timeString: Da formato a la fecha para ser mas legible.
||
||  * getPago: Recibe el tipo y el array de pagos y devuelve el monto
||  del tipo enviado.
||
||  * ticket2Cambio: Envia a traves del state del history al pos el
||  ticket seleccionado para hacer cambio
||
||  * ticket2Devolucion:Envia a traves del state del history al pos
||  el ticket seleccionado para hacer cambio
||
||  * changeStatusImpresion: Este metodo permite cambiar el estado del
||  ticket seleccionado a listo para imprimir y viceversa.
||
||  * imprimir: Si el status de la impresión es listo, manda a imprimir
||  el ticket seleccionado.
||
||  * cancelar: !!!!!!!!!!!!!!!!!!A DESARROLLAR!!!!!!!!!!!!!!!!!!!!!
||
||  * buscarTicket: Cambia el ticket seleccionado por la entrada del
||  input de busqueda.
||
||  * mapTicketsDelDia: Imprime las tarjetas de todo el array de tickets.
||
||  * mostrarAlert: muestra los alerts de la app.
||
||===================================================================
*/