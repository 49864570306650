import React, { useState, useEffect } from 'react';
import gql from 'graphql-tag';
import BeatLoader from "react-spinners/BeatLoader";

const MAPS_QUERY = gql`
query($query:String!){
    buscarEnGoogleMaps(query:$query){
      lat
      long
    }
  }
`;

const GET_TIENDAS_QUERY = gql`
query($lat:Float!,$long:Float!,$items:[ItemInput]!,$agente:String!,$cajero:String!,$tienda:String!){
    buscarTiendasPorDireccion(lat:$lat,long:$long,items:$items,agente:$agente,cajero:$cajero,tienda:$tienda){
      tienda{
        id
        nombre
        direccion {
            calle
            num_ext
            delegacion
            colonia
            ciudad
            cp
        }
      }
      tiempo_entrega
    }
  }
`;

export const SeleccionarTienda = ({ selectTienda, client, agente, items, cp, buscar,showAlert }) => {
    const [tiendas, setTiendas] = useState([]);
    const [loading, setLoading] = useState(false);

    useEffect(()=>{
        const request = async () => {
            if (!cp) {
                return;
            }
            setLoading(true);
            try {
                const result = await client.query({
                    variables: {
                        query:cp
                    },
                    query: MAPS_QUERY
                });
                const { lat, long } = result.data.buscarEnGoogleMaps;
                const variables = {
                    tienda: localStorage.getItem('tienda'),
                    cajero: localStorage.getItem('username'),
                    agente,
                    lat,
                    long,
                    items: items.map(item => {
                        const { producto, cantidad } = item;
                        return ({
                            producto,
                            cantidad
                        });
                    })
                }
                const result2 = await client.query({
                    variables,
                    query: GET_TIENDAS_QUERY
                });
                const temp = Array.from(result2.data.buscarTiendasPorDireccion).map(
                    tiendaInfo => {
                        const { tiempo_entrega } = tiendaInfo;
                        return {
                            ...tiendaInfo.tienda,
                            tiempo_entrega
                        }
                    }
                );
                setTiendas(Array.from(temp));
                setLoading(false);
            } catch (error) {
                showAlert('danger','Hubo un error buscando su longitud y latitud' + error);
            }
    
        }
        request();
    },[buscar]);

    return (
        <div className="seleccionar-tienda-resultados-contain-omni">
            Selecciona Tienda: 
            {
                loading
                    ?
                    <div className="loading-container">
                        <BeatLoader color='#36D7B7' />
                    </div>
                    :
                    tiendas.map((tienda, index) => (
                        <div className="seleccionar-tienda-inputs-box-omni seleccionar-tienda-inputs-box-space-omni" onClick={() => selectTienda(tienda.id)} key={'tienda'+index} >
                            <h5 className="c-cobro_label u-mb-small">{tienda.nombre}</h5>
                            <h5 className="c-cobro_label u-mb-small">{`${tienda.direccion.calle} ${tienda.direccion.num_ext} ${tienda.direccion.delegacion} ${tienda.direccion.colonia} ${tienda.direccion.ciudad}. CP ${tienda.direccion.cp}`}</h5>
                            <h5 className="c-cobro_label u-mb-small">Entrega en {tienda.tiempo_entrega / 60 >= 24 ? `${((tienda.tiempo_entrega / 60) / 24).toFixed(0)} días.` : `${(tienda.tiempo_entrega / 60).toFixed(0)} hrs.`}</h5>
                        </div>
                    ))
            }
        </div>
    );
}