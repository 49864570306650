import React, { useEffect, useState } from 'react';
import gql from 'graphql-tag';
import { PulseLoader } from 'react-spinners';

const GET_CLIENTE_QUERY = gql`
query($id:String!){
  getCliente(id:$id){
    nombre
  }
}
`
export function PedidoCard({ body, id, _liga, _fecha, total, client, history }) {
    const [loading, setLoading] = useState(true);
    const [nombreCliente, setNombreCliente] = useState(true);
    const [pedido, setPedido] = useState({
        cliente: '',
        items: []
    });
    useEffect(() => {
        setPedido(JSON.parse(body));
    }, []);

    useEffect(() => {
        const fetchNombre = async () => {
            if (pedido.cliente) {
                const res = await client.query({
                    query: GET_CLIENTE_QUERY,
                    variables: {
                        id: pedido.cliente
                    }
                });
                setNombreCliente(res.data.getCliente.nombre);
                setLoading(false);
            }
        }
        fetchNombre();
    }, [pedido.cliente]);

    const goToPedido = () => {
        history.push(`/pedidos/${id}`);
    }

    if (loading) {
        return (
            <div  className="pedidos-card" style={{display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <PulseLoader color='#36D7B7' loading={true} heightUnit='px' height={100} widthUnit='px' width={100} />
            </div>
        );
    }
    const { items } = pedido;
    return (
    <div className="pedidos-card" onClick={goToPedido}>
        <div>Cliente {nombreCliente}</div>
        <div>Productos: {getTotalItems(items)}</div>
        <div>Total: ${total}</div>
    </div>
    );
}

function getTotalItems(items) {
    let total = 0;
    for (const item of items) {
        if (item.producto !== 'ZFLETE') {
            total += item.cantidad;
        }
    }
    return total;
}
