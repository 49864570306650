import React, { Component } from 'react';
import Modal from 'react-modal';
import ProductoHijo from '../ProductoHijo';
import proptypes from 'prop-types';

class ProductoPadre extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showModal: false
        };
    }

    mostrarHijos = () => {
        const { changeActivoHijo } = this.props;
        this.setState({
            showModal: true
        }, () => changeActivoHijo(true));
    }

    mostrarColores = () => {
        let arrayColor = [];
        const { producto } = this.props;
        producto.variaciones.forEach(
            (variacion, index) => {
                if (index === 0) {
                    arrayColor.push(variacion.color);
                } else {
                    let repetido = false;
                    arrayColor.forEach(
                        color => {
                            if (color === variacion.color) repetido = true;
                        }
                    );
                    if (!repetido) arrayColor.push(variacion.color);
                }
            }
        );
        return arrayColor;
    }

    mostrarTallas = () => {
        let arrayTalla = [];
        const { producto } = this.props;
        producto.variaciones.forEach(
            (variacion, index) => {
                if (index === 0) {
                    arrayTalla.push(variacion.talla);
                } else {
                    let repetido = false;
                    arrayTalla.forEach(
                        talla => {
                            if (talla === variacion.talla) repetido = true;
                        }
                    );
                    if (!repetido) arrayTalla.push(variacion.talla);
                }
            }
        );
        return arrayTalla;
    }

    render() {
        const { producto, changeActivoHijo, addMonedero, /*loading,*/ agregarAlTicket } = this.props;
        if (producto.variaciones && producto.variaciones !== undefined && producto.variaciones !== null) {
            return (
                <React.Fragment>
                    <div className='c-event' onClick={this.mostrarHijos}>
                        <div className='c-event__img'>
                            <img src={producto.imagen} alt='' onError={(e) => { e.target.src = "/img/image_not_found.png" }} />
                        </div>
                        <div className='c-event__meta'>
                            <h3 className='c-event__title'>{producto.nombre}</h3>
                        </div>
                        <div>
                            <h4 className='c-event__place'>{producto.icod}</h4>
                        </div>
                    </div>
                    <br />
                    <Modal isOpen={this.state.showModal} onRequestClose={() => this.setState({ showModal: false }, () => { changeActivoHijo(false) })} style={{
                        overlay: {
                            top: 0,
                            left: 0,
                            right: 0,
                            bottom: 0,
                            zIndex: 500,
                            backgroundColor: 'rgba(0, 0, 0, 0.75)'
                        },
                        content: {
                            width: "750px",
                            display: 'block',
                            position: 'relative',
                            top: 'calc((100% - 637px) / 2)',
                            right: '0',
                            bottom: '0',
                            left: 'calc((100% - 750px) / 2)',
                            padding: '0',
                            zIndex: '-1',
                            outline: "0",
                            overflow: "hidden",
                            backgroundColor: "rgba(0,0,0,0)",
                            border: "0",

                        }
                    }}>
                        <ProductoHijo addMonedero={addMonedero} changeActivoHijo={changeActivoHijo} producto={producto} agregarAlTicket={agregarAlTicket} cerrar={() => this.setState({ showModal: false }, () => changeActivoHijo(false))} />
                    </Modal>
                </React.Fragment>
            )
        } else {
            return (
                <React.Fragment>
                    <div className='c-project-card u-mb-medium' onClick={() => agregarAlTicket(producto.icod)}>
                        <img className='c-product-item ' src={producto.imagen} alt='' onError={(e) => { e.target.src = "/img/image_not_found.png" }} />
                        <div className="c-project-card__content">
                            <div className="c-project-card__head">
                                <h4 className="c-project-card__title">{producto.nombre}</h4>
                                <p className="c-project-card__info">${producto.precio.toFixed(2)}</p>
                            </div>
                            <div>
                                <h4 className="c-product-size__title "> Venta Directa </h4>
                                <span className='c-product-id_title'>{producto.icod}</span>
                                <span className='c-product-discount'>{producto.descuento ? producto.descuento : 0}%</span>
                            </div>
                        </div>
                    </div>
                    <br />
                </React.Fragment>
            )
        }
    }
}

ProductoPadre.proptypes={
    changeActivoHijo:proptypes.func,
    loading:proptypes.func,
    agregarAlTicket:proptypes.func,
    onChangeSearch:proptypes.func,
    producto:proptypes.shape({
        imagen:proptypes.string,
        nombre:proptypes.string,
        icod:proptypes.string,
        precio:proptypes.number,
        talla:proptypes.string,
        color:proptypes.string,
        descuento:proptypes.number,
        variaciones:proptypes.shape({
                icod:proptypes.string,
                color:proptypes.string,
                talla:proptypes.string
        })
    })
}

export default ProductoPadre;
/*
||===================================================================
||                          COMPONENTE:
||===================================================================
||                          DESCRIPCIÓN
||
||
||
||
||
||
||
||
||
||
||===================================================================
||                             PROPS
||
||
||
||
||
||
||
||
||
||
||===================================================================
||                             STATE
||
||
||
||
||
||
||
||
||
||
||===================================================================
||                             FUNCIONES
||
||
||
||
||
||
||
||
||
||
||===================================================================
||                             OTROS
||
||
||
||
||
||
||
||
||
||
||===================================================================
*/