import React from "react"
import TablaProducto from "./TablaProducto";
import TablaCobro from "./TablaCobro";
import proptypes from 'prop-types';

function Ticket({ seleccionarMonederoYDevolver, borrandoItem, cambiar, editandoCantidad, calcularTotalTemportal, changeActivoEditarCantidad, loadingTable, cancelar, loading, cobrar, cambiarCantidad, ticket, items, productos, borrarItem }){
    const props_producto={ borrandoItem, editandoCantidad, changeActivoEditarCantidad, cambiarCantidad, productos, items, borrarItem };
    const props_cobro={ seleccionarMonederoYDevolver, cambiar, calcularTotalTemportal, loadingTable, cancelar, cobrar, ticket, items };
        return (
            <React.Fragment>
                <TablaProducto {...props_producto} loadingTable={loading} />
                <div className='main_ticket' align="right">
                    <TablaCobro {...props_cobro} />
                </div>
            </React.Fragment>
        );
}
Ticket.proptypes={ 
    seleccionarMonederoYDevolver:proptypes.func, 
    borrandoItem:proptypes.bool, 
    cambiar:proptypes.func, 
    editandoCantidad:proptypes.bool, 
    calcularTotalTemportal:proptypes.func, 
    changeActivoEditarCantidad:proptypes.func, 
    loadingTable:proptypes.bool, 
    cancelar:proptypes.func, 
    loading:proptypes.bool, 
    cobrar:proptypes.func, 
    cambiarCantidad:proptypes.func, 
    ticket: proptypes.shape({
        descuento: proptypes.number,
        subtotal: proptypes.number,
        iva: proptypes.number,
        total: proptypes.number
    }),
    items: proptypes.arrayOf(proptypes.shape({
        producto: proptypes.string,
        cantidad: proptypes.number,
        descuento: proptypes.number,
        precio_unitario: proptypes.number
    })),
    productos:proptypes.arrayOf(proptypes.shape({
        imagen:proptypes.string,
        nombre:proptypes.string,
        icod:proptypes.string,
        precio:proptypes.number,
        talla:proptypes.string,
        color:proptypes.string,
        descuento:proptypes.number
    })), 
    borrarItem:proptypes.func 
}
export default Ticket;
/*
||===================================================================
||                          COMPONENTE:
||===================================================================
||                          DESCRIPCIÓN
||
||
||
||
||
||
||
||
||
||
||===================================================================
||                             PROPS
||
||
||
||
||
||
||
||
||
||
||===================================================================
||                             STATE
||
||
||
||
||
||
||
||
||
||
||===================================================================
||                             FUNCIONES
||
||
||
||
||
||
||
||
||
||
||===================================================================
||                             OTROS
||
||
||
||
||
||
||
||
||
||
||===================================================================
*/