import React, { Component } from 'react';
import { Query } from 'react-apollo';
import gql from 'graphql-tag';
import ContainerBusqueda from './ContainerBusqueda';
import proptypes from 'prop-types';
const OBTENER_AGENTES_QUERY = gql`
query($q:String!){
  obtenerAgentes(q:$q){
    nombre,
    id
  }
}
`

const OBTENER_CLIENTES_QUERY = gql`
query($q:String!){
  obtenerClientes(q:$q){
    nombre,
    clicod,
    direcciones{
      telefono
    }
  }
}
`

const GET_CLIENTE_QUERY = gql`
query($id:String!){
  getCliente(id:$id){
    nombre
  }
}
`

const GET_AGENTE_QUERY = gql`
query($id:String!){
  getAgente(id:$id){
    nombre
  }
}
`

class Buscador extends Component {
  constructor(props) {
    super(props)
    this.state = {
      date: '02/11/1994 - 05:20:00',
      busquedaCliente: '',
      busquedaAgente: '',
      disableAgente: false,
      disableCliente: false,
      mostrarAgentes: false,
      mostrarClientes: false,
      activoAgente: false,
      activoCliente: false
    }
    this.textInput = null;

  }

  componentDidUpdate() {
    const { activoAgente, activoCliente } = this.state;
    const { activoAgregarCliente, activoHijo, activoCobro, activoEditarCantidad, } = this.props;
    if (!activoAgente && !activoCliente && !activoAgregarCliente && !activoHijo && !activoCobro && !activoEditarCantidad) {
      //document.getElementById("input-principal").focus();
      if(this.principalInput && !localStorage.getItem('phone')) this.principalInput.focus();
    }

  }

  componentDidMount() {
    if(this.principalInput) this.principalInput.focus();
    let intervalId = setInterval(() => {
      this.setState({
        date: `${new Date().toLocaleDateString()} - ${new Date().toLocaleTimeString()} `
      });
    }, 1000);
    this.setState({ intervalId });
  }

  componentWillUnmount() {
    const { intervalId } = this.state;
    clearInterval(intervalId);
  }

  changeBusquedaCliente = async event => {
    const { changeCliente } = this.props;
    if (event.key === 'Tab' || event.key === 'Enter') {
      event.preventDefault();
      const res = await changeCliente(event.target.value);
      if (!res) {
        this.setState({ busquedaCliente: event.target.value });
      }
    } else {
      this.setState({ busquedaCliente: event.target.value });
    }
  }

  changeBusquedaAgente = async event => {
    const { changeAgente } = this.props;
    if (event.key === 'Tab' || event.key === 'Enter') {
      event.preventDefault();
      const res = await changeAgente(event.target.value);
      if (!res) {
        this.setState({ busquedaCliente: event.target.value });
      }
    } else {
      this.setState({ busquedaAgente: event.target.value });
    }
  }

  enterProducto = event => {
    const { busquedaProducto, agregarATicket, setBusquedaProducto } = this.props;
    const { cliente, agente } = this.state;
    if (event.key === 'Tab') {
      event.preventDefault();
      agregarATicket(busquedaProducto, cliente, agente)
      setBusquedaProducto('');
    }
  }


  render() {
    const { agente, cliente, changeAgente, changeCliente, crearCliente, busquedaProducto, setBusquedaProducto } = this.props;
    const { date, busquedaAgente, mostrarAgentes, busquedaCliente, mostrarClientes, } = this.state;
    return (
      <React.Fragment>
        <div className='c-toolbar'>
          <h3 className='c-toolbar__title has-divider'>Nueva Venta</h3>
          <h5 className='c-toolbar-meta u-mr-auto'>
            <i className='far fa-clock'></i> {date}
          </h5>
        </div>
        <div className="c-table__title_1">
          <div className="buscador-agente">
            <label className="label-input">Agente </label>
            <div className='c-field has-icon-right'>
              <label className='c-field__label u-hidden-visually' htmlFor='input3'>Label</label>
              {
                agente === ''
                  ?
                  <React.Fragment>
                    <input className='c-input' id='input3' type='text' placeholder='Buscar Agente' list='agentes' name='agente' onKeyDown={this.changeBusquedaAgente} onFocus={() => this.setState({ mostrarAgentes: true, activoAgente: true })} onBlur={() => this.setState({ activoAgente: false })} autoComplete='off' />
                    <label htmlFor='input3' className='c-field__icon'> <i className='far fa-user-tie' /> </label>
                  </React.Fragment>
                  :
                  <React.Fragment>
                    <Query query={GET_AGENTE_QUERY} variables={{ id: agente }}>
                      {
                        ({ data, loading, error }) => {
                          if (loading) { return (<input className='c-input ' id='input3' type='text' readOnly placeholder='Cargando..' />) }
                          if (error) {
                            changeAgente('');
                            return (<React.Fragment>
                              <input className='c-input' id='input3' type='text' placeholder='Buscar Agente' list='agentes' name='agente' onKeyDown={this.changeBusquedaAgente} onFocus={() => this.setState({ mostrarAgentes: true, activoAgente: true })} onBlur={() => this.setState({ activoAgente: false })} autoComplete='off' />
                              <label htmlFor='input3' className='c-field__icon'> <i className='far fa-user-tie' /> </label>
                            </React.Fragment>)
                          }
                          return (
                            <React.Fragment>
                              <input className='c-input' id='input3' type='text' disabled={true} value={data.getAgente.nombre} />
                              <span className='c-field__icon'><i className='far fa-check' /></span>
                            </React.Fragment>
                          );
                        }
                      }
                    </Query>
                  </React.Fragment>
              }
            </div>
            <Query query={OBTENER_AGENTES_QUERY} variables={{ q: busquedaAgente }} >
              {
                ({ data, error, loading }) => {
                  if (loading) return <ContainerBusqueda loading={true} />
                  if (error) return null
                  return (
                    <ContainerBusqueda cerrar={() => this.setState({ mostrarAgentes: false })} mostrar={mostrarAgentes} agregar={changeAgente} callback={() => document.getElementById("input-principal").focus()} elements={data.obtenerAgentes} />
                  )
                }
              }
            </Query>
          </div>

          <div className='buscador-cliente'>
            <label className="label-input">Cliente </label>
            <div className='c-field has-icon-left input_gemelos2'>
              <label className='c-field__label u-hidden-visually' htmlFor='input1'>Label</label>
              {
                cliente === ''
                  ?
                  <React.Fragment>
                    <input className='c-input-2' id='input1' type='text' placeholder='Buscar Cliente' list='clientes' name='cliente' onKeyDown={this.changeBusquedaCliente} onFocus={() => this.setState({ mostrarClientes: true, activoCliente: true })} onBlur={() => this.setState({ activoCliente: false })} autoComplete='off' />
                    <label htmlFor='input1' className='c-field__icon'> <i className='far fa-user' /> </label>
                  </React.Fragment>
                  :
                  <React.Fragment>
                    <Query query={GET_CLIENTE_QUERY} variables={{ id: cliente }}>
                      {
                        ({ data, error, loading }) => {
                          if (loading) { return <React.Fragment><input className='c-input-2' id='input1' type='text' readOnly placeholder='Cargando..' /></React.Fragment> }
                          if (error) {
                            changeCliente('');
                            return (
                              <React.Fragment>
                                <input className='c-input-2' id='input1' type='text' placeholder='Buscar Cliente' list='clientes' name='cliente' onKeyDown={this.changeBusquedaCliente} onFocus={() => this.setState({ mostrarClientes: true, activoCliente: true })} onBlur={() => this.setState({ activoCliente: false })} autoComplete='off' />
                                <label htmlFor='input1' className='c-field__icon'> <i className='far fa-user' /> </label>
                              </React.Fragment>
                            )
                          }
                          return (
                            <React.Fragment>
                              <input className='c-input-2 ' id='input1' readOnly value={data.getCliente.nombre} disabled={true} type='text' />
                              <span className='c-field__icon'><i className='far fa-check' /></span>
                            </React.Fragment>
                          );
                        }
                      }
                    </Query>
                  </React.Fragment>
              }
            </div>
            <Query query={OBTENER_CLIENTES_QUERY} variables={{ q: busquedaCliente }} >
              {
                ({ data, error, loading }) => {
                  if (loading) return <ContainerBusqueda loading={true} />
                  if (error) return null
                  return (
                    <ContainerBusqueda cerrar={() => this.setState({ mostrarClientes: false })} mostrar={mostrarClientes} agregar={changeCliente} callback={() => document.getElementById("input-principal").focus()} elements={data.obtenerClientes} />
                  )
                }
              }
            </Query>
            <div className='c-add_client'>
              <button align='center' type='button' className='c-btn-base c-btn-add' data-toggle='modal' data-target='#onBoardModal' onClick={crearCliente}>
                <i className='far fa-user-plus'></i>
              </button>
            </div>
          </div>

        </div>
        <div className='c-table__title' align='center'>
          <div className='input_raro'>
            <div className='c-field has-icon-right'>
              <React.Fragment>
                <label className='c-field__label u-hidden-visually has-icon-left' htmlFor='input4'>Label</label>
                <input autoFocus={true} className='c-input' id='input-principal' type='text' placeholder='Agregar producto' onKeyDown={this.enterProducto} autoComplete='off' value={busquedaProducto} onChange={e => setBusquedaProducto(e.target.value)} ref={el => (this.principalInput = el)} />
                <label htmlFor='input-principal' className='c-field__icon'> <i className='far fa-shopping-bag' /> </label>
              </React.Fragment>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}
Buscador.proptypes={
  activoEditarCantidad:proptypes.bool,
  activoCobro:proptypes.bool,
  activoHijo:proptypes.bool,
  onRef:proptypes.func,
  activoAgregarCliente:proptypes.bool,
  crearCliente:proptypes.func,
  agregarATicket:proptypes.func,
  changeCliente:proptypes.func,
  changeAgente:proptypes.func,
  setBusquedaProducto:proptypes.func,
  q:proptypes.string,
  cliente:proptypes.string,
  agente:proptypes.string,
  busquedaProducto:proptypes.string
}
export default Buscador;
/*
||===================================================================
||                          COMPONENTE:
||===================================================================
||                          DESCRIPCIÓN
||
||
||
||
||
||
||
||
||
||
||===================================================================
||                             PROPS
||
||
||
||
||
||
||
||
||
||
||===================================================================
||                             STATE
||
||
||
||
||
||
||
||
||
||
||===================================================================
||                             FUNCIONES
||
||
||
||
||
||
||
||
||
||
||===================================================================
||                             OTROS
||
||
||
||
||
||
||
||
||
||
||===================================================================
*/