import React, { useState } from 'react';
import gql from 'graphql-tag';
import BeatLoader from "react-spinners/BeatLoader";
import Alerts from '../../../../constants/Alerts';

const CREAR_DIRECCION_MUTATION = gql`
mutation($direccion:DireccionInput!, $cliente:String!){
    crearDireccion(direccion:$direccion,cliente:$cliente){
      id
    }
  }
`;

export const CrearDireccion = ({ client, cliente, selectDireccion }) => {
    const [nombre, setNombre] = useState('');
    const [telefono, setTelefono] = useState('');
    const [calle, setCalle] = useState('');
    const [num_ext, setNumExt] = useState('');
    const [num_int, setNumInt] = useState('');
    const [colonia, setColonia] = useState('');
    const [delegacion, setDelegacion] = useState('');
    const [ciudad, setCiudad] = useState('');
    const [estado, setEstado] = useState('');
    const [cp, setCp] = useState('');
    const [loading, setLoading] = useState(false);
    const [tipoAlert,setTipoAlert] = useState('');
    const [mensajeAlert,setMensajeAlert] = useState('');
    const [mostrarAlert, setMostrarAlert] = useState(false);

    const submit = async () => {
        if (nombre && telefono && calle && num_ext && colonia && ciudad && estado && cp) {
            setLoading(true);
            const direccion = {
                nombre,
                telefono,
                calle,
                num_ext,
                num_int,
                colonia,
                delegacion,
                ciudad,
                estado,
                cp
            };
            try {
                const result = await client.mutate({
                    mutation: CREAR_DIRECCION_MUTATION,
                    variables: {
                        direccion,
                        cliente
                    }
                });
                selectDireccion(result.data.crearDireccion.id, cp);
            } catch (error) {
                handleAlert('danger',error);
            }
        } else {
            handleAlert('warning','Rellena los datos obligatorios');
        }
    }

    const handleAlert = (tipo,mensaje) => {
        setTipoAlert(tipo);
        setMensajeAlert(mensaje);
        setMostrarAlert(true);
        setTimeout(()=>{
            setMostrarAlert(false);
        },5000);
    }

    if (loading) {
        return (
            <div className="loading-container">
                <BeatLoader color='#36D7B7' />
            </div>
        );
    }

    return (
        <div className="crear-dir-container">
            <Alerts
                type={tipoAlert}
                value={mensajeAlert}
                show={mostrarAlert}
                closeRequest={() => setMostrarAlert(false)}
            />
            Crear Dirección
            <div className="inputs-cotainer-crear-dir">
                <input type="text" placeholder="Nombre*" className="c-input" value={nombre} onChange={({ target }) => setNombre(target.value)} />
                <input type="text" placeholder="Teléfono*" className="c-input" value={telefono} onChange={({ target }) => setTelefono(target.value)} />
            </div>
            <div className="inputs-cotainer-crear-dir">
                <input type="text" placeholder="Calle*" className="c-input" value={calle} onChange={({ target }) => setCalle(target.value)} />
                <input type="text" placeholder="Número Exterior*" className="c-input" value={num_ext} onChange={({ target }) => setNumExt(target.value)} />
            </div>
            <div className="inputs-cotainer-crear-dir">
                <input type="text" placeholder="Número Interior" className="c-input" value={num_int} onChange={({ target }) => setNumInt(target.value)} />
                <input type="text" placeholder="Colonia*" className="c-input" value={colonia} onChange={({ target }) => setColonia(target.value)} />
            </div>
            <div className="inputs-cotainer-crear-dir">
                <input type="text" placeholder="Delegación" className="c-input" value={delegacion} onChange={({ target }) => setDelegacion(target.value)} />
                <input type="text" placeholder="Ciudad*" className="c-input" value={ciudad} onChange={({ target }) => setCiudad(target.value)} />
            </div>
            <div className="inputs-cotainer-crear-dir">
                <input type="text" placeholder="Estado*" className="c-input" value={estado} onChange={({ target }) => setEstado(target.value)} />
                <input type="text" placeholder="Código Postal*" className="c-input" value={cp} onChange={({ target }) => setCp(target.value)} />
            </div>
            <button className="c-btn c-btn--pills u-mr-xsmall" onClick={submit}>Crear y Seleccionar Dirección</button>
        </div>
    );
}