import React, { Component } from 'react';
import GraficaBarras from './GraficaBarras';
import gql from 'graphql-tag';
import Alerts from '../../../constants/Alerts'
const GET_VENTAS_QUERY = gql`
query($tienda:String!, $finicio:String, $ffinal:String){
    getComparacionVentasDiarias(tienda:$tienda, finicio:$finicio, ffinal:$ffinal){
  		comparativo{
        actual{
          pagos{
            monto,
            tipo
          },
          total,
          piezas,
          tickets
        },
        pasado{
          pagos{
            monto,
            tipo
          },
          total,
          piezas,
          tickets
        }
      }
    }
}
`;//FALTA FINICIO Y FFINAL
class Graficas extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            pagosDia: [
                {
                    tipo: 1,
                    monto: 200
                },
                {
                    tipo: 5,
                    monto: 400
                },
                {
                    tipo: 7,
                    monto: 600
                }
            ],
            mensajeAlert: '',
            tipoAlert: '',
            mostrarAlert: false
        }
    }

    UNSAFE_componentWillMount() {
        let ventasSemanales = [];
        let piezasSemanales = [];
        let ticketsSemanales = [];
        let fecha = new Date();
        const numero = fecha.getDay();
        fecha.setDate(fecha.getDate() - numero)
        let finicio=fecha.toISOString();
        /*let finicio = fecha.toLocaleDateString('es-MX',{timeZone:'America/Mexico_City'});
        let dateArray = finicio.split('/');
        finicio = `${missingZero(dateArray[2])}${missingZero(dateArray[1])}${missingZero(dateArray[0])}T:00:00:00`;
        console.log(finicio)*/
        fecha.setDate(fecha.getDate() + 6)
        const ffinal=fecha.toISOString();
        /*let ffinal = fecha.toLocaleDateString('es-MX',{timeZone:'America/Mexico_City'});
        dateArray = ffinal.split('/');
        ffinal = `${missingZero(dateArray[2])}${missingZero(dateArray[1])}${missingZero(dateArray[0])}T:23:59:59`;*/
        /*function missingZero(str) {
            if (str.length === 1) {
                return '0' + str;
            } else {
                return str;
            }
        }*/
        const { client } = this.props;
        client.query({
            query: GET_VENTAS_QUERY,
            variables: {
                tienda: localStorage.getItem('tienda'),
                ffinal,
                finicio
            }
        }).then(
            res => {
                res.data.getComparacionVentasDiarias.forEach(
                    dia => {
                        ventasSemanales.push([dia.comparativo.pasado === null ? 0 : dia.comparativo.pasado.total, dia.comparativo.actual === null ? 0 : dia.comparativo.actual.total]);
                        piezasSemanales.push([dia.comparativo.pasado === null ? 0 : dia.comparativo.pasado.piezas, dia.comparativo.actual === null ? 0 : dia.comparativo.actual.piezas]);
                        ticketsSemanales.push([dia.comparativo.pasado === null ? 0 : dia.comparativo.pasado.tickets, dia.comparativo.actual === null ? 0 : dia.comparativo.actual.tickets]);

                    }
                );
                this.setState({ ventasSemanales, piezasSemanales, ticketsSemanales }, () => this.setState({ loading: false }));
            }
        ).catch(err => { this.mostrarAlert('danger', 'Error al cargar ventas diarias: ' + err.message); })
    }

    getPorcentaje = tipo => {
        const { pagosDia } = this.state;
        let montoTipo = 0;
        let montoTotal = 0;
        pagosDia.forEach(
            pago => {
                if (pago.tipo === parseInt(tipo,10)) montoTipo += pago.monto;
                montoTotal += pago.monto
            }
        );
        return ((montoTipo * 100) / montoTotal);
    }

    getMonto = (tipo) => {
        const { pagosDia } = this.state;
        let montoTipo = 0;
        pagosDia.forEach(
            pago => {
                if (pago.tipo === parseInt(tipo,10)) montoTipo += pago.monto;
            }
        );
        return montoTipo;
    }

    mostrarAlert = (tipoAlert, mensajeAlert) => {
        this.setState({ mensajeAlert, tipoAlert }, () => this.setState({ mostrarAlert: true }, () => setTimeout(() => this.setState({ mostrarAlert: false }), 5000)));
    }

    render() {
        const { tipoAlert, mensajeAlert, mostrarAlert, loading, ventasSemanales, piezasSemanales, ticketsSemanales } = this.state;
        return (
            <React.Fragment>
                <div style={{ display: 'flex', justifyContent: 'center', position: 'absolute', width: '100vw', left: 0, top: '53px' }}>
                    <Alerts
                        type={tipoAlert}
                        value={mensajeAlert}
                        show={mostrarAlert}
                        closeRequest={() => this.setState({ mostrarAlert: false })}
                    />
                </div>
                <div style={{ height: '149px', display: 'flex', justifyContent: 'space-between', marginBottom: '10px' }}>
                    {
                        loading
                            ?
                            <React.Fragment>
                                <div style={{ border: '1px solid #313d4f', width: '217px', height: '149px', borderRadius: '4px', backgroundColor: '#273142', padding: '10px' }}>
                                    <div style={{ height: '21px', display: 'flex', alignItems: 'center' }}>
                                        <h1 style={{ color: 'white', fontSize: '13px', margin: 0 }}>Ventas</h1>
                                    </div>
                                    <div style={{ height: 'calc(100% - 21px)' }}>
                                        <p>Cargando...</p>
                                    </div>
                                </div>
                                <div style={{ border: '1px solid #313d4f', width: '217px', height: '149px', borderRadius: '4px', backgroundColor: '#273142', padding: '10px' }}>
                                    <div style={{ height: '21px', display: 'flex', alignItems: 'center' }}>
                                        <h1 style={{ color: 'white', fontSize: '13px', margin: 0 }}>Piezas vendidas</h1>
                                    </div>
                                    <div style={{ height: 'calc(100% - 21px)' }}>
                                        <p>Cargando...</p>
                                    </div>
                                </div>
                                <div style={{ border: '1px solid #313d4f', width: '217px', height: '149px', borderRadius: '4px', backgroundColor: '#273142', padding: '10px' }}>
                                    <div style={{ height: '21px', display: 'flex', alignItems: 'center' }}>
                                        <h1 style={{ color: 'white', fontSize: '13px', margin: 0 }}>Tickets</h1>
                                    </div>
                                    <div style={{ height: 'calc(100% - 21px)' }}>
                                        <p>Cargando...</p>
                                    </div>
                                </div>
                                <div style={{ border: '1px solid #313d4f', width: '217px', height: '149px', borderRadius: '4px', backgroundColor: '#273142', padding: '10px' }}>
                                    <div style={{ height: '21px', display: 'flex', alignItems: 'center' }}>
                                        <h1 style={{ color: 'white', fontSize: '13px', margin: 0 }}>Tienda</h1>
                                    </div>
                                    <div style={{ height: 'calc(100% - 21px)' }}>
                                        <p>Cargando...</p>
                                    </div>
                                </div>
                            </React.Fragment>
                            :
                            <React.Fragment>
                                <GraficaBarras titulo='Ventas' datos={ventasSemanales} lefts={['calc((((100vw - 60px) - 880px)/2) + 5px)', 'calc((((100vw - 60px) - 880px)/2) + 32px)', 'calc((((100vw - 60px) - 880px)/2) + 62px)', 'calc((((100vw - 60px) - 880px)/2) + 92px)', 'calc((((100vw - 60px) - 880px)/2) + 122px)', 'calc((((100vw - 60px) - 880px)/2) + 152px)', 'calc((((100vw - 60px) - 880px)/2) + 182px)']} />
                                <GraficaBarras titulo='Piezas vendidas' datos={piezasSemanales} lefts={['calc((((100vw - 60px) - 880px)/2) + 225px)', 'calc((((100vw - 60px) - 880px)/2) + 252px)', 'calc((((100vw - 60px) - 880px)/2) + 279px)', 'calc((((100vw - 60px) - 880px)/2) + 306px)', 'calc((((100vw - 60px) - 880px)/2) + 343px)', 'calc((((100vw - 60px) - 880px)/2) + 372px)', 'calc((((100vw - 60px) - 880px)/2) + 399px)']} />
                                <GraficaBarras titulo='Tickets' datos={ticketsSemanales} lefts={['calc((((100vw - 60px) - 880px)/2) + 445px)', 'calc((((100vw - 60px) - 880px)/2) + 472px)', 'calc((((100vw - 60px) - 880px)/2) + 500px)', 'calc((((100vw - 60px) - 880px)/2) + 532px)', 'calc((((100vw - 60px) - 880px)/2) + 564px)', 'calc((((100vw - 60px) - 880px)/2) + 589px)', 'calc((((100vw - 60px) - 880px)/2) + 622px)']} />
                                <GraficaBarras titulo='Tienda'>
                                    <div className='name_store'>
                                        <h1 className='title_store'>{localStorage.getItem('razonSocial')}</h1>
                                        <p className='store_name' >
                                            Tienda #{localStorage.getItem('nombreTienda')}
                                        </p>
                                    </div>
                                </GraficaBarras>
                            </React.Fragment>
                    }
                </div>
            </React.Fragment>
        );
    }
}
export default Graficas;

/*
<React.Fragment>


*/