import React, { Component } from 'react';
import Fecha from '../Fecha';
import Graficas from './Graficas';
import HistorialVentas from './HistorialVentas';
import TablaAgentes from './TablaAgentes';
import { Mixpanel } from '../../constants/Mixpanel';

class Ventas extends Component {
    componentDidMount() {
        Mixpanel.ventasMounted(localStorage.getItem('rfc'))
    }

    render() {
        const { client } = this.props;
        return (
            <div className='container-ventas'>
                <div className='subcontainer-ventas'>
                    <Fecha titulo='Ventas del Día' />
                    <Graficas client={client} />
                    <HistorialVentas client={client} />
                    <TablaAgentes client={client} />
                </div>
            </div>
        );
    };
}
export default Ventas;