import React, { Component } from 'react';
import gql from 'graphql-tag';
import proptypes from 'prop-types';
const EXISTENCIAS_QUERY = gql`
query($id:String){
    getExistencias(id:$id){
      nombre,
      id
      existencias{
        cantidad_web,
        talla,
        color,
        icod
      }
    }
  }
`;

class Producto extends Component {
    constructor(props) {
        super(props);
        this.state = {
            arrayTallas: [],
            arrayColores: [],
            colorSelecto: '',
            tallaSelecto: '',
            idSelecto: '',
            activo: false,
            productoExistencias: [],
            tiendaCantidad: [],
            disponibilidad: 'N/A',
            tiendaSelecto: localStorage.getItem('tienda'),
            tiendaDefault: true
        }
    }

    componentDidMount() {
        const { client, icod, variaciones } = this.props;
        client.query({
            query: EXISTENCIAS_QUERY,
            variables: {
                id: icod
            }
        }).then(
            result => {
                this.setState({ productoExistencias: Array.from(result.data.getExistencias) })
            }
        ).catch(err => alert('Error obteniendo existencias: ' + err.message));
        let arrayTallas = [];
        let arrayColores = [];
        if (variaciones !== null && variaciones !== undefined) {
            variaciones.forEach(
                variacion => {
                    let dentro = false;
                    if (arrayTallas.length > 0) {
                        arrayTallas.forEach(
                            talla => {
                                if (talla === variacion.talla) dentro = true;
                            }
                        );
                        if (!dentro) arrayTallas.push(variacion.talla);
                    } else {
                        arrayTallas.push(variacion.talla);
                    }

                    if (arrayColores.length > 0) {
                        dentro = false;
                        arrayColores.forEach(
                            color => {
                                if (color === variacion.color) dentro = true;
                            }
                        );
                        if (!dentro) arrayColores.push(variacion.color);
                    } else {
                        arrayColores.push(variacion.color);
                    }

                }
            )
        }
        this.setState({ arrayTallas, arrayColores }, () => {
            const { arrayTallas, arrayColores } = this.state;
            if (arrayColores.length === 1) {
                if (arrayTallas.length === 1) {
                    this.setState({ colorSelecto: arrayColores[0], tallaSelecto: arrayTallas[0] }, this.getVariacion);
                } else {
                    this.setState({ colorSelecto: arrayColores[0] }, this.getVariacion);
                }
            } else if (arrayTallas.length === 1) {
                this.setState({ tallaSelecto: arrayTallas[0] }, this.getVariacion);
            }
        });
    }

    componentDidUpdate() {
        const {
            tiendaDefault,
            idSelecto
        } = this.state;
        if (tiendaDefault && idSelecto !== '') {
            this.setState({ tiendaDefault: false });
        }
    }

    dropdownString = (tienda, cantidad) => {
        let faltantes = 100;
        faltantes -= tienda.length;
        faltantes -= tienda.length;
        let tempString = ''
        for (let index = 0; index < faltantes; index++) {
            tempString += String.fromCharCode(160);
        }
        return `${tienda}${tempString}${cantidad}`
    }

    existeExistencias = (existencias) => {
        let existe = false;
        let cantidad = 0;
        const { colorSelecto, tallaSelecto } = this.state;
        existencias.forEach(
            existencia => {
                if (existencia.color === colorSelecto && existencia.talla === tallaSelecto) {
                    existe = true;
                    cantidad = existencia.cantidad_web
                }
            }
        );
        return { existe, cantidad }
    }

    existeTiendas = () => {
        const defaultTienda = localStorage.getItem('tienda');
        let hayDefault = false, cantidad = 0;
        let tiendaCantidad = [];
        const { productoExistencias, tiendaDefault } = this.state;
        productoExistencias.forEach(
            tienda => {
                let revision = this.existeExistencias(tienda.existencias);
                if (revision.existe) {
                    let tempObj = {};
                    if (revision.cantidad === null) {
                        tempObj.cantidad = 0;
                    } else {
                        tempObj.cantidad = revision.cantidad;
                    }
                    if (tienda.id === localStorage.getItem('tienda')) {
                        hayDefault = true;
                        cantidad = revision.cantidad;
                    }
                    tempObj.tiendaNombre = tienda.nombre;
                    tempObj.tiendaId = tienda.id;
                    tiendaCantidad.push(tempObj);
                }
            }
        );
        if (!hayDefault) {
            tiendaCantidad.push({ tiendaNombre: defaultTienda, cantidad: 0, tiendaId: localStorage.getItem('tienda') });
            this.setState({ tiendaCantidad });
        } else if (tiendaDefault) {
            this.setState({ tiendaCantidad, disponibilidad: cantidad });
        } else {
            this.setState({ tiendaCantidad });
        }

    }

    getVariacion = () => {
        const { colorSelecto, tallaSelecto } = this.state;
        const { variaciones } = this.props;
        if (colorSelecto !== '' && tallaSelecto !== '') {
            let icodFinal = '';
            variaciones.forEach(
                variacion => {
                    if (colorSelecto === variacion.color && tallaSelecto === variacion.talla) {
                        icodFinal = variacion.icod;
                    }
                }
            );
            this.setState({ idSelecto: icodFinal }, this.existeTiendas)
        }
    }

    getDisponibilidad = tiendaId => {
        const { tiendaCantidad } = this.state;
        let cantidad = 0;
        tiendaCantidad.forEach(
            tienda => {
                if (tienda.tiendaId === tiendaId) cantidad = tienda.cantidad;
            }
        );
        return cantidad;
    }

    render() {
        const { activo, tallaSelecto, arrayTallas, colorSelecto, arrayColores, tiendaSelecto, tiendaCantidad, disponibilidad } = this.state;
        const { imagen, nombre, icod, } = this.props;
        return (
            <div className="c-project">
                <div className="c-project__img">
                    <img src={imagen} alt="" />
                </div>
                <div className='c-stock__actions'>
                    <a disabled={!activo} className='c-board__btn'>
                        <i className='far fa-plus'></i>
                    </a>
                </div>
                <h3 className="c-project__title">{nombre}
                    <span className="c-project__status">Código <span className="u-text-bold">{icod}</span></span>
                </h3>

                <div className='c-stock__selects'>
                    <label className='c-field__label' for='select1'>Talla</label>
                    <div className='c-selected_2'>
                        <i className='c-selected-icon far fa-angle-down' style={{ right: '4px', marginTop: '-3px' }}></i>
                        <select className='c-selected' value={tallaSelecto} onChange={e => this.setState({ tallaSelecto: e.target.value }, this.getVariacion)}>
                            <option value=''>Seleccionar talla</option>
                            {
                                arrayTallas.map(
                                    (talla, index) => {
                                        return <option key={index + talla} value={talla}>{talla}</option>
                                    }
                                )
                            }<i className="fas fa-table-tennis    "></i>
                        </select>
                    </div>
                </div>
                <div className='c-pull-right'>
                    <label className='c-field__label' for='select1'>Color</label>
                    <div className='c-selected_2' id='select1'>
                        <i className='c-selected-icon far fa-angle-down' style={{ marginTop: '-3px' }}></i>
                        <select className='c-selected-2' value={colorSelecto} onChange={e => this.setState({ colorSelecto: e.target.value }, this.getVariacion)}>
                            <option value=''>Seleccionar color</option>
                            {
                                arrayColores.map(
                                    (color, index) => {
                                        return <option key={index + color} value={color}>{color}</option>
                                    }
                                )
                            }
                        </select>
                    </div>
                </div>
                <div style={{ justifyContent: 'space-between', width: '52%', marginTop: '16px' }}>
                    <label className='c-field__label' for='select1'>Tienda</label>
                    <div>
                        <div className='c-selected_2 ' id='select1'>
                            <i className='c-selected-icon far fa-angle-down' style={{ right: '4px', marginTop: '-3px' }}></i>
                            <select className='c-selected' value={tiendaSelecto} onChange={e => this.setState({ tiendaSelecto: e.target.value, disponibilidad: this.getDisponibilidad(e.target.value) })}>
                                <option value=''>Seleccionar tienda</option>
                                {
                                    tiendaCantidad.map(
                                        (tienda, index) => {
                                            return <option key={index + tienda.tiendaNombre} value={tienda.tiendaId}>{this.dropdownString(tienda.tiendaNombre, tienda.cantidad + '')}</option>
                                        }
                                    )
                                }
                            </select>
                        </div>
                    </div>
                </div>
                <div className='c-stock__footer'>
                    <label className='c-field__label' for='select1'>Disponibilidad</label>
                    <div className='c-stock__status u-color-success'>
                        <i className='u-color-my-primary'>{disponibilidad}</i>
                    </div>
                </div>
            </div>


        );
    }
}

Producto.proptypes = {
    nombre: proptypes.string,
    icod: proptypes.string,
    variaciones: proptypes.arrayOf(proptypes.shape({
        icod: proptypes.string,
        color: proptypes.string,
        talla: proptypes.string
    })),
    imagen: proptypes.string
}


export default Producto;