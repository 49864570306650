import React, { Component } from 'react';
import { Line } from 'react-chartjs-2';
import gql from 'graphql-tag';
import Alerts from '../../../constants/Alerts';
const GET_VENTAS_QUERY = gql`
query($tienda:String!){
    getComparacionVentasSemanales(tienda:$tienda){
        semana,
        comparativo{
            actual{
                total
            },
            pasado{
                total
            }
        }
    }
}
`;

class HistorialVentas extends Component {
    constructor(props) {
        super(props);
        this.state = {
            semanasActual: [],
            semanasPasado: [],
            loading: true,
            mensajeAlert: '',
            tipoAlert: '',
            mostrarAlert: false
        }
    }

    componentDidMount() {
        const { client } = this.props;
        client.query({
            query: GET_VENTAS_QUERY,
            variables: {
                tienda: localStorage.getItem('tienda')
            }
        }).then(
            res => {
                let semanasActual = [];
                let semanasPasado = [];
                res.data.getComparacionVentasSemanales.forEach(
                    semana => {
                        semanasPasado.push(semana.comparativo.pasado === null ? 0 : semana.comparativo.pasado.total);
                        semanasActual.push(semana.comparativo.actual === null ? 0 : semana.comparativo.actual.total);
                    }
                );
                this.setState({ semanasActual, semanasPasado }, () => this.setState({ loading: false }));
            }
        ).catch(err => this.mostrarAlert('danger','Error al cargar ventas semanales: '+err.message))
    }

    getYear=año=>{
        const d=new Date();
        const y=d.getFullYear().toString();
        const ly=parseInt(y,10)-1;
        if (año===1) {
            return y;
        } else {
            return ly;
        }
    }

    mostrarAlert = (tipoAlert, mensajeAlert) => {
        this.setState({ mensajeAlert, tipoAlert }, () => this.setState({ mostrarAlert: true }, () => setTimeout(() => this.setState({ mostrarAlert: false }), 5000)));
    }

    render() {
        this.getYear()
        const { tipoAlert, mensajeAlert, mostrarAlert, loading, semanasPasado, semanasActual } = this.state;
        return (
            <React.Fragment>
                <div style={{ display: 'flex', justifyContent: 'center', position: 'absolute', width: '100vw', left: 0, top: '53px' }}>
                    <Alerts
                        type={tipoAlert}
                        value={mensajeAlert}
                        show={mostrarAlert}
                        closeRequest={() => this.setState({ mostrarAlert: false })}
                    />
                </div>
                <div style={{ border: '1px solid #313d4f', height: '240px', backgroundColor: '#273142', marginBottom: '10px', borderRadius: '4px', padding: '10px' }}>
                    <div style={{ height: '18px', display: 'flex', alignItems: 'center', marginBottom: '10px' }}>
                        <h1 style={{ color: 'white', fontSize: '14px', margin: 0 }}>Historial de ventas</h1>
                        <div style={{ width: '8px', height: '8px', borderRadius: '7px', backgroundColor: '#886ce6', marginLeft: '27px' }}></div>
                        <p style={{ fontSize: '14px', marginLeft: '9px', color: '#7f8fa4' }}>Año Pasado</p>
                        <div style={{ width: '8px', height: '8px', borderRadius: '7px', backgroundColor: '#00aaff', marginLeft: '14px' }}></div>
                        <p style={{ fontSize: '14px', marginLeft: '9px', color: '#7f8fa4' }}>Año Actual</p>
                    </div>
                    <div style={{ height: '200px', color: 'white' }} ref={node => this.node = node} >
                        {
                            loading
                                ?
                                <p>Cargando...</p>
                                :
                                <React.Fragment>
                                    <Line
                                        data={
                                            {
                                                labels: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52],
                                                datasets: [
                                                    {
                                                        data: semanasPasado,
                                                        backgroundColor: [
                                                            'rgba(136,108,230,0.2)'
                                                        ],
                                                        borderColor: [
                                                            'rgba(136,108,230,1)',
                                                        ],
                                                        borderWidth: 1,
                                                        lineTension: 0,
                                                        pointBackgroundColor: ['rgba(39, 49, 66, 1)', 'rgba(39, 49, 66, 1)', 'rgba(39, 49, 66, 1)', 'rgba(39, 49, 66, 1)', 'rgba(39, 49, 66, 1)', 'rgba(39, 49, 66, 1)', 'rgba(39, 49, 66, 1)', 'rgba(39, 49, 66, 1)', 'rgba(39, 49, 66, 1)', 'rgba(39, 49, 66, 1)', 'rgba(39, 49, 66, 1)', 'rgba(39, 49, 66, 1)', 'rgba(39, 49, 66, 1)', 'rgba(39, 49, 66, 1)', 'rgba(39, 49, 66, 1)', 'rgba(39, 49, 66, 1)', 'rgba(39, 49, 66, 1)', 'rgba(39, 49, 66, 1)', 'rgba(39, 49, 66, 1)', 'rgba(39, 49, 66, 1)', 'rgba(39, 49, 66, 1)', 'rgba(39, 49, 66, 1)', 'rgba(39, 49, 66, 1)', 'rgba(39, 49, 66, 1)', 'rgba(39, 49, 66, 1)', 'rgba(39, 49, 66, 1)', 'rgba(39, 49, 66, 1)', 'rgba(39, 49, 66, 1)', 'rgba(39, 49, 66, 1)', 'rgba(39, 49, 66, 1)', 'rgba(39, 49, 66, 1)', 'rgba(39, 49, 66, 1)', 'rgba(39, 49, 66, 1)', 'rgba(39, 49, 66, 1)', 'rgba(39, 49, 66, 1)', 'rgba(39, 49, 66, 1)', 'rgba(39, 49, 66, 1)', 'rgba(39, 49, 66, 1)', 'rgba(39, 49, 66, 1)', 'rgba(39, 49, 66, 1)', 'rgba(39, 49, 66, 1)', 'rgba(39, 49, 66, 1)', 'rgba(39, 49, 66, 1)', 'rgba(39, 49, 66, 1)', 'rgba(39, 49, 66, 1)', 'rgba(39, 49, 66, 1)', 'rgba(39, 49, 66, 1)', 'rgba(39, 49, 66, 1)', 'rgba(39, 49, 66, 1)', 'rgba(39, 49, 66, 1)', 'rgba(39, 49, 66, 1)', 'rgba(39, 49, 66, 1)'],
                                                        pointHoverBorderColor: ['rgba(136, 108, 230, 1)', 'rgba(136, 108, 230, 1)', 'rgba(136, 108, 230, 1)', 'rgba(136, 108, 230, 1)', 'rgba(136, 108, 230, 1)', 'rgba(136, 108, 230, 1)', 'rgba(136, 108, 230, 1)', 'rgba(136, 108, 230, 1)', 'rgba(136, 108, 230, 1)', 'rgba(136, 108, 230, 1)', 'rgba(136, 108, 230, 1)', 'rgba(136, 108, 230, 1)', 'rgba(136, 108, 230, 1)', 'rgba(136, 108, 230, 1)', 'rgba(136, 108, 230, 1)', 'rgba(136, 108, 230, 1)', 'rgba(136, 108, 230, 1)', 'rgba(136, 108, 230, 1)', 'rgba(136, 108, 230, 1)', 'rgba(136, 108, 230, 1)', 'rgba(136, 108, 230, 1)', 'rgba(136, 108, 230, 1)', 'rgba(136, 108, 230, 1)', 'rgba(136, 108, 230, 1)', 'rgba(136, 108, 230, 1)', 'rgba(136, 108, 230, 1)', 'rgba(136, 108, 230, 1)', 'rgba(136, 108, 230, 1)', 'rgba(136, 108, 230, 1)', 'rgba(136, 108, 230, 1)', 'rgba(136, 108, 230, 1)', 'rgba(136, 108, 230, 1)', 'rgba(136, 108, 230, 1)', 'rgba(136, 108, 230, 1)', 'rgba(136, 108, 230, 1)', 'rgba(136, 108, 230, 1)', 'rgba(136, 108, 230, 1)', 'rgba(136, 108, 230, 1)', 'rgba(136, 108, 230, 1)', 'rgba(136, 108, 230, 1)', 'rgba(136, 108, 230, 1)', 'rgba(136, 108, 230, 1)', 'rgba(136, 108, 230, 1)', 'rgba(136, 108, 230, 1)', 'rgba(136, 108, 230, 1)', 'rgba(136, 108, 230, 1)', 'rgba(136, 108, 230, 1)', 'rgba(136, 108, 230, 1)', 'rgba(136, 108, 230, 1)', 'rgba(136, 108, 230, 1)', 'rgba(136, 108, 230, 1)', 'rgba(136, 108, 230, 1)'],
                                                        pointBorderColor: ['rgba(136, 108, 230, 1)', 'rgba(136, 108, 230, 1)', 'rgba(136, 108, 230, 1)', 'rgba(136, 108, 230, 1)', 'rgba(136, 108, 230, 1)', 'rgba(136, 108, 230, 1)', 'rgba(136, 108, 230, 1)', 'rgba(136, 108, 230, 1)', 'rgba(136, 108, 230, 1)', 'rgba(136, 108, 230, 1)', 'rgba(136, 108, 230, 1)', 'rgba(136, 108, 230, 1)', 'rgba(136, 108, 230, 1)', 'rgba(136, 108, 230, 1)', 'rgba(136, 108, 230, 1)', 'rgba(136, 108, 230, 1)', 'rgba(136, 108, 230, 1)', 'rgba(136, 108, 230, 1)', 'rgba(136, 108, 230, 1)', 'rgba(136, 108, 230, 1)', 'rgba(136, 108, 230, 1)', 'rgba(136, 108, 230, 1)', 'rgba(136, 108, 230, 1)', 'rgba(136, 108, 230, 1)', 'rgba(136, 108, 230, 1)', 'rgba(136, 108, 230, 1)', 'rgba(136, 108, 230, 1)', 'rgba(136, 108, 230, 1)', 'rgba(136, 108, 230, 1)', 'rgba(136, 108, 230, 1)', 'rgba(136, 108, 230, 1)', 'rgba(136, 108, 230, 1)', 'rgba(136, 108, 230, 1)', 'rgba(136, 108, 230, 1)', 'rgba(136, 108, 230, 1)', 'rgba(136, 108, 230, 1)', 'rgba(136, 108, 230, 1)', 'rgba(136, 108, 230, 1)', 'rgba(136, 108, 230, 1)', 'rgba(136, 108, 230, 1)', 'rgba(136, 108, 230, 1)', 'rgba(136, 108, 230, 1)', 'rgba(136, 108, 230, 1)', 'rgba(136, 108, 230, 1)', 'rgba(136, 108, 230, 1)', 'rgba(136, 108, 230, 1)', 'rgba(136, 108, 230, 1)', 'rgba(136, 108, 230, 1)', 'rgba(136, 108, 230, 1)', 'rgba(136, 108, 230, 1)', 'rgba(136, 108, 230, 1)', 'rgba(136, 108, 230, 1)']
                                                    },
                                                    {
                                                        data: semanasActual,
                                                        backgroundColor: [
                                                            'rgba(0,170,255,0.2)'
                                                        ],
                                                        borderColor: [
                                                            'rgba(0,170,255,1)'
                                                        ],
                                                        borderWidth: 1,
                                                        lineTension: 0,
                                                        pointBackgroundColor: ['rgba(39, 49, 66, 1)', 'rgba(39, 49, 66, 1)', 'rgba(39, 49, 66, 1)', 'rgba(39, 49, 66, 1)', 'rgba(39, 49, 66, 1)', 'rgba(39, 49, 66, 1)', 'rgba(39, 49, 66, 1)', 'rgba(39, 49, 66, 1)', 'rgba(39, 49, 66, 1)', 'rgba(39, 49, 66, 1)', 'rgba(39, 49, 66, 1)', 'rgba(39, 49, 66, 1)', 'rgba(39, 49, 66, 1)', 'rgba(39, 49, 66, 1)', 'rgba(39, 49, 66, 1)', 'rgba(39, 49, 66, 1)', 'rgba(39, 49, 66, 1)', 'rgba(39, 49, 66, 1)', 'rgba(39, 49, 66, 1)', 'rgba(39, 49, 66, 1)', 'rgba(39, 49, 66, 1)', 'rgba(39, 49, 66, 1)', 'rgba(39, 49, 66, 1)', 'rgba(39, 49, 66, 1)', 'rgba(39, 49, 66, 1)', 'rgba(39, 49, 66, 1)', 'rgba(39, 49, 66, 1)', 'rgba(39, 49, 66, 1)', 'rgba(39, 49, 66, 1)', 'rgba(39, 49, 66, 1)', 'rgba(39, 49, 66, 1)', 'rgba(39, 49, 66, 1)', 'rgba(39, 49, 66, 1)', 'rgba(39, 49, 66, 1)', 'rgba(39, 49, 66, 1)', 'rgba(39, 49, 66, 1)', 'rgba(39, 49, 66, 1)', 'rgba(39, 49, 66, 1)', 'rgba(39, 49, 66, 1)', 'rgba(39, 49, 66, 1)', 'rgba(39, 49, 66, 1)', 'rgba(39, 49, 66, 1)', 'rgba(39, 49, 66, 1)', 'rgba(39, 49, 66, 1)', 'rgba(39, 49, 66, 1)', 'rgba(39, 49, 66, 1)', 'rgba(39, 49, 66, 1)', 'rgba(39, 49, 66, 1)', 'rgba(39, 49, 66, 1)', 'rgba(39, 49, 66, 1)', 'rgba(39, 49, 66, 1)', 'rgba(39, 49, 66, 1)', 'rgba(39, 49, 66, 1)', 'rgba(39, 49, 66, 1)', 'rgba(39, 49, 66, 1)', 'rgba(39, 49, 66, 1)', 'rgba(39, 49, 66, 1)', 'rgba(39, 49, 66, 1)'],
                                                        pointHoverBorderColor: ['rgba(0, 170, 255, 1)', 'rgba(0, 170, 255, 1)', 'rgba(0, 170, 255, 1)', 'rgba(0, 170, 255, 1)', 'rgba(0, 170, 255, 1)', 'rgba(0, 170, 255, 1)', 'rgba(0, 170, 255, 1)', 'rgba(0, 170, 255, 1)', 'rgba(0, 170, 255, 1)', 'rgba(0, 170, 255, 1)', 'rgba(0, 170, 255, 1)', 'rgba(0, 170, 255, 1)', 'rgba(0, 170, 255, 1)', 'rgba(0, 170, 255, 1)', 'rgba(0, 170, 255, 1)', 'rgba(0, 170, 255, 1)', 'rgba(0, 170, 255, 1)', 'rgba(0, 170, 255, 1)', 'rgba(0, 170, 255, 1)', 'rgba(0, 170, 255, 1)', 'rgba(0, 170, 255, 1)', 'rgba(0, 170, 255, 1)', 'rgba(0, 170, 255, 1)', 'rgba(0, 170, 255, 1)', 'rgba(0, 170, 255, 1)', 'rgba(0, 170, 255, 1)', 'rgba(0, 170, 255, 1)', 'rgba(0, 170, 255, 1)', 'rgba(0, 170, 255, 1)', 'rgba(0, 170, 255, 1)', 'rgba(0, 170, 255, 1)', 'rgba(0, 170, 255, 1)', 'rgba(0, 170, 255, 1)', 'rgba(0, 170, 255, 1)', 'rgba(0, 170, 255, 1)', 'rgba(0, 170, 255, 1)', 'rgba(0, 170, 255, 1)', 'rgba(0, 170, 255, 1)', 'rgba(0, 170, 255, 1)', 'rgba(0, 170, 255, 1)', 'rgba(0, 170, 255, 1)', 'rgba(0, 170, 255, 1)', 'rgba(0, 170, 255, 1)', 'rgba(0, 170, 255, 1)', 'rgba(0, 170, 255, 1)', 'rgba(0, 170, 255, 1)', 'rgba(0, 170, 255, 1)', 'rgba(0, 170, 255, 1)', 'rgba(0, 170, 255, 1)', 'rgba(0, 170, 255, 1)', 'rgba(0, 170, 255, 1)', 'rgba(0, 170, 255, 1)'],
                                                        pointBorderColor: ['rgba(0, 170, 255, 1)', 'rgba(0, 170, 255, 1)', 'rgba(0, 170, 255, 1)', 'rgba(0, 170, 255, 1)', 'rgba(0, 170, 255, 1)', 'rgba(0, 170, 255, 1)', 'rgba(0, 170, 255, 1)', 'rgba(0, 170, 255, 1)', 'rgba(0, 170, 255, 1)', 'rgba(0, 170, 255, 1)', 'rgba(0, 170, 255, 1)', 'rgba(0, 170, 255, 1)', 'rgba(0, 170, 255, 1)', 'rgba(0, 170, 255, 1)', 'rgba(0, 170, 255, 1)', 'rgba(0, 170, 255, 1)', 'rgba(0, 170, 255, 1)', 'rgba(0, 170, 255, 1)', 'rgba(0, 170, 255, 1)', 'rgba(0, 170, 255, 1)', 'rgba(0, 170, 255, 1)', 'rgba(0, 170, 255, 1)', 'rgba(0, 170, 255, 1)', 'rgba(0, 170, 255, 1)', 'rgba(0, 170, 255, 1)', 'rgba(0, 170, 255, 1)', 'rgba(0, 170, 255, 1)', 'rgba(0, 170, 255, 1)', 'rgba(0, 170, 255, 1)', 'rgba(0, 170, 255, 1)', 'rgba(0, 170, 255, 1)', 'rgba(0, 170, 255, 1)', 'rgba(0, 170, 255, 1)', 'rgba(0, 170, 255, 1)', 'rgba(0, 170, 255, 1)', 'rgba(0, 170, 255, 1)', 'rgba(0, 170, 255, 1)', 'rgba(0, 170, 255, 1)', 'rgba(0, 170, 255, 1)', 'rgba(0, 170, 255, 1)', 'rgba(0, 170, 255, 1)', 'rgba(0, 170, 255, 1)', 'rgba(0, 170, 255, 1)', 'rgba(0, 170, 255, 1)', 'rgba(0, 170, 255, 1)', 'rgba(0, 170, 255, 1)', 'rgba(0, 170, 255, 1)', 'rgba(0, 170, 255, 1)', 'rgba(0, 170, 255, 1)', 'rgba(0, 170, 255, 1)', 'rgba(0, 170, 255, 1)', 'rgba(0, 170, 255, 1)'],
                                                        pointHoverBackgroundColor: ['rgba(39, 49, 66, 1)', 'rgba(39, 49, 66, 1)', 'rgba(39, 49, 66, 1)', 'rgba(39, 49, 66, 1)', 'rgba(39, 49, 66, 1)', 'rgba(39, 49, 66, 1)', 'rgba(39, 49, 66, 1)', 'rgba(39, 49, 66, 1)', 'rgba(39, 49, 66, 1)', 'rgba(39, 49, 66, 1)', 'rgba(39, 49, 66, 1)', 'rgba(39, 49, 66, 1)', 'rgba(39, 49, 66, 1)', 'rgba(39, 49, 66, 1)', 'rgba(39, 49, 66, 1)', 'rgba(39, 49, 66, 1)', 'rgba(39, 49, 66, 1)', 'rgba(39, 49, 66, 1)', 'rgba(39, 49, 66, 1)', 'rgba(39, 49, 66, 1)', 'rgba(39, 49, 66, 1)', 'rgba(39, 49, 66, 1)', 'rgba(39, 49, 66, 1)', 'rgba(39, 49, 66, 1)', 'rgba(39, 49, 66, 1)', 'rgba(39, 49, 66, 1)', 'rgba(39, 49, 66, 1)', 'rgba(39, 49, 66, 1)', 'rgba(39, 49, 66, 1)', 'rgba(39, 49, 66, 1)', 'rgba(39, 49, 66, 1)', 'rgba(39, 49, 66, 1)', 'rgba(39, 49, 66, 1)', 'rgba(39, 49, 66, 1)', 'rgba(39, 49, 66, 1)', 'rgba(39, 49, 66, 1)', 'rgba(39, 49, 66, 1)', 'rgba(39, 49, 66, 1)', 'rgba(39, 49, 66, 1)', 'rgba(39, 49, 66, 1)', 'rgba(39, 49, 66, 1)', 'rgba(39, 49, 66, 1)', 'rgba(39, 49, 66, 1)', 'rgba(39, 49, 66, 1)', 'rgba(39, 49, 66, 1)', 'rgba(39, 49, 66, 1)', 'rgba(39, 49, 66, 1)', 'rgba(39, 49, 66, 1)', 'rgba(39, 49, 66, 1)', 'rgba(39, 49, 66, 1)', 'rgba(39, 49, 66, 1)', 'rgba(39, 49, 66, 1)', 'rgba(39, 49, 66, 1)', 'rgba(39, 49, 66, 1)', 'rgba(39, 49, 66, 1)', 'rgba(39, 49, 66, 1)', 'rgba(39, 49, 66, 1)', 'rgba(39, 49, 66, 1)'],
                                                    }
                                                ]
                                            }
                                        }
                                        width={780}
                                        height={187}
                                        options={
                                            {
                                                tooltips: {
                                                    callbacks: {
                                                        label: function (tooltipItem, data) {
                                                            let item = tooltipItem.index;
                                                            let dataset1 = data.datasets[0];
                                                            let dataset2 = data.datasets[1];
                                                            const d=new Date();
                                                                    const y=d.getFullYear().toString();
                                                                    const ly=parseInt(y,10)-1;
                                                                    
                                                            return [` ${y}: ${new Intl.NumberFormat('es-MX', { style: 'currency', currency: 'MXN' }).format(dataset2.data[item])}`, ` ${ly}: ${new Intl.NumberFormat('es-MX', { style: 'currency', currency: 'MXN' }).format(dataset1.data[item])}`]
                                                        },
                                                        labelTextColor: function (tooltipItem, chart) {
                                                            return '#f0f3f8';
                                                        },
                                                        title: function (tooltipItem, data) {
                                                            return `Semana ${tooltipItem[0].index + 1}`
                                                        },
                                                        labelColor: function (tooltipItem, chart) {
                                                            //return 'rgba(0,170,255,1)'
                                                            return ['red', 'green']
                                                        }
                                                    }
                                                },
                                                fill: true,
                                                legend: {
                                                    display: false
                                                },
                                                scales: {
                                                    yAxes: [
                                                        {
                                                            ticks: {
                                                                callback: function (value, index, values) {
                                                                    return new Intl.NumberFormat('es-MX', { style: 'currency', currency: 'MXN' }).format(value);
                                                                },
                                                                fontColor: 'rgba(202,214,226,0.5)'
                                                            }
                                                        }
                                                    ],
                                                    xAxes: [
                                                        {
                                                            ticks: {
                                                                fontColor: 'rgba(202,214,226,0.5)'
                                                            }
                                                        }
                                                    ]
                                                }
                                            }
                                        }
                                    />
                                </React.Fragment>
                        }
                    </div>
                </div>
            </React.Fragment>
        );
    }
}
export default HistorialVentas;