import React from 'react';
import CategoriaContainer from './CategoriaContainer';
import proptypes from 'prop-types'

function Formulario({ changeNombre, nombre, changeCodigo, codigo, actionCodigo, categorias, click, removerCategoria, changeCategoriaString, actionCategorias, categoriaString }) {
    return (
        <div style={{ height: '477px', width: '270px', borderRadius: '4px', border: '1px solid #313d4f', backgroundColor: '#273142', padding: '10px 20px 10px 20px' }}>
            <p style={{ marginBottom: '12px', color: '#7f8fa4', fontSize: '12px', fontWeight: 'bold' }} >FILTRAR POR</p>
            <p style={{ marginBottom: '11px', color: '#7f8fa4', fontSize: '12px', fontWeight: 'bold' }} >Nombre</p>
            <input type='text' className='c-input' placeholder='Escribe nombre del producto' onChange={changeNombre} value={nombre} />
            <p style={{ marginBottom: '15px', color: '#7f8fa4', fontSize: '12px' }} >Ingresa el nombre de un producto</p>
            <p style={{ marginBottom: '11px', color: '#7f8fa4', fontSize: '12px', fontWeight: 'bold' }} >Código</p>
            <input type='text' className='c-input' placeholder='Escribe el código' onChange={changeCodigo} value={codigo} onKeyUp={actionCodigo} />
            <p style={{ marginBottom: '15px', color: '#7f8fa4', fontSize: '12px' }}>Ingresa el ID de un producto</p>
            <p style={{ marginBottom: '11px', color: '#7f8fa4', fontSize: '12px', fontWeight: 'bold' }}>Etiqueta</p>
            <input type='text' className='c-input' placeholder='Escribe  una  categoria' onChange={changeCategoriaString} onKeyUp={actionCategorias} value={categoriaString} />
            <p style={{ marginBottom: '15px', color: '#7f8fa4', fontSize: '12px' }} >Usa coma para multiples  categorias</p>
            <CategoriaContainer categorias={categorias} removerCategoria={removerCategoria} />
            <button className='c-btn' style={{ width: '100%' }} onClick={click} ><i className="far fa-search"></i>&nbsp;Buscar Producto</button>
        </div>
    );
}

Formulario.proptypes = {
    categorias: proptypes.arrayOf(proptypes.string),
    categoriaString: proptypes.string,
    nombre: proptypes.string,
    codigo: proptypes.string,
    changeNombre: proptypes.func,
    changeCodigo: proptypes.func,
    changeCategoriaString: proptypes.func,
    actionCategorias: proptypes.func,
    removerCategoria: proptypes.func,
    actionCodigo: proptypes.func,
    click: proptypes.func
}



export default Formulario;