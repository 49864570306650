import React, { Component } from 'react';

class Pago extends Component {
    constructor(props){
        super(props);
        this.state={
            cantidad:0
        }
    }

    handleChange=e => { 
        const { setCantidad, index } = this.props;
        if (!isNaN(e.target.value)){
            this.setState({ cantidad: e.target.value }); 
            setCantidad(index,e.target.value); 
        } 
}
    
    render() {
        const { tipo_nombre, monto } = this.props;
        const { cantidad } = this.state;
        return (
            <div className='stuffing' >
                <ul className='head__list'>
                    <li className='head__title'><h2 className='head__title-method'>{tipo_nombre.length >20 ? `${tipo_nombre.substr(0,20)}...` : tipo_nombre}</h2></li>
                    <li className='head__title'>
                        <h2 className={`head__title-cash`}>
                            {
                                new Intl.NumberFormat('es-MX', { style: 'currency', currency: 'MXN' }).format(monto)
                            }
                        </h2>
                    </li>
                    <li className='head__title'><h2 className='head__title-real c-field'>
                        <label className='c-field__label u-hidden-visually'>label</label>
                        <input onFocus={e => e.currentTarget.select()} onClick={e => e.currentTarget.select()} autoComplete='off' onChange={this.handleChange} value={cantidad} className='c-input c-real-input' id='input1' type='text' placeholder='$0.00'></input> </h2>
                    </li>
                    <li className='head__title'>
                        <h2 className='head__title-gap u-block'>
                            {
                                new Intl.NumberFormat('es-MX', { style: 'currency', currency: 'MXN' }).format(parseFloat(monto) - parseFloat(cantidad))
                            }
                        </h2>
                    </li>
                </ul>
            </div>
        );
    }
}
export default Pago;