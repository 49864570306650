import React, { Component } from "react";
import propTypes from "prop-types"
import "./styled.css"

class Alerts extends Component {
    constructor(props) {
        super(props);
        this.checkIcon=this.checkIcon.bind(this);
    }

    checkIcon=()=>{
        if(this.props.type==="success"){
            return "check"
        }else if(this.props.type==="info"){
            return "info"
        }else if(this.props.type==="warning"){
            return "exclamation"
        }else{
            return "times"
        }
    }

    render() {
        return (
            <React.Fragment>
                {
                    this.props.show
                        ?
                        <div className={`c-alert c-alert--${this.props.type?this.props.type:"danger"}`}>
                            <i className={`c-alert__icon fa fa-${this.checkIcon()}-circle`}></i>
                            <div>{this.props.value}</div>
                            <span className="c-close" data-dismiss="alert" onClick={this.props.closeRequest} ><i className="far fa-times"></i></span>
                        </div>
                        :
                        null
                }
            </React.Fragment>
        );
    }
}

/*
TYPES:
    danger
    warning
    info
    success
*/

Alerts.propTypes = {
    type: propTypes.string,
    value: propTypes.string,
    show: propTypes.bool,
    closeRequest: propTypes.func
};
export default Alerts;