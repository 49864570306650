import React, { Component } from 'react';
import Modal from 'react-modal';
import ReactToPrint from 'react-to-print';
import { graphql } from 'react-apollo';
import {flowRight as compose} from 'lodash';
import gql from 'graphql-tag';
import Alerts from '../../../constants/Alerts';
import ConfirmacionCobro from './ConfirmacionCobro';
import ImprimirTicket from '../../FormatosTickets';
import Hotkeys from 'react-hot-keys';
import { Mixpanel } from '../../../constants/Mixpanel';
import proptypes from 'prop-types';
const SerialPort = typeof window.require === 'function' ? window.require('serialport') : null;
const GET_CLIENTE_MONEDERO_QUERY = gql`
query($id:String!){
    getMonedero(id:$id){
        saldo,
        fecha_exp
    }
}
`;

const TICKET_MUTATION = gql`
mutation crearTickets($cliente:String!,$agente:String!,$tienda:String!,$items:[ItemInput]!,$pagos:[PagoInput]){
    crearTicket(cliente:$cliente,agente:$agente,tienda:$tienda,items:$items,pagos:$pagos){
        id,
        clave_factura
    }
}
`;

const GET_MONEDERO_QUERY = gql`
query($id:String!){
    getMonedero(id:$id){
        saldo,
        fecha_exp
    }
}
`;

const GET_CLIENTE_TARJETA_QUERY = gql`
query($tarjeta:String!){
    getClienteTarjeta(tarjeta:$tarjeta){
      clicod,
      puntos,
      nombre
    }
  }
`;

class Cobro extends Component {
    constructor(props) {
        super(props);
        this.state = {
            cantidadEfectivo: 0,
            cantidadPuntos: 0,
            cantidadTarjeta: 0,
            cantidadMonedero: 0,
            tempCantidadTarjeta: 0,
            tempCantidadPuntos: 0,
            tempCantidadEfectivo: 0,
            tempCantidadMonedero: 0,
            tempTotal: 0,
            habilitarCobrar: true,
            disable: false,
            pagosTarjeta: [],
            pagosMonedero: [],
            showConfirmacion: false,
            idTicket: '',
            styleEfectivo: {},
            styleTarjeta: { display: "none" },
            stylePuntos: { display: "none" },
            styleMonedero: { display: "none" },
            checkEfectivo: true,
            checkTarjeta: false,
            checkPuntos: false,
            checkMonedero: false,
            idMonedero: '',
            vigenciaMonedero: "",
            saldoMonedero: 0,
            monederoLeido: false,
            focusEfectivo: false,
            focusMonedero: false,
            focusPuntos: false,
            focusTarjeta: false,
            mostrarAlert: false,
            mensajeAlert: "",
            tipoAlert: "",
            clave_factura: '',
            tarjetaPuntos: false,
            puntos: 0,
            puntosNombre: '',
            tarjetaPuntosString: '',
            tipoTarjetaSeleccionado: '',
            tipoOtrosSeleccionado: '',
            tiposPago: {
                tarjeta: [],
                otros: []
            }
        };
        if (SerialPort !== null) this.port = new SerialPort('/dev/tty.usbserial', { baudRate: 9600 });
    }

    componentDidMount() {
        const efectivo = JSON.parse(localStorage.getItem('efectivo'));
        const tarjeta = JSON.parse(localStorage.getItem('tarjeta'));
        const puntos = JSON.parse(localStorage.getItem('puntos'));
        const monedero = JSON.parse(localStorage.getItem('monedero'));
        const otros = JSON.parse(localStorage.getItem('otros'));
        const tiposPago = { efectivo, tarjeta, puntos, monedero, otros };
        if (SerialPort !== null) {
            this.port.on('data', data => {
                let datos = '';
                datos += data.toString();
                datos = datos.substring(datos.indexOf('%') + 1, datos.indexOf('?') - 1);
                //this.getClienteTarjeta(datos);
                if (datos.includes('A010049939')) {
                    this.getClienteTarjeta('0012893789064');
                } else {
                    this.getClienteTarjeta('A1');
                }
            });
        }
        const { ticket } = this.props;
        this.setState({
            tempTotal: parseFloat(parseFloat(ticket.total).toFixed(2)),
            tiposPago
        }, () => {
            const { tempTotal } = this.state;
            this.setState({ tempCantidadEfectivo: tempTotal }, () => {
                const { puntosNombre, tarjetaPuntosString } = this.props;
                document.getElementById("inputCantidadEfectivo").focus()
                if (puntosNombre !== '') {
                    this.getClienteTarjeta(tarjetaPuntosString)
                }
            })
        }
        );
    }

    getClienteTarjeta = tarjeta => {
        const { client } = this.props;
        client.query({
            variables: {
                tarjeta
            },
            query: GET_CLIENTE_TARJETA_QUERY
        }).then(result => {
            const { clicod, puntos, nombre } = result.data.getClienteTarjeta;
            let tempCantidadPuntos;
            const { tempTotal } = this.state;
            if (puntos >= tempTotal) {
                tempCantidadPuntos = tempTotal;
            } else {
                tempCantidadPuntos = puntos;
            }
            const { changeCliente } = this.props;
            changeCliente(clicod);
            this.setState({ tempCantidadPuntos, tarjetaPuntos: true, puntos, puntosNombre: nombre },
                () => {
                    let { tempTotal, cantidadPuntos } = this.state;
                    tempTotal += cantidadPuntos;
                    this.setState({ tarjetaPuntosString: tarjeta, cantidadPuntos: 0, tempTotal }, () => {
                        const { disable, tempTotal } = this.state;
                        if (disable && tempTotal > 0) {
                            this.setState({ habilitarCobrar: true, disable: false, })
                        }
                        this.setState({ styleEfectivo: { display: "none" }, styleTarjeta: { display: "none" }, stylePuntos: {}, checkEfectivo: false, checkTarjeta: false, checkPuntos: true, checkMonedero: false, styleMonedero: { display: "none" }, focusEfectivo: false, focusMonedero: false, focusPuntos: true, focusTarjeta: false }, () => { document.getElementById("inputCantidadPuntos").focus(); })
                    });
                }
            );
        }).catch(err => {
            if (err.message.includes('404')) {
                this.getClienteMonedero(tarjeta)
            } else {
                this.mostrarAlert("danger", err.message);
            }
        });
    }

    getClienteMonedero = id => {
        const { client } = this.props;
        client.query({
            variables: {
                id
            },
            query: GET_CLIENTE_MONEDERO_QUERY
        }).then(result => {
            let vigenciaMonedero = result.data.getMonedero.fecha_exp;
            if (vigenciaMonedero.length >= 10) vigenciaMonedero = vigenciaMonedero.substring(0, 10);
            this.setState({ idMonedero: id, saldoMonedero: result.data.getMonedero.saldo, vigenciaMonedero, monederoLeido: true },
                () => this.setState({ styleEfectivo: { display: "none" }, styleTarjeta: { display: "none" }, stylePuntos: { display: "none" }, styleMonedero: {}, checkEfectivo: false, checkTarjeta: false, checkPuntos: false, checkMonedero: true, tempCantidadMonedero: this.state.tempTotal, focusEfectivo: false, focusMonedero: true, focusPuntos: false, focusTarjeta: false, tarjetaPuntos: false }, () => { document.getElementById("inputCantidadMonedero").focus(); })
            )
        }).catch(err => {
            if (err.message.includes('404')) {
                this.mostrarAlert("warning", "No se encontró cliente");
            } else {
                this.mostrarAlert("danger", err.message);
            }
        })
    }

    changeTotal = () => {
        const { ticket } = this.props;
        const { cantidadEfectivo, cantidadPuntos, cantidadTarjeta, cantidadMonedero } = this.state;
        let tempTotal = parseFloat(ticket.total) - parseFloat(cantidadEfectivo) - parseFloat(cantidadPuntos) - parseFloat(cantidadTarjeta) - parseFloat(cantidadMonedero);
        this.setState({ tempTotal: parseFloat(tempTotal.toFixed(2)) }, () => {
            const { tempTotal } = this.state;
            if (tempTotal <= 0) {
                this.setState({ habilitarCobrar: false, disable: true, idMonedero: "", vigenciaMonedero: "", saldoMonedero: 0 })
            }
            this.setState({ tempCantidadPuntos: tempTotal, tempCantidadEfectivo: tempTotal, tempCantidadTarjeta: tempTotal, tempCantidadMonedero: tempTotal, idMonedero: "", vigenciaMonedero: "", saldoMonedero: 0, monederoLeido: false });
        });

    }

    cobrar = async () => {
        const { tiposPago, pagosTarjeta, pagosMonedero } = this.state;
        let { tempTotal, cantidadEfectivo, cantidadPuntos, cantidadTarjeta, cantidadMonedero } = this.state;
        let pagos = [];
        tempTotal = parseFloat(tempTotal)
        cantidadEfectivo = parseFloat(cantidadEfectivo)
        cantidadTarjeta = parseFloat(cantidadTarjeta)
        cantidadPuntos = parseFloat(cantidadPuntos)
        cantidadMonedero = parseFloat(cantidadMonedero)

        let efectivo = 'false';
        let tarjeta = 'false';
        let puntos = 'false';
        let monedero = 'false';

        if (cantidadEfectivo > 0) {
            efectivo = 'true';
            let monto = 0;
            if (tempTotal < 0) {
                monto = cantidadEfectivo + tempTotal;
                monto = parseFloat(monto);
                monto = monto.toFixed(2);
                monto = parseFloat(monto);
            } else {
                monto = cantidadEfectivo.toFixed(2);
                monto = parseFloat(monto);
            }
            pagos.push({
                monto,
                tipo: tiposPago.efectivo.tipo
            })
        }

        if (cantidadPuntos > 0) {
            puntos = 'true';
            pagos.push({
                monto: cantidadPuntos,
                tipo: tiposPago.puntos.tipo
            });
        }

        if (cantidadTarjeta > 0) {
            tarjeta = 'true';
            pagosTarjeta.forEach(
                pago => {
                    pagos.push(pago)
                }
            );
        }
        if (cantidadMonedero > 0) {
            monedero = 'true';
            pagosMonedero.forEach(
                pago => {
                    pagos.push(pago)
                }
            );
        }
        const { agente, cliente, items, ticketMutation } = this.props;
        ticketMutation({
            variables: {
                agente,
                cliente,
                items,
                tienda: localStorage.getItem("tienda"),
                pagos
            }
        }).then(
            result => {
                const { changeTiempoFinal } = this.props;
                Mixpanel.pagosEnCobro(localStorage.getItem('rfc'), efectivo, tarjeta, puntos, monedero);
                changeTiempoFinal();
                this.setState({
                    idTicket: result.data.crearTicket.id,
                    clave_factura: result.data.crearTicket.clave_factura
                }, () => this.setState({ showConfirmacion: true }, () => document.getElementById("imprimirModalCobro").click()
                ));
            }
        ).catch(
            err => {
                Mixpanel.fallaCrearTicket(localStorage.getItem('rfc'), err.message)
                this.mostrarAlert('danger', 'Error creando ticket: ' + err.message)
            }
        )
    }

    agregarEfectivo = e => {
        e.preventDefault();
        const { cantidadEfectivo, tempCantidadEfectivo } = this.state;
        this.setState({
            cantidadEfectivo: parseFloat(cantidadEfectivo) + parseFloat(tempCantidadEfectivo)
        }, () => {
            this.changeTotal();
        });
    }

    agregarPuntos = e => {
        e.preventDefault();
        const { tempTotal, cantidadPuntos, tempCantidadPuntos, puntos } = this.state;
        if ((parseFloat(tempTotal) - parseFloat(cantidadPuntos) - parseFloat(tempCantidadPuntos)) >= 0 && (parseFloat(puntos) - parseFloat(cantidadPuntos) - parseFloat(tempCantidadPuntos)) >= 0) {
            this.setState({
                cantidadPuntos: parseFloat(cantidadPuntos) + parseFloat(tempCantidadPuntos)
            }, () => {
                this.changeTotal();
            });
        }

    }

    agregarMonedero = e => {
        e.preventDefault();
        const { monederoLeido, tiposPago, tempTotal, cantidadMonedero, pagosMonedero, saldoMonedero, idMonedero, vigenciaMonedero } = this.state;
        let { tempCantidadMonedero } = this.state;
        if (monederoLeido) {
            if ((parseFloat(tempTotal) - parseFloat(cantidadMonedero) - parseFloat(tempCantidadMonedero)) >= 0 && (parseFloat(saldoMonedero) - parseFloat(cantidadMonedero) - parseFloat(tempCantidadMonedero)) >= 0) {
                tempCantidadMonedero = parseFloat(cantidadMonedero) + parseFloat(tempCantidadMonedero);
                let tempPagos = pagosMonedero;
                let usado = false;
                tempPagos.forEach(
                    pago => {
                        if (pago.terminacion === idMonedero) usado = true;
                    }
                );
                if (!usado) {
                    tempPagos.push({
                        monto: parseFloat(tempCantidadMonedero),
                        tipo: tiposPago.monedero.tipo,
                        terminacion: idMonedero,
                        caducidad: vigenciaMonedero,
                        saldo_anterior: saldoMonedero
                    });
                    this.setState({
                        cantidadMonedero: parseFloat(tempCantidadMonedero),
                        pagosMonedero: tempPagos
                    }, () => {
                        this.changeTotal();
                    });
                } else {
                    this.mostrarAlert("warning", "Monedero ya utilizado en esta compra");
                }
            }
        }
    }

    agregarTarjeta = e => {
        e.preventDefault();
        const { tiposPago, tipoTarjetaSeleccionado, cantidadTarjeta, pagosTarjeta } = this.state;
        let { tempCantidadTarjeta } = this.state;
        if (tipoTarjetaSeleccionado !== '') {
            tempCantidadTarjeta = parseFloat(cantidadTarjeta) + parseFloat(tempCantidadTarjeta);
            let tempPagos = pagosTarjeta;
            tempPagos.push({
                monto: parseFloat(tempCantidadTarjeta),
                tipo: tiposPago.tarjeta[0].tipo,
                terminacion: '1234',
                autorizacion: 'auth',
                order_id: 'orderID',
                id: tipoTarjetaSeleccionado
            });
            this.setState({
                cantidadTarjeta: parseFloat(tempCantidadTarjeta),
                pagosTarjeta: tempPagos
            }, () => {
                this.changeTotal();
            });
        }
    }

    cantidad = items => {
        if (items.length > 0) {
            let cantidad = 0;
            items.map(
                item => cantidad = cantidad + item.cantidad
            );
            return cantidad;
        } else {
            return 0;
        }
    }

    seleccionarMonedero = () => {
        const { client } = this.props;
        const { idMonedero } = this.state;
        client.query({
            query: GET_MONEDERO_QUERY,
            variables: {
                id: idMonedero
            }
        }).then(
            result => {
                let vigenciaMonedero = result.data.getMonedero.fecha_exp;
                if (vigenciaMonedero.length >= 10) vigenciaMonedero = vigenciaMonedero.substring(0, 10);
                this.setState({ saldoMonedero: result.data.getMonedero.saldo, vigenciaMonedero, monederoLeido: true });
            }
        ).catch(err => {
            if (err.message.includes("404")) {
                this.mostrarAlert("warning", "Monedero no encontrado");
            } else {
                this.mostrarAlert("danger", err.message);
            }
        }
        );
    }

    mostrarAlert = (tipoAlert, mensajeAlert) => {
        this.setState({ tipoAlert, mensajeAlert },
            () => {
                this.setState({ mostrarAlert: true }, () => setTimeout(() => this.setState({ mostrarAlert: false }), 5000));
            }
        );
    }

    asterisco = cadena => {
        const len = cadena.length;
        if (len > 4) {
            let tempString = ''
            for (let index = 0; index < len - 5; index++) {
                tempString += '*';
            }
            tempString += `${cadena.charAt(len - 4)}${cadena.charAt(len - 3)}${cadena.charAt(len - 2)}${cadena.charAt(len - 1)}`;
            return tempString;
        } else {
            return cadena;
        }
    }

    onKeyUp(keyName, e, handle) {
        const { tempTotal } = this.state;
        switch (keyName) {
            case 'F1':
                this.setState({ styleEfectivo: {}, styleTarjeta: { display: "none" }, stylePuntos: { display: "none" }, checkEfectivo: true, checkTarjeta: false, checkPuntos: false, tempCantidadEfectivo: tempTotal, checkMonedero: false, styleMonedero: { display: "none" }, focusEfectivo: true, focusMonedero: false, focusPuntos: false, focusTarjeta: false }, () => { document.getElementById("inputCantidadEfectivo").focus(); });
                break;
            case 'F2':
                this.setState({ styleEfectivo: { display: "none" }, styleTarjeta: {}, stylePuntos: { display: "none" }, checkEfectivo: false, checkTarjeta: true, checkPuntos: false, tempCantidadTarjeta: tempTotal, checkMonedero: false, styleMonedero: { display: "none" }, focusEfectivo: false, focusMonedero: false, focusPuntos: false, focusTarjeta: true }, () => { document.getElementById("inputCantidadTarjeta").focus(); });
                break;
            case 'F3':
                this.setState({ styleEfectivo: { display: "none" }, styleTarjeta: { display: "none" }, stylePuntos: {}, checkEfectivo: false, checkTarjeta: false, checkPuntos: true, tempCantidadPuntos: tempTotal, checkMonedero: false, styleMonedero: { display: "none" }, focusEfectivo: false, focusMonedero: false, focusPuntos: true, focusTarjeta: false }, () => { document.getElementById("inputCantidadPuntos").focus(); });
                break;
            case 'F4':
                this.setState({ styleEfectivo: { display: "none" }, styleTarjeta: { display: "none" }, stylePuntos: { display: "none" }, styleMonedero: {}, checkEfectivo: false, checkTarjeta: false, checkPuntos: false, checkMonedero: true, tempCantidadMonedero: tempTotal, focusEfectivo: false, focusMonedero: true, focusPuntos: false, focusTarjeta: false }, () => { document.getElementById("inputCantidadMonedero").focus(); });
                break;
            default:
                break;
        }
    }

    render() {
        const { cerrar, items, ticket, puntos, productos, agente, cliente, cancelar, client } = this.props;
        const { tipoAlert, mensajeAlert, mostrarAlert, tempTotal, cantidadEfectivo, cantidadTarjeta, pagosTarjeta, cantidadPuntos, cantidadMonedero, pagosMonedero, checkEfectivo, checkTarjeta, checkMonedero, checkPuntos, styleEfectivo, disable, tempCantidadEfectivo, tempCantidadMonedero, tempCantidadPuntos, tempCantidadTarjeta, styleTarjeta, styleMonedero, stylePuntos, tiposPago, tarjetaPuntos, tarjetaPuntosString, puntosNombre, vigenciaMonedero, saldoMonedero, idMonedero, monederoLeido, idTicket, clave_factura, showConfirmacion } = this.state;

        return (
            <React.Fragment>
                <Hotkeys
                    keyName="F1,F2,F3,F4"
                    onKeyUp={this.onKeyUp.bind(this)}
                ></Hotkeys>
                <div className='c-modal--huge ' role="dialog" >
                    <div className="c-modal__dialog modal-dialog" role="document">
                        <div className='c-modal__content modal-content' >
                            <div className='c-modal__header'>
                                <h3 className='c-modal__title'> Métodos de Pago</h3>
                                <span className="c-modal__close" data-dismiss="modal" aria-label="Close">
                                    <i onClick={cerrar} className="far fa-times"></i>
                                </span>
                            </div>
                            <div style={{ display: 'flex', justifyContent: 'center', position: "absolute", zIndex: 900, width: '100%' }}>
                                <Alerts
                                    type={tipoAlert}
                                    value={mensajeAlert}
                                    show={mostrarAlert}
                                    closeRequest={() => this.setState({ mostrarAlert: false })}
                                />
                            </div>
                            <div className='c-modal__cobro'>
                                <div className="ticket-modal-cobro">
                                    <table className='a-table u-border-zero'>
                                        <tbody>
                                            <tr className='c-table__row-original'>
                                                <td className='c-table__cell-original u-color-b-titles'>Cantidad</td>
                                                <td className='c-align_right-money'>{this.cantidad(items) !== 1 ? `${this.cantidad(items)} pzas.` : "1 pza."}</td>
                                            </tr>
                                            <tr className='c-table__row-original'>
                                                <td className='c-table__cell-original u-color-b-titles'>Descuento</td>
                                                <td className='c-align_right-money'>{new Intl.NumberFormat('es-MX', { style: 'currency', currency: 'MXN' }).format(ticket.descuento)}</td>
                                            </tr>
                                            <tr className='c-table__row-original'>
                                                <td className='c-table__cell-original u-color-b-titles'>Impuestos</td>
                                                <td className='c-align_right-money'>{new Intl.NumberFormat('es-MX', { style: 'currency', currency: 'MXN' }).format(ticket.iva)}</td>
                                            </tr>
                                            <tr className='c-table__row-original'>
                                                <td className='c-table__cell-original u-color-b-titles'>Subtotal</td>
                                                <td className='c-align_right-money'>{new Intl.NumberFormat('es-MX', { style: 'currency', currency: 'MXN' }).format(ticket.subtotal)}</td>
                                            </tr>
                                            <tr className='c-table__row-original'>
                                                <td className='c-table__cell-original'> <h5 className='u-color-b-titles' style={{ margin: 0 }}>Total</h5></td>
                                                <td className='c-align_right-money'> <h6 className='u-color-white' style={{ margin: 0 }}>{new Intl.NumberFormat('es-MX', { style: 'currency', currency: 'MXN' }).format(ticket.total)}</h6></td>
                                            </tr>
                                            <tr className='c-table__row-original'>
                                                <td className='c-table__cell-original'> </td>
                                                <td className='c-table__cell-original'></td>
                                            </tr>
                                        </tbody>
                                    </table>
                                    <div className='c-spaces'>
                                        <h5 className='u-h5 u-color-white u-p-small'>{tempTotal === ticket.total ? "No hay pagos realizados" : "Pagos realizados"}</h5>
                                    </div>
                                    <div className="pagosContainerCobro">
                                        <div className='leftColHeader u-p-xsmall' style={{ display: `${cantidadEfectivo > 0 ? "" : "none"}` }}>
                                            <h5 className='tipo-pago  u-color-primary u-pl-xsmall'>Efectivo</h5>
                                            <p className='pago-realizado u-h5 '>{new Intl.NumberFormat('es-MX', { style: 'currency', currency: 'MXN' }).format(cantidadEfectivo)}

                                            </p>
                                        </div>
                                        <div style={{ display: `${cantidadTarjeta > 0 ? "" : "none"}` }}>
                                            {
                                                pagosTarjeta.length > 0
                                                    ?
                                                    pagosTarjeta.map((pago, index) => {
                                                        return (
                                                            <div className='leftColHeader u-p-xsmall'>
                                                                <h5 className='tipo-pago  u-color-primary u-pl-xsmall'>{`Tarjeta ${''/*pago.terminacion*/}`}</h5>
                                                                <p key={index} className='pago-realizado u-h5 '>{new Intl.NumberFormat('es-MX', { style: 'currency', currency: 'MXN' }).format(pago.monto)}</p>
                                                            </div>
                                                        )
                                                    })
                                                    :
                                                    null
                                            }
                                        </div>
                                        <div className='leftColHeader-puntos u-p-xsmall' style={{ display: `${cantidadPuntos > 0 ? "" : "none"}` }}>
                                            <h5 className='tipo-pago  u-color-primary u-pl-xsmall'>Puntos</h5>
                                            <p className='pago-realizado u-h5 '>{new Intl.NumberFormat('es-MX', { style: 'currency', currency: 'MXN' }).format(cantidadPuntos)}

                                            </p>
                                        </div>
                                        <div style={{ display: `${cantidadMonedero > 0 ? "" : "none"}` }}>
                                            {
                                                pagosMonedero.length > 0
                                                    ?
                                                    pagosMonedero.map((pago, index) => {
                                                        return (
                                                            <div className='leftColHeader u-p-xsmall'>
                                                                <h5 className='tipo-pago  u-color-primary u-pl-xsmall'>{`Monedero - ${pago.terminacion.length > 4 ? `${pago.terminacion[pago.terminacion.length - 4] + pago.terminacion[pago.terminacion.length - 3] + pago.terminacion[pago.terminacion.length - 2] + pago.terminacion[pago.terminacion.length - 1]}` : pago.terminacion}`}</h5>
                                                                <p key={index} className='pago-realizado u-h5 '>{new Intl.NumberFormat('es-MX', { style: 'currency', currency: 'MXN' }).format(pago.monto)}</p>
                                                            </div>
                                                        )
                                                    })
                                                    :
                                                    null
                                            }
                                        </div>
                                    </div>
                                    <table className='b-table  u-border-zero'>
                                        <tbody>
                                            <tr className='c-table__row-original'>
                                                <td className='c-table__cell-original'> <h4 className='u-color-b-titles'>Saldo</h4></td>
                                                <td className='c-table__cell-original'> <h5 className={`${tempTotal < 0 ? "u-text-white" : ""} faltante`}>{new Intl.NumberFormat('es-MX', { style: 'currency', currency: 'MXN' }).format(tempTotal)}</h5></td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                                <div className='pagos-modal-cobro'>
                                    <div className='tabla-pagos-modal-cobro u-justify-center '>
                                        <div className="tabbed">
                                            <input type="radio" name="tabs" id="tab-nav-1" readOnly checked={checkEfectivo} onClick={() => { this.setState({ styleEfectivo: {}, styleTarjeta: { display: "none" }, stylePuntos: { display: "none" }, checkEfectivo: true, checkTarjeta: false, checkPuntos: false, tempCantidadEfectivo: tempTotal, checkMonedero: false, styleMonedero: { display: "none" }, focusEfectivo: true, focusMonedero: false, focusPuntos: false, focusTarjeta: false }, () => { document.getElementById("inputCantidadEfectivo").focus(); }); }} />
                                            <label htmlFor="tab-nav-1">Efectivo <i className="fal fa-money-bill-alt u-text-small u-opacity-medium u-ml-small" /></label>

                                            <input type="radio" name="tabs" id="tab-nav-2" readOnly checked={checkTarjeta} onClick={() => { this.setState({ styleEfectivo: { display: "none" }, styleTarjeta: {}, stylePuntos: { display: "none" }, checkEfectivo: false, checkTarjeta: true, checkPuntos: false, tempCantidadTarjeta: tempTotal, checkMonedero: false, styleMonedero: { display: "none" }, focusEfectivo: false, focusMonedero: false, focusPuntos: false, focusTarjeta: true }, () => { document.getElementById("inputCantidadTarjeta").focus(); }); }} />
                                            <label htmlFor="tab-nav-2">Tarjeta <i className="fal fa-credit-card-front u-text-small u-opacity-medium u-ml-medium" /></label>

                                            <input type="radio" name="tabs" id="tab-nav-3" readOnly checked={checkPuntos} onClick={() => { this.setState({ styleEfectivo: { display: "none" }, styleTarjeta: { display: "none" }, stylePuntos: {}, checkEfectivo: false, checkTarjeta: false, checkPuntos: true, tempCantidadPuntos: tempTotal, checkMonedero: false, styleMonedero: { display: "none" }, focusEfectivo: false, focusMonedero: false, focusPuntos: true, focusTarjeta: false }, () => { document.getElementById("inputCantidadPuntos").focus(); }); }} />
                                            <label htmlFor="tab-nav-3">Puntos <i className="far fa-credit-card-blank u-text-small u-opacity-medium u-ml-medium" /></label>

                                            <input type="radio" name="tabs" id="tab-nav-4" readOnly checked={checkMonedero} onClick={() => { this.setState({ styleEfectivo: { display: "none" }, styleTarjeta: { display: "none" }, stylePuntos: { display: "none" }, styleMonedero: {}, checkEfectivo: false, checkTarjeta: false, checkPuntos: false, checkMonedero: true, tempCantidadMonedero: tempTotal, focusEfectivo: false, focusMonedero: true, focusPuntos: false, focusTarjeta: false }, () => { document.getElementById("inputCantidadMonedero").focus(); }); }} />
                                            <label htmlFor="tab-nav-4">Monedero <i className="far fa-credit-card-blank u-text-small u-opacity-medium u-ml-xsmall" /></label>
                                            <div style={styleEfectivo}>
                                                <div className='container-tarjeta'>
                                                    <div className='center'>
                                                        <button className="c-btn c-btn--pills u-mr-xsmall" disabled={disable} onClick={() => this.setState({ cantidadEfectivo: parseFloat(cantidadEfectivo + 0.5) }, () => { this.changeTotal(); this.setState({ tempCantidadEfectivo: tempTotal }); })}>$0.50</button>
                                                        <button className="c-btn c-btn--pills u-mr-xsmall" disabled={disable} onClick={() => this.setState({ cantidadEfectivo: parseFloat(cantidadEfectivo + 1) }, () => { this.changeTotal(); this.setState({ tempCantidadEfectivo: tempTotal }); })}>$1</button>
                                                        <button className="c-btn c-btn--pills u-mr-xsmall" disabled={disable} onClick={() => this.setState({ cantidadEfectivo: parseFloat(cantidadEfectivo + 2) }, () => { this.changeTotal(); this.setState({ tempCantidadEfectivo: tempTotal }); })}>$2</button>
                                                        <button className="c-btn c-btn--pills u-mr-xsmall" disabled={disable} onClick={() => this.setState({ cantidadEfectivo: parseFloat(cantidadEfectivo + 5) }, () => { this.changeTotal(); this.setState({ tempCantidadEfectivo: tempTotal }); })}>$5</button>
                                                        <button className="c-btn c-btn--pills u-mr-xsmall" disabled={disable} onClick={() => this.setState({ cantidadEfectivo: parseFloat(cantidadEfectivo + 10) }, () => { this.changeTotal(); this.setState({ tempCantidadEfectivo: tempTotal }); })}>$10</button>
                                                        <button className="c-btn c-btn--pills u-mr-xsmall" disabled={disable} onClick={() => this.setState({ cantidadEfectivo: parseFloat(cantidadEfectivo + 20) }, () => { this.changeTotal(); this.setState({ tempCantidadEfectivo: tempTotal }); })}>$20</button>
                                                    </div>
                                                    <div className='center u-mt-small   u-mb-medium'>
                                                        <button className='c-btn c-btn--pills u-mr-xsmall' disabled={disable} onClick={() => this.setState({ cantidadEfectivo: parseFloat(cantidadEfectivo + 50) }, () => { this.changeTotal(); this.setState({ tempCantidadEfectivo: tempTotal }); })}>$50</button>
                                                        <button className='c-btn c-btn--pills u-mr-xsmall' disabled={disable} onClick={() => this.setState({ cantidadEfectivo: parseFloat(cantidadEfectivo + 100) }, () => { this.changeTotal(); this.setState({ tempCantidadEfectivo: tempTotal }); })}>$100</button>
                                                        <button className='c-btn c-btn--pills u-mr-xsmall' disabled={disable} onClick={() => this.setState({ cantidadEfectivo: parseFloat(cantidadEfectivo + 200) }, () => { this.changeTotal(); this.setState({ tempCantidadEfectivo: tempTotal }); })}>$200</button>
                                                        <button className='c-btn c-btn--pills u-mr-xsmall' disabled={disable} onClick={() => this.setState({ cantidadEfectivo: parseFloat(cantidadEfectivo + 500) }, () => { this.changeTotal(); this.setState({ tempCantidadEfectivo: tempTotal }); })}>$500</button>
                                                        <button className='c-btn c-btn--pills u-mr-xsmall' disabled={disable} onClick={() => this.setState({ cantidadEfectivo: parseFloat(cantidadEfectivo + 1000) }, () => { this.changeTotal(); this.setState({ tempCantidadEfectivo: tempTotal }); })}>$1,000</button>
                                                    </div>
                                                    <div className='center'>
                                                        <form className='c-card__body'>
                                                            <div className='c-field u-mb-small'>
                                                                <h5 className="c-cobro_label u-mb-small " htmlFor="input1">Cantidad</h5>
                                                                <div className='input-centrado'>
                                                                    <div className="c-field c-field--inline-2  u-mr-small">
                                                                        <input onLoad={e => e.currentTarget.select()} id="inputCantidadEfectivo" onFocus={e => e.currentTarget.select()} className="c-input" placeholder="Cantidad" disabled={disable} type="text"
                                                                            onChange={e => {
                                                                                let RegEx = /^[0-9]*$/
                                                                                if (RegEx.test(e.target.value)) {
                                                                                    let tempCantidadEfectivo = e.target.value;
                                                                                    if (tempCantidadEfectivo[0] === '.') tempCantidadEfectivo = '0' + tempCantidadEfectivo;
                                                                                    this.setState({ tempCantidadEfectivo });
                                                                                }
                                                                            }}
                                                                            value={isNaN(tempCantidadEfectivo) || tempCantidadEfectivo < 0 ? 0 : tempCantidadEfectivo} />
                                                                    </div>
                                                                    <button disabled={disable} className="c-btn agregar-btn" onClick={this.agregarEfectivo}>
                                                                        <i className="fa fa-plus u-text-small u-opacity-medium u-mr-xsmall" />Agregar Pago
                                                                    </button>
                                                                </div>
                                                            </div>
                                                        </form>
                                                    </div>
                                                </div>
                                            </div>
                                            <div style={styleTarjeta}>
                                                <div className='container-efectivo'>
                                                    <div className='center'>
                                                        <form className='c-card__body'>


                                                            <div className='c-field u-mb-small'>
                                                                <h5 className="c-cobro_label u-mb-small " htmlFor="input1">Tipo de Tarjeta</h5>
                                                                <div className='input-centrado'>
                                                                    <div className="field-select u-width-50">
                                                                        <span className='c-selected'>
                                                                            <i className='c-selected-icon far fa-angle-down'></i>
                                                                            <select onChange={e => this.setState({ tipoTarjetaSeleccionado: e.target.value })}>
                                                                                <option value=''>Selecciona tipo de tarjeta</option>
                                                                                {
                                                                                    tiposPago.tarjeta
                                                                                        ?
                                                                                        tiposPago.tarjeta.map(
                                                                                            (pago, index) => <option key={index} value={pago.id}>{pago.descripcion}</option>
                                                                                        )
                                                                                        :
                                                                                        null
                                                                                }
                                                                            </select>
                                                                        </span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className='c-field u-mb-small'>
                                                                <h5 className="c-cobro_label u-mb-small " htmlFor="input1">Cantidad</h5>
                                                                <div className='input-centrado'>
                                                                    <div className="c-field c-field--inline-2  u-mr-small">
                                                                        <input id="inputCantidadTarjeta" onFocus={e => e.currentTarget.select()} className="c-input" placeholder="Cantidad" disabled={disable} type="text"
                                                                            onChange={e => {
                                                                                let RegEx = /^[0-9]*$/
                                                                                if (RegEx.test(e.target.value)) {
                                                                                    let tempCantidadTarjeta = e.target.value;
                                                                                    if (tempCantidadTarjeta[0] === '.') tempCantidadTarjeta = '0' + tempCantidadTarjeta;
                                                                                    this.setState({ tempCantidadTarjeta });
                                                                                }
                                                                            }}
                                                                            value={isNaN(tempCantidadTarjeta) || tempCantidadTarjeta < 0 ? 0 : tempCantidadTarjeta} />
                                                                    </div>
                                                                    <button disabled={disable} className="c-btn agregar-btn" onClick={this.agregarTarjeta}>
                                                                        <i className="fa fa-plus u-text-small u-opacity-medium u-mr-xsmall" />Agregar Pago
                                                                    </button>
                                                                </div>
                                                            </div>
                                                        </form>
                                                    </div>
                                                </div>
                                            </div>

                                            <div style={stylePuntos}>
                                                <div className='container-efectivo'>
                                                    <div className='center'>
                                                        <div className='c-card__body' style={{ display: `${tarjetaPuntos || puntos !== 0 ? 'none' : ''}` }}>
                                                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                                                <div style={{ width: '30px', height: '30px', borderRadius: '50%', backgroundImage: 'linear-gradient(to bottom, #2ea1f8, #1990ea)', marginRight: '15px', display: 'flex', justifyContent: 'center', alignItems: 'center', color: 'white' }}><i className="fal fa-credit-card"></i></div>
                                                                <p style={{ color: 'white', fontSize: '14px' }}>Escanea para agregar pago</p>
                                                            </div>
                                                            <p style={{ marginLeft: '45px', fontSize: '14px', color: '#7f8fa4' }}>Pasa la tarjeta de puntos por el escaner.</p>
                                                        </div>
                                                        <div style={{ display: `${tarjetaPuntos || puntos !== 0 ? '' : 'none'}` }}>
                                                            <form className='c-card__body'>
                                                                <div className="c-field u-mb-medium">
                                                                    <div className="c-input" style={{ border: '1px solid #39b54a', display: 'flex', justifyContent: 'space-between', color: '#39b54a' }} >
                                                                        <p style={{ color: 'white' }}>{this.asterisco(tarjetaPuntosString)}</p>
                                                                        <i className="fas fa-check" ></i>
                                                                    </div>
                                                                </div>
                                                                <div className='u-flex u-justify-between u-mb-medium'>
                                                                    <div>
                                                                        <h3 className='u-text-mute u-text-uppercase u-mb-small' style={{ fontSize: '14px' }}>Nombre</h3>
                                                                        <p style={{ color: 'white', fontSize: '14px' }}>{puntosNombre}</p>
                                                                    </div>
                                                                    <div>
                                                                        <h3 className='u-text-mute u-text-uppercase u-mb-small' style={{ fontSize: '14px' }}>Saldo</h3>
                                                                        <p style={{ color: 'white', fontSize: '14px' }}>{puntos !== 1 ? `${puntos} ptos.` : `${puntos} pto.`}</p>
                                                                    </div>
                                                                </div>
                                                                <div className='c-field u-mb-small'>
                                                                    <h5 className="c-cobro_label u-mb-small " htmlFor="input1">Puntos</h5>
                                                                    <div className='input-centrado'>
                                                                        <div className="c-field c-field--inline-2  u-mr-small">
                                                                            <input type="text" id="inputCantidadPuntos" onFocus={e => e.currentTarget.select()} onClick={e => e.currentTarget.select()} className="c-input" placeholder="Cantidad"
                                                                                onChange={e => {
                                                                                    let RegEx = /^[0-9]*$/
                                                                                    if (RegEx.test(e.target.value)) {
                                                                                        let tempCantidadPuntos = e.target.value;
                                                                                        if (tempCantidadPuntos[0] === '.') tempCantidadPuntos = '0' + tempCantidadPuntos;
                                                                                        this.setState({ tempCantidadPuntos });
                                                                                    }
                                                                                }}
                                                                                value={disable || isNaN(tempCantidadPuntos) || tempCantidadPuntos < 0 ? 0 : tempCantidadPuntos}
                                                                            />
                                                                        </div>
                                                                        <button disabled={tempCantidadPuntos > puntos || tempCantidadPuntos <= 0 || isNaN(tempCantidadPuntos)} className="c-btn agregar-btn" onClick={this.agregarPuntos}>
                                                                            <i className="fa fa-plus u-text-small u-opacity-medium u-mr-xsmall" />Agregar
                                                                    </button>
                                                                    </div>
                                                                </div>
                                                            </form>
                                                        </div>

                                                    </div>
                                                </div>
                                            </div>

                                            <div style={styleMonedero}>
                                                <div className='container-efectivo'>
                                                    <div className='center'>
                                                        <form className='c-card__body'>
                                                            <div className='u-flex u-justify-between '>
                                                                <h5 className="c-cobro_label u-mb-small">Agregar</h5>
                                                                <h5 className="c-cobro_label u-mb-small u-ml-xxlarge">Vigencia</h5>
                                                                <h5 className="c-cobro_label u-mb-small u-ml-medium ">Saldo</h5>
                                                            </div>
                                                            <div className='u-flex u-justify-between'>
                                                                <div className="c-field  u-mb-small  u-width-50 ">
                                                                    <input id="inputCantidadMonedero" className="c-input " autoComplete="off" autoCorrect="off" autoSave="off" disabled={true} onKeyDown={e => { if (e.key === "Tab") this.seleccionarMonedero() }} type="text" placeholder="Ingresar monedero" onChange={e => this.setState({ idMonedero: e.target.value })} value={idMonedero} />
                                                                </div>
                                                                <p className='u-color-white u-p-normal u-mr-xsmall'>{vigenciaMonedero !== "" ? vigenciaMonedero : "00/00"}</p>
                                                                <p className='u-color-white u-p-normal u-mr-xsmall'>{saldoMonedero !== 0 ? new Intl.NumberFormat('es-MX', { style: 'currency', currency: 'MXN' }).format(saldoMonedero) : "$00.00"}</p>
                                                            </div>
                                                            <div className='c-field u-mb-small'>
                                                                <h5 className="c-cobro_label u-mb-small " htmlFor="input1">Cantidad</h5>
                                                                <div className='input-centrado'>
                                                                    <div className="c-field c-field--inline-2  u-mr-small">
                                                                        <input onFocus={e => e.currentTarget.select()} className="c-input" placeholder="Cantidad" disabled={disable} type="text"
                                                                            onChange={e => {
                                                                                let RegEx = /^[0-9]*$/
                                                                                if (RegEx.test(e.target.value)) {
                                                                                    let tempCantidadMonedero = e.target.value;
                                                                                    if (tempCantidadMonedero[0] === '.') tempCantidadMonedero = '0' + tempCantidadMonedero;
                                                                                    this.setState({ tempCantidadMonedero });
                                                                                }
                                                                            }}
                                                                            value={isNaN(tempCantidadMonedero) || tempCantidadMonedero < 0 ? 0 : tempCantidadMonedero} />
                                                                    </div>
                                                                    <button className="c-btn agregar-btn" onClick={this.agregarMonedero} disabled={disable || !monederoLeido || tempCantidadMonedero > saldoMonedero} >
                                                                        <i className="fa fa-plus u-text-small u-opacity-medium u-mr-xsmall" />Aplicar
                                                                    </button>
                                                                </div>
                                                            </div>
                                                        </form>
                                                    </div>
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="c-modal__footer-1">
                            <button className='c-btn c-btn-cobro c-btn--fullwidth' onClick={this.cobrar} disabled={!disable} >Cobrar</button>
                        </div>
                        <div className='u-display_none'>
                            <ReactToPrint
                                trigger={() => <button id="imprimirModalCobro" className='c-btn  c-btn-cobro' disabled={!disable} >Cobrar</button>}
                                content={() => this.imprimirPosTicket}
                            />
                            <ImprimirTicket
                                datos={{
                                    ticket: {
                                        cambio: `${tempTotal}`,
                                        fecha: new Date(),
                                        id: idTicket,
                                        clave_factura: clave_factura,
                                        descuento: ticket.descuento,
                                        iva: ticket.iva,
                                        ieps: 0,
                                        subtotal: ticket.subtotal
                                    },
                                    pagos: {
                                        efectivo: cantidadEfectivo,
                                        monedero: pagosMonedero,
                                        puntos: {
                                            monto: cantidadPuntos,
                                            saldo_anterior: puntos
                                        },
                                        tarjeta: pagosTarjeta
                                    },
                                    items,
                                    productos,
                                    cliente,
                                    agente
                                }}
                                tipo={parseInt(localStorage.getItem('ticketTipo'), 10)}
                                ref={el => (this.imprimirPosTicket = el)}
                            />
                        </div>
                    </div>
                </div>
                <Modal style={{
                    overlay: {
                        top: 0,
                        left: 0,
                        right: 0,
                        bottom: 0,
                        zIndex: 500,
                        backgroundColor: '#273142'
                    },
                    content: {
                        width: "750px",
                        display: 'block',
                        position: 'relative',
                        top: 'calc((100% - 544px) / 2)',
                        right: '0',
                        bottom: '0',
                        left: 'calc((100% - 750px) / 2)',
                        padding: '0',
                        zIndex: '-1',
                        outline: "0",
                        overflow: "hidden",
                        backgroundColor: "rgba(0,0,0,0)",
                        border: "0",
                        boxShadow: "0px 30px 60px -19px rgba(0,0,0,0.75)"
                    }
                }} isOpen={showConfirmacion} >
                    <ConfirmacionCobro
                        client={client}
                        cancelar={cancelar}
                        idTicket={idTicket}
                        datos={{
                            ticket: {
                                cambio: `${tempTotal}`,
                                fecha: new Date(),
                                id: idTicket,
                                clave_factura: clave_factura,
                                descuento: ticket.descuento,
                                iva: ticket.iva,
                                ieps: 0,
                                subtotal: ticket.subtotal
                            },
                            pagos: {
                                efectivo: cantidadEfectivo,
                                monedero: pagosMonedero,
                                puntos: {
                                    monto: cantidadPuntos,
                                    saldo_anterior: puntos
                                },
                                tarjeta: pagosTarjeta
                            },
                            items,
                            productos,
                            cliente,
                            agente
                        }}
                    />
                </Modal>
            </React.Fragment>
        )
    }
}

Cobro.proptypes = {
    changeTiempoFinal: proptypes.func,
    cancelar: proptypes.func,
    cerrar: proptypes.func,
    changeCliente: proptypes.func,
    tarjetaPuntosString: proptypes.string,
    puntosNombre: proptypes.string,
    cliente: proptypes.string,
    agente: proptypes.string,
    puntos: proptypes.number,
    productos: proptypes.arrayOf(proptypes.shape({
        nombre: proptypes.string,
        icod: proptypes.string,
        precio: proptypes.number
    })),
    ticket: proptypes.shape({
        descuento: proptypes.number,
        subtotal: proptypes.number,
        iva: proptypes.number,
        total: proptypes.number
    }),
    items: proptypes.arrayOf(proptypes.shape({
        producto: proptypes.string,
        cantidad: proptypes.number,
        descuento: proptypes.number,
        precio_unitario: proptypes.number
    }))
}
export default compose(graphql(TICKET_MUTATION, { name: 'ticketMutation' }))(Cobro);
/*
||===================================================================
||                          COMPONENTE:
||===================================================================
||                          DESCRIPCIÓN
||
||
||
||
||
||
||
||
||
||
||===================================================================
||                             PROPS
||
||
||
||
||
||
||
||
||
||
||===================================================================
||                             STATE
||
||
||
||
||
||
||
||
||
||
||===================================================================
||                             FUNCIONES
||
||
||
||
||
||
||
||
||
||
||===================================================================
||                             OTROS
||
||
||
||
||
||
||
||
||
||
||===================================================================
*/