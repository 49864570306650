import React from 'react';
import Barra from './Barra';
import proptypes from 'prop-types';

function Barras({ maximo, valorDia, valorDiaAnterior, intDiaActual, dia }) {
    return (
        <React.Fragment>
            <div >
                <div style={{ height: '40px', width: '18px' }}>
                    <div style={{ marginLeft: '1px', position: 'absolute' }}>
                        <Barra maximo={maximo} valor={valorDia} backgroundImage='linear-gradient(to top, #1991eb, #2da1f8)' principal={false} />
                    </div>
                    <Barra maximo={maximo} valor={valorDiaAnterior} backgroundColor='#facf55' principal={true} />
                </div>
                <div style={{ width: '20px', display: 'flex', justifyContent: 'center', marginTop:'2.5px' }}>
                    <p style={{ fontSize: '7.5px', color:`${esHoy(intDiaActual,dia)?'white':'#7f8fa4'}` }}>{dia}</p>
                </div>
            </div>
        </React.Fragment>
    );
}

function esHoy(intDia,dia) {
    if(intDia===7)intDia=0;
    let dias=['DOM','LUN','MAR','MIÉ','JUE','VIE','SÁB'];
    if(dias[intDia]===dia){
        return true;
    }else{
        return false;
    }
}

Barras.proptypes={ 
    maximo:proptypes.number, 
    valorDia:proptypes.number, 
    valorDiaAnterior:proptypes.number, 
    intDiaActual:proptypes.number, 
    dia:proptypes.string
}
export default Barras;