import React, { Component } from 'react';
import proptypes from 'prop-types';

class Fecha extends Component {
    constructor(props) {
        super(props);
        this.state = {
            date: '02/11/1994 - 05:20:00'
        }
    }

    componentDidMount() {
        let intervalId = setInterval(() => {
            this.setState({
                date: `${new Date().toLocaleDateString('es-MX')} - ${new Date().toLocaleTimeString('es-MX')} `
            });
        }, 1000);
        this.setState({ intervalId })
    }

    componentWillUnmount() {
        // use intervalId from the state to clear the interval
        const { intervalId } = this.state;
        clearInterval(intervalId);
    }

    render() {
        const { date } = this.state;
        const { titulo } = this.props;
        return (
            <div className='c-toolbar'>
                <h3 className='c-toolbar__title has-divider'>{titulo}</h3>
                <h5 className='c-toolbar-meta u-mr-auto'>
                    <i className='far fa-clock'>
                    </i>
                    {date}
                </h5>
            </div>
        )
    }
}

Fecha.proptypes = {
    titulo:proptypes.string
}
export default Fecha;
/*
||===================================================================
||                       COMPONENTE: Fecha
||===================================================================
||                          DESCRIPCIÓN
|| Componente utilizado en toda la aplicación para mostrar la fecha y
|| un titulo.
||===================================================================
||                             PROPS
||  * titulo: Texto mostrado en la sección de la fecha.
||===================================================================
||                             STATE
||  * date: fecha y hora del día.
||===================================================================
*/