import React, { useEffect, useState } from 'react';
import { PulseLoader } from 'react-spinners';
import gql from 'graphql-tag';

const EXISTE_PEDIDO_QUERY = gql`
query($id:String!,$rfc:String!){
    existePedido(id:$id,rfc:$rfc){
      existe
    }
  }
`;

const GET_PEDIDO_QUERY = gql`
    query($rfc:String!, $id:String!){
        getPedidoEsperando(rfc:$rfc, id:$id){
            body,
            liga,
            total
        }
    }
`;

const GET_CLIENTE_QUERY = gql`
query($id:String!){
  getCliente(id:$id){
    nombre
  }
}
`;

export default function PedidoPendiente({ match: { params: { id } }, client }) {
    const [existe, setExiste] = useState(true);
    const [loading, setLoading] = useState(true);
    const [pedido, setPedido] = useState({
        cliente: '',
        productos: 0,
        total: '0',
        liga: ''
    });
    useEffect(() => {
        const fetchExiste = async () => {
            const res = await client.query({
                query: EXISTE_PEDIDO_QUERY,
                variables: {
                    id,
                    rfc: localStorage.getItem('rfc')
                },
                fetchPolicy: "no-cache"
            });
            setExiste(res.data.existePedido.existe);
        };
        const intervalId = setInterval(fetchExiste, 4000);
        return () => {
            clearInterval(intervalId);
        }
    }, []);

    useEffect(() => {
        const fetchData = async () => {
            const res = await client.query({
                query: GET_PEDIDO_QUERY,
                variables: {
                    id,
                    rfc: localStorage.getItem('rfc')
                }
            });
            if (!res.error) {
                const { liga, total, body } = res.data.getPedidoEsperando;
                const { cliente, items } = JSON.parse(body);
                const clienteNombre = await getClienteNombre(cliente);
                setPedido({
                    cliente: clienteNombre,
                    productos: contarItems(items),
                    total,
                    liga,
                })
                setLoading(false);
            }
        };
        fetchData();
    }, []);

    const contarItems = (items) => {
        let total = 0;
        for (const item of items) {
            if (item.producto !== 'ZFLETE') {
                total += item.cantidad;
            }
        }
        return total;
    }

    const getClienteNombre = async (id) => {
        const res = await client.query({
            query: GET_CLIENTE_QUERY,
            variables: {
                id
            }
        });
        return res.data.getCliente.nombre;
    }


    if (loading) {
        return (
            <div style={{ width: '100%', height: 'calc(100vh - 53px)', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <PulseLoader color='#36D7B7' loading={true} heightUnit='px' height={100} widthUnit='px' width={100} />
            </div>
        );
    }
    if (!existe) {
        return <div className="page-container pedido-pendiente">El pedido ya fue procesado...</div>
    }

    const { cliente, productos, total, liga } = pedido;
    return (
        <div className="page-container pedido-pendiente">
            <div style={{ display: 'flex', justifyContent: 'center', width: '100%', margin:'20px' }}>
                <PulseLoader color='#36D7B7' loading={true} heightUnit='px' height={100} widthUnit='px' width={100} />
            </div>
            <div style={{ textAlign: 'center', width: '100%', margin:'20px' }}>Esperando pago...</div>
            <div style={{marginTop:'50px'}}>El pedido será creado cuando se reciba confirmación de pago. Información:</div>
            <div style={{textAlign:'left', padding:'10px'}}>
                <div>Cliente: {cliente}</div>
                <div>Productos: {productos}</div>
                <div>Total: {total}</div>
            </div>
            <div style={{width:'100%', display:'flex', justifyContent:'center', alignItems:'center', flexDirection:'column', margin:'25px'}}>
                <div>Liga:</div>
                <textarea className='c-input esperando-pago-liga' value={liga} />
            </div>

        </div>
    );
}