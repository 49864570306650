import React from 'react';
import proptypes from 'prop-types'

function NavegacionCorte({ parcial, changeParcial }) {
    return (
        <div className='instance_corte '>
            <div className='instance-title' >
                <div className='c-btn-group'>
                    <div className={parcial ? 'c-btn trueCorteParcial' : 'c-btn falseCorteParcial'} onClick={() => { if (!parcial) changeParcial(true) }} align='center'>
                        <i className='far fa-inbox u-mr-xsmall'></i>
                        &nbsp;CORTE PARCIAL
                        </div>
                    <div className={!parcial ? 'c-btn trueCorteTotal' : 'c-btn falseCorteTotal'} onClick={() => { if (parcial) changeParcial(false) }} align='center'>
                        <i className='far fa-inbox-out u-mr-xsmall'></i>
                        &nbsp;CORTE FINAL
                        </div>
                </div>
            </div>
        </div>
    );
}
NavegacionCorte.proptypes={
    parcial:proptypes.bool,
    changeParcial:proptypes.func
}

export default NavegacionCorte;
/*
||===================================================================
||              COMPONENTE: Navegación de Corte de Caja
||===================================================================
||                          DESCRIPCIÓN
|| Permite desplazarnos entre los diferentes cortes de caja.
||===================================================================
||                             PROPS
||  * parcial: boleano que indica true si el corte es parcial y false
||  si el corte es total o final.
||
||  *changeParcial: funcion que recibe un boleano y permite cambiar el
||  valor de la variable parcial.
||===================================================================
*/