import React from 'react';
import Producto from './Producto';
import proptypes from 'prop-types';

function ProductosContainer({productos, loading, client}){
        return (
            <div className='inventario-productos-container'>
                {
                    productos.length > 0
                        ?
                        <React.Fragment>
                            {
                                loading
                                ?
                                <React.Fragment>
                                    <p>CARGANDO...</p>
                                </React.Fragment>
                                :
                                <React.Fragment>
                                    {
                                        productos.map(
                                            (producto,index) => <Producto key={index} client={client} nombre={producto.nombre} icod={producto.icod} variaciones={producto.variaciones}  imagen={producto.imagen} />
                                        )
                                    }
                                </React.Fragment>
                            }
                        </React.Fragment>
                        :
                        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%', height: '100%' }}>
                            <img src='/img/busqueda-vacia.png' alt='' />
                        </div>
                }
            </div>
        );
}

ProductosContainer.proptypes={
    productos:proptypes.arrayOf(proptypes.shape({
        imagen:proptypes.string,
        nombre:proptypes.string,
        precio:proptypes.number,
        talla:proptypes.string,
        color:proptypes.string,
        icod:proptypes.string,
        descuento:proptypes.number,
        variaciones:proptypes.arrayOf(proptypes.shape({
            icod: proptypes.string,
            color: proptypes.string,
            talla: proptypes.string
        }))
    })),
    loading:proptypes.bool
}
export default ProductosContainer;