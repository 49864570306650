import React from 'react';
import gql from 'graphql-tag';
import { Query } from 'react-apollo';
import ProductoPadre from '../ProductoPadre';
import CargandoProductoPadre from "../CargandoProductoPadre";
import proptypes from 'prop-types';

const BUSCAR_PRODUCTOS_QUERY = gql`
query buscarProductos($q: String!) {
    buscarProducto(q:$q) {
    productos{
        imagen,
        nombre,
        precio,
        talla,
        color,
        icod,
        descuento,
        variaciones {
            icod,
            color,
            talla
        }
    },
    ultimaPagina
    }
}
`;

function ProductoContainer({ changeActivoHijo, agregarAlTicket, q, mostrarHijos }){
        return(
            <Query query={BUSCAR_PRODUCTOS_QUERY} variables={{q,token:localStorage.getItem("token")}}>
                {
                    ({loading, error, data})=>{
                        if(loading) return <React.Fragment><CargandoProductoPadre/><CargandoProductoPadre/><CargandoProductoPadre/><CargandoProductoPadre/><CargandoProductoPadre/><CargandoProductoPadre/></React.Fragment>
                        if(error) {
                            if (error.message.includes('404')) {
                                return(
                                    <div style={{width:'100%', display:'flex', justifyContent:'center'}}>
                                        <img src='/img/sidebar_vacio.png' alt='' style={{width:'121px', height:131.594, opacity:0.5, marginTop:'30px', textAlign:'center'}} />
                                    </div>
                                );
                            } else {
                                return(
                                    <React.Fragment>
                                        <p>{error.message}</p>
                                    </React.Fragment>
                                );
                            }
                        }
                        return(
                            data.buscarProducto.productos.map(
                                (producto,index)=>{
                                    const props={changeActivoHijo, agregarAlTicket, mostrarHijos, producto}
                                    return (<ProductoPadre {...props} key={producto+index} />)
                                }
                            )
                        )
                    }
                }
            </Query>
        );
}

ProductoContainer.proptypes={
    changeActivoHijo:proptypes.func,
    agregarAlTicket:proptypes.func,
    q:proptypes.string
}

export default ProductoContainer;
/*
||===================================================================
||                          COMPONENTE:
||===================================================================
||                          DESCRIPCIÓN
||
||
||
||
||
||
||
||
||
||
||===================================================================
||                             PROPS
||
||
||
||
||
||
||
||
||
||
||===================================================================
||                             STATE
||
||
||
||
||
||
||
||
||
||
||===================================================================
||                             FUNCIONES
||
||
||
||
||
||
||
||
||
||
||===================================================================
||                             OTROS
||
||
||
||
||
||
||
||
||
||
||===================================================================
*/