import React, { Component } from 'react';
import gql from 'graphql-tag';
import Alerts from '../../../constants/Alerts';
import proptypes from 'prop-types';
import { getPagos } from '../../../constants/Util';
const GET_VENTAS_QUERY = gql`
query($tienda:String! ){
    getVentas(tienda:$tienda){
      pagos{
        monto,
        tipo,
        tipo_nombre
      }
    }
  }
`;

class Parcial extends Component {

    constructor(props) {
        super(props);
        this.state = {
            cantidadEfectivo: 0,
            nota: '',
            ventasEfectivo: 0,
            tipoAlert: '',
            mensajeAlert: '',
            mostrarAlert: false,
            tiposPago: {}
        }
    }

    async componentWillUnmount(){
    }

    componentDidMount() {
        const efectivo = JSON.parse(localStorage.getItem('efectivo'));
        const tiposPago = { efectivo };
        const { client, cambiarTotal } = this.props;
        this.setState(
            { tiposPago },
            () => {
                client.query({
                    variables: {
                        tienda: localStorage.getItem('tienda')
                    },
                    query: GET_VENTAS_QUERY
                }).then(
                    result => {
                        const { pagos } = result.data.getVentas[0];
                        const ventasEfectivo = getPagos(pagos, tiposPago.efectivo.tipo);
                        cambiarTotal(ventasEfectivo)
                        this.setState({
                            ventasEfectivo
                        });
                    }
                ).catch(err => this.mostrarAlert('danger', 'Error cargando ventas del día: ' + err.message))
            }
        )
    }

    cortar = () => {
        const { changePagos, changeComentario, mostrar } = this.props;
        const { tiposPago, cantidadEfectivo, nota } = this.state;
        const pagos = [
            {
                tipo: parseInt(tiposPago.efectivo.tipo,10),
                tipo_nombre: 'Efectivo',
                monto: cantidadEfectivo
            }
        ];
        changePagos(pagos, () => changeComentario(nota, () => mostrar(true)));
    }

    mostrarAlert = (tipoAlert, mensajeAlert) => {
        this.setState({ mensajeAlert, tipoAlert }, () => this.setState({ mostrarAlert: true }, () => setTimeout(() => this.setState({ mostrarAlert: false }), 5000)));
    }

    render() {
        const { cantidadEfectivo, nota, ventasEfectivo, mostrarAlert, mensajeAlert, tipoAlert } = this.state;
        const { children } = this.props;
        return (
            <React.Fragment>
                <div style={{ display: 'flex', justifyContent: 'center', position: 'absolute', top: '53px', left: 0, width: '100vw' }}>
                    <Alerts
                        type={tipoAlert}
                        value={mensajeAlert}
                        show={mostrarAlert}
                        closeRequest={() => this.setState({ mostrarAlert: false })}
                    />
                </div>
                <div className='c-corte-container'>
                    {
                        children
                    }
                    <div className='c-final_main'>
                        <div className='c-final-side'>
                            <div className='c-fina-summary'>
                                <div className='head-container'>
                                    <h2 className='withdraw_title'>Retiro de Efectivo</h2>
                                    <h1 className='amount-title'>
                                        {
                                            new Intl.NumberFormat('es-MX', { style: 'currency', currency: 'MXN' }).format(ventasEfectivo)
                                        }
                                    </h1>
                                </div>

                            </div>
                            <div className='parcial-container'>
                                <div className='parcial-head '>
                                    <div className='center u-mt-medium'>
                                        <div className='c-field u-mt-medium u-pl-small'>
                                            <label className='c-field__label'>Cantidad a retirar</label>
                                            <div className='c-field has-addon-left u-mb-small'>
                                                <span className='c-field__addon u-bg-money'>
                                                    <i className='far fa-dollar-sign u-color-my-primary'></i>
                                                </span>
                                                <input onChange={e => { if (!isNaN(e.target.value)) this.setState({ cantidadEfectivo: e.target.value }) }} value={cantidadEfectivo} className='c-input c-withdraw' id='input9' type='text' placeholder='0.00' autoComplete='off' onFocus={e => e.currentTarget.select()} onClick={e => e.currentTarget.select()}></input>
                                            </div>
                                        </div>
                                        <div className='c-withdraw__price'>
                                            <h3 className='t-withdraw_title'>Restante en Caja</h3>
                                            <h2 className='t-withdraw_price'>
                                                {
                                                    new Intl.NumberFormat('es-MX', { style: 'currency', currency: 'MXN' }).format(ventasEfectivo - cantidadEfectivo)
                                                }
                                            </h2>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='parcial-note'>
                            <div className='c-field'>
                                <label className='c-field__label'>Nota</label>
                                <textarea autoComplete='off' onChange={e => this.setState({ nota: e.target.value })} value={nota} placeholder='Escribe aquí una nota' className='c-input c-note' id='textarea2'>

                                </textarea>
                            </div>
                            <div className='u-block ' >
                                <button disabled={cantidadEfectivo > 0 ? false : true} onClick={this.cortar} className='c-btn c-btn--info c-btn--fullwidth '>CONFIRMAR</button>
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

Parcial.proptypes = {
    cambiarTotal:proptypes.func,
    changePagos:proptypes.func,
    changeComentario:proptypes.func,
    mostrar:proptypes.func
}
export default Parcial;
/*
||===================================================================
||                          COMPONENTE:
||===================================================================
||                          DESCRIPCIÓN
||  Interfaz para realizar el corte de caja parcial.
||===================================================================
||                             PROPS
||  * mostrar: función para mostrar el alert de confirmación para
||  realizar el corte.
||
||  * changeComentario: función para guardar el comentario en el state
||  del contenedor principal
||
||  * changePagos:función para guardar el comentario en el state
||  del contenedor principal
||===================================================================
||                             STATE
||  * cantidadEfectivo: flotante para guardar temporalmente el valor
||  de cantidad en efectivo.
||
||  * nota: cadena para guardar temporalmente el valor del comentario
||  del corte.
||
||  *mostrarAlert: boleano que es true cuando se muestra el alert.
||
||  *mensajeAlert: string con el mensaje del alert.
||
||  *tipoAlert: string que debe contener el tipo de alert a mostrar.
||===================================================================
||                             FUNCIONES
||  * cortar: Función donde se formatea el array de pagos y se realiza
||  la mutación de corte de caja.
||
||  *mostrarAlert: muestra los alerts de la app.
||===================================================================
*/

