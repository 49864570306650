import React from 'react';

export function Pestana({ titulo, handleClick, seleccionado, primero }) {
    const getIcon = () => {
        switch (titulo) {
            case 'efectivo':
                return <i className='fas fa-money-bill-alt'></i>;
            default:
                return <i className='fas fa-credit-card'></i>;
        }
    }

    return (
        <div className={seleccionado === titulo ? `pestaña-pagos-omni-active ${primero && 'primer-pestaña-omni'}` : `pestaña-pagos-omni ${primero && 'primer-pestaña-omni'}`} onClick={handleClick}>
            <div>{titulo}</div>
            {
                getIcon()
            }
        </div>
    );
}

