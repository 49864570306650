import React from "react";
import { Route, Redirect } from "react-router-dom";
import auth from "../Auth";
import { withApollo } from 'react-apollo';

const ProtectedRoute = ({ component: Component, client, ...rest }) => {
    return (
        <Route {...rest} render={props => {
            if (auth.isAuthenticated()) {
                return <Component {...props} client={client} />;
            } else {
                return (<Redirect to={{ pathname: "/login", state: { from: props.location } }} />);
            }
        }}
        />
    );
};
export default withApollo(ProtectedRoute);