import mixpanel from 'mixpanel-browser';
mixpanel.init(process.env.NODE_ENV === 'production'?'b35de0df67a2e80e2ec3044834468596':'c927d2e325bfed2aaca42f96fabbb789');

let actions = {
    login:$rfc=>{/**YES */
        mixpanel.track("Loged", {$rfc});
   },
    posMounted:$rfc=>{/**YES */
         mixpanel.track("POS Mounted", {$rfc});
    },
    ticketsMounted:$rfc=>{/**YES */
         mixpanel.track("Historial Tickets Mounted", {$rfc});
    },
    corteMounted:$rfc=>{/**YES */
         mixpanel.track("Corte de Caja Mounted", {$rfc});
    },
    ventasMounted:$rfc=>{/**YES */
         mixpanel.track("Ventas del Día Mounted", {$rfc});
    },
    inventarioMounted:$rfc=>{/**YES */
         mixpanel.track("Inventario Mounted", {$rfc});
    },
    corteParcial:($rfc, $porcentaje)=>{/**YES */
         mixpanel.track("Corte Parcial Realizado", {$rfc, $porcentaje});
    },
    fallaCrearTicket:($rfc,$mensaje)=>{/**YES */
         mixpanel.track("Error Realizando Ticket", {$rfc, $mensaje});
    },
    botonFacturar:($rfc)=>{/**YES */
         mixpanel.track("Click en Boton Facturar", {$rfc});
    },
    reimpresionTicketCofirmacion:($rfc)=>{/**YES */
         mixpanel.track("Click en Reimpresión", {$rfc});
    },
    pagosEnCobro:($rfc,$efectivo,$tarjeta,$puntos,$monedero)=>{/**YES */
         mixpanel.track("Pagos en Venta", {$rfc, $efectivo, $tarjeta, $monedero, $puntos});
    },
    tiempoVenta:($rfc,$tiempo)=>{/**YES */
         mixpanel.track("Error Realizando Ticket", {$rfc, $tiempo});
    },
    tiempoCalcularTicket:($rfc,$tiempo)=>{/**YES */
         mixpanel.track("Tiempo en calcular ticket", {$rfc, $tiempo});
    },
    busquedaSidebar:($rfc)=>{/**YES */
         mixpanel.track("Busqueda en Sidebar", {$rfc});
    }
};

export let Mixpanel = actions;

/*
EJEMPLO

let actions = {
    identify: (id) => {
         mixpanel.identify(id);
    },
    alias: (id) => {
         mixpanel.alias(id);
    },
    track: (name, props) => {
         mixpanel.track(name, props);
    },
    people: {
        set: (props) => {
             mixpanel.people.set(props);
        },
    },
};

*/