import React, { Component } from 'react';
import Alerts from "../../../../constants/Alerts";
import { Mixpanel } from '../../../../constants/Mixpanel';
import proptypes from 'prop-types';

class ProductoHijo extends Component {
    constructor(props) {
        super(props);
        this.state = {
            icod: "",
            arrayColores: [],
            arrayTallas: [],
            colorSeleccionado: "",
            tallaSeleccionado: ""
        }
    }

    agregar = e => {
        e.preventDefault();
        const { producto, agregarAlTicket, cerrar } = this.props;
        const { colorSeleccionado, tallaSeleccionado } = this.state;
        if (producto.variaciones !== null && producto.variaciones !== undefined) {
            if (colorSeleccionado !== "" && tallaSeleccionado !== "") {
                let icodFinal = "";
                producto.variaciones.forEach(
                    variacion => {
                        if (colorSeleccionado === variacion.color && tallaSeleccionado === variacion.talla) {
                            icodFinal = variacion.icod;
                        }
                    }
                );
                if (icodFinal !== "") {
                    agregarAlTicket(icodFinal, cerrar);
                    cerrar();
                } else {
                    this.mostrarAlert("warning", "El producto no existe");
                }
            } else {
                this.mostrarAlert("warning", "Selecciona color y talla");
            }
        } else {
            agregarAlTicket(producto.icod, cerrar);
            cerrar();
        }
        Mixpanel.busquedaSidebar(localStorage.getItem('rfc'));
    }

    componentDidMount() {
        let arrayTallas = [];
        let arrayColores = [];
        const { producto } = this.props;
        if (producto.variaciones !== null && producto.variaciones !== undefined) {
            producto.variaciones.forEach(
                variacion => {
                    let dentro=false;
                    if (arrayTallas.length > 0) {
                        arrayTallas.forEach(
                            talla => {
                                if (talla === variacion.talla) dentro = true;
                            }
                        );
                        if (!dentro) arrayTallas.push(variacion.talla);
                    } else {
                        arrayTallas.push(variacion.talla);
                    }

                    if (arrayColores.length > 0) {
                        dentro=false;
                        arrayColores.forEach(
                            color => {
                                if (color === variacion.color) dentro = true;
                            }
                        );
                        if (!dentro) arrayColores.push(variacion.color);
                    } else {
                        arrayColores.push(variacion.color);
                    }

                }
            )
        } else {
            arrayTallas.push(producto.talla);
            arrayColores.push(producto.color);
        }
        this.setState({ arrayTallas, arrayColores });
    }

    getCodigo = () => {
        const { producto } = this.props;
        const { colorSeleccionado, tallaSeleccionado } = this.state;
        if (producto.variaciones !== null && producto.variaciones !== undefined && colorSeleccionado !== "" && tallaSeleccionado !== "") {
            let tempcodigo = "";
            producto.variaciones.forEach(
                (variacion) => {
                    if (colorSeleccionado === variacion.color && tallaSeleccionado === variacion.talla) {
                        tempcodigo = variacion.icod
                    }
                }
            );
            return (
                <div className="">
                    <p className='u-color-white'>{tempcodigo !== "" ? tempcodigo : "Producto no encontrado"}</p>
                </div>
            );
        } else {
            return (
                <div className="">
                    <p className='u-color-white'>{producto.icod}</p>
                </div>
            );
        }
    }

    mostrarAlert = (tipoAlert, mensajeAlert) => {
        this.setState({ mensajeAlert, tipoAlert }, () => this.setState({ mostrarAlert: true },()=>setTimeout(()=>this.setState({mostrarAlert:false}),5000)));
    }

    render() {
        const { cerrar, producto } = this.props;
        const { tipoAlert, mensajeAlert, mostrarAlert, arrayTallas, arrayColores } = this.state;
        return (
            <React.Fragment>
                <form onSubmit={this.agregar} onKeyDown={event => { if (event.key === "Enter") { this.agregar(event); } }}>
                    <div className='' role='document'>
                        <div className='c-modal__content-2'>
                            <div className='c-modal__header'>
                                <h3 className='c-modal__title'>
                                    Agregar Producto a Ticket
                                    </h3>
                                <span className="c-modal__close" data-dismiss="modal" aria-label="Close">
                                    <i onClick={cerrar} className="far fa-times"></i>
                                </span>
                            </div>
                            <div style={{ display: 'flex', justifyContent: 'center', position:"absolute", left:"35%" }}>
                                    <Alerts
                                        type={tipoAlert}
                                        value={mensajeAlert}
                                        show={mostrarAlert}
                                        closeRequest={() => this.setState({ mostrarAlert: false })}
                                    />
                                </div>
                            <div className='c-modal__body'>
                                
                                <div className='c-project-card-o'>
                                    <div className=' o-card'>
                                        <div className='o-media__img c-task'>
                                            <img src={producto.imagen} onError={(e) => { e.target.src = "/img/image_not_found.png" }} alt='' />
                                        </div>
                                        <div className='o-media__body u-p-medium'>
                                            <div className='o-line u-align-items-start'>
                                                <h3 className='u-mb-medium u-color-white'>{producto.nombre}</h3>
                                            </div>
                                            <span className='c-project-card__meta'>
                                                <h6 className='u-color-my-primary'>Precio</h6>
                                                <p className='u-color-white'>{new Intl.NumberFormat('es-MX', { style: 'currency', currency: 'MXN' }).format(producto.precio)}</p>
                                            </span>
                                            <div className="c-project-card__meta u-mt-small">
                                                <h6 className='u-color-my-primary'>Descuento</h6>
                                                <p className='u-color-white'>{producto.descuento ? producto.descuento : "0"}%</p>
                                            </div>
                                            <span className='c-project-card__meta u-mt-small'>
                                                <p className='u-color-my-primary'>Código</p>
                                                {this.getCodigo()}
                                            </span>
                                        </div>
                                        <div className='align-c-field u-pt-auto'>
                                            <label className="c-field__label" htmlFor="select1">Talla</label>
                                            <div className='c-sizes_color'>
                                                <div className='field-select-1'>
                                                    <span className='c-selected'> 
                                                        <i className='c-selected-icon far fa-angle-down'></i>
                                                        <select onChange={e => this.setState({ tallaSeleccionado: e.target.value })}>
                                                            <option value="">Selecciona una talla</option>
                                                            {
                                                                arrayTallas.map(
                                                                    (talla, index) => {
                                                                        return <option key={index} value={talla}>{talla}</option>
                                                                    }
                                                                )
                                                            }
                                                        </select>
                                                    </span>
                                                </div>
                                            </div>
                                            <label className="c-field__label" htmlFor="select1">Color</label>
                                            <div className='c-sizes_color '>
                                                <div className='field-select'>
                                                    <span className='c-selected'>
                                                        <i className='c-selected-icon far fa-angle-down'></i>
                                                        <select onChange={e => this.setState({ colorSeleccionado: e.target.value })}>
                                                            <option value="">Selecciona un color</option>
                                                            {
                                                                arrayColores.map(
                                                                    (color, index) => {
                                                                        return <option key={index} value={color}>{color}</option>
                                                                    }
                                                                )
                                                            }
                                                        </select>
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                            <div className='c-modal__footer-1'>
                                <p>Agregar Producto a Ticket</p>
                                <div className='p-space'>
                                    <button type="submit" className="c-btn " >Agregar Producto</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
            </React.Fragment>
        );
    }
}
ProductoHijo.proptypes={
    addMonedero:proptypes.func,
    changeActivoHijo:proptypes.func,
    agregarAlTicket:proptypes.func,
    cerrar:proptypes.func,
    producto:proptypes.shape({
        imagen:proptypes.string,
        nombre:proptypes.string,
        icod:proptypes.string,
        precio:proptypes.number,
        talla:proptypes.string,
        color:proptypes.string,
        descuento:proptypes.number,
        variaciones:proptypes.shape({
                icod:proptypes.string,
                color:proptypes.string,
                talla:proptypes.string
        })
    })
}
export default ProductoHijo;
/*
={addMonedero} 
={changeActivoHijo} 
producto={producto} 
={agregarAlTicket} 
={() => this.setState({ showModal: false }, () => changeActivoHijo(false))}
||===================================================================
||                          COMPONENTE:
||===================================================================
||                          DESCRIPCIÓN
||
||
||
||
||
||
||
||
||
||
||===================================================================
||                             PROPS
||
||
||
||
||
||
||
||
||
||
||===================================================================
||                             STATE
||
||
||
||
||
||
||
||
||
||
||===================================================================
||                             FUNCIONES
||
||
||
||
||
||
||
||
||
||
||===================================================================
||                             OTROS
||
||
||
||
||
||
||
||
||
||
||===================================================================
*/