import React, { useState, useEffect } from 'react';
import Alerts from '../../../../constants/Alerts';
import { SeleccionarTienda } from './SeleccionarTienda';
import { SeleccionarDireccion } from './SeleccionarDireccion';
import { CrearPedido } from './CrearPedido';
import { SeleccionarTransporte } from './SeleccionarTransporte';

export const CobroOmni = ({ cerrar, client, agente, items, ticket, cliente, productos, cancelar, location }) => {
    const [tipoAlert, setTipoAlert] = useState('');
    const [mensajeAlert, setMensajeAlert] = useState('');
    const [mostrarAlert, setMostrarAlert] = useState(false);
    const [tiendaSelecta, setTiendaSelecta] = useState('');
    const [showMenuPrincipal, setShowMenuPrincipal] = useState(true);
    const [direccion, setDireccion] = useState(undefined);
    const [transporte, setTransporte] = useState(undefined);
    const [costoTransporte, setCostoTransporte] = useState(0);
    const [seleccion, setSeleccion] = useState('tienda');
    const [cp, setCp] = useState('');
    const [buscar, setBuscar] = useState(false);
    const [loadingImg, setLoadingImg] = useState(true);
    const [image, setImage] = useState('');
    const [telefono, setTelefono] = useState('');
    const [channel, setChannel] = useState('');

    useEffect(()=>{
        const phone = localStorage.getItem('phone');
        const channel = localStorage.getItem('channel');
        if (phone) {
            setTelefono(phone);
            setChannel(channel);
        }
    },[]);

    const seleccionar = (id) => {
        setTiendaSelecta(id);
        setShowMenuPrincipal(false);
    }

    const selectTransporte = (id, costo) => {
        setTransporte(id);
        setCostoTransporte(costo);
    }

    const selectDireccion = (id, cpDir) => {
        if (cpDir === cp) {
            setDireccion(id);
            if (transporte) {
                setShowMenuPrincipal(false);
            } else {
                handleAlert('warning', 'Seleccione Transporte.');
            }
        } else {
            handleAlert('warning', 'El código postal de la busqueda de transporte debe ser el mismo que de la dirección. Se realizará la busqueda del cp de la dirección seleccione la nueva opción de transporte.');
            setTransporte('');
            setCostoTransporte(0);
            setCp(cpDir);
            setBuscar(!buscar);
        }
    }

    const handleAlert = (tipo, mensaje) => {
        setTipoAlert(tipo);
        setMensajeAlert(mensaje);
        setMostrarAlert(true);
        setTimeout(() => {
            setMostrarAlert(false);
        }, 5000);
    }

    if (showMenuPrincipal) {
        return (
            <div className='c-modal--huge ' role="dialog" >
                <div className="c-modal__dialog modal-dialog" role="document">
                    <div className='c-modal__content modal-content' >
                        <div className='c-modal__header'>
                            <h3 className='c-modal__title'>Finalizar Pedido</h3>
                            <span className="c-modal__close" data-dismiss="modal" aria-label="Close">
                                <i onClick={cerrar} className="far fa-times"></i>
                            </span>
                        </div>
                        <div style={{ display: 'flex', justifyContent: 'center', position: "absolute", zIndex: 900, width: '100%' }}>
                            <Alerts
                                type={tipoAlert}
                                value={mensajeAlert}
                                show={mostrarAlert}
                                closeRequest={() => setMostrarAlert(false)}
                            />
                        </div>
                        <div className='c-modal__cobro'>
                            <div className="ticket-modal-cobro">
                                <table className='a-table u-border-zero'>
                                    <tbody>
                                        <tr className='c-table__row-original'>
                                            <td className='c-table__cell-original u-color-b-titles'>Cantidad</td>
                                            <td className='c-align_right-money'>1 pza.</td>
                                        </tr>
                                        <tr className='c-table__row-original'>
                                            <td className='c-table__cell-original u-color-b-titles'>Descuento</td>
                                            <td className='c-align_right-money'>{new Intl.NumberFormat('es-MX', { style: 'currency', currency: 'MXN' }).format(ticket.descuento)}</td>
                                        </tr>
                                        <tr className='c-table__row-original'>
                                            <td className='c-table__cell-original u-color-b-titles'>Impuestos</td>
                                            <td className='c-align_right-money'>{new Intl.NumberFormat('es-MX', { style: 'currency', currency: 'MXN' }).format(ticket.iva)}</td>
                                        </tr>
                                        <tr className='c-table__row-original'>
                                            <td className='c-table__cell-original u-color-b-titles'>Subtotal</td>
                                            <td className='c-align_right-money'>{new Intl.NumberFormat('es-MX', { style: 'currency', currency: 'MXN' }).format(ticket.subtotal)}</td>
                                        </tr>
                                        <tr className='c-table__row-original'>
                                            <td className='c-table__cell-original u-color-b-titles'>Envío</td>
                                            <td className='c-align_right-money'>{new Intl.NumberFormat('es-MX', { style: 'currency', currency: 'MXN' }).format(costoTransporte)}</td>
                                        </tr>
                                        <tr className='c-table__row-original'>
                                            <td className='c-table__cell-original'> <h5 className='u-color-b-titles' style={{ margin: 0 }}>Total</h5></td>
                                            <td className='c-align_right-money'> <h6 className='u-color-white' style={{ margin: 0 }}>{new Intl.NumberFormat('es-MX', { style: 'currency', currency: 'MXN' }).format(ticket.total + costoTransporte)}</h6></td>
                                        </tr>
                                        <tr className='c-table__row-original'>
                                            <td className='c-table__cell-original'> </td>
                                            <td className='c-table__cell-original'></td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <div className="content-container-modal-omni">
                                <div>
                                    <label className="label-input" style={{ margin: 0, fontSize: '1rem' }}>Ubicación</label>
                                    <div style={{ display: 'flex', marginTop: '10px' }}>
                                        <input className="c-input" style={{ height: '40px', width: '250px', }} type="text" placeholder="86690" value={cp} onChange={({ target }) => setCp(target.value)} />
                                        <button className="c-btn" style={{ height: '40px', width: '150px', marginLeft: '10px' }} onClick={() => setBuscar(!buscar)}>Buscar</button>
                                    </div>
                                </div>
                                <div style={{ marginTop: '10px' }}>
                                    <div className='pestañas-pagos-omni'>
                                        <div style={{ textAlign: 'center' }} className={`pestaña-omni primer-pestaña-omni ${seleccion === 'tienda' && 'pestaña-omni-active'}`} onClick={() => { setSeleccion('tienda'); setTransporte(''); setCostoTransporte(0); }}>
                                            Tienda
                                            </div>
                                        <div style={{ textAlign: 'center' }} className={`pestaña-omni ${seleccion === 'domicilio' && 'pestaña-omni-active'}`} onClick={() => { setSeleccion('domicilio'); setTiendaSelecta(''); }}>
                                            Domicilio
                                            </div>
                                    </div>
                                    <div className='pestañas-content'>
                                        {
                                            seleccion === 'tienda'
                                                ?
                                                <div>
                                                    <SeleccionarTienda
                                                        client={client}
                                                        agente={agente}
                                                        items={items}
                                                        cp={cp}
                                                        selectTienda={seleccionar}
                                                        buscar={buscar}
                                                        showAlert={handleAlert}
                                                    />
                                                </div>
                                                :
                                                <div style={{ display: 'flex' }}>
                                                    <SeleccionarTransporte
                                                        client={client}
                                                        cliente={cliente}
                                                        agente={agente}
                                                        items={items}
                                                        selectTransporte={selectTransporte}
                                                        cp={cp}
                                                        buscar={buscar}
                                                        showAlert={handleAlert}
                                                        transporteSelecto={transporte}
                                                    />
                                                    <SeleccionarDireccion
                                                        client={client}
                                                        cliente={cliente}
                                                        selectDireccion={selectDireccion}
                                                    />
                                                </div>
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="c-modal__footer-1">
                    </div>
                </div>
            </div>
        )
    }

    return (
        <div className='c-modal--huge ' role="dialog" >
            <div className="c-modal__dialog modal-dialog" role="document">
                <div className='c-modal__content modal-content' >
                    <div className='c-modal__header'>
                        <h3 className='c-modal__title'>Finalizar Pedido</h3>
                        <span className="c-modal__close" data-dismiss="modal" aria-label="Close">
                            <i onClick={cerrar} className="far fa-times"></i>
                        </span>
                    </div>
                    <div style={{ display: 'flex', justifyContent: 'center', position: "absolute", zIndex: 900, width: '100%' }}>
                        <Alerts
                            type={tipoAlert}
                            value={mensajeAlert}
                            show={mostrarAlert}
                            closeRequest={() => setMostrarAlert(false)}
                        />
                    </div>
                    <div className='c-modal__cobro'>
                        <div className="ticket-modal-cobro">
                            <table className='a-table u-border-zero'>
                                <tbody>
                                    <tr className='c-table__row-original'>
                                        <td className='c-table__cell-original u-color-b-titles'>Cantidad</td>
                                        <td className='c-align_right-money'>1 pza.</td>
                                    </tr>
                                    <tr className='c-table__row-original'>
                                        <td className='c-table__cell-original u-color-b-titles'>Descuento</td>
                                        <td className='c-align_right-money'>{new Intl.NumberFormat('es-MX', { style: 'currency', currency: 'MXN' }).format(ticket.descuento)}</td>
                                    </tr>
                                    <tr className='c-table__row-original'>
                                        <td className='c-table__cell-original u-color-b-titles'>Impuestos</td>
                                        <td className='c-align_right-money'>{new Intl.NumberFormat('es-MX', { style: 'currency', currency: 'MXN' }).format(ticket.iva)}</td>
                                    </tr>
                                    <tr className='c-table__row-original'>
                                        <td className='c-table__cell-original u-color-b-titles'>Subtotal</td>
                                        <td className='c-align_right-money'>{new Intl.NumberFormat('es-MX', { style: 'currency', currency: 'MXN' }).format(ticket.subtotal)}</td>
                                    </tr>
                                    <tr className='c-table__row-original'>
                                        <td className='c-table__cell-original u-color-b-titles'>Envío</td>
                                        <td className='c-align_right-money'>{new Intl.NumberFormat('es-MX', { style: 'currency', currency: 'MXN' }).format(costoTransporte)}</td>
                                    </tr>
                                    <tr className='c-table__row-original'>
                                        <td className='c-table__cell-original'> <h5 className='u-color-b-titles' style={{ margin: 0 }}>Total</h5></td>
                                        <td className='c-align_right-money'> <h6 className='u-color-white' style={{ margin: 0 }}>{new Intl.NumberFormat('es-MX', { style: 'currency', currency: 'MXN' }).format(ticket.total + costoTransporte)}</h6></td>
                                    </tr>
                                    <tr className='c-table__row-original'>
                                        <td className='c-table__cell-original'> </td>
                                        <td className='c-table__cell-original'></td>
                                    </tr>
                                </tbody>
                            </table>
                            {
                                loadingImg
                                    ?
                                    <h1>Cargando cotizacion...</h1>
                                    :
                                    <div style={{ display: 'flex', justifyContent: 'space-around', alignItems: 'center' }}>
                                        <img src={image} alt="cotizacion" style={{ maxHeight: '50px' }} />
                                    </div>
                            }
                        </div>
                        <div className="content-container-modal-omni">
                            <CrearPedido
                                setLoadingImg={setLoadingImg}
                                setImage={setImage}
                                tiendaEntrega={tiendaSelecta}
                                cliente={cliente}
                                descuento={ticket.descuento}
                                agente={agente}
                                items={items}
                                client={client}
                                monto={ticket.total + costoTransporte}
                                subtotal={ticket.subtotal}
                                products={productos}
                                impuestos={ticket.iva}
                                domicilio={direccion}
                                transporte={transporte}
                                costoTransporte={costoTransporte}
                                seleccion={seleccion}
                                cerrarPedido={cancelar}
                                cerrarModal={cerrar}
                                handleAlert={handleAlert}
                                telefono={telefono}
                                channel={channel}
                                location={location}
                            />
                        </div>
                    </div>
                </div>
                <div className="c-modal__footer-1">
                </div>
            </div>
        </div>
    );

};