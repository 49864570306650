import React from 'react';
import ReactDOM from 'react-dom';

import { unregister } from './registerServiceWorker';

import { BrowserRouter as Router } from 'react-router-dom';

import { ApolloProvider } from 'react-apollo';
import { ApolloClient } from 'apollo-client';
import { createHttpLink } from 'apollo-link-http';
import { setContext } from 'apollo-link-context';
import { InMemoryCache } from 'apollo-cache-inmemory';
import auth from './constants/Auth';


import App from './components/App';

const httpLink = createHttpLink({
  uri: process.env.REACT_APP_BACKEND_URL
});

const authLink = setContext(async (_, { headers }) => {
  if (!auth.estaVigenteToken() && auth.hayToken()) {
    await auth.getToken();
  }
  return {
    headers: {
      ...headers,
      Authorization: `Bearer ${auth.getAuthorization()}`,
      ConexionAuth: auth.getConexionAuth()
    }
  }
});



const client = new ApolloClient({
  link: authLink.concat(httpLink),
  cache: new InMemoryCache()
});


ReactDOM.render(<ApolloProvider client={client}><Router><App /></Router></ApolloProvider>, document.getElementById('root'));
unregister();
