import React from 'react';
import proptypes from 'prop-types';

function Barra({ principal, backgroundColor, backgroundImage, maximo, valor }) {
    return (
        <div className='contenedor-barra'>
            <div className='barra' style={{ height: `${(valor * 100) / maximo}%`, backgroundImage, backgroundColor, width: `${principal ? '18px' : '16px'}` }}>
            </div>
        </div>
    );
}

Barra.proptypes={ 
    principal:proptypes.bool, 
    backgroundColor:proptypes.string, 
    backgroundImage:proptypes.string, 
    maximo:proptypes.number, 
    valor:proptypes.number
}

export default Barra;