import React, { Component } from 'react';
import gql from 'graphql-tag';
import Alerts from '../../../constants/Alerts';
import proptypes from 'prop-types';
const SerialPort = typeof window.require === 'function' ? window.require('serialport') : null;

const GET_MONEDERO_QUERY = gql`
query($id:String!){
    getMonedero(id:$id){
        saldo,
        fecha_exp
    }
}
`;
class LeerMonedero extends Component {
    constructor(props) {
        super(props);
        this.state = {
            monedero: "",
            monto: 0,
            monederoCorrecto: false,
            disableCantidad: false,
            mensajeAlert: "",
            mostrarAlert: false,
            tipoAlert: ""
        }
    }

    componentDidMount() {
        if (SerialPort !== null) {
            this.port.on('data', data=>{
                let datos = '';
                datos += data.toString();
                datos = datos.substring(datos.indexOf('%') + 1, datos.indexOf('?') - 1);
                if (datos.includes('A010049939')) {
                    //this.getClienteTarjeta('0012893789064');
                } else {
                    this.setState(
                        {monedero:'A1'},
                        this.getMonedero
                        );
                }
            });
        }
        let { monto } = this.props;
        if (monto !== 0) {
            const { monedero } = this.props;
            if (monto < 0) monto = parseFloat(monto) * -1
            this.setState({ monto, disableCantidad: true, monedero });
        }
    }

    sub = e => {
        e.preventDefault();
        const { monedero, monto } = this.state;
        const { agregarAlMonedero, cerrar } = this.props;
        agregarAlMonedero(monedero, monto, cerrar);
    }

    getMonedero = e => {
        if(e) e.preventDefault();
        const { client } = this.props;
        const { monedero } = this.state;
        client.query({
            query: GET_MONEDERO_QUERY,
            variables: {
                id: monedero
            }
        }).then(
            result => {
                this.setState({ monederoCorrecto: true });
            }
        ).catch(err => {
            if (err.message === 'GraphQL error: 404') {
                this.setState({ tipoAlert: 'warning', mensajeAlert: 'Monedero no encontrado' },
                    () => this.setState({ mostrarAlert: true })
                );
            } else {
                this.setState({ tipoAlert: 'danger', mensajeAlert: err.message },
                    () => this.setState({ mostrarAlert: true },()=>setTimeout(()=>this.setState({mostrarAlert:false}),5000))
                );
            }
        });
    }

    render() {
        const { cerrar } = this.props;
        const { tipoAlert, mensajeAlert, mostrarAlert, monedero, monederoCorrecto, disableCantidad, monto  } = this.state;
        return (
            <React.Fragment>
                <div className='c-modal__header'>
                    <h3 className="c-modal__title">Monedero</h3>

                    <span className="c-modal__close" data-dismiss="modal" aria-label="Close">
                        <i onClick={cerrar} className="far fa-times"></i>
                    </span>
                </div>
                <div style={{ display: 'flex', justifyContent: 'center', position:"absolute", width:"100%" }}>
                    <Alerts
                        type={tipoAlert}
                        value={mensajeAlert}
                        show={mostrarAlert}
                        closeRequest={() => this.setState({ mostrarAlert: false })}
                    />
                </div>
                <div className='c-modal__body'>
                    <form onSubmit={this.sub}>
                        <div className='c-field u-mb-xsmall'>
                            <label className='c-field__label'>Monedero</label>
                            <div style={{ display: "flex", justifyContent: "space-between", height: "38px" }}>
                                <input autoFocus={true} type='text' className='c-input' id='card' value={monedero} onKeyDown={e => { if (e.key === "Tab") this.getMonedero(e) }} onChange={e => this.setState({ monedero: e.target.value })} placeholder="**** **** **** *352" disabled={monederoCorrecto} style={{ border: `${monederoCorrecto ? "1px solid #39b54a" : ""}`, width: "70%" }} />
                                <button className="c-btn" onClick={this.getMonedero} disabled={monederoCorrecto} style={{ width: "20%" }} >Validar</button>
                            </div>
                        </div>

                        <div className='c-field u-mb-xsmall'>
                            <label className='c-field__label' for=''>Cantidad</label>
                            <input type='text' className='c-input' id='' placeholder='Cantidad' value={monto} onChange={e => { if (!isNaN(e.target.value)) { this.setState({ monto: e.target.value }) } else { e.target.value = monto } }} disabled={disableCantidad} />

                        </div>
                        <div className='container-submit_monedero'>
                            <button className="c-btn c-btn--info" disabled={!monederoCorrecto}>Aceptar</button>
                        </div>
                    </form>
                </div>
            </React.Fragment>
        );
    }
}

LeerMonedero.proptypes={
    monto:proptypes.number,
    agregarAlMonedero:proptypes.func,
    cerrar:proptypes.func
}

export default LeerMonedero;
/*
||===================================================================
||                          COMPONENTE:
||===================================================================
||                          DESCRIPCIÓN
||
||
||
||
||
||
||
||
||
||
||===================================================================
||                             PROPS
||
||
||
||
||
||
||
||
||
||
||===================================================================
||                             STATE
||
||
||
||
||
||
||
||
||
||
||===================================================================
||                             FUNCIONES
||
||
||
||
||
||
||
||
||
||
||===================================================================
||                             OTROS
||
||
||
||
||
||
||
||
||
||
||===================================================================
*/
