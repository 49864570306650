import React from 'react';
import auth from '../../constants/Auth';

function Header() {
  const nombre = localStorage.getItem('nombre') ? localStorage.getItem('nombre') : '  ';
  return (
    <React.Fragment>
      <header className='c-navbar' style={{ display: `${window.location.pathname === '/login' ? 'none' : ''}` }}>
        <a className='c-navbar__brand u-mr-auto' >
          <img src='img/big-logo.png' alt='' />
        </a>
        <div className='dropdownCerrarSesion'>
          <div className='circle'>{nombre[0] + nombre[1]}</div>
          <div className='dropdownCerrarSesion-contenido' align='center'>
            <a className='c-dropdown__item dropdown-item' onClick={() =>{auth.logout(); localStorage.clear();}} href='/'><i className='fas fa-sign-out-alt'></i> Cerrar Sesión</a>
          </div>
        </div>
      </header>
    </React.Fragment>
  );
}

export default Header;
/*
||===================================================================
||                      COMPONENTE: Header
||===================================================================
||                          DESCRIPCIÓN
|| Header donde aparece iniciales del usuario y se encuentra la
|| opción de inicio de sesión.
||===================================================================
*/