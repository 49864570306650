import React, { Component } from 'react';
import gql from 'graphql-tag';
import Alerts from '../../../constants/Alerts';
import proptypes from 'prop-types';
//import { getPagos } from '../../../constants/Util';
import Pago from './Pago';
import { ClimbingBoxLoader } from 'react-spinners';
const GET_VENTAS_QUERY = gql`
query($tienda:String! ){
    getVentas(tienda:$tienda){
      pagos{
        monto,
        tipo,
        tipo_nombre
      }
    }
  }
`;

class Total extends Component {
    constructor(props) {
        super(props);
        this.state = {
            nota: '',
            ventasTotal: 0,
            tipoAlert: '',
            mensajeAlert: '',
            mostrarAlert: false,
            pagos: [],
            pagosRecibidos: [],
            loading:true
        }
    }

    cortar = () => {
        const { changePagos, changeComentario, mostrar } = this.props;
        const { pagos, pagosRecibidos, nota } = this.state;
        const realPagos=[];
        pagosRecibidos.forEach(
            (pago, index)=>{
                if(pago!==0){
                    let tempObj={};
                    const currentPago=pagos[index];
                    tempObj.tipo=currentPago.tipo;
                    tempObj.id=currentPago.id;
                    tempObj.monto=pago;
                    realPagos.push(tempObj);
                }
            }
        )
        changePagos(realPagos, () => changeComentario(nota, () => mostrar(true)));
    }

    componentWillUnmount(){
        const { cambiarTotal, changePagos } = this.props;
        cambiarTotal(0);
        changePagos([]);
    }

    componentDidMount() {
        const { client } = this.props;
        client.query({
            variables: {
                tienda: localStorage.getItem('tienda')
            },
            query: GET_VENTAS_QUERY
        }).then(
            result => {
                const { pagos } = result.data.getVentas[0];
                let ventasTotal = 0;
                let pagosRecibidos=[];
                pagos.forEach(
                    pago => {
                        pagosRecibidos.push(0);
                        ventasTotal += pago.monto;
                    }
                );
                this.setState({
                    loading:false,
                    pagosRecibidos,
                    pagos,
                    ventasTotal
                });
            }
        ).catch(err => this.mostrarAlert('danger', 'Error cargando ventas del día: ' + err.message))
    }

    mostrarAlert = (tipoAlert, mensajeAlert) => {
        this.setState({ mensajeAlert, tipoAlert }, () => this.setState({ mostrarAlert: true }, () => setTimeout(() => this.setState({ mostrarAlert: false }), 5000)));
    }

    setCantidad = (index, cantidad) => {
        let { pagosRecibidos } = this.state;
        pagosRecibidos[index]=parseFloat(cantidad);
        this.setState({pagosRecibidos});
    }

    getPagosRecibidos = () => {
        const { pagosRecibidos } = this.state;
        let total = 0;
        pagosRecibidos.map(
            pago => total += pago
        )
        return total;
    }

    render() {
        const { children } = this.props;
        const { tipoAlert, mensajeAlert, mostrarAlert,/* ventasEfectivo, cantidadEfectivo, ventasTarjeta, cantidadTarjeta, ventasPuntos, cantidadPuntos, */ventasTotal, nota, pagos, loading } = this.state;
        return (
            <React.Fragment>
                <div style={{ display: 'flex', justifyContent: 'center', position: 'absolute', top: '53px', width: '100vh' }}>
                    <Alerts
                        type={tipoAlert}
                        value={mensajeAlert}
                        show={mostrarAlert}
                        closeRequest={() => this.setState({ mostrarAlert: false })}
                    />
                </div>
                <div className='c-corte-container'>
                    {
                        children
                    }
                    <div style={{ display: 'flex', width: '100%', marginBottom: '30px', minHeight:'323px', backgroundColor: '#273142' }}>
                        {
                            loading
                            ?
                            <div style={{ width: '614px', backgroundColor: '#273142', display:'flex', justifyContent:'center', alignItems:'center' }}>
                                <ClimbingBoxLoader color='#36D7B7' />
                            </div>
                            :
                            <div style={{ width: '614px', backgroundColor: '#273142' }}>
                            {
                                pagos.map(
                                    (pago, index) => {
                                        const props = {
                                            ...pago,
                                            index,
                                            setCantidad:this.setCantidad
                                        };
                                        return (<Pago {...props} key={index} />)
                                    }
                                )
                            }
                            <div className='c-stuffing'>
                                <ul className='head__list'>
                                    <li className='head__title'><h2 className='head__title-method'>Total</h2></li>
                                    <li className='head__title'>
                                        <h2 className='head__title-total '>
                                            {new Intl.NumberFormat('es-MX', { style: 'currency', currency: 'MXN' }).format(ventasTotal)}
                                        </h2>
                                    </li>
                                    <li className='head__title'>
                                        <h2 className={`head__title-warn u-block ${parseFloat(ventasTotal) === this.getPagosRecibidos() ? 'u-color-successful' : 'u-text-danger'}`}>
                                            {
                                                new Intl.NumberFormat('es-MX', { style: 'currency', currency: 'MXN' }).format(this.getPagosRecibidos())
                                            }
                                        </h2>
                                    </li>
                                    <li className='head__title'>
                                        <h4 className='head__title-gap u-block u-color-succes'>
                                        </h4>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        }
                        <div className='parcial-note'>
                            <div className='c-field'>
                                <label className='c-field__label'>Nota</label>
                                <textarea autoComplete='off' onChange={e => this.setState({ nota: e.target.value })} value={nota} placeholder='Escribe aquí una nota' className='c-input c-note' id='textarea2'>
                                </textarea>
                            </div>
                            <div className='u-block' >
                                <button disabled={parseFloat(ventasTotal) === this.getPagosRecibidos() ? false : true} onClick={this.cortar} className='c-btn c-btn--info c-btn--fullwidth'>CONFIRMAR</button>
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

Total.proptypes = {
    changePagos: proptypes.func,
    changeComentario: proptypes.func,
    mostrar: proptypes.func
}

export default Total;
            /*
||===================================================================
||                          COMPONENTE:
||===================================================================
||                          DESCRIPCIÓN
||  Interfaz para realizar el corte de caja parcial.
||===================================================================
||                             PROPS
||  * mostrar: función para mostrar el alert de confirmación para
||  realizar el corte.
||
||  * changeComentario: función para guardar el comentario en el state
||  del contenedor principal
||
||  * changePagos:función para guardar el comentario en el state
||  del contenedor principal
||===================================================================
||                             STATE
||  * cantidadEfectivo: flotante para guardar temporalmente el valor
||  de cantidad en efectivo.
||
||  * cantidadTarjeta: flotante para guardar temporalmente el valor
||  de cantidad en tarjeta.
||
||  * cantidadPuntos: flotante para guardar temporalmente el valor
||  de cantidad en puntos.
||
||  * nota: cadena para guardar temporalmente el valor del comentario
||  del corte.
||
||  *mostrarAlert: boleano que es true cuando se muestra el alert.
||
||  *mensajeAlert: string con el mensaje del alert.
||
||  *tipoAlert: string que debe contener el tipo de alert a mostrar.
||===================================================================
||                             FUNCIONES
||  * cortar: Función donde se formatea el array de pagos y se realiza
||  la mutación de corte de caja.
||
||  * getPagos: obtiene la cantidad de pagos realizado de un tipo en
||  especifico. Recibe la información  del backend como primer param.
||  y un entero que representa el tipo como segundo.
||
||  *mostrarAlert: muestra los alerts de la app.
||===================================================================
*/