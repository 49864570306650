import React, { Component } from 'react';
import { Query } from 'react-apollo';
import ImprimirTicket from '../../FormatosTickets';
import gql from 'graphql-tag';
import proptypes from 'prop-types';
import { formatItems, getPagos } from '../../../constants/Util';
const GET_TICKET_QUERY = gql`
    query($id:String!){
        getTicket(id:$id){
        clave_factura,
        items{
            cantidad,
            descuento,
            producto,
            precio_unitario
        },
        pagos{
            tipo,
            monto,
            autorizacion,
            terminacion,
            order_id
        },
        productos{
            nombre,
            icod,
            precio,
            descuento
            },
        subtotal,
        iva,
        descuento
        }
    }
`;

class ImprimirTicketRealizado extends Component {
    constructor(props){
        super(props);
        this.state={
            tiposPago:{}
        }
    }
    
    componentDidMount() {
        //const { client }= this.props;
        const efectivo=JSON.parse(localStorage.getItem('efectivo'));
        const tarjeta=JSON.parse(localStorage.getItem('tarjeta'));
        const puntos=JSON.parse(localStorage.getItem('puntos'));
        const monedero=JSON.parse(localStorage.getItem('monedero'));
        const otros=JSON.parse(localStorage.getItem('otros'));
        const tiposPago={ efectivo, tarjeta, puntos, monedero, otros };
        this.setState({tiposPago},
            ()=>{
                const { listo } = this.props;
                listo(true);
            });
    }

    render() {
        const { id } = this.props;
        const { tiposPago } = this.state;
        return (
            <React.Fragment>
                <Query query={GET_TICKET_QUERY} variables={{ id }}>
                    {
                        ({ loading, error, data }) => {
                            if (loading) return <React.Fragment><p>Cargando...</p></React.Fragment>
                            if (error) return <React.Fragment><p>Error obteniendo ticket: {error.message}</p></React.Fragment>
                            return (
                                <React.Fragment>
                                    <ImprimirTicket
                                        datos={{
                                            ticket: {
                                                cambio:'0',
                                                fecha: new Date(data.getTicket.fecha),
                                                id,
                                                clave_factura: data.getTicket.clave_factura,
                                                descuento: data.getTicket.descuento,
                                                iva: data.getTicket.iva,
                                                ieps: 0,
                                                subtotal: data.getTicket.subtotal
                                            },
                                            pagos: {
                                                efectivo: tiposPago.efectivo ? getPagos(data.getTicket.pagos, tiposPago.efectivo.tipo) : 0,//efectivo: this.state.cantidadEfectivo,
                                                monedero:tiposPago.monedero&&getPagos(data.getTicket.pagos, tiposPago.monedero.tipo)>0?[{
                                                    "terminacion":"****",
                                                    "caducidad":"**/**",
                                                    "monto":getPagos(data.getTicket.pagos, tiposPago.monedero.tipo),
                                                    "saldo_anterior":0
                                                }]:[],//monedero: this.state.pagosMonedero,
                                                puntos: {
                                                    monto: tiposPago.puntos ? getPagos(data.getTicket.pagos, tiposPago.puntos.tipo) : 0,
                                                    saldo_anterior: 0
                                                },
                                                tarjeta:tiposPago.tarjeta&&getPagos(data.getTicket.pagos, tiposPago.tarjeta[0].tipo)>0?[{
                                                    "terminacion":"****",
                                                    "autorizacion":"******",
                                                    'banco':'********',
                                                    "monto":getPagos(data.getTicket.pagos, tiposPago.tarjeta[0].tipo)
                                                }]:[]//tarjeta: this.state.pagosTarjeta
                                            },
                                            items: formatItems(data.getTicket.items),
                                            productos: data.getTicket.productos,
                                            cliente: data.getTicket.cliente,
                                            agente: data.getTicket.agente
                                        }}
                                        tipo={parseInt(localStorage.getItem('ticketTipo'),10)}
                                    />
                                </React.Fragment>
                            );
                        }
                    }
                </Query>
            </React.Fragment>
        );
    }
}

ImprimirTicketRealizado.proptypes={
    listo:proptypes.func,
    id:proptypes.string
}

export default ImprimirTicketRealizado;
/*
||===================================================================
||                COMPONENTE: ImprimirTicketRealizado
||===================================================================
||                          DESCRIPCIÓN
|| Es el componente que se imprime cuando se manda a reimprimir el
|| ticket.
||===================================================================
||                             PROPS
||  * listo: metodo para cambiar el status a listo para imprimir.
||
||  * id: id del ticket a imprimir.
||===================================================================
||                             FUNCIONES
||  * getPagos:
||
||  * formatItems:
||
||  * getPrecio:
||
||  * getItems:
||
||  * objetoTemporalProductoItem:
||
||  * React - componentDidMount:
||===================================================================
||                             OTROS
|| Este componente no es stateless porque se necesita hacer referencia
|| para poder imprimirlo y esta propiedad no está disponible para
|| stateless components.
||===================================================================
*/